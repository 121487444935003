import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Time: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.5523 9.44772 11 10 11H12.5C13.0523 11 13.5 10.5523 13.5 10C13.5 9.44772 13.0523 9 12.5 9H11V6Z'
      fill={color}
    />
  </Icon>
)

Time.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Time }
