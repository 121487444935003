import { Icon } from 'ui/icons'

import type { FC } from 'react'
import type { IconProps } from 'ui/icons'

const WhatsApp: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 9.80697C17.5 13.8423 14.2033 17.1136 10.1364 17.1136C8.8452 17.1136 7.63222 16.7836 6.57685 16.2046L2.5 17.5L3.82892 13.5796C3.15849 12.4783 2.77244 11.1871 2.77244 9.80692C2.77244 5.77132 6.06927 2.5 10.1363 2.5C14.2033 2.50004 17.5 5.77137 17.5 9.80697ZM10.1364 3.6637C6.72232 3.6637 3.94533 6.41967 3.94533 9.80697C3.94533 11.151 4.38346 12.396 5.12455 13.4087L4.35124 15.6899L6.73035 14.9337C7.70802 15.5754 8.87879 15.9499 10.1363 15.9499C13.5499 15.9499 16.3273 13.1942 16.3273 9.80692C16.3273 6.41967 13.55 3.6637 10.1364 3.6637ZM13.8547 11.4896C13.8093 11.415 13.689 11.3701 13.5088 11.2807C13.3281 11.1911 12.4404 10.7577 12.2752 10.6981C12.1095 10.6384 11.9891 10.6084 11.8688 10.7876C11.7486 10.9672 11.4027 11.3701 11.2972 11.4896C11.1919 11.6093 11.0869 11.6242 10.9062 11.5346C10.726 11.445 10.1442 11.256 9.45467 10.6457C8.91814 10.1711 8.55581 9.58503 8.45049 9.40555C8.34517 9.22634 8.43934 9.12947 8.52961 9.04031C8.61085 8.95976 8.71014 8.83099 8.80052 8.72668C8.89078 8.62188 8.92078 8.54736 8.98084 8.42783C9.04132 8.30825 9.01084 8.20377 8.96589 8.11393C8.92078 8.02456 8.55966 7.14307 8.40924 6.78459C8.25881 6.42611 8.1088 6.48585 8.00327 6.48585C7.8979 6.48585 7.77768 6.47085 7.65715 6.47085C7.53678 6.47085 7.34114 6.5158 7.17582 6.69485C7.01044 6.87422 6.54427 7.30734 6.54427 8.18861C6.54427 9.07005 7.19087 9.92154 7.28124 10.041C7.3714 10.1601 8.52956 12.0274 10.3644 12.7448C12.2 13.4615 12.2 13.2227 12.5309 13.1924C12.8615 13.1629 13.5986 12.7595 13.7492 12.3412C13.8996 11.923 13.8996 11.5642 13.8547 11.4896Z'
    />
  </Icon>
)

WhatsApp.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { WhatsApp }
