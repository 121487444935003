import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchCurrentUrl } from 'modules/products/slices/productsFeed'

const useCurrentUrl = () => {
  const dispatch = useAppDispatch()
  const { currentUrl } = useAppSelector(state => state.products.productsFeed)

  useEffect(() => {
    dispatch(fetchCurrentUrl())
  }, [dispatch])

  return { currentUrl }
}

export { useCurrentUrl }
