import * as IconsList from 'ui/icons'
import { Box } from 'ui'

const Icons = () => {
  return (
    <Box ml='24px'>
      {Object.entries(IconsList).map(([name, Icon]) => (
        <Box display='flex' mt='24px'>
          <Icon />
          <Box ml='16px'>{name}</Box>
        </Box>
      ))}
    </Box>
  )
}

export { Icons }
