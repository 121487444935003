import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Promo: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 3C2 2.44772 2.44772 2 3 2H5C5.55228 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4C7.55228 4 8 3.55228 8 3C8 2.44772 8.44772 2 9 2H11C11.5523 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H17C17.5523 2 18 2.44772 18 3V17C18 17.5523 17.5523 18 17 18H15C14.4477 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16C12.4477 16 12 16.4477 12 17C12 17.5523 11.5523 18 11 18H9C8.44772 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 5.55228 18 5 18H3C2.44772 18 2 17.5523 2 17V3ZM4 4V16H4.17071C4.58254 14.8348 5.69378 14 7 14C8.30622 14 9.41746 14.8348 9.82929 16H10.1707C10.5825 14.8348 11.6938 14 13 14C14.3062 14 15.4175 14.8348 15.8293 16H16V4H15.8293C15.4175 5.16519 14.3062 6 13 6C11.6938 6 10.5825 5.16519 10.1707 4H9.82929C9.41746 5.16519 8.30622 6 7 6C5.69378 6 4.58254 5.16519 4.17071 4H4ZM10.8493 7.47199C10.6668 7.17847 10.3456 7 10 7C9.65441 7 9.33329 7.17847 9.15079 7.47199L8.69543 8.20437L7.85817 8.41113C7.52263 8.49399 7.25366 8.74424 7.14686 9.07295C7.04006 9.40166 7.11056 9.76221 7.33332 10.0265L7.88914 10.6859L7.82705 11.546C7.80217 11.8908 7.95706 12.2239 8.23667 12.4271C8.51629 12.6302 8.88098 12.6746 9.20114 12.5444L10 12.2195L10.7989 12.5444C11.1191 12.6746 11.4838 12.6302 11.7634 12.4271C12.043 12.2239 12.1979 11.8908 12.173 11.546L12.1109 10.6859L12.6667 10.0265C12.8895 9.76221 12.96 9.40166 12.8532 9.07295C12.7464 8.74424 12.4774 8.49399 12.1419 8.41113L11.3046 8.20437L10.8493 7.47199Z'
      fill={color}
    />
  </Icon>
)

Promo.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Promo }
