import ReactHighlighter from 'react-highlighter'

import type { FC } from 'react'

interface Props {
  search?: string
}

const Highlighter: FC<Props> = ({ search = '', children }) => (
  <ReactHighlighter search={search.length > 1 && search}>
    {children}
  </ReactHighlighter>
)

export { Highlighter }
