import React from 'react'

import { ErrorPage } from 'ui/icons/Illustrations'
import { LogoIcon } from 'ui/icons'
import { ErrorsWrapper } from 'ui'
import { styled } from 'ui/theme'

interface ErrorBoundaryState {
  hasError: boolean
}

const ErrorBoundaryWrap = styled.div`
  .__logo {
    margin-bottom: 24px;
  }

  .__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    color: #10142d;
    margin: 0;
  }

  .__desc {
    margin: 8px 0;
    font-size: 15px;
    line-height: 24px;
    color: #10142d;
  }
`

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  static getDerivedStateFromError() {
    return {
      hasError: true,
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error) {
    // tslint:disable-next-line:no-console
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryWrap>
          <ErrorsWrapper>
            <div className='__logo'>
              <LogoIcon />
            </div>

            <p className='__title'>Ошибка на странице</p>
            <p className='__desc'>Попробуйте обновить страницу</p>

            <ErrorPage />
          </ErrorsWrapper>
        </ErrorBoundaryWrap>
      )
    }

    return this.props.children
  }
}

export { ErrorBoundary }
