import { useEffect, useRef } from 'react'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { styled } from 'ui/theme'

import { Typography } from 'ui'
import { ChevronIcon } from 'ui/icons'
import { formatRelativeDate } from 'utils/textFormatting'

import { NotificationWrap } from './styles'

import { ActionLinkType, NotificationStatus } from 'modules/notifications/types'
import type { FC } from 'react'
import type {
  Notification,
  NotificationActionLink,
} from 'modules/notifications/types'

const { Body, Caption } = Typography

const BodyWrap = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;
`

interface Props {
  id: string
  body: string
  createdAt: string
  status: NotificationStatus
  onInView?: (id: string) => void
  link?: NotificationActionLink
  onClick: (id: Notification['id']) => void
}

const NotificationItem: FC<Props> = ({
  id,
  body = '',
  createdAt,
  status,
  onInView,
  link,
  onClick,
}) => {
  const history = useHistory()
  const wasSeenRef = useRef(false)
  const { inView, ref } = useInView()

  useEffect(() => {
    if (
      inView === true &&
      status === NotificationStatus.New &&
      !wasSeenRef.current
    ) {
      if (typeof onInView === 'function') {
        wasSeenRef.current = true
        onInView(id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const handleClick = () => {
    if (link?.type === ActionLinkType.OrderPage) {
      history.push(`/orders/${link.target_id}`)
    }

    onClick(status === NotificationStatus.New ? id : null)
  }

  const actionable = Boolean(link)

  return (
    <NotificationWrap
      ref={ref}
      className={cn(actionable && 'actionable')}
      onClick={actionable ? handleClick : null}
    >
      {status === NotificationStatus.New && (
        <div className='__newStatusMarker' />
      )}

      <Caption mb='8px' className='__createdAt'>
        {createdAt && formatRelativeDate(new Date(createdAt))}
      </Caption>

      <BodyWrap>
        <Body>{body}</Body>
        {actionable && <ChevronIcon right />}
      </BodyWrap>
    </NotificationWrap>
  )
}

export { NotificationItem }
