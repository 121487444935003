import { useAppSelector } from 'store/hooks'

import { ORDER_PRODUCT_STATUSES } from 'modules/common/constants/dictionaries'
import { formatDate } from 'utils/textFormatting'

import { Typography, Box, Condition } from 'ui'

import type { FC } from 'react'
import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

const { Subtitle, Body } = Typography

const minWidth = '170px'

const Info: FC = () => {
  const { details, delivery } = useAppSelector(state => state.orders.orders)

  return (
    <Box mb='24px'>
      <Subtitle as='p' mb='16px'>
        Информация о заказе
      </Subtitle>

      <Box>
        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Номер заказа</Body>
          </Box>

          <Body as='p'>{details.id || '—'}</Body>
        </Box>

        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Дата оформления</Body>
          </Box>

          <Body as='p'>
            {details.created_at
              ? formatDate(
                  new Date(details.created_at),
                  'В HH:mm, dd MMM yyyy г.',
                )
              : '—'}
          </Body>
        </Box>

        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Срок подтверждения</Body>
          </Box>

          <Body as='p'>
            {details.max_confirm_time
              ? formatDate(
                  new Date(details.max_confirm_time),
                  'До HH:mm, dd MMM yyyy г.',
                )
              : '—'}
          </Body>
        </Box>

        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Статус</Body>
          </Box>

          <Body as='p'>
            {ORDER_PRODUCT_STATUSES[details.status] || details.status || '—'}
          </Body>
        </Box>

        <Box display='flex'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Неделимость</Body>
          </Box>

          <Body as='p'>
            {Boolean(details.id)
              ? details.payment.dividable
                ? 'Нет'
                : 'Да'
              : '—'}
          </Body>
        </Box>

        <Condition
          match={
            details.status &&
            details.status !== ORDERS_FILTER_STATUS.WAITING_CONFIRMATION &&
            details.status !== ORDERS_FILTER_STATUS.ON_ASSEMBLY &&
            details.status !== ORDERS_FILTER_STATUS.COMPLETED &&
            details.status !== ORDERS_FILTER_STATUS.CANCELED
          }
        >
          <Box display='flex' mt='8px'>
            <Box minWidth={minWidth} mr='49px'>
              <Body as='p'>Стоимость доставки</Body>
            </Box>

            <Body as='p'>
              {delivery.products.length === 0
                ? '—'
                : delivery.totalDeliveryCost + ' ₸'}
            </Body>
          </Box>
        </Condition>
      </Box>
    </Box>
  )
}

export { Info }
