import { FC } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { InstallmentsFilters, InstallmentList } from './components'

import { useInstallments } from 'modules/installments/hooks/useInstallments'
import { useInstallmentCategories } from 'modules/installments/hooks/useInstallmentCategories'
import {
  changeCategoryFilter,
  changeMonthFilter,
} from 'modules/installments/slices/installments'
import { ALL_OPTIONS } from 'modules/common/constants/dictionaries'

const AvailableInstallments: FC = () => {
  const dispatch = useAppDispatch()
  const { installments, installmentsLoading } = useInstallments()
  const { categories } = useInstallmentCategories()

  const { category: selectedCategory, month: selectedMonth } = useAppSelector(
    state => state.installments.installments.filters,
  )

  const handleChangeCategory = (category: string) => {
    dispatch(changeCategoryFilter(category))
  }

  const handleChangeMonth = (month: number) => {
    dispatch(changeMonthFilter(month))
  }

  const isSearch = selectedMonth !== null || selectedCategory !== ALL_OPTIONS

  return (
    <>
      <InstallmentsFilters
        categories={categories}
        selectedCategory={selectedCategory}
        selectedMonth={selectedMonth}
        onChangeCategory={handleChangeCategory}
        onChangeMonth={handleChangeMonth}
      />
      <InstallmentList
        installments={installments}
        isSearch={isSearch}
        isLoading={installmentsLoading}
      />
    </>
  )
}

export { AvailableInstallments }
