import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const MiniSwitchOn: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1667 5.83331H5.83342C3.53341 5.83331 1.66675 7.69998 1.66675 9.99998C1.66675 12.3 3.53341 14.1666 5.83342 14.1666H14.1667C16.4667 14.1666 18.3334 12.3 18.3334 9.99998C18.3334 7.69998 16.4667 5.83331 14.1667 5.83331ZM14.1667 12.5C12.7834 12.5 11.6667 11.3833 11.6667 9.99998C11.6667 8.61665 12.7834 7.49998 14.1667 7.49998C15.5501 7.49998 16.6667 8.61665 16.6667 9.99998C16.6667 11.3833 15.5501 12.5 14.1667 12.5Z'
      fill='#319CF3'
    />
  </Icon>
)

MiniSwitchOn.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { MiniSwitchOn }
