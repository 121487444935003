import { css } from '@emotion/react'
import { styled } from 'ui/theme'

const mini = css`
  min-width: 6px;
  min-height: 6px;
`

const unlim = css`
  position: initial;
  padding: 2px 4px;
  transform: initial;
  margin-left: 4px;
  min-width: 24px;
`

const BadgeWrap = styled.div<{ isMini: boolean; isUnlim: boolean }>`
  position: relative;
  display: flex;

  .__badge {
    user-select: none;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    min-height: 16px;
    padding: 0 3px;
    border-radius: 12px;
    transform: translate(50%, -50%);
    background-color: ${props => props.theme.colors.main.status.error};
    color: ${props => props.theme.colors.text.white};

    ${({ isMini }) => isMini && mini}
    ${({ isUnlim }) => isUnlim && unlim}
  }
`

export { BadgeWrap }
