import { styled } from 'ui/theme'

import type { LinkProps } from 'react-router-dom'

export const Item = styled.span<LinkProps>`
  display: flex;
  text-decoration: none;
  width: 100%;
  padding-left: 24px;
  min-height: 40px;
  align-items: center;
  user-select: none;

  &:hover .title,
  &.active .title {
    background-color: ${props => props.theme.colors.main.background.gray};
  }
`

export const ItemContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  min-width: 40px;
  height: 32px;
  padding-left: 10px;
  border-radius: 12px;
  cursor: pointer;
`

export const ChildrenWrap = styled.ul`
  position: relative;
  padding-left: 24px;

  &::before {
    content: '';
    position: absolute;
    left: 17.5px;
    width: 1px;
    height: calc(100% - 20px);
    background: ${props => props.theme.greyColorPalette[10]};
  }
`
