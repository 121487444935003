import { useAppTheme, styled } from 'ui/theme'
import { useAppSelector } from 'store/hooks'

import { Typography, Anchor, Box } from 'ui'
import {
  InstagramIcon,
  FacebookIcon,
  TelegramIcon,
  LinkedinIcon,
  WhatsAppIcon,
  YoutubeIcon,
  TwitterIcon,
  NewTabIcon,
  TiktokIcon,
  MailruIcon,
  SkypeIcon,
  OKIcon,
  VKIcon,
} from 'ui/icons'

import { env } from 'settings/env'

import { ContactType } from 'modules/common/types'

import type { FC } from 'react'

const { Caption } = Typography

const FooterWrap = styled.footer`
  grid-area: footer;
  border-top: 1px solid ${props => props.theme.greyColorPalette[10]};
  padding: 8px 68px;
  height: 104px;
`

const icons = {
  facebook: FacebookIcon,
  telegram: TelegramIcon,
  instagram: InstagramIcon,
  youtube: YoutubeIcon,
  tiktok: TiktokIcon,
  linkedin: LinkedinIcon,
  twitter: TwitterIcon,
  mailru: MailruIcon,
  ok: OKIcon,
  skype: SkypeIcon,
  vk: VKIcon,
  whatsapp: WhatsAppIcon,
}

const StyledAnchor = styled(Anchor)`
  .icon:hover {
    fill: ${props => props.theme.colors.text.link};
  }
`

interface LinkItemProps {
  name: string
  value: string
  comment: string
}

const LinkItem: FC<LinkItemProps> = ({
  name = '',
  value = '',
  comment = '',
}) => {
  const theme = useAppTheme()

  return (
    <Box as='li' display='flex' flexDirection='column'>
      <Typography
        fontSize='16px'
        fontWeight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.m}
      >
        {name}
      </Typography>
      <Anchor
        href={value}
        target='_blank'
        fontWeight={theme.fontWeights.semibold}
        fontSize='20px'
        lineHeight={theme.lineHeights.l}
      >
        {comment}
      </Anchor>
    </Box>
  )
}

const Footer: FC = () => {
  const theme = useAppTheme()
  const contacts = useAppSelector(state => state.common.contacts.list)

  const links = contacts.filter(contact => contact.type === ContactType.Link)
  const phones = contacts.filter(contact => contact.type === ContactType.Phone)
  const emails = contacts.filter(contact => contact.type === ContactType.Email)
  const socialNetworks = contacts.filter(
    contact => contact.type === ContactType.Social,
  )

  return (
    <FooterWrap>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        height='100%'
      >
        <Box display='flex'>
          <Box
            as='ul'
            display='grid'
            gridAutoFlow='column'
            gridColumnGap='48px'
            justifyContent='start'
            mb='4px'
          >
            {phones.map(phone => (
              <LinkItem
                key={phone?.id}
                name={phone?.name}
                value={phone?.value}
                comment={phone?.comment}
              />
            ))}

            {links.map(link => (
              <LinkItem
                key={link?.id}
                name={link?.name}
                value={link?.value}
                comment={link?.comment}
              />
            ))}

            {emails.map(email => (
              <LinkItem
                key={email?.id}
                name={email?.name}
                value={email?.value}
                comment={email?.comment}
              />
            ))}
          </Box>

          <Box ml='auto'>
            <Typography
              fontSize={theme.fontSizes.body.m}
              fontWeight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.m}
            >
              Мы в социальных сетях
            </Typography>
            <Box
              mt='8px'
              display='grid'
              gridAutoFlow='column'
              gridColumnGap='16px'
            >
              {socialNetworks.map(socialNetwork => {
                const Icon = icons?.[socialNetwork.name]
                return (
                  <StyledAnchor
                    key={socialNetwork?.id}
                    target='_blank'
                    href={socialNetwork?.value}
                  >
                    {Icon && <Icon className='icon' />}
                  </StyledAnchor>
                )
              })}
            </Box>
          </Box>
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='end'>
          <Anchor
            display='flex'
            alignItems='center'
            target='_blank'
            href={env.CUSTOMER_BASE_URL}
            lineHeight={theme.lineHeights.m}
          >
            <Box mr='6px'>Маркетплейс Airba</Box>
            <NewTabIcon color={theme.colors.text.link} />
          </Anchor>

          <Caption weight='semibold' color='text.secondaryBlack'>
            © TOO Airba marketplace {new Date().getFullYear()}
          </Caption>
        </Box>
      </Box>
    </FooterWrap>
  )
}

export { Footer }
