import { css } from '@emotion/react'
import { Theme } from 'ui/theme/src/types'

export const getModalStyles = (theme: Theme) => css`
  .rc-dialog {
    position: relative;
    width: 520px;
  }

  .rc-dialog-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rc-dialog-header {
    margin-bottom: 8px;
  }

  .rc-dialog-title {
    line-height: ${theme.lineHeights.l};
    font-size: ${theme.fontSizes.subtitle.s};
    font-weight: ${theme.fontWeights.semibold};
  }

  .rc-dialog-content {
    position: relative;
    background-color: #ffffff;
    border: none;
    border-radius: ${theme.radii.xs};
    padding: 16px 16px 24px;
    box-shadow: 0 8px 14px rgba(16, 20, 45, 0.05),
      0 3.87387px 9.86002px rgba(16, 20, 45, 0.08);
  }

  .rc-dialog-close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 20px;
    opacity: 0.5;
    background: none;
    border: none;
    padding: 0;
  }

  .rc-dialog-close:hover {
    opacity: 1;
  }

  .rc-dialog-zoom-enter,
  .rc-dialog-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  .rc-dialog-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }

  .rc-dialog-zoom-enter.rc-dialog-zoom-enter-active,
  .rc-dialog-zoom-appear.rc-dialog-zoom-appear-active {
    animation-name: rcDialogZoomIn;
    animation-play-state: running;
  }

  .rc-dialog-zoom-leave.rc-dialog-zoom-leave-active {
    animation-name: rcDialogZoomOut;
    animation-play-state: running;
  }

  @keyframes rcDialogZoomIn {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  @keyframes rcDialogZoomOut {
    0% {
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
  }

  .rc-dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #373737;
    background-color: rgba(55, 55, 55, 0.4);
    height: 100%;
    filter: alpha(opacity=50);
    z-index: 1050;
  }

  .rc-dialog-mask-hidden {
    display: none;
  }
`
