import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Notification: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9713 4.33961C11.9902 4.22923 12 4.11576 12 4C12 2.89543 11.1046 2 10 2C8.89543 2 8 2.89543 8 4C8 4.11576 8.00983 4.22922 8.02871 4.3396C6.12531 5.01736 4.64967 6.65258 4.23494 8.72621L3.18018 14H3C2.44772 14 2 14.4477 2 15C2 15.4784 2.33596 15.8784 2.78482 15.9768L2.78018 16H6.79815L7.50385 17.0586C8.69132 18.8398 11.3087 18.8398 12.4962 17.0586L13.2019 16H17.2198L17.2151 15.9768C17.664 15.8784 18 15.4784 18 15C18 14.4477 17.5523 14 17 14H16.8198L15.765 8.72621C15.3503 6.65259 13.8747 5.01738 11.9713 4.33961ZM14.7802 14L13.8039 9.11844C13.4412 7.3052 11.8491 6 9.99999 6C8.15084 6 6.55875 7.3052 6.1961 9.11844L5.21979 14H14.7802ZM9.20409 16C9.60224 16.5259 10.3978 16.5259 10.7959 16H9.20409Z'
      fill={color}
    />
  </Icon>
)

Notification.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#10142D',
}

export { Notification }
