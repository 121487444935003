import { useAppDispatch, useAppSelector } from 'store/hooks'

import { Anchor, Button, FormField } from 'ui'
import { matchNumberMask } from 'utils/matchInputMask'

import { checkPhone } from 'modules/auth/slices/auth'

import { env } from 'settings/env'

import type { FC, Dispatch, SetStateAction } from 'react'
import type { AuthInputError } from 'modules/auth/types/interfaces'

interface PhoneFormProps {
  phone: string
  setPhone: Dispatch<SetStateAction<string>>
}

const PhoneForm: FC<PhoneFormProps & AuthInputError> = ({
  phone,
  error,
  setPhone,
  setErrorStatus,
}) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(state => state.auth.auth.loading)

  const onSubmit = event => {
    event.preventDefault()

    const matchedPhone = matchNumberMask(phone)

    if (matchedPhone.length === 11) {
      dispatch(
        checkPhone({
          phone: matchedPhone,
        }),
      )

      setErrorStatus(false)
    } else {
      setErrorStatus(true)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <FormField
        placeholder='+7 (XXX) XXX-XX-XX'
        label='Номер телефона'
        maskType='PHONE'
        size='large'
        type='text'
        value={phone}
        error={error}
        onChange={e => setPhone(e.target.value)}
        onClear={() => {
          setPhone('')
          setErrorStatus(false)
        }}
        data-test-id='InputPhoneForm'
      />

      <Button
        mt='24px'
        type='submit'
        loading={loading}
        fullWidth
        data-test-id='ContinueButton'
      >
        Продолжить
      </Button>

      <Anchor
        href={env.PARTNER_BASE_URL}
        target='_blank'
        className='__create-partner-button'
      >
        <Button variant='secondary' mt='16px' type='button' fullWidth>
          Зарегистрироваться
        </Button>
      </Anchor>
    </form>
  )
}

export { PhoneForm }
