import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchWithInstallmentProducts } from '../slices/products'

const useWithInstallmentProducts = () => {
  const dispatch = useAppDispatch()
  const { withInstallmentProducts } = useAppSelector(
    state => state.installments.products,
  )

  const { category, month } = useAppSelector(
    state => state.installments.products.withInstallmentProductsFilters,
  )

  useEffect(() => {
    dispatch(fetchWithInstallmentProducts())
  }, [category, month, dispatch])

  return { withInstallmentProducts }
}

export { useWithInstallmentProducts }
