import { styled } from 'ui/theme'

const NotificationsWrap = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.main.background.white};
  min-width: 469px;
  box-shadow: 0px 8px 14px rgba(16, 20, 45, 0.16),
    0px 3.87387px 9.86002px rgba(16, 20, 45, 0.0898405);
  border-radius: ${props => props.theme.radii.xxs};
  overflow: hidden;

  .__tab-item-name {
    display: flex;
    flex: 1 0 50%;
    justify-content: center;
  }
`
export { NotificationsWrap }
