import React from 'react'
import ReactDOM from 'react-dom'

import { Provider as StoreProvider } from 'react-redux'
import { Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ThemeProvider } from '@emotion/react'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from 'store/configureStore'
import history from 'settings/history'

import {
  PreloadProvider,
  ErrorBoundary,
  RenderRoutes,
} from 'modules/core/components'

import { routes } from 'settings/routes'
import { env } from 'settings/env'
import { theme } from 'ui/theme'

import type { FC } from 'react'

import 'assets/normalize.css'
import 'assets/main.css'

const Index: FC = () => {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.NODE_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })

  return (
    <React.StrictMode>
      <StoreProvider store={store}>
        <ErrorBoundary>
          <PersistGate persistor={persistor}>
            <PreloadProvider history={history}>
              <ThemeProvider theme={theme}>
                <Router history={history}>
                  <RenderRoutes routes={routes} />
                </Router>
              </ThemeProvider>
            </PreloadProvider>
          </PersistGate>
        </ErrorBoundary>
      </StoreProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))
