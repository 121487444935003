import { forwardRef } from 'react'
import { Text } from 'ui'

import { Body } from 'ui/typography/Typography/Body'
import { Title } from 'ui/typography/Typography/Title'
import { Caption } from 'ui/typography/Typography/Caption'
import { Subtitle } from 'ui/typography/Typography/Subtitle'
import { Headline } from 'ui/typography/Typography/Headline'

import type { Ref } from 'react'
import type { TypographyProps } from 'ui/typography/Typography/types'

const Typography: TypographyProps = forwardRef(
  ({ children, ...props }, ref) => (
    <Text ref={ref as Ref<HTMLSpanElement>} {...props}>
      {children}
    </Text>
  ),
)

Typography.Body = Body
Typography.Title = Title
Typography.Caption = Caption
Typography.Subtitle = Subtitle
Typography.Headline = Headline

export { Typography }
