import { useAppSelector } from 'store/hooks'

import { Button, Modal, Typography } from 'ui'

import type { FC } from 'react'

interface AutoRejectModalProps {
  visible: boolean
  onOk: VoidFunction
  onClose: VoidFunction
}

const AutoRejectModal: FC<AutoRejectModalProps> = ({
  visible,
  onClose,
  onOk,
}) => {
  const loading = useAppSelector(state => state.orders.orders.loadingOfDecision)

  return (
    <Modal title='Погодите–ка!' visible={visible} onClose={onClose} width={365}>
      <Typography.Body as='p' mb='24px'>
        Вы убрали все товары, хотите отменить весь заказ?
      </Typography.Body>

      <Button mb='16px' fullWidth onClick={onOk} loading={loading}>
        Да, давайте!
      </Button>

      <Button
        variant='secondary'
        disabled={loading}
        onClick={onClose}
        fullWidth
      >
        Нет, верните меня обратно
      </Button>
    </Modal>
  )
}

export { AutoRejectModal }
