import { useFinanceActions } from 'modules/finance/hooks'

import { RangePickerInput, Search, Box } from 'ui'

import type { FC } from 'react'

const FinanceActions: FC = () => {
  const { search, datepicker } = useFinanceActions()

  return (
    <Box pt='24px' px='24px' mb='16px' display='flex' alignItems='flex-end'>
      <Box width='296px' mr='16px'>
        <Search placeholder='ID платежа' {...search} />
      </Box>

      <Box width='296px'>
        <RangePickerInput label='Период платежа' {...datepicker} />
      </Box>
    </Box>
  )
}

export default FinanceActions
