import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { instance, AppThunk } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'
import { getDataWithKey } from 'utils/getDataWithKey'

import { FinanceState, TransactionsRes } from 'modules/finance/types/interfaces'
import { AxiosResponse } from 'axios'
import {
  FinanceStatusType,
  TransactionType,
  BalanceType,
} from 'modules/finance/types'

/**
 * Actions
 */

export const fetchTransactions: AppThunk<TransactionType[]> = createAsyncThunk(
  'finance/transactions/fetch',
  (_, thunkApi) => {
    const { pagination, filters } = thunkApi.getState().finance.finance

    const config = {
      params: {
        limit: pagination.limit,
        search: filters.search,
        page: pagination.page,
        from: filters.dateRange.from,
        to: filters.dateRange.to,
      },
    }

    return instance(thunkApi)
      .get('/accounter/api/v1/transactions/analytics', config)
      .then((res: AxiosResponse<TransactionsRes>) => {
        thunkApi.dispatch(changeTotal(res.data.count))
        return getDataWithKey(res.data.transactions, 'uuid')
      })
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const fetchBalance: AppThunk<BalanceType> = createAsyncThunk(
  'finance/balance/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get(`/accounter/api/v1/balance/merchant`)
      .then((res: AxiosResponse<BalanceType>) => res.data)
      .catch(thunkErrorHandler(thunkApi)),
)

export const changeTransactionsPage: AppThunk<void, number> = createAsyncThunk(
  'finance/transactions/changePage',
  (page, thunkApi) => {
    thunkApi.dispatch(changePage(page))
    thunkApi.dispatch(fetchTransactions())
  },
)

export const changeTransactionsLimit: AppThunk<void, number> = createAsyncThunk(
  'finance/transactions/changeLimit',
  (limit, thunkApi) => {
    thunkApi.dispatch(changeLimit(limit))
    thunkApi.dispatch(fetchTransactions())
  },
)

/**
 * Reducer
 */

const initialState: FinanceState = {
  list: [],
  loadingOfList: false,
  errorOfList: null,

  balance: {
    in_account: 0,
    to_withdrawal: 0,
  },
  loadingOfBalance: false,
  errorOfBalance: null,

  filters: {
    status: 'ALL_PAYMENTS',
    search: '',
    dateRange: {
      from: null,
      to: null,
    },
  },

  pagination: {
    page: 1,
    limit: 20,
    total: null,
  },
}

const financeSlice = createSlice({
  name: 'finance',
  initialState,
  reducers: {
    changeStatusFilter: (state, action: PayloadAction<FinanceStatusType>) => {
      state.filters.status = action.payload
    },
    changeDateFilter: (
      state,
      action: PayloadAction<FinanceState['filters']['dateRange']>,
    ) => {
      state.filters.dateRange = action.payload
    },
    changeSearch: (state, action: PayloadAction<string>) => {
      state.filters.search = action.payload
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.pagination.total = action.payload
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    changeLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTransactions.pending, state => {
        state.loadingOfList = true
        state.errorOfList = null
      })
      .addCase(fetchTransactions.fulfilled, (state, { payload }) => {
        state.loadingOfList = false
        state.errorOfList = null
        state.list = payload
      })
      .addCase(fetchTransactions.rejected, (state, { payload }) => {
        state.loadingOfList = false
        state.errorOfList = payload.error
      })

      .addCase(fetchBalance.pending, state => {
        state.loadingOfBalance = true
        state.errorOfBalance = null
      })
      .addCase(fetchBalance.fulfilled, (state, { payload }) => {
        state.loadingOfBalance = false
        state.errorOfBalance = null
        state.balance = payload
      })
      .addCase(fetchBalance.rejected, (state, { payload }) => {
        state.loadingOfBalance = false
        state.errorOfBalance = payload.error
      })
  },
})

export const {
  changeStatusFilter,
  changeDateFilter,
  changeSearch,
  changeTotal,
  changePage,
  changeLimit,
} = financeSlice.actions

export default financeSlice.reducer
