import { FC } from 'react'

import { TabsContent } from 'ui/tabs/styles'

import { TabsContentItemProps } from 'ui/tabs/TabsGroup/types'

const TabsContentItem: FC<TabsContentItemProps> = ({ active, children }) => (
  <TabsContent active={active}>{children}</TabsContent>
)

export default TabsContentItem
