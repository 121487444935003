import { useProfile } from 'modules/profile/hooks'

import { RenderRoutes } from 'modules/core/components'
import { profileRoutes } from 'modules/profile/routes'

import type { FC } from 'react'

const Profile: FC = () => {
  useProfile()

  return <RenderRoutes routes={profileRoutes} />
}

export { Profile }
