import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchUploadEventDetails } from '../slices/productsFeed'

const useUploadEventDetails = (id: string) => {
  const dispatch = useAppDispatch()
  const { uploadEventDetails } = useAppSelector(
    state => state.products.productsFeed,
  )

  useEffect(() => {
    dispatch(fetchUploadEventDetails({ id }))
  }, [id, dispatch])

  return { uploadEventDetails }
}

export { useUploadEventDetails }
