import { Redirect } from 'react-router-dom'

import { App, RenderRoutes } from 'modules/core/components'
import { Icons } from './icons'

import type { FC } from 'react'
import type { RenderRoutesProps } from 'modules/common/types/interfaces'

const RenderMainRoutes: FC<RenderRoutesProps> = ({ routes }) => {
  const allRoutes = [
    {
      path: '/',
      key: 'stub',
      exact: true,
      skip: true,
      component: () => <Redirect to={routes[0].path} />,
      routes: [],
    },
    {
      path: '/icons',
      key: 'icons',
      exact: true,
      skip: true,
      component: () => <Icons />,
      routes: [],
    },
    ...routes,
  ]

  return (
    <App>
      <RenderRoutes routes={allRoutes} />
    </App>
  )
}

export { RenderMainRoutes }
