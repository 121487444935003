import { useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getAddressFromGeo,
  fetchPickupPoint,
  onClear,
} from 'modules/profile/slices/pickupPoints'

import type { ScheduleType } from 'modules/profile/types'

export const usePickupPointActions = (pickupPointId: string) => {
  const dispatch = useAppDispatch()
  const { pickupPoint, loadingPickupPoint, loadingCreate } = useAppSelector(
    state => state.profile.pickupPoints,
  )

  const [weekdays, setWeekdays] = useState<string[]>([])
  const [schedule, setSchedule] = useState<ScheduleType[]>(pickupPoint.schedule)

  const [street, setStreet] = useState<string>()

  const [mapConfig] = useState<{
    center: number[]
    zoom: number
  }>({
    center: [43.238293, 76.945465],
    zoom: 12,
  })

  const [coords, setCoords] = useState<number[]>([])

  const [addressData, setAddressData] = useState({
    street: '',
    house: '',
    geo: {
      latitude: '',
      longitude: '',
    },
  })

  const [iconCaption, setIconCaption] = useState<string | null>(null)

  const changeState = data => {
    const filteredWeekdays = data.schedule.filter(
      value => value.time_ranges?.length === 1,
    )
    const weekdays = filteredWeekdays.map(value => `${value.weekday}`)
    setSchedule(
      data.schedule.map(value => {
        return {
          ...value,
          time_ranges: value.time_ranges.length
            ? [
                {
                  from: new Date(
                    `January 01, 2000 ${value.time_ranges[0].from}:00`,
                  ),
                  to: new Date(
                    `January 01, 2000 ${value.time_ranges[0].to}:00`,
                  ),
                },
              ]
            : value.time_ranges,
        }
      }),
    )

    setAddressData({
      ...addressData,
      street: data?.address?.street,
      house: data?.address?.house,
      geo: data?.address?.geo,
    })
    setWeekdays(weekdays)
  }

  useEffect(() => {
    if (pickupPointId !== 'create') {
      dispatch(
        fetchPickupPoint({
          store_id: pickupPointId,
          changeState: changeState,
        }),
      )
    }
    return () => {
      dispatch(onClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupPointId, dispatch])

  useEffect(() => {
    setSchedule(
      schedule.map(value =>
        weekdays.includes(`${value.weekday}`)
          ? value
          : {
              ...value,
              time_ranges: [],
            },
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekdays])

  const { cities } = useAppSelector(state => state.common.cities)

  const handleChangeWeekDays = (value: string[]) => {
    setWeekdays(value)
  }

  const getAddress = (value: number[]) => {
    setIconCaption('Загрузка...')
    getAddressFromGeo(value).then(r => {
      setIconCaption(null)
      setAddressData({
        street:
          r.data.response.GeoObjectCollection.featureMember[1].GeoObject.name,
        geo: {
          latitude: value[1].toFixed(6).toString(),
          longitude: value[0].toFixed(6).toString(),
        },
        house:
          r.data.response.GeoObjectCollection.featureMember[0].GeoObject
            .metaDataProperty.GeocoderMetaData.AddressDetails.Country
            .AdministrativeArea.Locality.Thoroughfare.Premise.PremiseNumber,
      })
      if (r.data.response.GeoObjectCollection.featureMember.length > 0) {
        setStreet(
          r.data.response.GeoObjectCollection.featureMember[0].GeoObject.name,
        )
      } else {
        setIconCaption('Выберите точку выдачи в г. Алматы')
      }
    })
  }

  const mapClick = (e: any) => {
    setCoords(e.get('coords'))
    getAddress(e.get('coords').reverse())
  }

  return {
    weekdays: {
      onChange: handleChangeWeekDays,
      value: weekdays.sort(),
    },
    loadingPickupPoint,
    cities: cities,
    setAddressData,
    loadingCreate,
    iconCaption,
    pickupPoint,
    addressData,
    setSchedule,
    mapConfig,
    schedule,
    mapClick,
    street,
    coords,
  }
}
