import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Heart: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.301 3.50317C13.0544 1.49894 16.1236 1.49894 17.877 3.50318C18.6279 4.36149 19 5.43868 19 6.50904C19 8.76672 17.5348 11.1285 15.8856 13.0606C14.1971 15.0388 12.1267 16.7841 10.5547 17.8321C10.2188 18.056 9.7812 18.056 9.4453 17.8321C7.87333 16.7841 5.80288 15.0388 4.1144 13.0606C2.46517 11.1285 1 8.76672 1 6.50904C1 5.43868 1.37209 4.36149 2.12299 3.50318C3.87639 1.49894 6.94558 1.49894 8.69899 3.50318L10 4.9903L11.301 3.50317ZM16.3717 4.82006C15.4152 3.72665 13.7628 3.72665 12.8063 4.82006L10.7526 7.16748C10.5627 7.38454 10.2884 7.50904 10 7.50904C9.71161 7.50904 9.43726 7.38454 9.24737 7.16748L7.19372 4.82006C6.23715 3.72665 4.58482 3.72665 3.62825 4.82006C3.21165 5.29625 3 5.89923 3 6.50904C3 8.00304 4.03483 9.88679 5.6356 11.7622C7.00281 13.3639 8.65217 14.8085 10 15.7822C11.3478 14.8085 12.9972 13.3639 14.3644 11.7622C15.9652 9.88679 17 8.00304 17 6.50904C17 5.89923 16.7883 5.29625 16.3717 4.82006Z'
      fill={color}
    />
  </Icon>
)

Heart.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Heart }
