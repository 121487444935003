import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchInstallmentDetails } from '../slices/installments'

import { Installment } from '../types/types'

const useInstallmentDetails = (id: Installment['id']) => {
  const dispatch = useAppDispatch()
  const { installmentDetails, installmentDetailsError } = useAppSelector(
    state => state.installments.installments,
  )

  useEffect(() => {
    dispatch(fetchInstallmentDetails({ id }))
  }, [id, dispatch])

  return { installmentDetails, installmentDetailsError }
}

export { useInstallmentDetails }
