import { styled } from 'ui/theme'
import {
  mainAside,
  transitionDuration,
} from 'modules/common/constants/properties'

const Main = styled.main`
  width: calc(100% - ${mainAside.width});
  transition: ${transitionDuration};

  &.collapsed {
    width: calc(100% - ${mainAside.collapsedWidth});
  }
`

export { Main }
