import { styled } from 'ui/theme'

export const SearchWrap = styled.div`
  position: relative;
  background: linear-gradient(90deg, #b346b0, #605cd7);
  border-radius: 2px;
  padding: 1px;

  .__clear-icon,
  .__loupe-icon,
  .__search-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .__loupe-icon {
    left: 16px;
  }

  .__clear-icon {
    right: 92px;
    cursor: pointer;
  }

  .__search-button {
    right: 12px;
    top: 52%;
    color: ${props => props.theme.colors.projects.secondaryMarket};
    text-transform: uppercase;
    padding: 0;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1em;
    background: none;
    border: none;
  }

  input {
    width: 100%;
    height: 38px;
    outline: none;
    font-size: ${props => props.theme.fontSizes.body.s};
    border: none;
    border-radius: 2px;
    padding: 0 124px 0 52px;
    color: ${props => props.theme.colors.text.black};
    background: ${props => props.theme.colors.main.background.gray};
    transition: 90ms;

    &::placeholder {
      color: ${props => props.theme.colors.text.secondaryBlack};
    }

    &:focus {
      background: ${props => props.theme.colors.main.background.white};
    }
  }
`
