import { Children } from 'react'
import { ChevronIcon } from 'ui/icons'
import { Typography } from 'ui'
import { BreadcrumbsWrap } from './styles'

import type { FC, ReactNode } from 'react'
import type { SpaceProps } from 'styled-system'

const Delimiter: FC = () => (
  <Typography as='p' fontSize={0} ml='8px' mr='8px'>
    <ChevronIcon right />
  </Typography>
)

const Breadcrumbs: FC<SpaceProps> = ({ children, ...props }) => {
  const childrenArr = Children.map(children, child => child) || []

  const items = childrenArr.reduce(
    (acc: ReactNode[], item, index): ReactNode[] => {
      if (index > 0) {
        acc.push(<Delimiter key={index} />)
      }
      acc.push(item)
      return acc
    },
    [],
  )

  return <BreadcrumbsWrap {...props}>{items}</BreadcrumbsWrap>
}

export { Breadcrumbs }
