import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'

import { fetchPickupPoints } from 'modules/profile/slices/pickupPoints'
import { fetchOrderDetails } from 'modules/orders/slices/orders'

import { IOrderRouteMatch } from 'modules/orders/types/interfaces'

export const useOrderDetails = () => {
  const params = useParams<IOrderRouteMatch>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (params.orderId) {
      dispatch(fetchPickupPoints())
      dispatch(
        fetchOrderDetails({
          refresh: true,
          orderId: params.orderId,
        }),
      )
    }
  }, [params.orderId, dispatch])
}
