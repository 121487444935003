import { FC } from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'

import { isNav } from 'utils/isNav'

import { NavTabsMenu } from 'ui/navTabs/NavTabsMenu'
import { Typography, Dropdown, Box } from 'ui'
import { useAppTheme } from 'ui/theme'
import { ChevronIcon } from 'ui/icons'

import { Nav } from 'ui/navTabs/styles'

import type { IRoute } from 'modules/common/types/interfaces'
import type { NavProps } from './types'

interface NavigationProps extends NavProps {
  routes: IRoute[]
  params?: {
    [param: string]: string
  }
}

const NavTabs: FC<NavigationProps> = ({ routes, params, ...props }) => {
  const theme = useAppTheme()
  const location = useLocation()
  const history = useHistory()

  const currentRoutePath = `/${location.pathname.split('/')[1]}`
  const currentPath = `/${location.pathname.split('/')[1]}/${
    location.pathname.split('/')[2]
  }`

  return (
    <Nav {...props}>
      {routes.filter(isNav).map(route => {
        let path = route.path

        if (params) {
          Object.keys(params).forEach(
            param => (path = path.replace(`:${param}`, params[param])),
          )
        }

        return route.routes &&
          route.routes.length !== 0 &&
          route.routes.some(isNav) ? (
          <Dropdown key={route.key} overlay={NavTabsMenu(route.routes)}>
            <Box
              display='flex'
              alignItems='center'
              className={`__nav-link ${
                currentRoutePath === route.path && '__active-nav-link'
              }`}
            >
              <Typography.Body weight='semibold' mr='8px'>
                {route.nav.name}
              </Typography.Body>

              <Typography fontSize='0px'>
                <ChevronIcon color={theme.colors.icon.black} />
              </Typography>
            </Box>
          </Dropdown>
        ) : (
          <Box>
            <NavLink
              activeClassName='__active-nav-link'
              className='__nav-link'
              key={route.key}
              to={path}
            >
              <Typography.Body weight='semibold'>
                {route.nav.name}
              </Typography.Body>
            </NavLink>
            {currentPath === route.isButton?.path &&
              route.isButton?.button(history)}
          </Box>
        )
      })}
    </Nav>
  )
}

export { NavTabs }
