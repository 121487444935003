import { CheckboxWrap } from 'ui/inputs/Checkbox/styles'

import type { FC } from 'react'
import type { FormCheckboxProps } from 'ui/inputs/Checkbox/types'

const Checkbox: FC<FormCheckboxProps> = ({ indeterminate, ...props }) => (
  <CheckboxWrap indeterminate={!props.checked && indeterminate}>
    <label>
      <input type='checkbox' {...props} />
      <div className='__checkbox' />
    </label>
  </CheckboxWrap>
)

export { Checkbox }
