import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Orders: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.51158 1.6988C9.43388 1.17177 10.5661 1.17177 11.4884 1.6988L16.4884 4.55594C17.4231 5.09007 18 6.0841 18 7.16067V12.8394C18 13.916 17.4231 14.91 16.4884 15.4441L11.4884 18.3013C10.5661 18.8283 9.43388 18.8283 8.51158 18.3013L3.51158 15.4441C2.57686 14.91 2 13.916 2 12.8394V7.16067C2 6.0841 2.57686 5.09007 3.51158 4.55594L8.51158 1.6988ZM10.4961 3.43528C10.1887 3.25961 9.81129 3.25961 9.50386 3.43528L4.50386 6.29243C4.19229 6.47047 4 6.80181 4 7.16067V12.8394C4 13.1982 4.19229 13.5296 4.50386 13.7076L9.50386 16.5648C9.81129 16.7404 10.1887 16.7404 10.4961 16.5648L15.4961 13.7076C15.8077 13.5296 16 13.1982 16 12.8394V7.16067C16 6.80181 15.8077 6.47047 15.4961 6.29243L10.4961 3.43528ZM5.63176 7.50389C5.90577 7.02437 6.51662 6.85777 6.99614 7.13178L10 8.84827L13.0039 7.13178C13.4834 6.85777 14.0942 7.02437 14.3682 7.50389C14.6423 7.9834 14.4757 8.59426 13.9961 8.86827L11 10.5803V14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14V10.5803L6.00386 8.86827C5.52434 8.59426 5.35775 7.9834 5.63176 7.50389Z'
      fill={color}
    />
  </Icon>
)

Orders.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Orders }
