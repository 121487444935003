import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { FormField, Box, Typography, Button, Link, Tooltip } from 'ui'
import { QuestionIcon, EditIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'

import { useCurrentUrl } from 'modules/products/hooks'

import {
  uploadAutomatically,
  editAutomatically,
  clearError,
} from 'modules/products/slices/productsFeed'

import type { FC, SyntheticEvent, ChangeEventHandler } from 'react'

const { Caption, Body } = Typography

const ErrorOverlay = (
  <Body weight='semibold' color='text.white'>
    Произошла ошибка, возможно:
    <Box as='ol' pl='20px'>
      <li>По этой ссылке ничего не нашли</li>
      <li>Ссылка неправильная</li>
      <li>Нужно ввести логин и пароль</li>
      <li>Неправильный логин или пароль</li>
    </Box>
  </Body>
)

const Automatic: FC = () => {
  const { uploadLoading, uploadError } = useAppSelector(
    state => state.products.productsFeed,
  )
  const dispatch = useAppDispatch()
  const theme = useAppTheme()

  const [isEditMode, setIsEditMode] = useState(false)
  const [password, setPassword] = useState('')
  const [login, setLogin] = useState('')
  const [url, setUrl] = useState('')

  const { currentUrl } = useCurrentUrl()

  useEffect(() => {
    if (currentUrl) {
      setUrl(currentUrl.url || '')
      setLogin(currentUrl.user || '')
      setPassword(currentUrl.password || '')
    }
  }, [currentUrl])

  useEffect(() => {
    return () => {
      dispatch(clearError())
    }
  }, [dispatch])

  const handleChangeLogin: ChangeEventHandler<HTMLInputElement> = event => {
    const { value } = event.currentTarget
    setLogin(value)
  }

  const handleChangePassword: ChangeEventHandler<HTMLInputElement> = event => {
    const { value } = event.currentTarget
    setPassword(value)
  }

  const handleToggleEditMode = () => {
    if (!isNewUrl) {
      setIsEditMode(old => !old)
      setUrl(currentUrl.url || '')
      setPassword(currentUrl.password || '')
      setLogin(currentUrl.user || '')
      dispatch(clearError())
    }
  }

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()

    const data = {
      url,
      user: login.length ? login : null,
      password: password.length ? password : null,
    }

    if (isEditMode) {
      dispatch(
        editAutomatically({
          body: data,
          onSuccess: () => {
            setIsEditMode(false)
          },
        }),
      )
    } else {
      dispatch(uploadAutomatically({ body: data }))
    }
  }

  const isNewUrl = !Boolean(currentUrl?.url)

  return (
    <Box mt='24px' ml='24px'>
      <form onSubmit={handleSubmit}>
        <Box display='flex' alignItems='end'>
          <Box width='320px'>
            <FormField
              readOnly={!isNewUrl && !isEditMode}
              label='Ссылка на прайс–лист в формате XML'
              placeholder='Ссылка на XML'
              size='large'
              width='320px'
              value={url}
              error={Boolean(uploadError)}
              onClear={() => setUrl('')}
              onChange={e => {
                setUrl(e.target.value)
                dispatch(clearError())
              }}
              tooltip={{
                overlay: ErrorOverlay,
                visible: Boolean(uploadError),
              }}
            />
          </Box>

          {!isNewUrl && !isEditMode && (
            <Tooltip overlay='Изменить ссылку'>
              <Button
                onClick={handleToggleEditMode}
                ml='8px'
                type='button'
                icon={EditIcon}
                size='edit'
              />
            </Tooltip>
          )}
        </Box>

        <Caption
          as='p'
          mt='8px'
          weight='semibold'
          color={theme.colors.text.secondaryBlack}
        >
          Ссылка на XML обновляется автоматически каждые 10 мин.
        </Caption>

        <Link to='/faq/load-price-list'>
          <Box
            display='grid'
            alignItems='center'
            gridAutoFlow='column'
            gridColumnGap='8px'
            mt='8px'
          >
            <QuestionIcon color={theme.colors.icon.active} />
            Инструкция как добавить XML файл
          </Box>
        </Link>

        {(isNewUrl || isEditMode) && (
          <Box width='320px' mt='24px' display='grid' gridRowGap='16px'>
            <FormField
              subText='Не обязательное поле'
              label='Логин basic auth'
              placeholder='Логин'
              size='large'
              name='xml_login'
              value={login}
              autoComplete='off'
              onChange={handleChangeLogin}
              onClear={() => setLogin('')}
            />

            <FormField
              subText='Не обязательное поле'
              label='Пароль basic auth'
              placeholder='Пароль'
              type='password'
              name='xml_password'
              size='large'
              value={password}
              autoComplete='new-password'
              onChange={handleChangePassword}
            />

            <Button type='submit' mt='8px' loading={uploadLoading}>
              Сохранить
            </Button>

            {isEditMode && (
              <Button
                type='button'
                variant='secondary'
                onClick={handleToggleEditMode}
              >
                Отменить
              </Button>
            )}
          </Box>
        )}
      </form>
    </Box>
  )
}

export { Automatic }
