import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const MiniSwitchOff: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1667 5.83331H5.83342C3.53341 5.83331 1.66675 7.69998 1.66675 9.99998C1.66675 12.3 3.53341 14.1666 5.83342 14.1666H14.1667C16.4667 14.1666 18.3334 12.3 18.3334 9.99998C18.3334 7.69998 16.4667 5.83331 14.1667 5.83331ZM5.83342 12.5C4.45008 12.5 3.33341 11.3833 3.33341 9.99998C3.33341 8.61665 4.45008 7.49998 5.83342 7.49998C7.21675 7.49998 8.33342 8.61665 8.33342 9.99998C8.33342 11.3833 7.21675 12.5 5.83342 12.5Z'
      fill='#888A96'
    />
  </Icon>
)

MiniSwitchOff.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { MiniSwitchOff }
