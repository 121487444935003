import { FC, Fragment, useState } from 'react'

import {TabsHead, TabsContent, Typography, Box} from 'ui'

import { AvailableInstallments, WithInstallmentProducts } from './components'

enum InstallmentsTab {
  Installments = 'installments',
  WithInstallmentProducts = 'withInstallmentProducts',
}

const Installments: FC = () => {
  const [activeTab, setActiveTab] = useState<InstallmentsTab>(
    InstallmentsTab.Installments,
  )

  const handleChangeTab = (tab: InstallmentsTab) => {
    setActiveTab(tab)
  }

  return (
    <Fragment>
      <Box pt='24px' px='24px' mb='24px'>
        <Typography.Title as='p' mb='16px'>
          Акции
        </Typography.Title>

        <TabsHead marginBottom='0' activeTab={activeTab} onChange={handleChangeTab}>
          <TabsHead.Item tabValue={InstallmentsTab.Installments}>
            Проходящие акции
          </TabsHead.Item>
          <TabsHead.Item tabValue={InstallmentsTab.WithInstallmentProducts}>
            Товары участвующие в акциях
          </TabsHead.Item>
        </TabsHead>
      </Box>

      <TabsContent activeTab={activeTab}>
        <TabsContent.Item tabValue={InstallmentsTab.Installments}>
          <AvailableInstallments />
        </TabsContent.Item>

        <TabsContent.Item tabValue={InstallmentsTab.WithInstallmentProducts}>
          <WithInstallmentProducts />
        </TabsContent.Item>
      </TabsContent>
    </Fragment>
  )
}

export { Installments }
