import { useRef, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { Transition } from 'react-transition-group'

import { Typography, Tooltip, toast, IconButton, Box } from 'ui'
import { DocumentIcon, DeleteIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'

import { getFadeStyles } from 'utils/getTransitionStyles'
import { uploadManually } from 'modules/products/slices/productsFeed'

import { FileName, Button } from './styles'

import type { FC } from 'react'

const { Body, Caption } = Typography

const transitionDuration = 150

const permittedFileFormats = ['xml', 'xlsx', 'xls']

const Manual: FC = () => {
  const { uploadLoading } = useAppSelector(state => state.products.productsFeed)
  const dispatch = useAppDispatch()
  const theme = useAppTheme()
  const ref = useRef(null)

  const [file, setFile] = useState<File>(null)

  const handleChangeFile = ({ target: { files } }) => {
    const newFile = files?.[0]

    if (!newFile) {
      return
    }

    const format = newFile.name.split('.').pop()

    if (permittedFileFormats.some(frmt => frmt === format)) {
      setFile(newFile)
    } else {
      toast('Выбран файл неверного формата')
    }
  }

  const handleUploadFile = () => {
    const onUpload = () => {
      clearFile()
    }

    dispatch(uploadManually({ file, onUpload }))
  }

  const handleClearFile = () => {
    clearFile()
    toast('Файл удален')
  }

  const clearFile = () => {
    setFile(null)
    ref.current.value = null
  }

  const accept = permittedFileFormats.map(format => `.${format}`).join(',')

  return (
    <Box mt='24px' ml='24px'>
      <Body as='p' mb='8px'>
        Выберите и загрузите файл в формате XLSX или XML
      </Body>

      <label>
        <Button as='div' variant='secondary'>
          Выбрать файл
        </Button>

        <input
          hidden
          ref={ref}
          type='file'
          accept={accept}
          onChange={handleChangeFile}
        />
      </label>

      <Transition in={Boolean(file)} timeout={transitionDuration} unmountOnExit>
        {state => (
          <div
            style={{
              ...getFadeStyles(transitionDuration).default,
              ...getFadeStyles(transitionDuration).transition[state],
            }}
          >
            <Box
              backgroundColor='main.background.gray'
              borderRadius='xxs'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              padding='16px'
              width='296px'
              mt='16px'
              mb='24px'
            >
              <Box
                display='grid'
                gridAutoFlow='column'
                gridColumnGap='8px'
                alignItems='center'
              >
                <DocumentIcon color={theme.colors.icon.black} />

                <Box>
                  <FileName as='p'>{file?.name || 'Отсутствует'}</FileName>

                  <Caption color='text.secondaryBlack'>
                    {(file?.size || 0) / 1000}KB
                  </Caption>
                </Box>
              </Box>

              <Tooltip
                placement='top'
                overlay='Удалить файл'
                destroyTooltipOnHide
              >
                <IconButton onClick={handleClearFile}>
                  <DeleteIcon color={theme.colors.icon.black} />
                </IconButton>
              </Tooltip>
            </Box>

            <Button loading={uploadLoading} onClick={handleUploadFile}>
              Загрузить
            </Button>
          </div>
        )}
      </Transition>
    </Box>
  )
}

export { Manual }
