import { useEffect, useRef, useState } from 'react'
import { styled } from 'ui/theme'
import { Tooltip } from 'ui'

import { Size as TooltipSize } from 'ui/tooltip'

import type { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react'

const Wrapper = styled.span`
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

interface Props<T extends ElementType> {
  as?: T
  children?: ReactNode
}

const Ellipsis = <T extends ElementType = 'span'>({
  as,
  children,
  ...props
}: Props<T> & Omit<ComponentPropsWithoutRef<T>, keyof Props<T>>) => {
  const ref = useRef(null)
  const [isEllipsis, setIsEllipsis] = useState(false)

  useEffect(() => {
    const element = ref?.current
    if (element) {
      const { offsetWidth, scrollWidth } = element
      setIsEllipsis(scrollWidth > offsetWidth)
    }
  }, [children])

  return (
    <Tooltip
      overlay={children}
      placement='bottom'
      size={TooltipSize.Nano}
      {...(!isEllipsis && { visible: false })}
    >
      <Wrapper ref={ref} as={as} {...props}>
        {children}
      </Wrapper>
    </Tooltip>
  )
}

export { Ellipsis }
