import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchInstallments } from '../slices/installments'

const useInstallments = () => {
  const dispatch = useAppDispatch()
  const { installments, installmentsLoading, filters } = useAppSelector(
    state => state.installments.installments,
  )

  const { category, month } = filters

  useEffect(() => {
    dispatch(fetchInstallments())
  }, [category, month, dispatch])

  return { installments, installmentsLoading }
}

export { useInstallments }
