import { ThemeColors, ThemeGreyColorPalette } from 'ui/theme/src/types'

export const greyColorPalette: ThemeGreyColorPalette = {
  0: '#FFFFFF',
  5: '#F2F2F2',
  10: '#E6E6E6',
  15: '#D9D9D9',
  20: '#CCCCCC',
  25: '#BFBFBF',
  30: '#B3B3B3',
  35: '#A6A6A6',
  40: '#999999',
  45: '#8C8C8C',
  50: '#808080',
  55: '#737373',
  60: '#666666',
  65: '#595959',
  70: '#4D4D4D',
  75: '#404040',
  80: '#333333',
  85: '#262626',
  90: '#1A1A1A',
  95: '#0D0D0D',
  100: '#000000',
}

const colors: ThemeColors = {
  text: {
    black: '#10142D',
    secondaryBlack: '#888A96',
    white: '#FCFCFD',
    secondaryWhite: 'rgba(252, 252, 252, 0.65)',
    link: '#60B3F6',
    linkVisited: '#2180CE',
  },
  icon: {
    black: '#10142D',
    secondaryBlack: '#888A96',
    white: '#FCFCFD',
    hover: '#60B3F6',
    push: '#605CD7',
    active: '#319CF3',
  },
  button: {
    secondary: '#FFFBEE',
    default: '#FFFFFF',
    text: '#424242',
  },
  main: {
    brand: {
      primary:
        'linear-gradient(135deg, #FD7842 0%, #B346B0 36.06%, #605CD7 67.04%, #319CF3 100%)',
      secondary:
        'linear-gradient(90deg, #F9B50B -12.5%, #F9E214 -12.49%, #F79E1B 115.63%)',
      gradientHot: 'linear-gradient(135deg, #FD7842 0%, #B346B0 100%)',
      gradientCold: 'linear-gradient(315deg, #605CD7 0%, #319CF3 100%)',
    },
    background: {
      gray: '#F3F4FB',
      white: '#FCFCFF',
      black: '#10142D',
      warning: '#FFF3C8',
    },
    special: {
      discount: '#FF3A2E',
      credit: '#FFEE57',
    },
    features: {
      rating: '#FDB242',
      afterPrice: '#FF3A2E',
    },
    status: {
      success: '#1ACE37',
      error: '#F15515',
      notify: '#FFAA47',
    },
  },
  projects: {
    primaryMarket: '#319CF3',
    primaryLightMarket: '#90CAF9',
    secondaryMarket: '#605CD7',
    secondaryLightMarket: '#AFADEB',
  },
}

export default colors
