import {
  createAsyncThunk,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { AppThunk, instance } from 'store/configureStore'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { changeAccountStatus, fetchPartners } from 'modules/auth/slices/auth'

import { CredentialsState } from 'modules/auth/types/interfaces'
import { ACCOUNT_STATUS } from 'modules/auth/types/enums'

export const clearAll = createAction('auth/credentials/clearAll')
export const clearAllData = createAction('auth/credentials/clearAllData')

/**
 * Initial State
 */

const initialState: CredentialsState = {
  merchant_refresh_token: '',
  merchant_access_token: '',
  user_access_token: '',
  user_refresh_token: '',
  mid: '',
}

/**
 * Async Actions
 */

export const logout: AppThunk = createAsyncThunk(
  'auth/credentials/logout',
  (_, thunkAPI) =>
    instance(thunkAPI)
      .delete('/sso/api/v1/auth/signout')
      .then(() => {
        thunkAPI.dispatch(clearAll())
      })
      .catch(() => {
        thunkAPI.dispatch(clearAll())
      })
      .finally(() => {
        window.location.reload()
      }),
)

export const partnerLogout: AppThunk = createAsyncThunk(
  'auth/credentials/partnerLogout',
  (_, thunkAPI) => {
    thunkAPI.dispatch(clearAllData())
    thunkAPI.dispatch(fetchPartners())
    thunkAPI.dispatch(changeAccountStatus(ACCOUNT_STATUS.PARTNER_SELECTION))
  },
)

/**
 * Slice
 */

const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    auth: (state, action: PayloadAction<CredentialsState>) => {
      state.merchant_access_token =
        action.payload.merchant_access_token || state.merchant_access_token
      state.merchant_refresh_token =
        action.payload.merchant_refresh_token || state.merchant_refresh_token
      state.user_access_token =
        action.payload.user_access_token || state.user_access_token
      state.user_refresh_token =
        action.payload.user_refresh_token || state.user_refresh_token
      state.mid = action.payload.mid || state.mid
    },
  },
  extraReducers: builder =>
    builder.addCase(partnerLogout.fulfilled, state => {
      state.merchant_refresh_token = ''
      state.merchant_access_token = ''
      state.mid = ''
    }),
})

export const { auth } = credentialsSlice.actions
export default persistReducer(
  {
    storage,
    key: 'merchant_cabinet_credentials',
    version: 1,
  },
  credentialsSlice.reducer,
)
