import { ProductCategory } from 'modules/products/types'
import { FC } from 'react'
import { Box, Search, Select } from 'ui'

const { Option } = Select

interface Props {
  search: string
  onSearch: (test: string) => void
  onChangeCategory: (category: string) => void
  categories: ProductCategory[]
  selectedCategory: string | string[]
}

const OnSaleFilters: FC<Props> = ({
  search,
  onSearch,
  onChangeCategory,
  categories,
  selectedCategory,
}) => {
  return (
    <Box
      display='grid'
      gridTemplateColumns='auto auto 1fr'
      alignItems='end'
      justifyItems='end'
      mt='-16px'
      mb='24px'
      px='24px'
    >
      <Box minWidth='400px' mr='16px'>
        <Search
          placeholder='Название или артикул товара'
          value={search}
          onSearch={onSearch}
        />
      </Box>

      <Box minWidth='296px'>
        <Select
          label='Категории'
          value={selectedCategory}
          listHeight={400}
          dropdownMatchSelectWidth={296}
          onChange={onChangeCategory}
        >
          <Option value=''>Все категории</Option>
          {categories.map(category => (
            <Option key={category.slug} value={category.slug}>
              {category?.title?.ru}
            </Option>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export { OnSaleFilters }
