import { FC } from 'react'
import { Installment } from 'modules/installments/types/types'
import { Box } from 'ui'
import { DefaultEmpty } from 'ui/table/components'

import { InstallmentCard } from '../InstallmentCard'

interface Props {
  installments: Installment[]
  isSearch: boolean
  isLoading: boolean
}

const InstallmentList: FC<Props> = ({ installments, isSearch, isLoading }) => (
  <Box as='ul' mt='24px' px='24px' pb='24px' display='grid' gridRowGap='16px'>
    {isLoading || installments.length ? (
      installments.map(installment => (
        <InstallmentCard
          key={installment.id}
          id={installment.id}
          title={installment.title?.ru}
          startAt={installment.start_at}
          endAt={installment.end_at}
          duration={installment.duration}
        />
      ))
    ) : (
      <Box mt='32px'>
        <DefaultEmpty
          temporarilyEmpty={!isSearch}
          emptyText={!isSearch && 'Пока здесь ничего нет'}
        />
      </Box>
    )}
  </Box>
)

export { InstallmentList }
