import { FC } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { useTableCbx } from 'ui/table/useTableCbx'

import {
  useWithInstallmentCategories,
  useWithInstallmentProducts,
} from 'modules/installments/hooks'

import {
  WithInstallmentProductsTable,
  WithInstallmentProductsActions,
} from './components'

import { InstallmentsFilters } from '../AvailableInstallments/components'
import {
  changeWithInstallmentProductsCategoryFilter,
  changeWithInstallmentProductsMonthFilter,
} from 'modules/installments/slices/products'
import { ALL_OPTIONS } from 'modules/common/constants/dictionaries'

const WithInstallmentProducts: FC = () => {
  const dispatch = useAppDispatch()
  const { withInstallmentProducts: products } = useWithInstallmentProducts()

  const { withInstallmentCategories } = useWithInstallmentCategories()

  const { category: selectedCategory, month: selectedMonth } = useAppSelector(
    state => state.installments.products.withInstallmentProductsFilters,
  )

  const {
    cbxState,
    onChangeCbx,
    onCheckAllCbxChange,
    onResetCbx,
    isAllSelected,
  } = useTableCbx(products, 'sku')

  const handleChangeCategory = (category: string) => {
    dispatch(changeWithInstallmentProductsCategoryFilter(category))
  }

  const handleChangeMonth = (month: number) => {
    dispatch(changeWithInstallmentProductsMonthFilter(month))
  }

  const isSearch = selectedMonth !== null || selectedCategory !== ALL_OPTIONS

  return (
    <>
      <InstallmentsFilters
        categories={withInstallmentCategories}
        selectedCategory={selectedCategory}
        selectedMonth={selectedMonth}
        onChangeCategory={handleChangeCategory}
        onChangeMonth={handleChangeMonth}
      />
      <WithInstallmentProductsActions
        isAllSelected={isAllSelected}
        onCheckAllCbxChange={onCheckAllCbxChange}
        cbxState={cbxState}
        onResetCbx={onResetCbx}
      />
      <WithInstallmentProductsTable
        products={products}
        cbxState={cbxState}
        onChangeCbx={onChangeCbx}
        isSearch={isSearch}
      />
    </>
  )
}

export { WithInstallmentProducts }
