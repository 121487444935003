import { FC } from 'react'

import DayPicker, { DayPickerProps } from 'react-day-picker'

import { DatepickerWrap } from 'ui/datePicker/styles'

import {
  FIRST_DAY_OF_WEEK,
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  LABELS,
} from 'ui/datePicker/locale'

const locale = 'ru'

const BasePicker: FC<DayPickerProps> = ({ modifiers, ...props }) => {
  const initialModifiers = {
    weekends: {
      daysOfWeek: [6, 0],
    },
  }

  return (
    <DatepickerWrap>
      <DayPicker
        {...props}
        modifiers={{
          ...initialModifiers,
          ...modifiers,
        }}
        months={MONTHS[locale]}
        weekdaysLong={WEEKDAYS_LONG[locale]}
        weekdaysShort={WEEKDAYS_SHORT[locale]}
        firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
        labels={LABELS[locale]}
      />
    </DatepickerWrap>
  )
}

export default BasePicker
