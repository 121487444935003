import { css } from '@emotion/react'
import { variant } from 'styled-system'

import type { Theme } from 'ui/theme/src/types'
import type {
  StyledButtonProps,
  VariantProps,
  VariantsMap,
} from 'ui/button/types'

const variantStyles = (theme: Theme): VariantsMap => {
  const primary = css`
    background: ${theme.colors.projects.primaryMarket};
    color: ${theme.colors.text.white};

    svg {
      path {
        fill: ${theme.colors.text.white};
      }
    }
  `

  const secondary = css`
    background: ${theme.colors.button.secondary};
    color: ${theme.colors.text.black};

    svg {
      path {
        fill: ${theme.colors.text.black};
      }
    }
  `

  const defaultVariant = css`
    background: ${theme.colors.button.default};
    color: ${theme.colors.text.black};
    border: 1px solid ${theme.greyColorPalette[5]};

    svg {
      path {
        fill: ${theme.colors.text.black};
      }
    }
  `

  return {
    primary,
    secondary,
    default: defaultVariant,
  }
}

export const variants = (props: StyledButtonProps & VariantProps) =>
  variant({
    variants: variantStyles(props.theme),
  })
