import { Box, Typography } from 'ui'
import { styled } from 'ui/theme'

import type { FC } from 'react'
import type { ScheduleType } from 'modules/profile/types'

interface ScheduleProps {
  schedule: ScheduleType[]
}

const ScheduleWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;

  &:last-of-type {
    margin-right: 0;

    .__time-range {
      border-right: none;
      padding-right: 0;
    }
  }

  .__time-range {
    border-right: 1px solid ${props => props.theme.greyColorPalette[5]};
    box-sizing: content-box;
    padding-right: 4px;
    min-width: 47px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const weekdays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

const { Caption, Body } = Typography

const Schedule: FC<ScheduleProps> = ({ schedule }) => (
  <Box display='flex'>
    {schedule.map(day => (
      <ScheduleWrap key={day.weekday}>
        <Body color='text.secondaryBlack' mb='4px'>
          {weekdays[day.weekday - 1]}
        </Body>

        <Box className='__time-range'>
          {day.time_ranges && day.time_ranges.length !== 0 ? (
            <Box>
              <Body as='p'>{day.time_ranges[0].from}-</Body>
              <Body as='p'>{day.time_ranges[0].to}</Body>
            </Box>
          ) : (
            <Caption as='p' color='text.secondaryBlack'>
              -
            </Caption>
          )}
        </Box>
      </ScheduleWrap>
    ))}
  </Box>
)

export { Schedule }
