import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { matchNumberMask } from 'utils/matchInputMask'
import { FormField, Button } from 'ui'

import { userSignIn } from 'modules/auth/slices/auth'

import type { FC } from 'react'
import type {
  AuthInputError,
  PasswordFormProps,
} from 'modules/auth/types/interfaces'

const TabPasswordForm: FC<PasswordFormProps & AuthInputError> = ({
  phone,
  error,
  setErrorStatus,
}) => {
  const loading = useAppSelector(state => state.auth.auth.loading)
  const dispatch = useAppDispatch()

  const [password, setPassword] = useState('')

  const onSubmit = event => {
    event.preventDefault()
    const matchedPhone = matchNumberMask(phone)

    dispatch(
      userSignIn({
        phone: matchedPhone,
        password,
      }),
    )

    setErrorStatus(false)
  }

  return (
    <form onSubmit={onSubmit}>
      <FormField
        placeholder='Пароль'
        type='password'
        label='Пароль'
        size='large'
        error={error}
        value={password}
        onChange={e => setPassword(e.target.value)}
        data-test-id='InputPasswordForm'
      />

      <Button
        mt='24px'
        loading={loading}
        fullWidth
        data-test-id='MerchantLoginButton'
      >
        Войти
      </Button>
    </form>
  )
}

export { TabPasswordForm }
