import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { instance, AppThunk } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'
import { formatDate } from 'utils/textFormatting'

import { toast } from 'ui'

import { AxiosRequestConfig } from 'axios'

import { UploadEventStatus } from 'modules/products/types'
import type {
  ProductsFeedState,
  UploadAutomaticallyOptions,
  UploadManuallyOptions,
  CurrentFeedUrl,
  UploadEvent,
} from 'modules/products/types'
import { EVENT_TYPES } from 'modules/common/constants/dictionaries'

const initialUploadEventDetailsState: UploadEvent = {
  created_at: '',
  description: '',
  entity_id: '',
  entity_type: '',
  event_type: '',
  id: '',
  messages: [],
  meta: null,
  status: UploadEventStatus.Error,
}

const initialState: ProductsFeedState = {
  uploadLoading: false,
  uploadError: null,

  uploadHistory: [],
  uploadHistoryLoading: false,
  uploadHistoryError: null,
  uploadHistoryPagination: {
    page: 1,
    limit: 20,
    total: null,
  },
  uploadHistoryFilters: {
    date: null,
    status: '',
    type: '',
  },

  uploadEventDetails: initialUploadEventDetailsState,
  uploadEventDetailsLoading: false,
  uploadEventDetailsError: null,

  lastSuccessfulUpload: initialUploadEventDetailsState,
  lastSuccessfulUploadLoading: false,
  lastSuccessfulUploadError: null,

  statuses: [
    { id: 'success', title: 'Загружено' },
    { id: 'processed', title: 'Обработано' },
    { id: 'error', title: 'Ошибка' },
  ],

  types: Object.entries(EVENT_TYPES).map(([id, title]) => ({
    id,
    title,
  })),

  currentUrl: null,
  currentUrlLoading: false,
  currentUrlError: null,
}

/**
 * Async Actions
 */

export const uploadAutomatically: AppThunk<void, UploadAutomaticallyOptions> =
  createAsyncThunk('productsFeed/upload/automatically', ({ body }, thunkApi) =>
    instance(thunkApi)
      .post('/merchant-gateway/api/v1/merchant/documents/url', body)
      .then(() => {
        toast('Прайс–лист загружен')
      })
      .catch(thunkErrorHandler(thunkApi)),
  )

export const editAutomatically: AppThunk<
  CurrentFeedUrl,
  UploadAutomaticallyOptions
> = createAsyncThunk(
  'productsFeed/edit/automatically',
  ({ body, onSuccess }, thunkApi) =>
    instance(thunkApi)
      .put('/merchant-gateway/api/v1/merchant/documents/url', body)
      .then(() => {
        toast('Ссылка на прайс–лист изменена')
        onSuccess()
        return body
      })
      .catch(thunkErrorHandler(thunkApi)),
)

export const uploadManually: AppThunk<void, UploadManuallyOptions> =
  createAsyncThunk('productsFeed/upload/manually', (options, thunkApi) => {
    const { file, onUpload } = options
    const formData = new FormData()

    formData.append('formfile', file)

    return instance(thunkApi)
      .post(
        `/merchant-gateway/api/v1/merchant/documents/upload/${file?.name}`,
        formData,
      )
      .then(() => {
        toast('Прайс–лист загружен')
        if (typeof onUpload === 'function') {
          onUpload()
        }
      })
      .catch(error => {
        const message =
          error.response.status === 403 ? 'Недостаточно прав' : 'Ошибка'
        toast(message)
        thunkErrorHandler(error)
      })
  })

export const fetchCurrentUrl: AppThunk<CurrentFeedUrl> = createAsyncThunk(
  'productsFeed/currentUrl/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get(`/merchant-gateway/api/v1/merchant/documents/url`)
      .then(res => res.data)
      .catch(thunkErrorHandler(thunkApi)),
)

export const fetchUploadHistory: AppThunk<UploadEvent[]> = createAsyncThunk(
  'productsFeed/uploadHistory/fetch',
  (_, thunkApi) => {
    const { date, status, type } =
      thunkApi.getState().products.productsFeed.uploadHistoryFilters

    const { page, limit } =
      thunkApi.getState().products.productsFeed.uploadHistoryPagination

    const config: AxiosRequestConfig = {
      params: {
        updated_at_short: date
          ? formatDate(new Date(date), 'yyy-MM-dd')
          : undefined,
        status: typeof status === 'string' ? status : undefined,
        load_type: typeof type === 'string' ? type : undefined,
        page,
        limit,
        sort_key: 'created_at',
        sort_dir: 'desc',
      },
    }

    return instance(thunkApi)
      .get(`/audit/api/v1/merchants/events`, config)
      .then(res => {
        thunkApi.dispatch(productsFeedSlice.actions.changeTotal(res.data.total))
        return res.data.events
      })
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const fetchLastSuccessfulUpload: AppThunk<UploadEvent> =
  createAsyncThunk('', (_, thunkApi) => {
    const config = {
      params: {
        limit: 1,
        page: 1,
        sort_key: 'created_at',
        sort_dir: 'desc',
        status: UploadEventStatus.Success,
      },
    }

    return instance(thunkApi)
      .get(`/audit/api/v1/merchants/events`, config)
      .then(res =>
        res.data?.events?.length
          ? res.data.events[0]
          : initialUploadEventDetailsState,
      )
      .catch(thunkErrorHandler(thunkApi))
  })

export const fetchUploadEventDetails: AppThunk<UploadEvent, { id: string }> =
  createAsyncThunk(
    'productsFeed/uploadHistoryDetails/fetch',
    (options, thunkApi) => {
      const { id } = options

      return instance(thunkApi)
        .get(`/audit/api/v1/merchants/events/${id}`)
        .then(res => res.data)
        .catch(thunkErrorHandler(thunkApi))
    },
  )

export const changeUploadHistoryPage: AppThunk<void, number> = createAsyncThunk(
  'orders/details/changePage',
  (page, thunkApi) => {
    thunkApi.dispatch(productsFeedSlice.actions.changePage(page))
    thunkApi.dispatch(fetchUploadHistory())
  },
)

export const changeUploadHistoryLimit: AppThunk<void, number> =
  createAsyncThunk('orders/details/changeLimit', (limit, thunkApi) => {
    thunkApi.dispatch(productsFeedSlice.actions.changeLimit(limit))
    thunkApi.dispatch(fetchUploadHistory())
  })

/**
 * Reducer
 */

const productsFeedSlice = createSlice({
  name: 'productsFeed',
  initialState,
  reducers: {
    changeDateFilter: (state, action: PayloadAction<Date>) => {
      state.uploadHistoryPagination.page = 1
      state.uploadHistoryFilters.date = action.payload
    },
    changeStatusFilter: (state, action: PayloadAction<string | string[]>) => {
      state.uploadHistoryPagination.page = 1
      state.uploadHistoryFilters.status = action.payload
    },
    changeTypeFilter: (state, action: PayloadAction<string | string[]>) => {
      state.uploadHistoryPagination.page = 1
      state.uploadHistoryFilters.type = action.payload
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.uploadHistoryPagination.page = action.payload
    },
    changeLimit: (state, action: PayloadAction<number>) => {
      state.uploadHistoryPagination.limit = action.payload
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.uploadHistoryPagination.total = action.payload
    },
    clearError: (state, action: PayloadAction) => {
      state.uploadError = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUploadHistory.pending, state => {
        state.uploadHistoryLoading = true
        state.uploadHistoryError = null
      })
      .addCase(fetchUploadHistory.fulfilled, (state, action) => {
        state.uploadHistoryLoading = false
        state.uploadHistory = action.payload
      })
      .addCase(fetchUploadHistory.rejected, (state, action) => {
        state.uploadHistoryLoading = false
        state.uploadHistoryError = action.payload.error
      })

      .addCase(uploadAutomatically.pending, state => {
        state.uploadLoading = true
        state.uploadError = null
      })
      .addCase(uploadAutomatically.fulfilled, state => {
        state.uploadLoading = false
      })
      .addCase(uploadAutomatically.rejected, (state, action) => {
        state.uploadLoading = false
        state.uploadError = action.payload.error
      })

      .addCase(editAutomatically.pending, state => {
        state.uploadLoading = true
        state.uploadError = null
      })
      .addCase(editAutomatically.fulfilled, (state, action) => {
        state.currentUrl = action.payload
        state.uploadLoading = false
      })
      .addCase(editAutomatically.rejected, (state, action) => {
        state.uploadLoading = false
        state.uploadError = action.payload.error
      })

      .addCase(uploadManually.pending, state => {
        state.uploadLoading = true
        state.uploadError = null
      })
      .addCase(uploadManually.fulfilled, state => {
        state.uploadLoading = false
      })
      .addCase(uploadManually.rejected, (state, action) => {
        state.uploadLoading = false
        state.uploadError = action.payload.error
      })

      .addCase(fetchUploadEventDetails.pending, state => {
        state.uploadEventDetailsLoading = true
        state.uploadEventDetailsError = null
      })
      .addCase(fetchUploadEventDetails.fulfilled, (state, action) => {
        state.uploadEventDetailsLoading = false
        state.uploadEventDetails = action.payload
      })
      .addCase(fetchUploadEventDetails.rejected, (state, action) => {
        state.uploadEventDetailsLoading = false
        state.uploadEventDetailsError = action.payload.error
      })

      .addCase(fetchCurrentUrl.pending, state => {
        state.currentUrlLoading = true
        state.currentUrlError = null
      })
      .addCase(fetchCurrentUrl.fulfilled, (state, action) => {
        state.currentUrlLoading = false
        state.currentUrl = action.payload
      })
      .addCase(fetchCurrentUrl.rejected, (state, action) => {
        state.currentUrlLoading = false
        state.currentUrlError = action.payload.error
      })

      .addCase(fetchLastSuccessfulUpload.pending, state => {
        state.lastSuccessfulUploadLoading = true
        state.lastSuccessfulUploadError = null
      })
      .addCase(fetchLastSuccessfulUpload.fulfilled, (state, action) => {
        state.lastSuccessfulUploadLoading = false
        state.lastSuccessfulUpload = action.payload
      })
      .addCase(fetchLastSuccessfulUpload.rejected, (state, action) => {
        state.lastSuccessfulUploadLoading = false
        state.lastSuccessfulUploadError = action.payload.error
      })
  },
})

export const {
  changeDateFilter,
  changeStatusFilter,
  changeTypeFilter,
  clearError,
} = productsFeedSlice.actions

export default productsFeedSlice.reducer
