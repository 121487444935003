import { css } from '@emotion/react'
import { Theme } from 'ui/theme/src/types'

export const getTooltipStyles = (theme: Theme) => css`
  .rc-tooltip {
    opacity: 1;
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
  }

  .rc-tooltip-inner {
    min-height: auto;
    padding: 4px;
    color: #fff;
    border-radius: ${theme.radii.xxs};
    line-height: ${theme.lineHeights.m};
    font-size: ${theme.fontSizes.body.m};
    font-weight: ${theme.fontWeights.semibold};
    background-color: ${theme.colors.main.background.black};

    &.nano {
      line-height: ${theme.lineHeights.xs};
      font-size: ${theme.fontSizes.caption.m};
      font-weight: ${theme.fontWeights.semibold};
    }
  }

  .rc-tooltip-hidden {
    display: none;
  }

  .rc-tooltip-placement-top,
  .rc-tooltip-placement-topLeft,
  .rc-tooltip-placement-topRight {
    padding: 5px 0 9px 0;
  }

  .rc-tooltip-placement-right,
  .rc-tooltip-placement-rightTop,
  .rc-tooltip-placement-rightBottom {
    padding: 0 5px 0 9px;
  }

  .rc-tooltip-placement-bottom,
  .rc-tooltip-placement-bottomLeft,
  .rc-tooltip-placement-bottomRight {
    padding: 9px 0 5px 0;
  }

  .nano.rc-tooltip-placement-bottom {
    padding-top: 0px;
  }

  .nano .rc-tooltip-content {
    margin-top: -4px;
  }

  .rc-tooltip-placement-left,
  .rc-tooltip-placement-leftTop,
  .rc-tooltip-placement-leftBottom {
    padding: 0 9px 0 5px;
  }

  .rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .nano .rc-tooltip-arrow {
    display: none;
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${theme.colors.main.background.black};
    border-width: 7px 4px 0;
    margin-left: -4px;
    bottom: 2px;
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
  }

  .rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: 15%;
  }

  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 15%;
  }

  .rc-tooltip-placement-right .rc-tooltip-arrow,
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: ${theme.colors.main.background.black};
    border-width: 4px 7px 4px 0;
    margin-top: -3px;
    left: 2px;
  }

  .rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
  }

  .rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }

  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: 15%;
  }

  .rc-tooltip-placement-left .rc-tooltip-arrow,
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: ${theme.colors.main.background.black};
    border-width: 4px 0 4px 7px;
    margin-top: -3px;
    right: 2px;
  }

  .rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
  }

  .rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }

  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: 15%;
  }

  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${theme.colors.main.background.black};
    top: 2px;
    margin-left: -4px;
    border-width: 0 4px 7px;
  }

  .rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
  }

  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 15%;
  }

  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 15%;
  }

  .rc-tooltip.rc-tooltip-zoom-appear,
  .rc-tooltip.rc-tooltip-zoom-enter {
    opacity: 0;
  }

  .rc-tooltip.rc-tooltip-zoom-enter,
  .rc-tooltip.rc-tooltip-zoom-leave {
    display: block;
  }

  .rc-tooltip-zoom-enter,
  .rc-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: 60ms;
    animation-fill-mode: both;
    animation-play-state: paused;
  }

  .rc-tooltip-zoom-leave {
    animation-duration: 60ms;
    animation-fill-mode: both;
    animation-play-state: paused;
  }

  .rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
  .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
    animation-name: rcToolTipZoomIn;
    animation-play-state: running;
  }

  .rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
    animation-name: rcToolTipZoomOut;
    animation-play-state: running;
  }

  @keyframes rcToolTipZoomIn {
    0% {
      opacity: 0;
      transform: scale(0.9, 0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  @keyframes rcToolTipZoomOut {
    0% {
      opacity: 1;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform: scale(0.9, 0.9);
    }
  }
`
