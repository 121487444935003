import { styled } from 'ui/theme'
import { typography, space, color, layout } from 'styled-system'

import type { TextProps } from 'ui/typography/Text/types'

const Text = styled.span<TextProps>`
  ${typography};
  ${space};
  ${color};
  ${layout};
`

export { Text }
