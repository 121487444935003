import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Phone: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.15161 4.34319C6.76108 3.95266 6.12792 3.95266 5.73739 4.34319L5.3788 4.70178C4.61755 5.46303 4.32256 6.57382 4.60582 7.61246L4.75148 8.14651C5.7763 11.9042 8.71189 14.8398 12.4696 15.8646L13.0036 16.0103C14.0423 16.2935 15.1531 15.9986 15.9143 15.2373L16.2729 14.8787C16.6634 14.4882 16.6634 13.855 16.2729 13.4645L15.577 12.7686C15.2473 12.4389 14.7127 12.4389 14.383 12.7686C13.8682 13.2834 13.1067 13.4632 12.416 13.233L12.7323 12.2843L12.416 13.233L11.9618 13.0815C9.87152 12.3848 8.23131 10.7446 7.53457 8.65435L7.38315 8.20008C7.15292 7.50939 7.33268 6.7479 7.84749 6.23308C8.17721 5.90337 8.17721 5.36879 7.84749 5.03907L7.15161 4.34319ZM4.32318 2.92897C5.49475 1.7574 7.39425 1.7574 8.56582 2.92897L9.26171 3.62486C10.3611 4.72421 10.3724 6.4996 9.29559 7.61285L9.43194 8.02189C9.92961 9.51492 11.1012 10.6865 12.5942 11.1842L12.278 12.1329L12.5942 11.1842L13.0033 11.3205C14.1165 10.2438 15.8919 10.255 16.9913 11.3544L17.6871 12.0503C18.8587 13.2219 18.8587 15.1214 17.6871 16.2929L17.3285 16.6515C16.0598 17.9203 14.2085 18.4119 12.4774 17.9398L11.9434 17.7942C7.50244 16.583 4.03311 13.1137 2.82195 8.67275L2.6763 8.13869C2.20419 6.40763 2.69583 4.55632 3.96458 3.28757L4.32318 2.92897Z'
      fill={color}
    />
  </Icon>
)

Phone.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Phone }
