import { useState, forwardRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import NumberFormat from 'react-number-format'

import { Condition, Tooltip, Typography } from 'ui'
import { useAppTheme } from 'ui/theme'
import { DeniedIcon } from 'ui/icons'

import { PasswordVisibilityIcon, ClearValueIcon } from './components'

import { FormFieldWrap } from './styles'

import { FormFieldMaskType } from './types'

import type { FC } from 'react'
import type { FormFieldProps } from './types'

const { Caption } = Typography

const FormField: FC<FormFieldProps> = forwardRef<
  HTMLInputElement,
  FormFieldProps
>(
  (
    {
      label,
      subText,
      size,
      maskType,
      type,
      error,
      onClear,
      tooltip,
      allowClear,
      disabled = false,
      isPaginate,
      width,
      readOnly,
      ...props
    },
    ref,
  ) => {
    const theme = useAppTheme()
    const [passwordType, setPasswordType] = useState('password')

    const onEyeClick = () => {
      setPasswordType(passwordType === 'password' ? 'text' : 'password')
    }

    const FormFieldContainer = (
      <div className='__form-field-container'>
        <CSSTransition
          in={error}
          timeout={100}
          unmountOnExit
          classNames='__form-field-error'
        >
          <div className='__form-field-error-icon'>
            <DeniedIcon color={theme.colors.main.status.error} />
          </div>
        </CSSTransition>

        {maskType ? (
          <NumberFormat
            {...(props as any)}
            readOnly={readOnly}
            disabled={disabled}
            className='__form-field-input'
            format={FormFieldMaskType[maskType]}
            type={type === 'password' ? passwordType : type}
            mask='_'
          />
        ) : (
          <input
            {...props}
            ref={ref}
            readOnly={readOnly}
            disabled={disabled}
            className='__form-field-input'
            type={type === 'password' ? passwordType : type}
          />
        )}

        <Condition match={type === 'password'}>
          <PasswordVisibilityIcon onEyeClick={onEyeClick} type={passwordType} />
        </Condition>

        <Condition
          match={
            type !== 'password' &&
            String(props.value).length !== 0 &&
            allowClear
          }
        >
          {!readOnly && <ClearValueIcon onClear={onClear} />}
        </Condition>
      </div>
    )

    return (
      <FormFieldWrap
        size={size}
        width={width}
        error={error}
        value={props.value}
        allowClear={allowClear && !readOnly}
        isPaginate={isPaginate}
      >
        <label>
          <Condition match={Boolean(label)}>
            <Caption
              as='p'
              mb='4px'
              weight='semibold'
              color={
                disabled
                  ? theme.greyColorPalette[20]
                  : theme.colors.projects.primaryMarket
              }
            >
              {label}
            </Caption>
          </Condition>

          {!tooltip ? (
            FormFieldContainer
          ) : (
            <Tooltip
              placement={tooltip.placement || 'right'}
              visible={tooltip.visible}
              overlay={tooltip.overlay}
            >
              {FormFieldContainer}
            </Tooltip>
          )}
        </label>

        <Condition match={Boolean(subText)}>
          <Caption
            as='p'
            mt='8px'
            weight='semibold'
            color={
              disabled
                ? theme.greyColorPalette[20]
                : theme.colors.text.secondaryBlack
            }
          >
            {subText}
          </Caption>
        </Condition>
      </FormFieldWrap>
    )
  },
)

FormField.defaultProps = {
  size: 'default',
  allowClear: true,
}

export { FormField }
