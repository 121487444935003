import { styled, useAppTheme } from 'ui/theme'

import { Tooltip, Typography } from 'ui'
import { MenuCollapseIcon, MenuIcon } from 'ui/icons'

import type { FC, MouseEventHandler } from 'react'

const { Caption } = Typography

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>
  isOpen: boolean
  caption: string
}

export const StyledButton = styled.button<Omit<Props, 'caption'>>`
  border: none;
  width: 100%;
  min-height: 52px;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${props => (props.isOpen ? '0 12px 0 34px' : '0')};
  justify-content: ${props => (props.isOpen ? 'space-between' : 'center')};

  &:hover .icon {
    background-color: ${props => props.theme.colors.main.background.gray};
  }
`

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 12px;
  width: 40px;
  height: 32px;
  padding: 0;
`

const ExpandMenuButton: FC<Props> = ({ caption, isOpen, onClick }) => {
  const theme = useAppTheme()

  return (
    <Tooltip overlay={isOpen ? 'Свернуть' : 'Развернуть'}>
      <StyledButton isOpen={isOpen} onClick={onClick}>
        {isOpen && (
          <Caption as='h3' color='text.secondaryBlack'>
            {caption}
          </Caption>
        )}

        <IconWrap className='icon'>
          {isOpen ? (
            <MenuCollapseIcon color={theme.colors.projects.primaryMarket} />
          ) : (
            <MenuIcon color={theme.colors.projects.primaryMarket} />
          )}
        </IconWrap>
      </StyledButton>
    </Tooltip>
  )
}

export { ExpandMenuButton }
