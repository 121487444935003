import { useState } from 'react'

import { initialDetails } from 'modules/orders/slices/orders'

import type { OrderType } from 'modules/orders/types'

export const useRejectModal = () => {
  const [rejectModal, setRejectModal] = useState({
    visible: false,
    details: initialDetails,
  })

  const onClose = () => {
    setRejectModal({
      visible: false,
      details: initialDetails,
    })
  }

  const onShow = (details: OrderType) => {
    setRejectModal({
      visible: true,
      details,
    })
  }

  return {
    visible: rejectModal.visible,
    details: rejectModal.details,
    onClose,
    onShow,
  }
}
