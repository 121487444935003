import { styled } from 'ui/theme'
import { mainHeader } from 'modules/common/constants/properties'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header header'
    'aside main'
    'footer footer';
  min-height: 100vh;
`

export const Aside = styled.aside`
  grid-area: aside;
  width: 400px;
`

export const Nav = styled.nav`
  position: sticky;
  top: ${mainHeader.height};
  padding: 24px 0 24px 48px;
  max-height: calc(100vh - ${mainHeader.height});
  overflow-y: auto;
  white-space: nowrap;
  box-sizing: border-box;
`

export const Main = styled.main`
  grid-area: main;
  overflow-x: auto;
`
