import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppThunk, instance } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'
import { getDataWithKey } from 'utils/getDataWithKey'

import { EmployeesState } from 'modules/profile/types/interfaces'
import {
  EmployeeType,
  EmployeePermissionType,
  EmployeeTdidType,
} from 'modules/profile/types'
import { AxiosResponse } from 'axios'

/**
 * Actions
 */

export const fetchEmployees: AppThunk<EmployeeType[]> = createAsyncThunk(
  'employees/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get('/merchant-profile/api/v1/profile/employees')
      .then((res: AxiosResponse<EmployeeType[]>) =>
        getDataWithKey(res.data, 'tdid'),
      )
      .catch(thunkErrorHandler(thunkApi)),
)

export const fetchEmployeesPermissions: AppThunk<EmployeePermissionType[], EmployeeTdidType> = createAsyncThunk(
  'employeesPermission/fetch',
  (body, thunkApi) =>
    instance(thunkApi)
      .get(`/merchant-profile/api/v1/profile/employees/${body.tdid}/permissions`)
      .then((res: AxiosResponse<EmployeePermissionType[]>) =>
        res.data
      )
      .catch(thunkErrorHandler(thunkApi)),
)

/**
 * Reducer
 */

const initialState: EmployeesState = {
  list: [],
  loading: false,
  error: null,

  listOfPermissions: [],
  permissionsLoading: false,
  permissionError: null
}

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchEmployees.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchEmployees.fulfilled, (state, { payload }) => {
        state.loading = false
        state.error = null
        state.list = payload
      })
      .addCase(fetchEmployees.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.error
      })

      .addCase(fetchEmployeesPermissions.pending, state => {
        state.permissionsLoading = true
        state.permissionError = null
      })
      .addCase(fetchEmployeesPermissions.fulfilled, (state, { payload }) => {
        state.permissionsLoading = false
        state.permissionError = null
        state.listOfPermissions = payload
      })
      .addCase(fetchEmployeesPermissions.rejected, (state, { payload }) => {
        state.permissionsLoading = false
        state.permissionError = payload.error
      })
})

export default employeesSlice.reducer
