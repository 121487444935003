import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Exit: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.7929 5.79289C12.1834 5.40237 12.8166 5.40237 13.2071 5.79289L16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L13.2071 14.2071C12.8166 14.5976 12.1834 14.5976 11.7929 14.2071C11.4024 13.8166 11.4024 13.1834 11.7929 12.7929L13.5858 11H7C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9H13.5858L11.7929 7.20711C11.4024 6.81658 11.4024 6.18342 11.7929 5.79289Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 3C3 2.44772 3.44772 2 4 2H9C9.55228 2 10 2.44772 10 3C10 3.55228 9.55228 4 9 4H5V16H9C9.55228 16 10 16.4477 10 17C10 17.5523 9.55228 18 9 18H4C3.44772 18 3 17.5523 3 17V3Z'
      fill={color}
    />
  </Icon>
)

Exit.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Exit }
