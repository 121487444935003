import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { instance } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'

import type { AppThunk } from 'store/configureStore'
import type { ContactsState } from 'modules/common/types/interfaces'
import type { AxiosResponse } from 'axios'
import type { Contact } from 'modules/common/types'

/**
 * Actions
 */

export const fetchContacts: AppThunk<Contact[]> = createAsyncThunk(
  'contacts/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get('/config-discovery/api/v2/contacts', {
        params: { user_type: 'merchant' },
      })
      .then(
        (res: AxiosResponse<{ contacts_info: Contact[] }>) =>
          res.data.contacts_info,
      )
      .catch(thunkErrorHandler(thunkApi)),
)

/**
 * Reducer
 */

const initialState: ContactsState = {
  list: [],
  loading: false,
  error: null,
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchContacts.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchContacts.fulfilled, (state, { payload }) => {
        state.loading = false
        state.error = null
        state.list = payload
      })
      .addCase(fetchContacts.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.error
      }),
})

export default contactsSlice.reducer
