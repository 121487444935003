import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { parseISO, differenceInDays } from 'date-fns'

import { Typography, Button, Box, Modal, Divider } from 'ui'
import { InfoIcon } from 'ui/icons'

import { useInstallmentDetails } from 'modules/installments/hooks/useInstallmentDetails'
import {
  formatCurrency,
  formatDate,
  formatDays,
  formatPercents,
} from 'utils/textFormatting'

const { Body, Subtitle, Caption } = Typography

interface Props {
  id: string
}

const InstallmentDetails: FC<Props> = ({ id }) => {
  const history = useHistory()
  const { installmentDetails, installmentDetailsError } =
    useInstallmentDetails(id)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  if (installmentDetailsError?.code === 404) {
    history.push('/404')
  }

  const start = parseISO(installmentDetails?.start_at)
  const end = parseISO(installmentDetails?.end_at)

  const daysBetween = differenceInDays(end, start)
  const days = daysBetween >= 0 ? daysBetween + 1 : 0

  return (
    <>
      <Box mt='24px' px='24px'>
        <Subtitle>Информация об акции</Subtitle>

        <Box
          as='dl'
          display='grid'
          mt='8px'
          gridTemplateColumns='max-content max-content'
          gridColumnGap='16px'
        >
          <Box display='grid' gridGap='8px'>
            <Body as='dt'>Тип акции</Body>
            <Body as='dt'>Период проведения</Body>
            <Body as='dt'>Минимальная сумма товара для участия в акции</Body>
            <Body as='dt'>Максимальная сумма товара для участия в акции</Body>
          </Box>

          <Box display='grid' gridGap='8px'>
            <Body as='dd' margin='0'>
              {installmentDetails?.title?.ru
                ? installmentDetails?.title?.ru
                : '-'}
            </Body>
            <Body>
              {formatDate(start, 'С dd MMMM')} {formatDate(end, 'по dd MMMM')} (
              {days} {formatDays(days)})
            </Body>
            <Body as='dd'>
              {installmentDetails?.max_product_price
                ? formatCurrency(installmentDetails?.min_product_price)
                : '-'}
            </Body>
            <Body as='dd'>
              {installmentDetails?.max_product_price
                ? formatCurrency(installmentDetails.max_product_price)
                : '-'}
            </Body>
          </Box>
        </Box>

        <Button mt='22px' onClick={handleOpenModal}>
          Категории участвующие в акции
        </Button>

        <Box display='flex' alignItems='center' mt='16px'>
          <InfoIcon />
          <Caption as='p' ml='8px' color='text.secondaryBlack'>
            В списке находятся только те товары, которые подходят под условия
            данной акции
          </Caption>
        </Box>
      </Box>

      <Modal
        visible={modalIsOpen}
        title='Категории и комисии'
        onClose={handleCloseModal}
        width='365px'
        bodyStyle={{
          height: '504px',
          display: 'flex',
          flexDirection: 'column',
          margin: '0 -16px',
        }}
      >
        <Box px='16px'>
          <Body as='p' mb='16px'>
            Список категорий учавствующих в акции
          </Body>
        </Box>

        <Divider />

        <Box
          display='grid'
          gridRowGap='8px'
          py='16px'
          alignContent='start'
          flex='1 0 0'
          overflowY='auto'
          px='16px'
        >
          {installmentDetails?.categories.map(category => (
            <Box
              key={category.slug}
              display='grid'
              gridAutoFlow='column'
              gridTemplateColumns='1fr auto'
              gridColumnGap='8px'
              alignItems='center'
            >
              <Body key={category.slug}>{category?.title?.ru}</Body>
              <Body>{formatPercents(installmentDetails?.commission)}</Body>
            </Box>
          ))}
        </Box>

        <Divider />

        <Box px='16px'>
          <Button mt='16px' fullWidth onClick={handleCloseModal}>
            Закрыть
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export { InstallmentDetails }
