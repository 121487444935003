import { Box } from 'ui'
import { OrderDetailsTable, OrderDetailsInfo } from './components'

import type { FC } from 'react'

const OrderDetailsData: FC = () => (
  <Box>
    <Box px='24px' mb='24px'>
      <OrderDetailsInfo />
    </Box>
    <OrderDetailsTable />
  </Box>
)

export { OrderDetailsData }
