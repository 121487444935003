import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { instance, AppThunk } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'

import type {
  Refund,
  RefundsState,
  Sorting,
  UpdateRefundsStateParams,
} from 'modules/refunds/types'

/**
 * Init State
 */

const initialState: RefundsState = {
  refunds: [],
  refundsLoading: false,
  refundsError: null,

  updateRefundLoading: false,
  updateRefundError: null,

  filters: { search: '', type: null, state: null },
  sorting: '',
  pagination: {
    page: 1,
    limit: 20,
    total: null,
  },
}

/**
 * Actions
 */

export const fetchRefunds: AppThunk<Refund[]> = createAsyncThunk(
  'refunds/fetch',
  (_, thunkApi) => {
    const { pagination, filters, sorting } = thunkApi.getState().refunds.refunds

    const params = {
      sorting: sorting,
      limit: pagination.limit,
      page: pagination.page,
      order_id: filters.search ? filters.search : undefined,
    }

    const config = {
      params,
    }

    return instance(thunkApi)
      .get(`/rms/api/v1/refunds`, config)
      .then(res => {
        thunkApi.dispatch(refundsSlice.actions.changeTotal(res.data.count))

        return res.data.refunds
      })
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const updateRefundsState: AppThunk<void, UpdateRefundsStateParams> =
  createAsyncThunk('refunds/updateState', (options, thunkApi) => {
    const { id, state, onSuccess } = options

    const body = {
      state,
    }

    return instance(thunkApi)
      .patch(`/rms/api/v1/refund/${id}`, body)
      .then(() => {
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      })
      .catch(thunkErrorHandler(thunkApi))
  })

export const changeRefundsSorting: AppThunk<void, Sorting> = createAsyncThunk(
  'refunds/changeSorting',
  (sorting, thunkApi) => {
    thunkApi.dispatch(changeSorting(sorting))
    thunkApi.dispatch(fetchRefunds())
  },
)

export const changeRefundsPage: AppThunk<void, number> = createAsyncThunk(
  'products/changePage',
  (page, thunkApi) => {
    thunkApi.dispatch(refundsSlice.actions.changePage(page))
    thunkApi.dispatch(fetchRefunds())
  },
)

export const changeRefundsLimit: AppThunk<void, number> = createAsyncThunk(
  'products/changeLimit',
  (limit, thunkApi) => {
    thunkApi.dispatch(refundsSlice.actions.changeLimit(limit))
    thunkApi.dispatch(fetchRefunds())
  },
)

/**
 * Slice
 */

const refundsSlice = createSlice({
  name: 'refunds',
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    changeLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload
    },
    changeSearchFilter: (state, action: PayloadAction<string>) => {
      state.pagination.page = 1
      state.filters.search = action.payload
    },
    changeSorting: (state, action: PayloadAction<Sorting>) => {
      state.sorting = action.payload
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.pagination.total = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRefunds.pending, state => {
        state.refundsLoading = true
        state.refundsError = null
      })
      .addCase(fetchRefunds.fulfilled, (state, action) => {
        state.refunds = action.payload
        state.refundsLoading = false
        state.refundsError = null
      })
      .addCase(fetchRefunds.rejected, (state, action) => {
        state.refundsLoading = false
        state.refundsError = action.payload.error
      })

      .addCase(updateRefundsState.pending, state => {
        state.updateRefundLoading = true
        state.updateRefundError = null
      })
      .addCase(updateRefundsState.fulfilled, state => {
        state.updateRefundLoading = false
        state.updateRefundError = null
      })
      .addCase(updateRefundsState.rejected, (state, action) => {
        state.updateRefundLoading = false
        state.updateRefundError = action.payload.error
      })
  },
})

export const { changeSearchFilter, changeSorting } = refundsSlice.actions
export default refundsSlice.reducer
