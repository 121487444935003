import { HistoryFilters, HistoryTable } from './components'
import { useUploadHistory } from 'modules/products/hooks'

const UploadHistory = () => {
  const { uploadHistory } = useUploadHistory()

  return (
    <>
      <HistoryFilters />
      <HistoryTable records={uploadHistory} />
    </>
  )
}

export { UploadHistory }
