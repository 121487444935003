import { FC } from 'react'
import { Box, Select } from 'ui'
import { ALL_OPTIONS } from 'modules/common/constants/dictionaries'
import { addMonths, eachMonthOfInterval } from 'date-fns'

import { InstallmentCategory } from 'modules/installments/types/types'
import { capitalize, formatDate } from 'utils/textFormatting'

const { Option } = Select

interface Props {
  categories: InstallmentCategory[]
  selectedCategory: string
  selectedMonth: number | null
  onChangeCategory: (category: string) => void
  onChangeMonth: (month: number) => void
}

const InstallmentsFilters: FC<Props> = ({
  categories,
  selectedCategory,
  selectedMonth,
  onChangeCategory,
  onChangeMonth,
}) => {
  const now = new Date()

  const nowPlus2Months = addMonths(now, 2)

  const months = eachMonthOfInterval({ start: now, end: nowPlus2Months })

  return (
    <Box
      display='grid'
      gridGap='16px'
      gridAutoFlow='column'
      justifyContent='start'
      px='24px'
    >
      <Box width='296px'>
        <Select
          label='Период прохождения акции'
          placeholder='Период прохождения акции'
          onChange={onChangeMonth}
          value={selectedMonth}
        >
          {months.map(month => (
            <Option key={month.getMonth()} value={month.getMonth()}>
              {capitalize(formatDate(month, 'LLLL'))}
            </Option>
          ))}
        </Select>
      </Box>

      <Box width='296px'>
        <Select
          label='Категория'
          value={
            selectedCategory === ALL_OPTIONS
              ? [ALL_OPTIONS, ...categories.map(cat => cat.slug)]
              : selectedCategory
          }
          onChange={onChangeCategory}
        >
          <Option value={ALL_OPTIONS}>Все категории</Option>
          {categories.map(category => (
            <Option key={category.slug} value={category.slug}>
              {category?.title?.ru}
            </Option>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export { InstallmentsFilters }
