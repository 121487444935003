import { useState } from 'react'
import ReactPaginate from 'react-paginate'

import { Box, FormField, Select } from 'ui'
import { PaginationWrap } from 'ui/pagination/styles'
import { ChevronIcon, EllipsisIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'

import type { FC } from 'react'
import type { PaginationProps } from 'ui/pagination/types'

const Pagination: FC<PaginationProps> = ({
  page = 1,
  limit,
  total,
  onPageChange,
  onLimitChange,
}) => {
  const [pageTo, setPageTo] = useState<number>()
  const theme = useAppTheme()

  const pageCount = total / limit
  const pageNumber = page - 1

  return (
    Boolean(total) &&
    pageCount > 1 && (
      <PaginationWrap>
        <ReactPaginate
          pageCount={Math.ceil(pageCount)}
          forcePage={pageNumber}
          onPageChange={({ selected }) => onPageChange(selected + 1)}
          previousLabel={<ChevronIcon color={theme.colors.icon.black} left />}
          nextLabel={<ChevronIcon color={theme.colors.icon.black} right />}
          disableInitialCallback={true}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          breakLabel={<EllipsisIcon />}
          containerClassName='__pagination-container'
          breakClassName='__break'
          pageClassName='__page'
          activeClassName='__active-page'
          previousClassName='__prev-button'
          nextClassName='__next-button'
          disabledClassName='__disabled'
        />
        <>
          {total > 250 && (
            <Box maxWidth={157} mr='8px'>
              <Select
                value={[limit]}
                listHeight={95}
                onChange={value => onLimitChange(value)}
                size='nano'
                isPaginate
              >
                <Select.Option value={10}>10/страница</Select.Option>
                <Select.Option value={15}>15/страница</Select.Option>
                <Select.Option value={20}>20/страница</Select.Option>
                <Select.Option value={30}>30/страница</Select.Option>
                <Select.Option value={50}>50/страница</Select.Option>
              </Select>
            </Box>
          )}
          {pageCount > 5 && (
            <Box display='flex' alignItems='center'>
              Перейти к
              <Box maxWidth='55px' ml='8px'>
                <FormField
                  defaultValue={pageTo}
                  isPaginate
                  size='nano'
                  allowClear={false}
                  type='number'
                  min={1}
                  max={Math.ceil(pageCount)}
                  onChange={event => setPageTo(+event.target.value)}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      onPageChange(pageTo)
                    }
                  }}
                />
              </Box>
            </Box>
          )}
        </>
      </PaginationWrap>
    )
  )
}

export { Pagination }
