import { Typography, Box, Link } from 'ui'
import { useLastSuccessfulUpload } from 'modules/products/hooks/useLastSuccessfulUpload'
import { formatDate } from 'utils/textFormatting'

import type { FC } from 'react'

const { Subtitle, Body } = Typography

interface Props {
  download: boolean
  pathname: string
  format: string
}

const ActiveFeedCard: FC<Props> = ({ download, pathname, format }) => {
  const { lastSuccessfulUpload } = useLastSuccessfulUpload()

  return (
    <Box width='400px' mt='24px' ml='100px'>
      <Box
        display='flex'
        flexDirection='column'
        backgroundColor='main.background.gray'
        borderRadius='xxs'
        padding='16px'
        mb='24px'
      >
        {lastSuccessfulUpload ? (
          <>
            <Subtitle mb='8px'>
              Сейчас активен прайс–лист {lastSuccessfulUpload.entity_id}
            </Subtitle>
            <Body>
              Дата загрузки:{' '}
              {lastSuccessfulUpload?.meta?.updated_at
                ? formatDate(
                    new Date(lastSuccessfulUpload.meta.updated_at),
                    'В HH:mm, dd MMMM yyyy',
                  )
                : 'неизвестна'}
            </Body>
          </>
        ) : (
          <Subtitle>
            Сейчас нет активного <br />
            прайс–листа
          </Subtitle>
        )}
      </Box>

      <Body as='p' weight='semibold' mb='8px'>
        Скачать шаблон для заполнения
      </Body>

      <Link
        download={download}
        target='_blank'
        to={{
          pathname,
        }}
      >
        Формат <strong>{format}</strong>
      </Link>
    </Box>
  )
}

export { ActiveFeedCard }
