import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useInstructionPage } from 'modules/faq/hooks'

import { Modal } from 'ui'

import { Container, Image, getModalStyles } from './styles'

import type { FC, SyntheticEvent } from 'react'
import { Global } from '@emotion/react'

interface Params {
  slug: string
}

const Faq: FC = () => {
  const { slug } = useParams<Params>()

  const { instructionPage } = useInstructionPage(slug)

  const [imageModalVisible, setImageModalVisible] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>(null)

  const handleClick = (event: SyntheticEvent<HTMLDivElement>) => {
    const target = event.target as HTMLImageElement

    if (target.tagName.toLowerCase() === 'img') {
      setImageUrl(target.src)
      setImageModalVisible(true)
    }
  }

  const handleClose = () => {
    setImageModalVisible(false)
  }

  const html = instructionPage
    ? `<h1>${instructionPage.title.ru}</h1>${instructionPage.body.ru}`
    : ''

  return (
    <>
      <Container
        onClick={handleClick}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />

      <Global styles={getModalStyles} />
      <Modal visible={imageModalVisible} onClose={handleClose}>
        <Image src={imageUrl} alt={'kek'} />
      </Modal>
    </>
  )
}

export { Faq }
