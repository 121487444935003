import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Calendar: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M2.5 10C2.5 9.44772 2.94772 9 3.5 9H4.5C5.05228 9 5.5 9.44772 5.5 10C5.5 10.5523 5.05228 11 4.5 11H3.5C2.94772 11 2.5 10.5523 2.5 10ZM6.5 10C6.5 10.5523 6.94772 11 7.5 11H8.5C9.05228 11 9.5 10.5523 9.5 10C9.5 9.44772 9.05228 9 8.5 9H7.5C6.94772 9 6.5 9.44772 6.5 10ZM10.5 10C10.5 10.5523 10.9477 11 11.5 11H12.5C13.0523 11 13.5 10.5523 13.5 10C13.5 9.44772 13.0523 9 12.5 9H11.5C10.9477 9 10.5 9.44772 10.5 10ZM14.5 10C14.5 10.5523 14.9477 11 15.5 11H16.5C17.0523 11 17.5 10.5523 17.5 10C17.5 9.44771 17.0523 9 16.5 9H15.5C14.9477 9 14.5 9.44772 14.5 10ZM15.5 6C14.9477 6 14.5 6.44772 14.5 7C14.5 7.55228 14.9477 8 15.5 8H16.5C17.0523 8 17.5 7.55228 17.5 7C17.5 6.44772 17.0523 6 16.5 6H15.5ZM10.5 7C10.5 7.55228 10.9477 8 11.5 8H12.5C13.0523 8 13.5 7.55228 13.5 7C13.5 6.44772 13.0523 6 12.5 6H11.5C10.9477 6 10.5 6.44772 10.5 7ZM6.5 7C6.5 7.55228 6.94771 8 7.5 8H8.5C9.05229 8 9.5 7.55228 9.5 7C9.5 6.44772 9.05228 6 8.5 6H7.49999C6.94771 6 6.5 6.44772 6.5 7ZM2.5 13C2.5 13.5523 2.94772 14 3.5 14H4.5C5.05228 14 5.5 13.5523 5.5 13C5.5 12.4477 5.05228 12 4.5 12H3.5C2.94772 12 2.5 12.4477 2.5 13ZM6.5 13C6.5 13.5523 6.94772 14 7.5 14H8.5C9.05228 14 9.5 13.5523 9.5 13C9.5 12.4477 9.05228 12 8.5 12H7.5C6.94772 12 6.5 12.4477 6.5 13ZM10.5 13C10.5 13.5523 10.9477 14 11.5 14H12.5C13.0523 14 13.5 13.5523 13.5 13C13.5 12.4477 13.0523 12 12.5 12H11.5C10.9477 12 10.5 12.4477 10.5 13Z'
      fill={color}
    />
  </Icon>
)

Calendar.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Calendar }
