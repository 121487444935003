import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import type { AsideState } from '../types/interfaces'

/**
 * Initial State
 */

const initialState: AsideState = {
  isOpen: true,
}

/**
 * Slice
 */

const asideMenuSlice = createSlice({
  name: 'asideMenu',
  initialState,
  reducers: {
    toggleAside: state => {
      state.isOpen = !state.isOpen
    },
  },
})

export const { toggleAside } = asideMenuSlice.actions

export default persistReducer(
  {
    storage,
    key: 'asideMenu',
    version: 1,
  },
  asideMenuSlice.reducer,
)
