import { useEffect, useState } from 'react'

import { formatDate } from 'utils/textFormatting'

import { BaseInputPicker } from 'ui/datePicker/BaseInputPicker'
import { Dropdown, RangePicker } from 'ui'

import type { FC } from 'react'
import type {
  RangePickerOverlayProps,
  DatePickerInputProps,
  DateRange,
} from 'ui/datePicker/types'

const RangePickerInput: FC<DatePickerInputProps & RangePickerOverlayProps> = ({
  size = 'default',
  label,
  subText,
  date,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const [newDate, setNewDate] = useState<DateRange>({
    from: null,
    to: null,
  })

  useEffect(() => {
    if (date.from && date.to) {
      setVisible(false)
    }

    setNewDate(date)
  }, [date])

  useEffect(() => {
    if (visible) {
      setNewDate({
        from: null,
        to: null,
      })
    }
  }, [visible])

  const dateFrom = date.from
    ? formatDate(date.from, 'dd MMM yyyy г.')
    : 'Не выбрано'

  const dateTo = date.to ? ` - ${formatDate(date.to, 'dd MMM yyyy г.')}` : ''

  const value = dateFrom + dateTo

  return (
    <Dropdown
      overlay={<RangePicker date={newDate} {...props} />}
      onVisibleChange={value => setVisible(value)}
      visible={visible}
    >
      <div>
        <BaseInputPicker
          size={size}
          value={value}
          label={label}
          subText={subText}
          visible={visible}
          showClear={Boolean(date.to || date.from)}
          onClear={() => {
            props.onChange({
              from: null,
              to: null,
            })
          }}
        />
      </div>
    </Dropdown>
  )
}

export { RangePickerInput }
