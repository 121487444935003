import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Facebook: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <g clipPath='url(#clip0_39_61)'>
      <path d='M11.1616 17.5V10.6583H13.4572L13.8016 7.99117H11.1616V6.2886C11.1616 5.51665 11.3751 4.99057 12.4834 4.99057L13.8945 4.98999V2.60442C13.6505 2.57271 12.8128 2.5 11.8378 2.5C9.80183 2.5 8.40798 3.74272 8.40798 6.02445V7.99117H6.10547V10.6583H8.40798V17.5H11.1616Z' />
    </g>
    <defs>
      <clipPath id='clip0_39_61'>
        <rect width='15' height='15' transform='translate(2.5 2.5)' />
      </clipPath>
    </defs>
  </Icon>
)

Facebook.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Facebook }
