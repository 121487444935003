import { Global } from '@emotion/react'
import RcSelect, { Option } from 'rc-select'

import { getSelectStyles, SelectWrap } from 'ui/inputs/Select/styles'
import { ActiveCircleIcon, ChevronIcon, CloseIcon } from 'ui/icons'
import { Typography, Condition } from 'ui'
import { useAppTheme } from 'ui/theme'

import type { SelectProps } from 'ui/inputs/Select/types'

const { Caption } = Typography

const Select: SelectProps = ({
  children,
  disabled,
  label,
  subText,
  size,
  isPaginate,
  ...props
}) => {
  const theme = useAppTheme()

  return (
    <SelectWrap size={size} isPaginate={isPaginate}>
      <Global styles={getSelectStyles} />
      <Condition match={Boolean(label)}>
        <Caption
          as='p'
          mb='4px'
          weight='semibold'
          color={
            disabled
              ? theme.greyColorPalette[20]
              : theme.colors.projects.primaryMarket
          }
        >
          {label}
        </Caption>
      </Condition>
      <RcSelect
        {...props}
        disabled={disabled}
        menuItemSelectedIcon={<ActiveCircleIcon />}
        clearIcon={<CloseIcon color={theme.colors.icon.secondaryBlack} />}
        inputIcon={<ChevronIcon color={theme.colors.icon.black} down />}
      >
        {children}
      </RcSelect>
      <Condition match={Boolean(subText)}>
        <Caption
          as='p'
          mt='8px'
          weight='semibold'
          color={
            disabled
              ? theme.greyColorPalette[20]
              : theme.colors.text.secondaryBlack
          }
        >
          {subText}
        </Caption>
      </Condition>
    </SelectWrap>
  )
}

Select.Option = Option

Select.defaultProps = {
  size: 'default',
  animation: 'slide-up',
}

export { Select }
