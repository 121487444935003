import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { AppThunk, instance } from 'store/configureStore'

import history from 'settings/history'
import { thunkErrorHandler } from 'utils/errorHandler'
import { getDataWithKey } from 'utils/getDataWithKey'

import { toast } from 'ui'
import {
  YANDEX_MAP_KEY_SEARCH,
  YANDEX_MAP_KEY_GEO,
} from 'modules/common/constants/dictionaries'

import type { AxiosError } from 'modules/common/types'
import type { AxiosResponse } from 'axios'
import type {
  FetchPickupPointType,
  PickupPointType,
  EditPickupPoint,
} from 'modules/profile/types'
import type {
  ChangePickupPointStatus,
  CreatePickupPoint,
  PickupPointsState,
} from 'modules/profile/types/interfaces'

/**
 * Actions
 */

export const fetchPickupPoints: AppThunk<PickupPointType[]> = createAsyncThunk(
  'profile/pickupPoints/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get('/merchant-profile/api/v1/profile/pickup-points')
      .then((res: AxiosResponse<PickupPointType[]>) =>
        getDataWithKey(res.data, 'store_id'),
      )
      .catch(thunkErrorHandler(thunkApi)),
)

export const fetchPickupPoint: AppThunk<PickupPointType, FetchPickupPointType> =
  createAsyncThunk('profile/pickupPoint/fetch', (params, thunkApi) =>
    instance(thunkApi)
      .get(`/merchant-profile/api/v1/profile/pickup-points/${params.store_id}`)
      .then((res: AxiosResponse<PickupPointType>) => {
        params.changeState(res.data)
        return res.data
      })
      .catch(thunkErrorHandler(thunkApi)),
  )

export const getDataFromMap = (searchAddress: string) => {
  return axios.get(
    `https://search-maps.yandex.ru/v1/?text=${searchAddress}&type=geo&lang=ru_RU&apikey=${YANDEX_MAP_KEY_SEARCH}&bbox=76.813044,43.188202~77.021785,43.351774&rspn=1`,
  )
}

export const getAddressFromGeo = (geo: number[]) => {
  const geoString = geo.join()
  return axios.get(
    `https://geocode-maps.yandex.ru/1.x/?apikey=${YANDEX_MAP_KEY_GEO}&geocode=${geoString}&format=json&bbox=76.813044,43.188202~77.021785,43.351774&rspn=1`,
  )
}

export const disablePickupPoint: AppThunk<
  string | string[],
  ChangePickupPointStatus
> = createAsyncThunk(
  'profile/pickupPoints/disablePickupPoint',
  (body, thunkApi) =>
    typeof body.storeID === 'string'
      ? instance(thunkApi)
          .put(
            `/merchant-profile/api/v1/profile/pickup-points/${body.storeID}/disable`,
          )
          .then(() => {
            toast(`Точка выдачи выключена`)
            return body.storeID
          })
          .catch(thunkErrorHandler(thunkApi))
      : Promise.all(
          body.storeID.map(id =>
            instance(thunkApi)
              .put(
                `/merchant-profile/api/v1/profile/pickup-points/${id}/disable`,
              )
              .then(() => {
                return id
              })
              .catch(thunkErrorHandler(thunkApi)),
          ),
        ).then(res => {
          toast(`Точки выдачи выключены`)
          return res
        }),
)

// TODO: Типизировать
export const enablePickupPoint: AppThunk<any, ChangePickupPointStatus> =
  createAsyncThunk('profile/pickupPoints/enablePickupPoint', (body, thunkApi) =>
    typeof body.storeID === 'string'
      ? instance(thunkApi)
          .put(
            `/merchant-profile/api/v1/profile/pickup-points/${body.storeID}/enable`,
          )
          .then(() => {
            toast(`Точка выдачи включена`)
            return body.storeID as string
          })
          .catch(thunkErrorHandler(thunkApi))
      : Promise.all(
          body.storeID.map(id =>
            instance(thunkApi)
              .put(
                `/merchant-profile/api/v1/profile/pickup-points/${id}/enable`,
              )
              .then(() => {
                return id
              })
              .catch(thunkErrorHandler(thunkApi)),
          ),
        ).then(res => {
          toast(`Точки выдачи включены`)
          return res
        }),
  )

export const createPickupPoint: AppThunk<CreatePickupPoint, PickupPointType> =
  createAsyncThunk(
    'profile/pickupPoints/createPickupPoint',
    (params, thunkApi) =>
      instance(thunkApi)
        .post('/merchant-profile/api/v1/profile/pickup-points', params)
        .then(res => {
          history.push('/profile/pickup-points', {
            createAction: true,
          })
          toast('Точка выдачи сохранена')
          return res.data
        })
        .catch((err: AxiosError) => {
          if (err.response && err.response.data) {
            toast(err.response.data.error.message)
          }
          thunkErrorHandler(thunkApi)(err)
        }),
  )

export const editPickupPoint: AppThunk<any, EditPickupPoint> = createAsyncThunk(
  'profile/pickupPoints/editPickupPoint',
  (params, thunkApi) =>
    instance(thunkApi)
      .put(
        `/merchant-profile/api/v1/profile/pickup-points/${params.pickupPointId}`,
        params.pickupPoint,
      )
      .then(res => {
        history.push('/profile/pickup-points', {
          createAction: true,
        })
        toast('Точка выдачи сохранена')
        return res.data
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.data) {
          toast(err.response.data.error.message)
        }
        thunkErrorHandler(thunkApi)(err)
      }),
)

const toggleChangeList = (arr, id, status) =>
  typeof id === 'string'
    ? arr.map(list =>
        list.store_id === id ? { ...list, is_active: !list.is_active } : list,
      )
    : arr.map(item => {
        id.forEach(i =>
          item.store_id === i ? (item.is_active = !!status) : null,
        )
        return item
      })

/**
 * Reducer
 */

const initialState: PickupPointsState = {
  loadingList: true,
  loadingPickupPoint: false,
  loadingChangeStatus: false,
  loadingCreate: false,
  error: null,
  list: [],
  pickupPoint: {
    priority: 1,
    address: {
      apartment: '',
      city_id: '750000000',
      floor: null,
      geo: {
        latitude: '',
        longitude: '',
      },
      house: '',
      street: '',
      zipcode: '',
    },
    contact: {
      info: '',
      phone: '',
    },
    name: '',
    schedule: [
      { weekday: 1, time_ranges: [] },
      { weekday: 2, time_ranges: [] },
      { weekday: 3, time_ranges: [] },
      { weekday: 4, time_ranges: [] },
      { weekday: 5, time_ranges: [] },
      { weekday: 6, time_ranges: [] },
      { weekday: 7, time_ranges: [] },
    ],
    store_id: '',
  },
}

const pickupPoints = createSlice({
  name: 'pickupPoints',
  initialState,
  reducers: {
    onClear: state => {
      state.pickupPoint = initialState.pickupPoint
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPickupPoints.pending, state => {
        state.loadingList = true
        state.error = null
      })
      .addCase(fetchPickupPoints.fulfilled, (state, action) => {
        state.loadingList = false
        state.error = null
        state.list = action.payload
      })
      .addCase(fetchPickupPoints.rejected, (state, action) => {
        state.loadingList = false
        state.error = action.payload.error
      })

      .addCase(fetchPickupPoint.pending, state => {
        state.loadingPickupPoint = true
        state.error = null
      })
      .addCase(fetchPickupPoint.fulfilled, (state, action) => {
        state.loadingPickupPoint = false
        state.error = null
        state.pickupPoint = action.payload
      })
      .addCase(fetchPickupPoint.rejected, (state, action) => {
        state.loadingPickupPoint = false
        state.error = action.payload.error
      })

      .addCase(disablePickupPoint.pending, state => {
        state.loadingChangeStatus = true
        state.error = null
      })
      .addCase(disablePickupPoint.fulfilled, (state, action) => {
        state.loadingChangeStatus = false
        state.error = null
        state.list = toggleChangeList(state.list, action.payload, false)
      })
      .addCase(disablePickupPoint.rejected, (state, action) => {
        state.loadingChangeStatus = false
        state.error = action.payload.error
      })

      .addCase(enablePickupPoint.pending, state => {
        state.loadingChangeStatus = true
        state.error = null
      })
      .addCase(enablePickupPoint.fulfilled, (state, action) => {
        state.loadingChangeStatus = false
        state.error = null
        state.list = toggleChangeList(state.list, action.payload, true)
      })
      .addCase(enablePickupPoint.rejected, (state, action) => {
        state.loadingChangeStatus = false
        state.error = action.payload.error
      })

      .addCase(createPickupPoint.pending, state => {
        state.loadingCreate = true
        state.error = null
      })
      .addCase(createPickupPoint.fulfilled, (state, action) => {
        state.loadingCreate = false
        state.error = null
      })
      .addCase(createPickupPoint.rejected, (state, action) => {
        state.loadingCreate = false
        state.error = action.payload.error
      })

      .addCase(editPickupPoint.pending, state => {
        state.loadingCreate = true
        state.error = null
      })
      .addCase(editPickupPoint.fulfilled, (state, action) => {
        state.loadingCreate = false
        state.error = null
      })
      .addCase(editPickupPoint.rejected, (state, action) => {
        state.loadingCreate = false
        state.error = action.payload.error
      })
  },
})

export const { onClear } = pickupPoints.actions

export default pickupPoints.reducer
