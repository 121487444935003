import { Link } from 'react-router-dom'
import cn from 'classnames'

import { Typography, Ellipsis } from 'ui'
import { Item, ChildrenWrap, ItemContent } from '../../styles'
import { ChevronIcon } from 'ui/icons'

import { Key } from 'modules/common/types/enums'

import type { FC, KeyboardEventHandler } from 'react'
import type { NodeProps } from '../../types'

const { Body } = Typography

const Node: FC<NodeProps> = ({
  id,
  title,
  children,
  openedIds,
  selectedIds,
  onSelect,
  onOpen,
}) => {
  const isOpen = openedIds.indexOf(id) > -1
  const isActive = selectedIds.indexOf(id) > -1
  const isLink = !children?.length

  const handleClick = () => {
    isLink ? onSelect(id) : onOpen(id)
  }

  const handleKeyDown: KeyboardEventHandler = event => {
    const { code } = event

    if (code === Key.Enter) {
      isLink ? onSelect(id) : onOpen(id)
    }
  }

  return (
    <li role='treeitem' aria-expanded={isOpen}>
      <Item
        as={isLink ? Link : undefined}
        to={isLink ? `/faq/${id}` : undefined}
        key={id}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        className={cn(isActive && 'active')}
        tabIndex={0}
      >
        <ItemContent className='title'>
          {children && <ChevronIcon up={isOpen} />}
          <Ellipsis as={Body} pl={isLink ? '28px' : ''}>
            {title}
          </Ellipsis>
        </ItemContent>
      </Item>

      {children && isOpen && (
        <ChildrenWrap>
          {children.map(node => (
            <Node
              id={node.id}
              key={node.id}
              title={node.title}
              openedIds={openedIds}
              selectedIds={selectedIds}
              children={node.children}
              onSelect={onSelect}
              onOpen={onOpen}
            />
          ))}
        </ChildrenWrap>
      )}
    </li>
  )
}

export { Node }
