import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { formatDate } from 'utils/textFormatting'

import { Anchor, Pagination, Table, Typography } from 'ui'
import { useAppTheme } from 'ui/theme'

import {
  changeUploadHistoryLimit,
  changeUploadHistoryPage,
} from 'modules/products/slices/productsFeed'
import {
  EVENT_STATUS_COLORS,
  EVENT_STATUS_TITLE,
  EVENT_TYPES,
} from 'modules/common/constants/dictionaries'

import type { FC } from 'react'
import type { UploadEvent } from 'modules/products/types'
import type { ColumnsType } from 'ui/table/types'

interface Props {
  records: UploadEvent[]
}

const { Body } = Typography

const HistoryTable: FC<Props> = ({ records }) => {
  const history = useHistory()
  const theme = useAppTheme()
  const dispatch = useAppDispatch()
  const pagination = useAppSelector(
    state => state.products.productsFeed.uploadHistoryPagination,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const handleChangePage = (page: number) => {
    dispatch(changeUploadHistoryPage(page))
  }

  const handleChangeLimit = (limit: number) => {
    dispatch(changeUploadHistoryLimit(limit))
  }

  const handleRow = (row: UploadEvent) => ({
    onClick: () => {
      const id = row.id
      if (id) {
        history.push(`/products/upload/events/${id}`)
      }
    },
  })

  const columns: ColumnsType<UploadEvent> = [
    { key: 'id', title: 'ID Прайс–листа', dataIndex: 'id' },
    {
      key: 'link',
      title: 'Ссылка на прайс–лист',
      dataIndex: ['meta', 'url'],
      render: url => <Anchor href={url}>{url}</Anchor>,
    },
    { key: 'filename', title: 'Название файла', dataIndex: 'entity_id' },
    { title: 'Загружено предложений', dataIndex: ['meta', 'uploaded'] },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: status => (
        <Body color={theme.colors.main.status[EVENT_STATUS_COLORS[status]]}>
          {EVENT_STATUS_TITLE[status]}
        </Body>
      ),
    },
    {
      key: 'uploadDate',
      title: 'Дата загрузки',
      dataIndex: ['meta', 'updated_at'],
      render: date => formatDate(new Date(date), 'В HH:mm, dd MMM'),
    },
    {
      key: 'type',
      title: 'Тип загрузки',
      dataIndex: ['meta', 'load_type'],
      render: type => EVENT_TYPES[type],
    },
  ]

  return (
    <>
      <Table columns={columns} data={records} onRow={handleRow} rowKey='id' />
      <Pagination
        {...pagination}
        onPageChange={handleChangePage}
        onLimitChange={handleChangeLimit}
      />
    </>
  )
}

export { HistoryTable }
