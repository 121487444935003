import { useState } from 'react'

import { TabPasswordForm, TabSmsForm } from 'modules/auth/components'

import { Typography, TabsHead, TabsContent, Box } from 'ui'
import { useAppTheme } from 'ui/theme'
import { BackIcon } from 'ui/icons'

import type { FC } from 'react'
import type {
  AuthInputError,
  PasswordFormProps,
} from 'modules/auth/types/interfaces'

const { Subtitle, Body } = Typography

const PasswordForm: FC<PasswordFormProps & AuthInputError> = ({
  phone,
  error,
  setErrorStatus,
}) => {
  const theme = useAppTheme()
  const [activeTab, setActiveTab] = useState('password')

  return (
    <Box>
      <button
        className='__back-button'
        onClick={() => window.location.reload()}
      >
        <BackIcon color={theme.colors.icon.black} />
        <Body weight='semibold' ml='8px'>
          Назад
        </Body>
      </button>

      <Subtitle as='p' mb='8px'>
        Вход в личный кабинет
      </Subtitle>

      <Body as='p' mb='8px'>
        {phone}
      </Body>

      <TabsHead
        activeTab={activeTab}
        onChange={tab => setActiveTab(tab)}
        marginBottom='16px'
      >
        <TabsHead.Item tabValue='password'>Пароль</TabsHead.Item>
        <TabsHead.Item tabValue='sms'>SMS</TabsHead.Item>
      </TabsHead>

      <TabsContent activeTab={activeTab}>
        <TabsContent.Item tabValue='password'>
          <TabPasswordForm
            phone={phone}
            error={error}
            setErrorStatus={setErrorStatus}
          />
        </TabsContent.Item>
        <TabsContent.Item tabValue='sms'>
          <TabSmsForm
            phone={phone}
            error={error}
            setErrorStatus={setErrorStatus}
          />
        </TabsContent.Item>
      </TabsContent>
    </Box>
  )
}

export { PasswordForm }
