import cn from 'classnames'
import { Global } from '@emotion/react'

import BaseTooltip from 'rc-tooltip'

import { getTooltipStyles } from 'ui/tooltip/styles'

import { Size } from './enums'

import type { FC } from 'react'
import type { TooltipProps } from 'rc-tooltip/lib/Tooltip'

interface Props extends TooltipProps {
  size?: Size
}

const Tooltip: FC<Props> = ({ children, size = Size.Default, ...props }) => (
  <>
    <Global styles={getTooltipStyles} />
    <BaseTooltip
      overlayClassName={cn(size === Size.Nano && Size.Nano)}
      mouseEnterDelay={size === Size.Nano ? 0.4 : 0.05}
      mouseLeaveDelay={0}
      {...props}
    >
      {children}
    </BaseTooltip>
  </>
)

Tooltip.defaultProps = {
  animation: 'zoom',
}

export { Tooltip }
