import {
  format as fnsFormat,
  formatRelative as fnsFormatRelative,
  isValid,
} from 'date-fns'
import ruLocale from 'date-fns/locale/ru'

export const formatDate = (date: Date, format: string) => {
  return isValid(date)
    ? fnsFormat(date, format, {
        locale: ruLocale,
      })
    : date.toString()
}

export const formatRelativeDate = (
  date: Date,
  dateToCompare: Date = new Date(),
) => {
  const formatRelativeLocale = {
    today: 'Сегодня в HH:mm',
    yesterday: 'Вчера в HH:mm',
    tomorrow: 'Завтра в HH:mm',
    lastWeek: 'd MMMM в HH:mm',
    nextWeek: 'd MMMM в HH:mm',
    other: 'd MMMM в HH:mm',
  }

  return fnsFormatRelative(date, dateToCompare, {
    locale: {
      ...ruLocale,
      formatRelative: token => formatRelativeLocale[token],
    },
  })
}

export const formatCurrency = ($number: number): string => {
  const isInt = $number % 1 === 0
  const maximumFractionDigits = isInt ? 0 : 1

  return new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'KZT',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits,
  }).format($number)
}

export const formatPercents = ($number: number): string => {
  return new Intl.NumberFormat('ru', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format($number / 10000)
}

export const formatNumber = ($number: number): string => {
  return new Intl.NumberFormat('ru').format($number)
}

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    const intlCode = match[1] ? '+7 ' : ''
    return [
      intlCode,
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4],
      '-',
      match[5],
    ].join('')
  }

  return phoneNumberString
}

export const formatPlural = (
  count: number = 0,
  words: [string, string, string],
): string => {
  if (words?.length === 3) {
    let n = Math.abs(count)
    n %= 100

    if (n >= 5 && n <= 20) {
      return words[2]
    }
    n %= 10
    if (n === 1) {
      return words[0]
    }
    if (n >= 2 && n <= 4) {
      return words[1]
    }
    return words[2]
  } else {
    throw new Error('words must be provided')
  }
}

export const formatDays = (count: number): string => {
  const words: [string, string, string] = ['день', 'дня', 'дней']

  return formatPlural(count, words)
}
