import { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { GeolocationControl, Map, Placemark, YMaps } from 'react-yandex-maps'

import { useAppDispatch } from 'store/hooks'
import { usePickupPointActions } from 'modules/profile/hooks'
import {
  createPickupPoint,
  editPickupPoint,
} from 'modules/profile/slices/pickupPoints'

import { YANDEX_MAP_KEY_GEO } from 'modules/common/constants/dictionaries'
import { matchNumberMask } from 'utils/matchInputMask'
import { formatDate } from 'utils/textFormatting'

import { UploadSchedule } from 'modules/profile/pages/Profile/components/PickupPoints/components'

import { useAppTheme } from 'ui/theme'
import {
  Breadcrumbs,
  Typography,
  FormField,
  Tooltip,
  Select,
  Button,
  Link,
  Tags,
  Box,
} from 'ui'

import type { PickupPointRouteMatch } from 'modules/profile/types/interfaces'

const { Title, Body, Subtitle } = Typography
const { Option } = Select

const weekDaysList = [
  { name: 'Пн', value: '1' },
  { name: 'Вт', value: '2' },
  { name: 'Ср', value: '3' },
  { name: 'Чт', value: '4' },
  { name: 'Пт', value: '5' },
  { name: 'Сб', value: '6' },
  { name: 'Вс', value: '7' },
]

const weekDaysNames = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
]

const CreatePickupPoints = () => {
  const theme = useAppTheme()
  const dispatch = useAppDispatch()

  const match = useRouteMatch<PickupPointRouteMatch>()
  const pickupPointId = match.params.id

  const {
    loadingPickupPoint,
    setAddressData,
    loadingCreate,
    pickupPoint,
    addressData,
    iconCaption,
    setSchedule,
    mapConfig,
    weekdays,
    schedule,
    mapClick,
    street,
    coords,
    cities,
  } = usePickupPointActions(pickupPointId)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { pickupPoint },
  })

  useEffect(() => {
    if (!loadingPickupPoint) {
      reset({
        pickupPoint: pickupPoint,
      })
    }
  }, [loadingPickupPoint, pickupPoint, reset])

  const onSubmit = data => {
    let body = {
      pickupPoint: {
        ...data.pickupPoint,
        contact: {
          phone: matchNumberMask(data.pickupPoint.contact.phone),
          info: data.pickupPoint.contact.info,
        },
        address: {
          street: addressData.street,
          house: addressData.house,
          geo: addressData.geo,
          apartment: data.pickupPoint.address.apartment,
          city_id: data.pickupPoint.address.city_id,
          floor: Number(data.pickupPoint.address.floor),
          zipcode: data.pickupPoint.address.zipcode,
        },
        schedule: schedule.map(value => {
          return {
            ...value,
            time_ranges: value.time_ranges.length
              ? [
                  {
                    from: formatDate(
                      new Date(value.time_ranges[0].from),
                      'HH:mm',
                    ),
                    to: formatDate(new Date(value.time_ranges[0].to), 'HH:mm'),
                  },
                ]
              : [],
          }
        }),
      },
    }
    pickupPointId === 'create'
      ? dispatch(createPickupPoint(body.pickupPoint))
      : dispatch(
          editPickupPoint({ pickupPoint: body.pickupPoint, pickupPointId }),
        )
  }

  return (
    <Box p='24px'>
      <Title as='p' mb='8px'>
        {pickupPointId === 'create'
          ? 'Добавление точки выдачи'
          : 'Редактирование точки выдачи'}
      </Title>
      <Breadcrumbs>
        <Link to='/profile/pickup-points'>Точки выдачи</Link>
        <Body color={theme.colors.icon.secondaryBlack}>
          {pickupPointId === 'create'
            ? 'Добавление точки выдачи'
            : 'Редактирование точки выдачи'}
        </Body>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Subtitle as='p' mt='24px' mb='16px'>
          Основная информация
        </Subtitle>
        <FormField
          {...register('pickupPoint.store_id', {
            required: true,
          })}
          placeholder='Например 234–5'
          label='ID точки выдачи'
          required
          allowClear={false}
          type='text'
          width='320px'
          style={{ marginBottom: '16px' }}
        />
        <FormField
          {...register('pickupPoint.name', {
            required: true,
          })}
          placeholder='Название точки выдачи'
          label='Название'
          allowClear={false}
          required
          type='text'
          width='320px'
          style={{ marginBottom: '16px' }}
        />
        <FormField
          {...register('pickupPoint.contact.info', {
            required: true,
          })}
          placeholder='Введите имя'
          label='Имя контакта'
          required
          allowClear={false}
          type='text'
          width='320px'
          style={{ marginBottom: '16px' }}
        />
        <Controller
          control={control}
          name='pickupPoint.contact.phone'
          render={({ field: { onChange, value } }) => (
            <FormField
              placeholder='+7 (XXX) XXX-XX-XX'
              label='Номер телефона'
              required
              value={value}
              onChange={onChange}
              maskType='FULL_PHONE'
              allowClear={false}
              type='text'
              width='320px'
            />
          )}
        />
        <Subtitle as='p' mt='24px' mb='16px'>
          Адрес
        </Subtitle>
        <Box display='flex'>
          <Box mr='107px'>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label='Город'
                  listHeight={400}
                  value={value}
                  onChange={onChange}
                  dropdownMatchSelectWidth={296}
                  style={{ marginBottom: '16px' }}
                  disabled
                >
                  {cities.map(city => (
                    <Option key={city.slug} value={city.id}>
                      {city?.title?.ru}
                    </Option>
                  ))}
                </Select>
              )}
              name='pickupPoint.address.city_id'
            />
            <FormField
              placeholder='Введите улицу'
              value={addressData.street}
              required
              onChange={e =>
                setAddressData({ ...addressData, street: e.target.value })
              }
              label='Улица'
              allowClear={false}
              type='text'
              width='320px'
              style={{ marginBottom: '16px' }}
            />
            <FormField
              placeholder='Номер дома'
              value={addressData.house}
              required
              onChange={e =>
                setAddressData({ ...addressData, house: e.target.value })
              }
              label='Дом'
              allowClear={false}
              type='text'
              width='320px'
              style={{ marginBottom: '16px' }}
            />
            <Box
              display='flex'
              justifyContent='space-between'
              width='320px'
              mb='16px'
            >
              <FormField
                {...register('pickupPoint.address.apartment', {
                  required: true,
                })}
                placeholder='Номер кв.'
                required
                label='Квартира'
                allowClear={false}
                type='number'
                width='156px'
              />
              <Tooltip
                overlay='Введите коректный этаж'
                placement='right'
                visible={!!errors.pickupPoint?.address.floor}
              >
                <FormField
                  {...register('pickupPoint.address.floor', {
                    maxLength: 3,
                  })}
                  placeholder='Этаж'
                  required
                  label='Этаж'
                  allowClear={false}
                  type='number'
                  width='156px'
                />
              </Tooltip>
            </Box>
            <FormField
              {...register('pickupPoint.address.zipcode')}
              placeholder='Введите индекс'
              label='Индекс'
              required
              allowClear={false}
              type='number'
              width='320px'
              style={{ marginBottom: '16px' }}
            />
            <FormField
              placeholder='Введите широту'
              label='Широта'
              required
              value={addressData.geo.latitude}
              onChange={e =>
                setAddressData({
                  ...addressData,
                  geo: {
                    latitude: e.target.value,
                    longitude: addressData.geo.longitude,
                  },
                })
              }
              maskType='LATITUDE_LONGITUDE'
              allowClear={false}
              type='text'
              width='320px'
              style={{ marginBottom: '16px' }}
            />
            <FormField
              placeholder='Введите долготу'
              label='Долгота'
              required
              value={addressData.geo.longitude}
              onChange={e =>
                setAddressData({
                  ...addressData,
                  geo: {
                    longitude: e.target.value,
                    latitude: addressData.geo.latitude,
                  },
                })
              }
              maskType='LATITUDE_LONGITUDE'
              allowClear={false}
              type='text'
              width='320px'
              style={{ marginBottom: '16px' }}
            />
          </Box>
          <Box>
            <YMaps query={{ apikey: YANDEX_MAP_KEY_GEO }}>
              <Map
                defaultState={mapConfig}
                state={mapConfig}
                width='504px'
                height='504px'
                onClick={(e: Event) => mapClick(e)}
              >
                <Placemark
                  geometry={coords}
                  properties={{
                    iconCaption: iconCaption || street,
                  }}
                />
                <GeolocationControl />
              </Map>
            </YMaps>
          </Box>
        </Box>
        <Subtitle as='p' mt='24px' mb='16px'>
          График работы
        </Subtitle>
        <FormField
          placeholder='(UTC+06:00) Astana'
          label='Часовой пояс'
          allowClear={false}
          disabled
          type='text'
          width='320px'
          style={{ marginBottom: '16px' }}
        />
        <Tags list={weekDaysList} isWeekdays {...weekdays} />
        {schedule.map((day, index, arr) => (
          <UploadSchedule
            weekDaysNames={weekDaysNames}
            timeRanges={day.time_ranges}
            setSchedule={setSchedule}
            weekdays={weekdays.value}
            day={day.weekday}
            schedule={arr}
            index={index}
          />
        ))}
        <Box width='320px'>
          <Button mt='24px' fullWidth type='submit' loading={loadingCreate}>
            сохранить точку выдачи
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export { CreatePickupPoints }
