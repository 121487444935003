import { FC } from 'react'
import { styled } from 'ui/theme'

import { Link, Typography, Box } from 'ui'
import { Star } from 'ui/icons'

import { formatDate, formatDays } from 'utils/textFormatting'
import { parseISO, differenceInDays } from 'date-fns'

import { ListItem } from './styles'

const { Body, Subtitle, Headline } = Typography

const RotatedTitle = styled(Headline)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
`

interface Props {
  id: string
  title: string
  startAt: string
  endAt: string
  duration: number
}

const InstallmentCard: FC<Props> = ({
  id,
  title,
  startAt,
  endAt,
  duration,
}) => {
  const start = parseISO(startAt)
  const end = parseISO(endAt)

  const days =
    differenceInDays(end, start) >= 0 ? differenceInDays(end, start) + 1 : 0

  return (
    <ListItem>
      <Box position='relative'>
        <Star />
        <RotatedTitle color='text.white'>0•0•{duration}</RotatedTitle>
      </Box>
      <Box display='grid' gridRowGap='8px' ml='16px'>
        <Subtitle>{title}</Subtitle>
        <Body>
          {formatDate(start, 'С dd MMMM')} {formatDate(end, 'по dd MMMM')} (
          {days} {formatDays(days)})
        </Body>
      </Box>

      <Link to={`/installments/${id}`}>Участвовать в акции</Link>
    </ListItem>
  )
}

export { InstallmentCard }
