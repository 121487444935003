import { styled } from 'ui/theme'
import { TableWrapProps } from 'ui/table/types'
import { ifProp } from 'styled-tools'

export const TableWrap = styled.div<TableWrapProps>`
  position: relative;

  .__airba-custom-table.rc-table-ping-left {
    .rc-table-tbody {
      .rc-table-cell-fix-left-last {
        box-shadow: -2px 0 2px rgba(0, 0, 0, 0.24);
      }
    }
  }

  .__airba-custom-table.rc-table-ping-right {
    .rc-table-tbody {
      .rc-table-cell-fix-right-first {
        box-shadow: -2px 0 2px rgba(0, 0, 0, 0.24);
      }
    }
  }

  .__airba-custom-table {
    position: relative;
    box-sizing: border-box;
    font-size: ${props => props.theme.fontSizes.body.m};
    line-height: ${props => props.theme.lineHeights.m};

    table {
      width: 100%;
      border-spacing: 0;
    }

    th,
    td {
      position: relative;
      white-space: normal;
      box-sizing: border-box;
      word-break: break-word;
      transition: box-shadow 0.3s;
    }

    .rc-table-sticky-holder {
      position: sticky;
      z-index: 1;
      background: ${props => props.theme.colors.main.background.white};
    }

    .rc-table-body,
    .rc-table-content {
      overflow: ${props =>
        props.showHeader ? 'auto hidden' : 'hidden'} !important;
    }

    .rc-table-row .rc-table-cell {
      padding: 0 12px;
      height: 56px;
      border-bottom: 1px solid #e5e5e5;

      &:first-of-type {
        padding-left: 24px;
      }

      &:last-of-type {
        padding-right: 24px;
      }
    }

    .rc-table-thead {
      vertical-align: top;

      .rc-table-cell {
        height: 40px;
        text-align: left;
        padding: 0 12px;
        vertical-align: middle;
        border-bottom: 1px solid #e5e5e5;
        color: ${props => props.theme.colors.text.black};
        font-weight: ${props => props.theme.fontWeights.semibold};
        line-height: ${props => props.theme.lineHeights.m};

        &:first-of-type {
          padding-left: 24px;
        }

        &:last-of-type {
          padding-right: 24px;
        }
      }

      .cell-no-padding {
        padding: 0;

        &:first-of-type {
          padding-left: 0;

          button {
            padding-left: 24px;
          }
        }

        &:last-of-type {
          padding-right: 0;

          button {
            padding-right: 24px;
          }
        }
      }
    }

    .rc-table-tbody {
      color: ${props => props.theme.colors.text.black};

      .rc-table-expanded-row-fixed {
        overflow: visible !important;
      }

      .expand-row-parent,
      .expand-row-parent .rc-table-cell-fix-right {
        background: ${props => props.theme.colors.main.background.gray};
      }

      .rc-table-row.hovered {
        position: relative;
      }

      .rc-table-row.hoverable {
        cursor: pointer;

        &:hover {
          background-color: ${props => props.theme.colors.main.background.gray};

          .rc-table-cell-fix-right {
            background-color: ${props =>
              props.theme.colors.main.background.gray};
          }
        }
      }

      .rc-table-placeholder > .rc-table-cell {
        padding: 64px 0 0 0;
      }

      .rc-table-expanded-row {
        background: ${props => props.theme.colors.main.background.gray};

        > .rc-table-cell {
          padding: 0;
        }
      }

      .rc-table-cell-fix-right {
        background: ${props => props.theme.colors.main.background.white};
        padding-right: 12px !important;
      }

      ${({ theme }) =>
        ifProp(
          'row',
          `
        .rc-table-row {
          cursor: pointer;

          &:hover {
            background-color: ${theme.colors.main.background.gray};
          }
        }
      `,
        )}
    }

    .rc-table-cell.rc-table-cell-ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`
