import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Denied: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.70711 2.87868C6.26972 2.31607 7.03278 2 7.82843 2H12.1716C12.9672 2 13.7303 2.31607 14.2929 2.87868L17.1213 5.70711C17.6839 6.26972 18 7.03278 18 7.82843V12.1716C18 12.9672 17.6839 13.7303 17.1213 14.2929L14.2929 17.1213C13.7303 17.6839 12.9672 18 12.1716 18H7.82843C7.03278 18 6.26972 17.6839 5.70711 17.1213L2.87868 14.2929C2.31607 13.7303 2 12.9672 2 12.1716V7.82843C2 7.03278 2.31607 6.26972 2.87868 5.70711L5.70711 2.87868ZM7.82843 4C7.56321 4 7.30886 4.10536 7.12132 4.29289L4.29289 7.12132C4.10536 7.30886 4 7.56321 4 7.82843V12.1716C4 12.4368 4.10536 12.6911 4.29289 12.8787L7.12132 15.7071C7.30886 15.8946 7.56321 16 7.82843 16H12.1716C12.4368 16 12.6911 15.8946 12.8787 15.7071L15.7071 12.8787C15.8946 12.6911 16 12.4368 16 12.1716V7.82843C16 7.56321 15.8946 7.30886 15.7071 7.12132L12.8787 4.29289C12.6911 4.10536 12.4368 4 12.1716 4H7.82843Z'
      fill={color}
    />
    <path
      d='M6.79289 6.79289C6.40237 7.18342 6.40237 7.81658 6.79289 8.20711L8.58579 10L6.79289 11.7929C6.40237 12.1834 6.40237 12.8166 6.79289 13.2071C7.18342 13.5976 7.81658 13.5976 8.20711 13.2071L10 11.4142L11.7929 13.2071C12.1834 13.5976 12.8166 13.5976 13.2071 13.2071C13.5976 12.8166 13.5976 12.1834 13.2071 11.7929L11.4142 10L13.2071 8.20711C13.5976 7.81658 13.5976 7.18342 13.2071 6.79289C12.8166 6.40237 12.1834 6.40237 11.7929 6.79289L10 8.58579L8.20711 6.79289C7.81658 6.40237 7.18342 6.40237 6.79289 6.79289Z'
      fill={color}
    />
  </Icon>
)

Denied.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Denied }
