import { FC } from 'react'
import { Icon, IconProps } from 'ui/icons/index'

const DescSort: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4167 17C16.061 17 16.5834 16.4777 16.5834 15.8333C16.5834 15.189 16.061 14.6667 15.4167 14.6667H13.0834C12.439 14.6667 11.9167 15.189 11.9167 15.8333C11.9167 16.4777 12.439 17 13.0834 17H15.4167ZM15.4167 11.1667C16.061 11.1667 16.5834 10.6443 16.5834 10C16.5834 9.35567 16.061 8.83333 15.4167 8.83333H10.75C10.1057 8.83333 9.58337 9.35567 9.58337 10C9.58337 10.6443 10.1057 11.1667 10.75 11.1667H15.4167ZM16.5834 4.16667C16.5834 4.811 16.061 5.33333 15.4167 5.33333L3.75004 5.33333C3.10571 5.33333 2.58338 4.811 2.58338 4.16667C2.58338 3.52233 3.10571 3 3.75004 3L15.4167 3C16.061 3 16.5834 3.52233 16.5834 4.16667Z"
      fill="#10142D"
    />
    <path
      d="M6.66667 10C6.66667 9.35568 6.14433 8.83334 5.5 8.83334C4.85567 8.83334 4.33333 9.35568 4.33333 10V13.0168L3.99162 12.6751C3.53601 12.2194 2.79732 12.2194 2.34171 12.6751C1.8861 13.1307 1.8861 13.8694 2.34171 14.325L4.67504 16.6583C5.13065 17.1139 5.86935 17.1139 6.32496 16.6583L8.65829 14.325C9.1139 13.8694 9.1139 13.1307 8.65829 12.6751C8.20268 12.2194 7.46399 12.2194 7.00837 12.6751L6.66667 13.0168V10Z"
      fill="#10142D"
    />
  </Icon>
)

DescSort.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#10142D',
}

export { DescSort }
