import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { matchNumberMask } from 'utils/matchInputMask'
import { Button, Condition, FormField } from 'ui'

import { requestOTP, verifyOTP } from 'modules/auth/slices/auth'

import type { FC } from 'react'
import type {
  AuthInputError,
  PasswordFormProps,
} from 'modules/auth/types/interfaces'

let counter

const TabSmsForm: FC<PasswordFormProps & AuthInputError> = ({
  phone,
  error,
  setErrorStatus,
}) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(state => state.auth.auth.loading)

  const [code, setCode] = useState('')
  const [time, setTime] = useState(60)
  const [otpRequested, setOtpRequested] = useState(false)

  useEffect(() => {
    if (otpRequested) {
      if (time > 0) {
        counter = setTimeout(() => {
          setTime(time - 1)
        }, 1000)
      }
    }

    return () => {
      clearTimeout(counter)
    }
  }, [time, otpRequested])

  const onSubmit = event => {
    event.preventDefault()

    const matchedPhone = matchNumberMask(phone)
    const matchedCode = matchNumberMask(code)

    if (otpRequested) {
      if (matchedCode.length === 4) {
        dispatch(
          verifyOTP({
            phone: matchedPhone,
            otp: matchedCode,
          }),
        )

        setErrorStatus(false)
      } else {
        setErrorStatus(true)
      }
    } else {
      setOtpRequested(true)

      dispatch(
        requestOTP({
          phone: matchedPhone,
        }),
      )
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <FormField
        type='text'
        size='large'
        maskType='CODE'
        label='SMS-код'
        placeholder='Введите код из SMS'
        disabled={!otpRequested}
        value={code}
        error={error}
        onChange={e => setCode(e.target.value)}
        onClear={() => {
          setErrorStatus(false)
          setCode('')
        }}
      />

      <Condition match={otpRequested}>
        <Button
          mt='4px'
          size='nano'
          type='button'
          variant='secondary'
          disabled={time !== 0}
          fullWidth
          onClick={() => {
            setTime(60)

            dispatch(
              requestOTP({
                phone: matchNumberMask(phone),
              }),
            )
          }}
        >
          {time === 0
            ? 'Выслать код повторно'
            : `Выслать код повторно через ${time} сек.`}
        </Button>
      </Condition>

      <Button mt='24px' type='submit' loading={loading} fullWidth>
        {otpRequested ? 'Войти' : 'Выслать код'}
      </Button>
    </form>
  )
}

export { TabSmsForm }
