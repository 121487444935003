import type { FC } from 'react'

import { useOrderActions } from 'modules/orders/hooks'
import { tableFilters } from 'modules/orders/pages/Orders/filters'

import { Search, Box, Select, RangePickerInput } from 'ui'

const OrdersActions: FC = () => {
  const { search, status, datepicker } = useOrderActions()

  return (
    <Box mb='16px' display='flex' alignItems='flex-end'>
      <Box width='296px' mr='16px'>
        <Search placeholder='Номер заказа' {...search} />
      </Box>

      <Box width='296px' mr='16px'>
        <Select label='Статусы заказа' listHeight={392} {...status}>
          {tableFilters.map((filter, idx) => (
            <Select.Option key={idx} value={filter.value}>
              {filter.name}
            </Select.Option>
          ))}
        </Select>
      </Box>

      <Box width='296px'>
        <RangePickerInput label='Период создания заказа' {...datepicker} />
      </Box>
    </Box>
  )
}

export { OrdersActions }
