import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from 'modules/common/components'
import { Box, Typography, Textarea, Button, toast, Breadcrumbs, Link } from 'ui'
import { useUploadEventDetails } from 'modules/products/hooks/useUploadEventDetails'
import { formatDate } from 'utils/textFormatting'
import {
  EVENT_STATUS_COLORS,
  EVENT_STATUS_TITLE,
  EVENT_TYPES,
} from 'modules/common/constants/dictionaries'
import { useAppTheme } from 'ui/theme'

const { Body } = Typography

interface Params {
  eventId: string
}

const UploadEventDetails: FC = () => {
  const { eventId } = useParams<Params>()
  const theme = useAppTheme()

  const { uploadEventDetails } = useUploadEventDetails(eventId)

  const message =
    uploadEventDetails.description +
    (uploadEventDetails.messages
      ? `\n\nПодробная информация по процессу обработки: \n${uploadEventDetails.messages.join(
          '\n',
        )}`
      : '')

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(message).then(() => {
      toast('Логи скопированы')
    })
  }

  return (
    <Layout title='Результат проверки'>
      <Breadcrumbs mb='24px'>
        <Link to='/products/upload/history'>Загрузка прайс листа</Link>

        <Body>Результат проверки</Body>
      </Breadcrumbs>

      <Box display='flex' flexWrap='wrap'>
        <Box display='flex' mr='40px' mb='24px'>
          <Box mr='16px' minWidth='200px'>
            <Body as='p' mb='8px'>
              ID Прайс–листа
            </Body>

            <Body as='p' mb='8px'>
              Название файла
            </Body>

            <Body as='p' mb='8px'>
              Загружено предложений
            </Body>

            <Body as='p' mb='8px'>
              Статус
            </Body>

            <Body as='p' mb='8px'>
              Дата загрузки
            </Body>

            <Body as='p' mb='8px'>
              Тип загрузки
            </Body>
          </Box>

          <Box minWidth='200px'>
            <Body as='p' mb='8px'>
              {uploadEventDetails.id || '-'}
            </Body>

            <Body as='p' mb='8px'>
              {uploadEventDetails.entity_id || '-'}
            </Body>

            <Body as='p' mb='8px'>
              {uploadEventDetails.meta?.uploaded || '-'}
            </Body>

            <Body
              as='p'
              mb='8px'
              color={
                theme.colors.main.status[
                  EVENT_STATUS_COLORS[uploadEventDetails?.status]
                ]
              }
            >
              {EVENT_STATUS_TITLE[uploadEventDetails?.status] || '-'}
            </Body>

            <Body as='p' mb='8px'>
              {(uploadEventDetails.meta?.updated_at &&
                formatDate(
                  new Date(uploadEventDetails.meta?.updated_at),
                  'В HH:mm, dd MMM',
                )) ||
                '-'}
            </Body>

            <Body as='p' mb='8px'>
              {EVENT_TYPES[uploadEventDetails.meta?.load_type] || '-'}
            </Body>
          </Box>
        </Box>

        <Box flexGrow={1} width='500px' maxWidth='720px'>
          <Textarea readOnly value={message} height='264px' />
          <Button mt='24px' variant='secondary' onClick={handleCopyToClipboard}>
            Скопировать логи
          </Button>
        </Box>
      </Box>
    </Layout>
  )
}

export { UploadEventDetails }
