import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppThunk, instance } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'
import { toast } from 'ui'

import { ChangePasswordType, RecoveryPhone } from 'modules/profile/types'
import { SettingsState } from 'modules/profile/types/interfaces'
import { AxiosResponse } from 'axios'

/**
 * Actions
 */

export const recoveryPhone: AppThunk<RecoveryPhone> = createAsyncThunk(
  'recoveryPhone/fetch',
  (body, thunkApi) =>
    instance(thunkApi)
      .get('/sso/api/v1/recovery')
      .then((res: AxiosResponse<RecoveryPhone>) => res.data)
      .catch(thunkErrorHandler(thunkApi)),
)

export const recoveryPhoneOTP: AppThunk<
  void,
  ChangePasswordType
> = createAsyncThunk('recoveryPhoneOTP/fetch', (body, thunkApi) =>
  instance(thunkApi)
    .put('/sso/api/v1/recovery/otp', {
      otp: body.otp,
      password: body.password,
    })
    .then(res => {
      body.onCloseCb()
      toast('Пароль изменён')
      return res.data
    })
    .catch(thunkErrorHandler(thunkApi)),
)

/**
 * Reducer
 */

const initialState: SettingsState = {
  loading: false,
  error: null,
  maskedPhone: '',
  OTPError: null,
  OTPLoading: false,
}

const changePasswordOTP = createSlice({
  name: 'recoveryPhone',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(recoveryPhone.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(recoveryPhone.fulfilled, (state, { payload }) => {
        state.loading = false
        state.maskedPhone = payload.masked_phone
        state.error = null
      })
      .addCase(recoveryPhone.rejected, (state, { payload }) => {
        state.loading = false
        state.maskedPhone = ''
        state.error = payload.error
      })
      .addCase(recoveryPhoneOTP.pending, state => {
        state.OTPLoading = true
        state.OTPError = null
      })
      .addCase(recoveryPhoneOTP.fulfilled, state => {
        state.OTPLoading = false
        state.OTPError = null
      })
      .addCase(recoveryPhoneOTP.rejected, (state, { payload }) => {
        state.OTPLoading = false
        state.OTPError = payload.error
      }),
})

export default changePasswordOTP.reducer
