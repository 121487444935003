import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const File: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 14.2749L5.00081 14.2881C5.00226 14.3084 5.00564 14.3439 5.01318 14.3892C5.02905 14.4844 5.05893 14.5961 5.10871 14.6956C5.15654 14.7913 5.21258 14.8565 5.27791 14.9001C5.33699 14.9395 5.4607 15 5.71429 15H12.5714H14.2857C14.3891 15 14.5761 14.9383 14.7572 14.7572C14.9383 14.5761 15 14.3891 15 14.2857V10V9H14C12.3431 9 11 7.65685 11 6V5H5.71429C5.4607 5 5.33699 5.06052 5.27791 5.09991C5.21258 5.14347 5.15654 5.2087 5.10871 5.30436C5.05893 5.40393 5.02905 5.51561 5.01318 5.61083C5.00564 5.65607 5.00226 5.69158 5.00081 5.71187L5 5.72512V14.2749ZM17 8V10V14.2857C17 15.0395 16.6332 15.7096 16.1714 16.1714C15.7096 16.6332 15.0395 17 14.2857 17H12.5714H5.71429C5.11073 17 4.59158 16.8462 4.16851 16.5642C3.75171 16.2863 3.48632 15.923 3.31986 15.5901C3.15536 15.2611 3.07809 14.9442 3.04039 14.718C3.02115 14.6025 3.01114 14.5041 3.00589 14.4306C3.00325 14.3937 3.00179 14.3626 3.00097 14.3383C3.00057 14.3261 3.00032 14.3155 3.00018 14.3068L3.00004 14.2949L3.00001 14.29L3 14.2878L3 14.2867C3 14.2856 4.6694 14.285 3 14.2867V5.71429C4.00987 5.71429 4.06147 5.71429 3 5.71429L3 5.71225L3.00001 5.71004L3.00004 5.70511L3.00018 5.69324C3.00032 5.68445 3.00057 5.67391 3.00097 5.66172C3.00179 5.63737 3.00325 5.60628 3.00589 5.56938C3.01114 5.49592 3.02115 5.3975 3.04039 5.28203C3.07809 5.05582 3.15536 4.73893 3.31986 4.40993C3.48632 4.07701 3.75171 3.71368 4.16851 3.43581C4.59158 3.15376 5.11073 3 5.71429 3H12C14.7614 3 17 5.23858 17 8ZM6 10C6 9.44772 6.44772 9 7 9H11C11.5523 9 12 9.44772 12 10C12 10.5523 11.5523 11 11 11H7C6.44772 11 6 10.5523 6 10ZM6 13C6 12.4477 6.44772 12 7 12H9C9.55228 12 10 12.4477 10 13C10 13.5523 9.55228 14 9 14H7C6.44772 14 6 13.5523 6 13ZM14.8293 7C14.528 6.14759 13.8524 5.47199 13 5.17071V6C13 6.55228 13.4477 7 14 7H14.8293Z'
      fill={color}
    />
  </Icon>
)

File.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { File }
