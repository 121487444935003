import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchOrders, fetchOrdersProfit } from 'modules/orders/slices/orders'
import { fetchPickupPoints } from 'modules/profile/slices/pickupPoints'
import { fetchBalance } from 'modules/finance/slices/finance'

export const useOrders = () => {
  const dispatch = useAppDispatch()
  const { status, search } = useAppSelector(state => state.orders.orders.filter)

  useEffect(() => {
    dispatch(fetchOrdersProfit())
    dispatch(fetchPickupPoints())
    dispatch(fetchBalance())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchOrders())
  }, [status, search, dispatch])
}
