export const getFadeStyles = (duration = 300) => ({
  default: {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  },
  transition: {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  },
})
