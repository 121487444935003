import { Fragment, useState } from 'react'
import { useAppSelector } from 'store/hooks'

import { TabsHead, TabsContent, Badge, Box } from 'ui'
import { OnSaleTab, OnCheckTable } from './components'

import type { FC } from 'react'

enum ProductsTab {
  Sale = 'sale',
  Check = 'check',
}

const Products: FC = () => {
  const { unrelatedProductsTotal } = useAppSelector(
    state => state.products.unrelatedProducts,
  )
  const [activeTab, setActiveTab] = useState<ProductsTab>(ProductsTab.Sale)

  const handleChangeTab = (tab: ProductsTab) => {
    setActiveTab(tab)
  }

  return (
    <Fragment>
      <Box pt='24px' px='24px' mb='24px'>
        <TabsHead
          marginBottom='0'
          activeTab={activeTab}
          onChange={handleChangeTab}
        >
          <TabsHead.Item tabValue={ProductsTab.Sale}>
            <Badge>В продаже</Badge>
          </TabsHead.Item>
          <TabsHead.Item tabValue={ProductsTab.Check}>
            <Badge count={unrelatedProductsTotal} unlim>
              Не связаны
            </Badge>
          </TabsHead.Item>
        </TabsHead>
      </Box>

      <TabsContent activeTab={activeTab}>
        <TabsContent.Item tabValue={ProductsTab.Sale}>
          <OnSaleTab />
        </TabsContent.Item>
        <TabsContent.Item tabValue={ProductsTab.Check}>
          <OnCheckTable />
        </TabsContent.Item>
      </TabsContent>
    </Fragment>
  )
}

export default Products
