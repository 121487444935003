import type { FC } from 'react'

import { formatCurrency } from 'utils/textFormatting'
import { Typography, Box } from 'ui'

import { OrderProductsType } from 'modules/orders/types'

interface TotalPriceProps {
  products: OrderProductsType[]
}

const { Body } = Typography

const TotalPrice: FC<TotalPriceProps> = ({ products }) => (
  <Box py='8px' mb='16px' px='24px' backgroundColor='main.background.gray'>
    <Body mr='172px' weight='semibold'>
      Итого
    </Body>
    <Body weight='semibold'>
      {formatCurrency(
        products
          .filter(product => !product.removed)
          .reduce((a, b) => a + Number(b.price) * Number(b.quantity), 0),
      )}
    </Body>
  </Box>
)

export { TotalPrice }
