import { styled } from 'ui/theme'

const ListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.main.background.gray};
  min-height: 136px;
  padding: 24px;
  box-sizing: border-box;
`

export { ListItem }
