import { Box } from 'ui'

export const Withdraw = (
  <Box>
    Можно вывести = продано – удержано. <br />
    В удержано входят все комиссии и возвраты <br />
    товаров. Выводы проводятся ежедневно. <br />
    Минимальная сумма для вывода — 50 000 ₸. <br />
    Максимальная сумма вывода не ограничена.
  </Box>
)

export const Active = (
  <Box>
    Здесь все подтвержденные <br />
    партнером незавершенные заказы
  </Box>
)

export const Sold = <Box>Здесь все выполненные заказы</Box>
