import { Global } from '@emotion/react'

import Dialog from 'rc-dialog'

import { getModalStyles } from 'ui/modal/styles'
import { CloseOutlinedIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'
import { Button, Box } from 'ui'

import type { FC } from 'react'
import type { ModalProps } from 'ui/modal/types'

const Modal: FC<ModalProps> = ({
  children,
  onOk,
  okText,
  onCancel,
  fullWidthButtons,
  cancelText,
  okLoading,
  disabled,
  ...props
}) => {
  const theme = useAppTheme()

  const buttonSpace = onCancel ? '16px' : '0'

  return (
    <>
      <Global styles={getModalStyles} />
      <Dialog
        {...props}
        footer={null}
        closeIcon={
          <CloseOutlinedIcon color={theme.colors.icon.secondaryBlack} />
        }
      >
        {children}

        {(onOk || onCancel) && (
          <Box
            mt='24px'
            display='flex'
            justifyContent='flex-end'
            flexDirection={fullWidthButtons ? 'column' : 'row'}
          >
            {onCancel && (
              <Button
                order={fullWidthButtons ? 1 : 0}
                variant='secondary'
                onClick={onCancel}
                fullWidth={fullWidthButtons}
                mr={fullWidthButtons ? '0px' : '8px'}
              >
                {cancelText || 'Отмена'}
              </Button>
            )}
            {onOk && (
              <Button
                onClick={onOk}
                loading={okLoading}
                fullWidth={fullWidthButtons}
                disabled={disabled}
                mb={fullWidthButtons ? buttonSpace : '0'}
              >
                {okText || 'Сохранить'}
              </Button>
            )}
          </Box>
        )}
      </Dialog>
    </>
  )
}

Modal.defaultProps = {
  destroyOnClose: true,
  animation: 'zoom',
}

export { Modal }
