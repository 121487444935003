import { FC } from 'react'

import { styled } from 'ui/theme'
import { AscSortIcon, DefaultSortIcon, DescSortIcon } from 'ui/icons'

interface Props {
  onChangeSorting: (sorting: any) => void
  sorting: string
  title: string
  desc: string
  asc: string
}

const SortButton = styled.button`
  cursor: pointer;
  padding: 0 12px;
  border: none;
  background-color: transparent;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
  width: 100%;
  height: 100%;
  text-align: inherit;

  &:hover {
    background-color: ${props => props.theme.colors.main.background.gray};
  }
`

const SortIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  font-size: 0;
  line-height: 0;
`

const Sort: FC<Props> = ({ onChangeSorting, sorting, title, desc, asc }) => {
  const handleChangeSorting = () => {
    if (sorting === '' || (sorting !== asc && sorting !== desc)) {
      onChangeSorting(asc)
    } else if (sorting === asc) {
      onChangeSorting(desc)
    } else if (sorting === desc) {
      onChangeSorting('')
    }
  }

  return (
    <SortButton onClick={handleChangeSorting}>
      {title}
      <SortIcon>
        {sorting !== asc && sorting !== desc && <DefaultSortIcon />}
        {sorting === asc && <AscSortIcon />}
        {sorting === desc && <DescSortIcon />}
      </SortIcon>
    </SortButton>
  )
}

export default Sort
