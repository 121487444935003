import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Plate: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 4C2 2.89543 2.89543 2 4 2H7C8.10457 2 9 2.89543 9 4V7C9 8.10457 8.10457 9 7 9H4C2.89543 9 2 8.10457 2 7V4ZM7 4H4V7H7V4ZM11 4C11 2.89543 11.8954 2 13 2H16C17.1046 2 18 2.89543 18 4V7C18 8.10457 17.1046 9 16 9H13C11.8954 9 11 8.10457 11 7V4ZM16 4H13V7H16V4ZM2 13C2 11.8954 2.89543 11 4 11H7C8.10457 11 9 11.8954 9 13V16C9 17.1046 8.10457 18 7 18H4C2.89543 18 2 17.1046 2 16V13ZM7 13H4V16H7V13ZM11 13C11 11.8954 11.8954 11 13 11H16C17.1046 11 18 11.8954 18 13V16C18 17.1046 17.1046 18 16 18H13C11.8954 18 11 17.1046 11 16V13ZM16 13H13V16H16V13Z'
      fill={color}
    />
  </Icon>
)

Plate.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Plate }
