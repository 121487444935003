import { FC } from 'react'

import { useAppTheme } from 'ui/theme'
import { Text } from 'ui'

import { TextProps } from 'ui/typography/Text/types'

const Headline: FC<TextProps> = ({
  color = 'text.black',
  children,
  ...props
}) => {
  const theme = useAppTheme()

  return (
    <Text
      {...props}
      color={color}
      lineHeight={theme.lineHeights.m}
      fontSize={theme.fontSizes.headline.s}
      fontWeight={theme.fontWeights.bold}
    >
      {children}
    </Text>
  )
}

export { Headline }
