import { forwardRef } from 'react'

import { useAppTheme } from 'ui/theme'
import { Text } from 'ui'

import type { FC, Ref } from 'react'
import type { TextProps } from 'ui/typography/Text/types'

interface TitleProps extends TextProps {
  level?: 1 | 2 | 3
}

const Title: FC<TitleProps> = forwardRef(
  ({ level = 3, color = 'text.black', children, ...props }, ref) => {
    const theme = useAppTheme()

    const levels = {
      1: {
        fontSize: theme.fontSizes.title.l,
        fontWeight: theme.fontWeights.black,
        lineHeight: theme.lineHeights.xxl[2],
      },
      2: {
        fontSize: theme.fontSizes.title.m,
        fontWeight: theme.fontWeights.bold,
        lineHeight: theme.lineHeights.xxl[1],
      },
      3: {
        fontSize: theme.fontSizes.title.s,
        fontWeight: theme.fontWeights.semibold,
        lineHeight: theme.lineHeights.xxl[0],
      },
    }

    return (
      <Text
        {...props}
        ref={ref as Ref<HTMLSpanElement>}
        color={color}
        fontSize={levels[level].fontSize}
        fontWeight={levels[level].fontWeight}
        lineHeight={levels[level].lineHeight}
      >
        {children}
      </Text>
    )
  },
)

export { Title }
