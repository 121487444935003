import BasePicker from 'ui/datePicker/BasePicker'

import type { FC } from 'react'
import type { DatePickerProps } from 'ui/datePicker/types'

const DatePicker: FC<DatePickerProps> = ({ date, onChange, ...props }) => (
  <BasePicker
    className='__datepicker'
    onDayClick={onChange}
    selectedDays={date}
    {...props}
  />
)

export { DatePicker }
