import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Location: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 4C7.21803 4 5 6.07261 5 9C5 10.1065 5.51508 11.5299 6.49745 12.8865C7.3875 14.1157 8.60475 15.2099 10 15.8992C11.3952 15.2099 12.6125 14.1157 13.5025 12.8865C14.4849 11.5299 15 10.1065 15 9C15 6.07261 12.782 4 10 4ZM3 9C3 4.92739 6.15456 2 10 2C13.8454 2 17 4.92739 17 9C17 10.6549 16.2651 12.4815 15.1225 14.0595C13.9703 15.6507 12.3341 17.0876 10.3939 17.9191C10.1424 18.027 9.85763 18.027 9.60608 17.9191C7.66592 17.0876 6.02969 15.6507 4.87755 14.0595C3.73492 12.4815 3 10.6549 3 9Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 8C9.44772 8 9 8.44772 9 9C9 9.55228 9.44772 10 10 10C10.5523 10 11 9.55228 11 9C11 8.44772 10.5523 8 10 8ZM7 9C7 7.34315 8.34315 6 10 6C11.6569 6 13 7.34315 13 9C13 10.6569 11.6569 12 10 12C8.34315 12 7 10.6569 7 9Z'
      fill={color}
    />
  </Icon>
)

Location.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Location }
