import type { FC } from 'react'

import { SignInContainer, SignInWrap } from 'modules/auth/pages/SignIn/styles'
import { SignInHeader, SignInForm } from 'modules/auth/pages/SignIn/components'

const SignIn: FC = () => (
  <SignInWrap>
    <SignInContainer>
      <SignInHeader />
      <SignInForm />
    </SignInContainer>
  </SignInWrap>
)

export { SignIn }
