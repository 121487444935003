import { theme, styled } from 'ui/theme'
import shouldForwardProp from '@styled-system/should-forward-prop'
import {
  flexbox,
  grid,
  space,
  layout,
  border,
  color,
  position,
} from 'styled-system'

import type {
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  GridProps,
  BorderProps,
  PositionProps,
  BackgroundColorProps,
} from 'styled-system'

type BoxProps = PositionProps &
  LayoutProps &
  GridProps &
  FlexboxProps &
  SpaceProps &
  BorderProps &
  BackgroundColorProps

const Box = styled('div', { shouldForwardProp })<BoxProps>`
  box-sizing: border-box;
  min-width: 0;
  ${position}
  ${layout}
  ${grid}
  ${flexbox}
  ${space}
  ${border}
  ${color}
`

Box.defaultProps = {
  theme,
}

export { Box }
