import { configureStore } from '@reduxjs/toolkit'
import { persistStore, Persistor } from 'redux-persist'

import { rootReducer } from 'store/rootReducer'

import { env } from 'settings/env'

import type { IResponseError } from 'modules/common/types/interfaces'
import type { AsyncThunk } from '@reduxjs/toolkit'

const store = configureStore({
  reducer: rootReducer,
  devTools: env.STAND !== 'prod',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
})

if (env.NODE_ENV === 'development' && (module as any).hot) {
  // tslint:disable-next-line
  ;(module as any).hot.accept('../rootReducer', () => {
    const newRootReducer = require('../rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

const persistor: Persistor = persistStore(store)

type RootState = ReturnType<typeof rootReducer>
type AppDispatch = typeof store.dispatch
type ThunkApiConfig = {
  state: RootState
  dispatch: AppDispatch
  rejectValue: IResponseError
}

type AppThunk<Returned = void, ThunkArg = void> = AsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig
>

export { store, persistor }
export type { RootState, AppDispatch, AppThunk }
