import { css } from '@emotion/react'

import type { SizesMap } from 'ui/inputs/FormField/types'
import { variant } from 'styled-system'

const sizesStyles = (): SizesMap => {
  const nano = css`
    .__form-field-input {
      min-height: 32px;
    }
  `
  const defaultSize = css`
    .__form-field-input {
      min-height: 36px;
    }
  `

  const large = css`
    .__form-field-input {
      min-height: 48px;
    }
  `

  return {
    nano,
    default: defaultSize,
    large,
  }
}

export const sizes = () =>
  variant({
    variants: sizesStyles(),
    prop: 'size',
  })
