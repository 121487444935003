import { useState, useEffect, useRef, useCallback } from 'react'

import { env } from 'settings/env'

type UseControlledConfig<Value> = {
  controlledValue?: Value
  initialValue?: Value
  name: string
}

const useControlled = <Value>({
  controlledValue,
  initialValue,
  name,
}: UseControlledConfig<Value>) => {
  const isControlled = controlledValue !== undefined
  const { current: origIsControlled } = useRef(isControlled)
  const [internalValue, setInternalValue] = useState(initialValue)
  const { current: origInitialValue } = useRef(initialValue)
  const value = origIsControlled ? controlledValue : internalValue

  useEffect(() => {
    if (env.NODE_ENV !== 'production') {
      if (origIsControlled !== isControlled) {
        console.warn(
          `"${name}" is changed from ${
            origIsControlled
              ? 'uncontrolled to controlled'
              : 'controlled to uncontrolled'
          }.`,
        )
      }
    }
  }, [origIsControlled, isControlled, name])

  useEffect(() => {
    if (env.NODE_ENV !== 'production') {
      if (!origIsControlled && origInitialValue !== initialValue) {
        console.warn(
          `"${name}" is changing its defaultValue after being initialized. Make "${name}" a controlled component instead.`,
        )
      }
    }
  }, [origInitialValue, initialValue, name, origIsControlled])

  const setValueIfUncontrolled = useCallback(
    (newValue: Value) => {
      if (!origIsControlled) {
        setInternalValue(newValue)
      }
    },
    [origIsControlled],
  )

  return [value, setValueIfUncontrolled] as const
}

export { useControlled }
