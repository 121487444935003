import { DateUtils } from 'react-day-picker'

import BasePicker from 'ui/datePicker/BasePicker'

import type { FC } from 'react'
import type { RangePickerProps } from 'ui/datePicker/types'

const RangePicker: FC<RangePickerProps> = ({ date, onChange, ...props }) => {
  const modifiers = {
    start: date.from,
    end: date.to,
  }

  const onDayClick = day => {
    const range = DateUtils.addDayToRange(day, date)
    onChange(range)
  }

  const currentYear = new Date().getFullYear()
  const prevMonth = new Date().getMonth() - 1
  const month = new Date(currentYear, prevMonth)

  return (
    <BasePicker
      onDayClick={onDayClick}
      selectedDays={[
        date.from,
        {
          from: date.from,
          to: date.to,
        },
      ]}
      {...props}
      className='__rangePicker'
      modifiers={modifiers}
      numberOfMonths={2}
      month={month}
    />
  )
}

export { RangePicker }
