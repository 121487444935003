import React, { useCallback, useState } from 'react'

import { CbxState } from 'modules/common/types/interfaces'

type Item = {
  [key: string]: any
  id?: number | string
}

const initialState: CbxState = {
  checkedList: [],
  indeterminate: false,
  checkAll: false,
}

export const useTableCbx = (items: Item[], idType = 'id') => {
  const [cbxState, setCbxState] = useState<CbxState>(initialState)

  const onChangeCbx = (checkedId: string | number) => {
    setCbxState(prevState => {
      const { checkedList } = prevState
      const newList = checkedList.some(item => item === checkedId)
        ? checkedList.filter(item => item !== checkedId)
        : [...checkedList, checkedId]

      return {
        checkedList: newList,
        indeterminate: newList.length > 0 && newList.length < items.length,
        checkAll: newList.length === items.length,
      }
    })
  }

  const onChangeCbxArr = (checkedList: string[] | number[]) => {
    setCbxState({
      ...cbxState,
      checkedList,
    })
  }

  const onCheckAllCbxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCbxState({
      checkedList: e.target.checked ? items.map(item => item[idType]) : [],
      indeterminate: false,
      checkAll: e.target.checked,
    })
  }

  const onResetCbx = useCallback(() => setCbxState(initialState), [])

  const isAllSelected =
    items.length > 0 && items.length === cbxState.checkedList.length

  return {
    cbxState,
    onChangeCbx,
    onChangeCbxArr,
    onCheckAllCbxChange,
    onResetCbx,
    setCbxState,
    isAllSelected,
  }
}
