export enum FetchRefundState {
  CREATED = 0,
  IN_PROGRESS = 2,
  DISPUTE = 3,
  FINISHED = 4,
  REJECTED = 5,
}

export enum ORDERS_FILTER_STATUS {
  WAITING_CONFIRMATION = 'waiting_confirmation',
  ON_ASSEMBLY = 'on_assembly',
  ASSEMBLED = 'assembled',
  DELIVERING = 'delivering',
  COMPLETED = 'completed',
  REASSEMBLY = 'reassembly',
  CANCELED = 'canceled',
}

export enum SORTING_TYPES {
  ID_ASC = '_id:asc',
  ID_DESC = '_id:desc',
  TOTAL_PRICE_ASC = 'total_price:asc',
  TOTAL_PRICE_DESC = 'total_price:desc',
  CREATED_AT_ASC = 'created_at:asc',
  CREATED_AT_DESC = 'created_at:desc',
  COMPLETED_TIME_ASC = 'completed_time:asc',
  COMPLETED_TIME_DESC = 'completed_time:desc',
}
