import { AxiosError } from 'modules/common/types'

interface CustomerRejectWithValue {
  rejectWithValue: any
}

export const thunkErrorHandler = (thunkApi: CustomerRejectWithValue) => (
  error: AxiosError,
) => {
  return thunkApi.rejectWithValue(
    (error.response && error.response.data) || {
      response: {
        data: {
          error: {
            message: 'UNKNOWN_ERROR',
          },
        },
      },
    },
  )
}
