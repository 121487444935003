import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { changeOrderPickupPoint } from 'modules/orders/slices/orders'

import { PickupPointType } from 'modules/profile/types'

export const useChangeOrderPickupPoint = () => {
  const dispatch = useAppDispatch()

  const [activePickupPoint, setActivePickupPoint] = useState<PickupPointType>()

  const details = useAppSelector(state => state.orders.orders.details)
  const pickupPoints = useAppSelector(state => state.profile.pickupPoints.list)

  const onChangePickupPoint = (pickupPointId: string) => {
    setActivePickupPoint(
      pickupPoints.find(pickupPoint => pickupPoint.store_id === pickupPointId),
    )

    dispatch(changeOrderPickupPoint(pickupPointId))
  }

  useEffect(() => {
    setActivePickupPoint(
      pickupPoints.find(
        pickupPoint => pickupPoint.store_id === details.pickup_point_id,
      ),
    )
  }, [details, pickupPoints])

  return {
    activePickupPoint,
    onChangePickupPoint,
  }
}
