import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchUnrelatedProducts } from 'modules/products/slices/unrelatedProducts'

const useUnrelatedProducts = (text: string) => {
  const dispatch = useAppDispatch()
  const { unrelatedProducts } = useAppSelector(
    state => state.products.unrelatedProducts,
  )

  useEffect(() => {
    dispatch(fetchUnrelatedProducts({ search: text }))
  }, [text, dispatch])

  return { unrelatedProducts }
}

export { useUnrelatedProducts }
