import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Replay: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M10.0001 4.16677V1.84177C10.0001 1.46677 9.5501 1.28344 9.29177 1.55011L6.1251 4.70844C5.95844 4.87511 5.95844 5.13344 6.1251 5.30011L9.28344 8.45844C9.55011 8.71677 10.0001 8.53344 10.0001 8.15844V5.83344C13.1084 5.83344 15.5668 8.68344 14.8834 11.9084C14.4918 13.8001 12.9584 15.3251 11.0751 15.7168C8.1001 16.3418 5.4501 14.3001 5.0501 11.5418C4.99177 11.1418 4.64177 10.8334 4.23344 10.8334C3.73344 10.8334 3.33344 11.2751 3.4001 11.7751C3.91677 15.4334 7.4001 18.1418 11.3418 17.3751C13.9418 16.8668 16.0334 14.7751 16.5418 12.1751C17.3668 7.90011 14.1168 4.16677 10.0001 4.16677Z'
      fill={color}
    />
  </Icon>
)

Replay.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Replay }
