import { combineReducers } from '@reduxjs/toolkit'

import contacts from 'modules/common/slices/contacts'
import aside from 'modules/common/slices/asideMenu'
import cities from 'modules/common/slices/cities'

export default combineReducers({
  contacts,
  cities,
  aside,
})
