import { Box } from 'ui'

const onlyLatinRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[A-Z]{0,})(?=.*[a-z]{0,})(?=.*\d{0,})(?=.*[!@#$%^&*()_=+\[\]{};:”\|,.])[A-Za-z\d!@#$%^&*()_=+\[\]{};:”\|,.]{8,}$/
const upperCaseRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_=+\[\]{};:”\|,.])[A-Za-z\d!@#$%^&*()_=+\[\]{};:”\|,.]{8,}$/

export const validatePassword = (
  password: string,
  setError,
  setPassMessage,
  setVisibleTooltip,
) => {
  if (password.length === 0) {
    setError(false)
    setPassMessage(null)
    setVisibleTooltip(false)
  } else if (password.length < 8) {
    setError(true)
    setPassMessage(<Box>Используйте хотя бы 8 символов</Box>)
    setVisibleTooltip(true)
  } else if (!onlyLatinRegex.test(password)) {
    setError(true)
    setPassMessage(
      <Box>
        Для пароля можно использовать
        <br />
        только буквы латинского алфавита,
        <br />
        цифры и символы: !@#$%^&*()_=+[]{};:”\|,.
      </Box>,
    )
    setVisibleTooltip(true)
  } else if (!upperCaseRegex.test(password)) {
    setError(true)
    setPassMessage(
      <Box>
        Используйте большие и маленькие
        <br />
        буквы, добавьте цифры
      </Box>,
    )
    setVisibleTooltip(true)
  } else {
    setError(false)
    setPassMessage(
      <Box>
        Отлично! Такой подойдёт. <br />
        Только не забудьте его
      </Box>,
    )
    setVisibleTooltip(true)
  }
}
