import { useState } from 'react'

import { useNotifications } from 'modules/notifications/hooks'

import {
  TabsHead,
  TabsContent,
  Box,
  Typography,
  Badge,
  IconButton,
  Divider,
} from 'ui'
import { CloseOutlinedIcon } from 'ui/icons'
import { NeedActionTab } from '../NeedActionTab'
import { SystemTab } from '../SystemTab'

import {
  NotificationStatus,
  NotificationTab,
} from 'modules/notifications/types'

import { NotificationsWrap } from './styles'

import type { FC } from 'react'

const { Subtitle } = Typography

interface Props {
  onClose: () => void
  onInView?: (id: string) => void
}

const Notifications: FC<Props> = ({ onClose, onInView }) => {
  const {
    actionableNotifications,
    actionableLoading,
    informationalNotifications,
    informationalLoading,
  } = useNotifications()

  const [activeTab, setActiveTab] = useState<NotificationTab>(
    NotificationTab.Actions,
  )

  const handleChangeTab = (tab: NotificationTab) => {
    setActiveTab(tab)
  }

  const handleClose = () => {
    onClose()
  }

  const newActionableCount =
    actionableNotifications.filter(
      note => note.status === NotificationStatus.New,
    ).length || 0

  const newInformationalCount =
    informationalNotifications.filter(
      note => note.status === NotificationStatus.New,
    ).length || 0

  return (
    <NotificationsWrap>
      <Box display='flex' justifyContent='space-between' padding='16px'>
        <Subtitle weight='semibold'>Уведомления</Subtitle>
        <IconButton onClick={handleClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>

      <TabsHead
        activeTab={activeTab}
        onChange={handleChangeTab}
        marginBottom='0'
        fullWidth
      >
        <TabsHead.Item tabValue={NotificationTab.Actions}>
          <Badge count={newActionableCount} unlim>
            Требуют действия
          </Badge>
        </TabsHead.Item>
        <TabsHead.Item tabValue={NotificationTab.System}>
          <Badge count={newInformationalCount} unlim>
            Системные
          </Badge>
        </TabsHead.Item>
      </TabsHead>

      <Divider />

      <TabsContent activeTab={activeTab}>
        <TabsContent.Item tabValue={NotificationTab.Actions}>
          <NeedActionTab
            notifications={actionableNotifications}
            loading={actionableLoading}
            onInView={onInView}
            onClickItem={onClose}
          />
        </TabsContent.Item>
        <TabsContent.Item tabValue={NotificationTab.System}>
          <SystemTab
            notifications={informationalNotifications}
            loading={informationalLoading}
            onInView={onInView}
            onClickItem={onClose}
          />
        </TabsContent.Item>
      </TabsContent>
    </NotificationsWrap>
  )
}

export { Notifications }
