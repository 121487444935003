import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Upload: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M6.16669 8.33335H7.49169V12.5C7.49169 12.9583 7.86669 13.3333 8.32502 13.3333H11.6584C12.1167 13.3333 12.4917 12.9583 12.4917 12.5V8.33335H13.8167C14.5584 8.33335 14.9334 7.43335 14.4084 6.90835L10.5834 3.08335C10.2584 2.75835 9.73335 2.75835 9.40835 3.08335L5.58335 6.90835C5.05835 7.43335 5.42502 8.33335 6.16669 8.33335ZM4.16669 15.8333C4.16669 16.2917 4.54169 16.6667 5.00002 16.6667H15C15.4584 16.6667 15.8334 16.2917 15.8334 15.8333C15.8334 15.375 15.4584 15 15 15H5.00002C4.54169 15 4.16669 15.375 4.16669 15.8333Z'
      fill={color}
    />
  </Icon>
)

Upload.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Upload }
