import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const VK: FC<IconProps> = ({ originalWidth, originalHeight, ...props }) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.6448 11.2309C15.5785 11.1651 15.5123 11.0993 15.5123 11.0335C15.5123 10.9677 15.5785 10.836 15.6448 10.7702C16.5729 9.51952 17.3021 8.53216 18.0976 7.21568C18.3628 6.82073 18.3628 6.49161 18.2965 6.42579C18.2302 6.29414 18.0313 6.16249 17.8987 6.16249C16.8381 6.16249 16.374 6.16249 15.1808 6.16249C14.8494 6.16249 14.5179 6.16249 14.319 6.55744C13.7887 7.67645 12.9932 9.32205 12.264 9.98029C12.1314 10.1119 11.9989 10.1778 11.8 10.1778C11.6011 10.1778 11.4685 10.0461 11.4685 9.84864C11.4685 8.66381 11.4685 7.67645 11.4685 6.49161C11.4685 6.29414 11.4022 6.09667 11.2034 5.96502C11.0045 5.83337 10.8056 5.83337 10.6068 5.83337H8.35287C7.68996 5.83337 7.29222 6.09667 7.29222 6.22832C7.29222 6.35997 7.62367 6.49161 7.82254 6.62326C8.02141 6.82073 8.22029 7.08403 8.22029 7.41315V9.71699C8.22029 9.91447 8.154 10.1119 7.95512 10.2436C7.88883 10.3094 7.75625 10.3094 7.68996 10.3094C7.55738 10.3094 7.49109 10.2436 7.4248 10.1778C6.56302 9.05875 6.56302 8.9271 5.3035 6.68909C5.23721 6.55744 5.17092 6.49161 5.03834 6.35997C4.83946 6.22832 4.64059 6.22832 4.44172 6.22832C3.44736 6.22832 2.85074 6.22832 2.18784 6.22832C1.52493 6.22832 1.65751 6.62326 1.7238 6.75491C2.453 8.26886 3.1822 9.71699 4.04398 11.1651C5.03834 12.8765 5.9664 13.9297 7.49109 14.6538C8.02141 14.9171 8.61803 14.9829 9.21465 14.9829H10.8056C11.0045 14.9829 11.3359 14.8513 11.3359 14.588V13.4031C11.3359 13.074 11.6674 12.9424 11.8663 12.8765C12.0651 12.8107 12.264 12.8765 12.3966 13.074C13.2584 13.9956 13.1921 13.9297 13.9876 14.7196C14.1202 14.9171 14.3853 15.0487 14.6505 14.9829C16.9707 14.9829 16.9707 14.9829 17.5673 14.9829C17.7661 14.9171 17.8987 14.8513 17.965 14.6538C18.0313 14.5221 18.1639 14.1272 17.965 13.8639C17.2358 12.8765 16.4403 12.0208 15.6448 11.2309Z'
    />
  </Icon>
)

VK.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { VK }
