import { Fragment, useEffect, useRef, useState } from 'react'

import RcTable from 'rc-table'

import { DefaultEmpty, TableOverlay } from 'ui/table/components'
import { TableWrap } from 'ui/table/styles'
import { ChevronIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'
import { Box } from 'ui/layout'
import { Portal } from 'ui'

import type { ReactElement, Key } from 'react'
import type { TableProps } from 'ui/table/types'

const Table = <T extends {}>({
  emptyText,
  emptyButton,
  temporarilyEmpty,
  expandable,
  multipleExpand,
  rowClassName,
  showRenderGeneralActions,
  renderGeneralActions,
  renderRowActions: RenderRowActions,
  onRow,
  rowKey,
  ...props
}: TableProps<T>): ReactElement<TableProps<T>> => {
  const theme = useAppTheme()
  const ref = useRef(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([])
  const [hoveredRowCoordinates, setHoveredRowCoordinates] = useState(null)
  const [hoveredRowId, setHoveredRowId] = useState(null)

  let onRowProps = {}

  useEffect(() => {
    if (hoveredRowId) {
      const element = ref?.current.getElementsByClassName('hovered')[0]
      setHoveredRowCoordinates(element.getBoundingClientRect())
    }
  }, [hoveredRowId])

  const onExpandedRowsChange = (rowKeys: readonly Key[]) => {
    if (multipleExpand) {
      setExpandedRowKeys(rowKeys)
    } else {
      setExpandedRowKeys(
        rowKeys.length > 0 ? [rowKeys[rowKeys.length - 1]] : [],
      )
    }
  }

  const columns = props.columns.map(({ noPadding, ...column }) => ({
    ...column,
    className: `${column.className} ${noPadding ? 'cell-no-padding' : ''}`,
  }))

  return (
    <Fragment>
      <TableWrap
        ref={ref}
        showHeader={props.data.length !== 0}
        row={Boolean(onRow) || Boolean(RenderRowActions)}
        onMouseLeave={() => {
          if (RenderRowActions) {
            setHoveredRowId(null)
          }
        }}
      >
        <RcTable
          rowKey={rowKey}
          tableLayout='fixed'
          scroll={{
            x: 1214,
          }}
          onRow={row => {
            if (RenderRowActions) {
              onRowProps = {
                ...onRowProps,
                onMouseEnter: () => setHoveredRowId(row[rowKey as string]),
              }
            }

            if (onRow) {
              onRowProps = {
                ...onRowProps,
                ...onRow(row),
              }
            }

            return onRowProps
          }}
          emptyText={() => (
            <DefaultEmpty
              emptyText={emptyText}
              emptyButton={emptyButton}
              temporarilyEmpty={temporarilyEmpty}
            />
          )}
          showHeader={props.data.length !== 0}
          expandable={{
            expandIconColumnIndex: columns.length,
            columnWidth: 56,
            expandedRowKeys,
            onExpandedRowsChange,
            expandIcon: props => (
              <Box
                onClick={e => props.onExpand(props.record, e)}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <ChevronIcon
                  color={theme.colors.icon.black}
                  down={!props.expanded}
                  up={props.expanded}
                />
              </Box>
            ),
            ...expandable,
          }}
          rowClassName={(record, index, indent) =>
            `${
              typeof rowClassName === 'function'
                ? rowClassName(record, index, indent)
                : rowClassName
            } ${expandable ? 'hoverable' : ''} ${
              record[rowKey as string] === hoveredRowId ? 'hovered' : ''
            }
          `
          }
          sticky={{
            offsetHeader: 68,
          }}
          {...props}
          columns={columns}
          className='__airba-custom-table'
        />

        <Portal>
          <TableOverlay
            show={Boolean(hoveredRowId) && !showRenderGeneralActions}
            top={(hoveredRowCoordinates?.top || 0) + window.scrollY - 30}
            placement='right'
          >
            <RenderRowActions
              {...props.data.find(
                item => item[rowKey as string] === hoveredRowId,
              )}
            />
          </TableOverlay>
        </Portal>

        <TableOverlay
          top='-16px'
          placement='center'
          show={showRenderGeneralActions}
        >
          {renderGeneralActions}
        </TableOverlay>
      </TableWrap>
    </Fragment>
  )
}

export { Table }
