import React, { Fragment, FC, ReactElement } from 'react'

interface RadioGroupProps {
  name: string
  value: string
  onChange: (value: string) => void
}

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  value,
  children,
  onChange,
}) => (
  <Fragment>
    {children &&
      React.Children.map<ReactElement, any>(children, child =>
        React.cloneElement(child, {
          name,
          defaultChecked: child.props.value === value,
          onClick: () => onChange(child.props.value),
        }),
      )}
  </Fragment>
)

export default RadioGroup
