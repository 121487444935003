import { useAppSelector } from 'store/hooks'

import { formatPhoneNumber } from 'utils/textFormatting'
import { Typography, Box } from 'ui'

import { Schedule } from 'modules/common/components'

import type { FC } from 'react'
import type { PickupPointType } from 'modules/profile/types'

const { Subtitle, Body } = Typography

const minWidth = '170px'

interface PickupPointProps {
  activePickupPoint: PickupPointType
}

const PickupPoint: FC<PickupPointProps> = ({ activePickupPoint }) => {
  const details = useAppSelector(state => state.orders.orders.details)

  return details.storage_type === 'fbs' && activePickupPoint ? (
    <Box>
      <Subtitle as='p' mb='16px'>
        Точка выдачи
      </Subtitle>

      <Box>
        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Адрес</Body>
          </Box>

          <Body as='p'>
            {activePickupPoint.address.street} {activePickupPoint.address.house}
            , офис {activePickupPoint.address.apartment}, этаж{' '}
            {activePickupPoint.address.floor}
          </Body>
        </Box>

        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Название</Body>
          </Box>

          <Body as='p'>{activePickupPoint.name}</Body>
        </Box>

        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Контактное лицо</Body>
          </Box>

          <Body as='p'>{activePickupPoint.contact.info}</Body>
        </Box>

        <Box display='flex' mb='8px'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>Номер телефона</Body>
          </Box>

          <Body as='p'>
            {formatPhoneNumber(activePickupPoint.contact.phone)}
          </Body>
        </Box>

        <Box display='flex'>
          <Box minWidth={minWidth} mr='49px'>
            <Body as='p'>График работы</Body>
          </Box>

          <Schedule schedule={activePickupPoint.schedule} />
        </Box>
      </Box>
    </Box>
  ) : null
}

export { PickupPoint }
