import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Installment: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.44881 2.73758C9.18395 2.13956 10.2379 2.13956 10.973 2.73758L11.5345 3.19432L12.2491 3.07951C13.1848 2.92917 14.0975 3.45613 14.4351 4.34161L14.693 5.01789L15.3693 5.27576C16.2547 5.6134 16.7817 6.52613 16.6314 7.46179L16.5166 8.1764L16.9733 8.73787C17.5713 9.47301 17.5713 10.5269 16.9733 11.2621L16.5166 11.8235L16.6314 12.5382C16.7817 13.4738 16.2547 14.3865 15.3693 14.7242L14.693 14.9821L14.4351 15.6583C14.0975 16.5438 13.1848 17.0708 12.2491 16.9204L11.5345 16.8056L10.973 17.2624C10.2379 17.8604 9.18395 17.8604 8.44881 17.2624L7.88734 16.8056L7.17273 16.9204C6.23706 17.0708 5.32434 16.5438 4.9867 15.6583L4.72883 14.9821L4.05255 14.7242C3.16707 14.3865 2.64011 13.4738 2.79044 12.5382L2.90526 11.8235L2.44852 11.2621C1.85049 10.5269 1.85049 9.47301 2.44852 8.73787L2.90526 8.1764L2.79044 7.46179C2.64011 6.52613 3.16707 5.6134 4.05255 5.27576L4.72883 5.01789L4.9867 4.34161C5.32434 3.45613 6.23706 2.92917 7.17273 3.07951L7.88734 3.19432L8.44881 2.73758ZM9.71091 4.28906L9.14944 4.7458C8.70733 5.10546 8.13278 5.25941 7.57007 5.169L6.85545 5.05418L6.59758 5.73046C6.39453 6.26299 5.97392 6.68359 5.4414 6.88665L4.76512 7.14452L4.87993 7.85913C4.97035 8.42184 4.81639 8.99639 4.45674 9.43851L4 9.99997L4.45674 10.5614C4.81639 11.0036 4.97035 11.5781 4.87993 12.1408L4.76512 12.8554L5.4414 13.1133C5.97392 13.3164 6.39453 13.737 6.59758 14.2695L6.85545 14.9458L7.57007 14.8309C8.13278 14.7405 8.70733 14.8945 9.14944 15.2541L9.71091 15.7109L10.2724 15.2541C10.7145 14.8945 11.289 14.7405 11.8518 14.8309L12.5664 14.9458L12.8242 14.2695C13.0273 13.737 13.4479 13.3164 13.9804 13.1133L14.6567 12.8554L14.5419 12.1408C14.4515 11.5781 14.6054 11.0036 14.9651 10.5614L15.4218 9.99997L14.9651 9.43851C14.6054 8.99639 14.4515 8.42184 14.5419 7.85913L14.6567 7.14452L13.9804 6.88665C13.4479 6.68359 13.0273 6.26299 12.8242 5.73046L12.5664 5.05418L11.8518 5.169C11.289 5.25941 10.7145 5.10546 10.2724 4.7458L9.71091 4.28906Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.418 7.29287C12.8085 7.68339 12.8085 8.31655 12.418 8.70708L8.41802 12.7071C8.02749 13.0976 7.39433 13.0976 7.0038 12.7071C6.61328 12.3166 6.61328 11.6834 7.0038 11.2929L11.0038 7.29287C11.3943 6.90234 12.0275 6.90234 12.418 7.29287Z'
      fill={color}
    />
    <path
      d='M12.7109 12C12.7109 12.5523 12.2632 13 11.7109 13C11.1586 13 10.7109 12.5523 10.7109 12C10.7109 11.4477 11.1586 11 11.7109 11C12.2632 11 12.7109 11.4477 12.7109 12Z'
      fill={color}
    />
    <path
      d='M8.71091 7.99997C8.71091 8.55226 8.26319 8.99997 7.71091 8.99997C7.15863 8.99997 6.71091 8.55226 6.71091 7.99997C6.71091 7.44769 7.15863 6.99997 7.71091 6.99997C8.26319 6.99997 8.71091 7.44769 8.71091 7.99997Z'
      fill={color}
    />
  </Icon>
)

Installment.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Installment }
