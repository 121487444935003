import { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { useTableCbx } from 'ui/table/useTableCbx'

import { EditIcon, MiniSwitchOffIcon, MiniSwitchOnIcon } from 'ui/icons'
import { CheckboxButton } from 'ui/button/ActionButton'
import {
  ActionButton,
  Typography,
  Checkbox,
  NavTabs,
  Divider,
  Switch,
  Modal,
  Table,
  Box,
} from 'ui'

import {
  disablePickupPoint,
  enablePickupPoint,
  fetchPickupPoints,
} from 'modules/profile/slices/pickupPoints'

import { Schedule } from 'modules/common/components'
import { profileRoutes } from 'modules/profile/routes'

import { formatPhoneNumber } from 'utils/textFormatting'

import type { FC } from 'react'
import type { ColumnsType } from 'ui/table/types'
import type { PickupPointType } from 'modules/profile/types'

const { Body } = Typography

const PickupPoints: FC = () => {
  const dispatch = useAppDispatch()

  const pickupPoints = useAppSelector(state => state.profile.pickupPoints.list)
  const CITIES = useAppSelector(state => state.common.cities.CITIES)

  const { cbxState, onChangeCbx, onCheckAllCbxChange, isAllSelected } =
    useTableCbx(pickupPoints, 'store_id')

  const [visible, setVisible] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (history.location.state) {
      dispatch(fetchPickupPoints())
      window.scrollTo(0, 0)
    }
  }, [history, dispatch])

  const columns: ColumnsType<PickupPointType> = [
    {
      title: '',
      dataIndex: 'store_id',
      key: 'store_id',
      width: 44,
      render: id => (
        <Checkbox
          id={id}
          value={id}
          onChange={() => onChangeCbx(id)}
          checked={cbxState.checkedList.some(itemId => itemId === id)}
        />
      ),
    },
    {
      title: '№',
      dataIndex: 'priority',
      key: 'priority',
      width: 40,
    },
    {
      title: 'ID',
      dataIndex: 'store_id',
      key: 'store_id',
      width: 380,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      width: 490,
      render: address => (
        <Body>{`${CITIES[address.city_id]?.title?.ru}, ${address.street} ${
          address.house
        }, офис ${address.apartment}`}</Body>
      ),
    },
    {
      title: 'График работы',
      dataIndex: 'schedule',
      key: 'schedule',
      width: 400,
      render: schedule => <Schedule schedule={schedule} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 288,
    },
    {
      title: 'Имя контакта',
      dataIndex: 'contact',
      key: 'contact',
      width: 134,
      render: contact => (
        <Box>
          <Body as='p'>{contact.info}</Body>
        </Box>
      ),
    },
    {
      title: 'Номер контакта',
      dataIndex: 'contact',
      key: 'contact',
      width: 170,
      render: contact => (
        <Box>
          <Body as='p'>{formatPhoneNumber(contact.phone)}</Body>
        </Box>
      ),
    },
    {
      title: '',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 63,
      fixed: 'right',
      render: (isActive, row) => (
        <Switch
          checked={isActive}
          onChange={() =>
            isActive
              ? dispatch(disablePickupPoint({ storeID: row.store_id }))
              : dispatch(enablePickupPoint({ storeID: row.store_id }))
          }
        />
      ),
    },
  ]

  const modalColumns: ColumnsType<PickupPointType> = [
    {
      title: 'ID',
      dataIndex: 'store_id',
      key: 'store_id',
      width: 60,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      width: 130,
      render: address => (
        <Body>{`${CITIES[address.city_id]?.title?.ru}, ${address.street} офис ${
          address.house
        }`}</Body>
      ),
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Имя контакта',
      dataIndex: 'contact',
      key: 'contact',
      width: 134,
      render: contact => (
        <Box>
          <Body as='p'>{contact.info}</Body>
        </Box>
      ),
    },
    {
      title: 'Номер контакта',
      dataIndex: 'contact',
      key: 'contact',
      width: 90,
      render: contact => (
        <Box>
          <Body as='p'>{formatPhoneNumber(contact.phone)}</Body>
        </Box>
      ),
    },
  ]

  return (
    <Fragment>
      <Box pt='24px' px='24px' mb='24px'>
        <Typography.Title as='p' mb='16px'>
          Профиль
        </Typography.Title>

        <NavTabs routes={profileRoutes} />
      </Box>
      <Divider />
      <Box py='8px' pr='24px' pl='12px' display='flex' alignItems='center'>
        <CheckboxButton htmlFor='pickup-point-table-cbx'>
          <Checkbox
            id='pickup-point-table-cbx'
            checked={cbxState.checkAll}
            onChange={onCheckAllCbxChange}
            indeterminate={cbxState.indeterminate}
          />

          <Body weight='semibold'>
            {isAllSelected
              ? `${cbxState.checkedList.length} Снять выделение`
              : 'Выделить всё'}
          </Body>
        </CheckboxButton>

        {cbxState.checkedList.length > 0 && (
          <Box display='flex'>
            <ActionButton
              icon={MiniSwitchOnIcon}
              onClick={() =>
                dispatch(
                  enablePickupPoint({
                    storeID: cbxState.checkedList as string[],
                  }),
                )
              }
            >
              Включить точку выдачи
            </ActionButton>
            <ActionButton
              icon={MiniSwitchOffIcon}
              onClick={() => {
                dispatch(
                  disablePickupPoint({
                    storeID: cbxState.checkedList as string[],
                  }),
                )
              }}
            >
              Выключить точку выдачи
            </ActionButton>
          </Box>
        )}
        {cbxState.checkedList.length === 1 && (
          <ActionButton
            icon={EditIcon}
            onClick={() => {
              history.push(`/profile/pickup-points/${cbxState.checkedList[0]}`)
            }}
          >
            Изменить
          </ActionButton>
        )}
      </Box>
      <Divider />

      <Box mt='16px'>
        <Table data={pickupPoints} columns={columns} />
      </Box>

      <Modal
        title='Отключение точки выдачи'
        okText='отключить'
        width='720px'
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        onClose={() => setVisible(false)}
      >
        <Body as='p' mb='16px'>
          В списке есть одна или несколько точек выдачи привязанная к
          отменённому заказу. Перед отключением точки выдачи убедитесь, что все
          товары с отменённых заказов вернулись на склад
        </Body>
        <Body weight='semibold'>Точки выдачи</Body>
        <Box mt='16px'>
          <Table data={pickupPoints} columns={modalColumns} scroll={null} />
        </Box>
      </Modal>
    </Fragment>
  )
}

export { PickupPoints }
