export enum NotificationTab {
  Actions = 'actions',
  System = 'system',
}

export enum NotificationStatus {
  New = 'new',
  Read = 'read',
}

export enum ActionLinkType {
  OrderPage = 'order_page',
}
