import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchProducts } from 'modules/products/slices/products'

import { FetchProductsOptions } from 'modules/products/types'

const useProducts = ({
  search,
  category,
  withInstallment,
}: FetchProductsOptions = {}) => {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector(state => state.products.products)

  useEffect(() => {
    dispatch(fetchProducts({ search, category, withInstallment }))
  }, [search, category, withInstallment, dispatch])

  return { products }
}

export { useProducts }
