import { css, SerializedStyles } from '@emotion/react'
import { styled } from 'ui/theme'

import { Theme } from 'ui/theme/src/types'
import { SizesProps } from 'ui/inputs/Select/types'

export const sizes = ({ size }: SizesProps): SerializedStyles => {
  const nano = css`
    height: 32px;
  `

  const defaultSize = css`
    height: 36px;
  `

  const large = css`
    height: 48px;
  `

  const sizeNames = {
    nano,
    default: defaultSize,
    large,
  }

  return sizeNames[size]
}

export const SelectWrap = styled.div`
  .rc-select {
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: ${props => props.theme.fontSizes.body.m};
    line-height: ${props => props.theme.lineHeights.m};
    color: ${props => props.theme.colors.text.black};
    transition: all 0.2s;
    background: #ffffff;
    border-radius: ${props =>
      props.isPaginate ? props.theme.radii.xxxs : props.theme.radii.xxs};
    border: 1px solid
      ${props =>
        props.isPaginate
          ? props.theme.greyColorPalette[10]
          : props.theme.colors.text.secondaryBlack};

    ${sizes};
    &:not(.rc-select-customize-input) {
      .rc-select-selector {
        height: 100%;
        width: 85%;
        padding: 0 0 1px 12px;
        box-sizing: border-box;

        .rc-select-selection-search {
          width: 100%;

          &-input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
          }
        }
      }
    }

    &-disabled {
      &,
      & input {
        cursor: not-allowed;
      }

      .rc-select-selector {
        opacity: 0.3;
      }
    }

    // ============== Selector ===============
    .rc-select-selection-placeholder {
      opacity: 0.4;
      pointer-events: none;
    }

    // ============== Search ===============
    .rc-select-selection-search-input {
      appearance: none;

      &::-webkit-search-cancel-button {
        display: none;
        appearance: none;
      }
    }

    // --------------- Single ----------------
    &-single {
      .rc-select-selector {
        display: flex;
        position: relative;

        .rc-select-selection-item,
        .rc-select-selection-placeholder {
          width: 95%;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: absolute;
          pointer-events: none;
          top: 50%;
          overflow: hidden;
          transform: translateY(-50%);
        }
      }
    }

    // ================ Icons ================
    &-allow-clear {
      &.rc-select-multiple .rc-select-selector {
        padding-right: 20px;
      }

      .rc-select-clear {
        position: absolute;
        right: 38px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 0;
      }
    }

    &-show-arrow {
      &.rc-select-multiple .rc-select-selector {
        padding-right: 20px;
      }

      .rc-select-arrow {
        pointer-events: none;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 0;
      }
    }

    // =============== Focused ===============
    &-focused {
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      border-color: ${props => props.theme.colors.projects.primaryMarket};
    }

    // ============== Dropdown ===============
  }
`

export const getSelectStyles = (theme: Theme) => css`
  .rc-select-item-option-state {
    right: auto;
    left: 16px;
    top: 50% !important;
    line-height: 0;
    transform: translateY(-50%);
  }

  .rc-select {
    &-dropdown {
      box-shadow: 0 8px 14px rgba(16, 20, 45, 0.16),
        0 3.87387px 9.86002px rgba(16, 20, 45, 0.0898405);
      position: absolute;
      background: ${theme.colors.main.background.white};
      border-radius: ${theme.radii.xs};
      z-index: 2;

      &-hidden {
        display: none;
      }
    }

    // =============== Option ================
    &-item {
      padding: 16px 16px 16px 44px;
      font-size: 15px;
      line-height: 24px;
      cursor: pointer;
      color: ${theme.colors.text.black};

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 18px;
        top: 50% !important;
        transform: translateY(-50%);
        border: 2px solid ${theme.colors.icon.secondaryBlack};
        border-radius: ${theme.radii.r};
        width: 12px;
        height: 12px;
      }

      // >>> Group
      &-group {
        color: #999;
        font-weight: bold;
        font-size: 80%;
      }

      // >>> Option
      &-option {
        position: relative;

        &-grouped {
          padding-left: 24px;
        }

        .rc-select-item-option-state {
          position: absolute;
          right: 0;
          top: 4px;
          pointer-events: none;
        }

        // ------- Active -------
        &-active {
          background-color: ${theme.greyColorPalette[5]};
        }

        // ------ Disabled ------
        &-disabled {
          color: #999;
        }
      }

      // >>> Empty
      &-empty {
        text-align: center;
        color: ${theme.colors.text.secondaryBlack};
        padding: 16px 0;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  .rc-select-selection__choice-zoom {
    transition: all 0.3s;
  }

  .rc-select-selection__choice-zoom-appear {
    opacity: 0;
    transform: scale(0.5);

    &&-active {
      opacity: 1;
      transform: scale(1);
    }
  }

  .rc-select-selection__choice-zoom-leave {
    opacity: 1;
    transform: scale(1);

    &&-active {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  .rc-select-dropdown {
    &-slide-up-enter,
    &-slide-up-appear {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      transform-origin: 0 0;
      opacity: 0;
      animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
      animation-play-state: paused;
    }

    &-slide-up-leave {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      transform-origin: 0 0;
      opacity: 1;
      animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
      animation-play-state: paused;
    }

    &-slide-up-enter&-slide-up-enter-active&-placement-bottomLeft,
    &-slide-up-appear&-slide-up-appear-active&-placement-bottomLeft {
      animation-name: rcSelectDropdownSlideUpIn;
      animation-play-state: running;
    }

    &-slide-up-leave&-slide-up-leave-active&-placement-bottomLeft {
      animation-name: rcSelectDropdownSlideUpOut;
      animation-play-state: running;
    }

    &-slide-up-enter&-slide-up-enter-active&-placement-topLeft,
    &-slide-up-appear&-slide-up-appear-active&-placement-topLeft {
      animation-name: rcSelectDropdownSlideDownIn;
      animation-play-state: running;
    }

    &-slide-up-leave&-slide-up-leave-active&-placement-topLeft {
      animation-name: rcSelectDropdownSlideDownOut;
      animation-play-state: running;
    }
  }

  @keyframes rcSelectDropdownSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform-origin: 0 0;
      transform: scaleY(1);
    }
  }
  @keyframes rcSelectDropdownSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0 0;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleY(0);
    }
  }
`
