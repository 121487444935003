import BaseDropdown from 'rc-dropdown'
import Menu, { Item } from 'rc-menu'

import { Global } from '@emotion/react'
import { styles } from './styles'

import type { DropdownProps } from 'ui/dropdown/types'

const Dropdown: DropdownProps = ({ children, ...props }) => (
  <>
    <Global styles={styles} />
    <BaseDropdown {...props}>{children}</BaseDropdown>
  </>
)

Dropdown.defaultProps = {
  animation: 'slide-up',
  trigger: ['click'],
}

Dropdown.Menu = Menu
Dropdown.MenuItem = Item

export { Dropdown }
