import { styled } from 'ui/theme'

import { flexbox, grid, border, space, layout, position } from 'styled-system'

import type { NavProps } from './types'

export const Nav = styled.nav<NavProps>`
  display: flex;
  align-items: center;
  height: 48px;

  .__nav-link {
    height: 48px;
    cursor: pointer;
    padding: 12px 16px;
    transition: all 0.1s;
    text-decoration: none;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;

    &:hover {
      background-color: ${props => props.theme.greyColorPalette[5]};
    }
  }

  .__active-nav-link {
    border-bottom: 2px solid #319cf3;
  }

  ${position}
  ${layout}
  ${grid}
  ${flexbox}
  ${space}
  ${border}
`
