export const PAYMENT_TYPES = {
  card_offline: 'Картой при получении',
  card_online: 'Картой онлайн',
  installment: 'Рассрочка',
  credit: 'Кредит',
  cash: 'Наличными',
}

export const STORAGE_TYPES = {
  fbo: 'Склад Airba',
  fbs: 'Мой склад',
}

export const ORDER_PRODUCT_STATUSES = {
  assembled: 'Готов к выдаче',
  canceled: 'Отменен',
  on_assembly: 'В сборке',
  courier_appointed: 'courier_goes_to_pickup',
  courier_arrived_for: 'Курьер прибыл забрать продукт',
  courier_checked_and_took: 'Курьер проверил и забрал продукт',
  courier_went_to_deliver: 'Курьер отправился на доставку',
  courier_arrived_at_buyer: 'Курьер прибыл к покупателю',
  delivered: 'Продукт доставлен',
  delivering: 'В доставке',
  completed: 'Завершен',
  merchant_did_not_confirm: 'Данный продукт не был подтвержден вовремя',
  merchant_did_not_assembly: 'Продавец не собрал продукт вовремя',
  returned: 'Продукт возвращен покупателем',
  reassembly: 'Пересборка',
  waiting_confirmation: 'Ожидает подтверждения',
}

export const TRANSACTION_REASONS = {
  order: 'Продажа товаров',
  return: 'Возврат',
  tax: 'tax',
}

export const TRANSACTION_TYPES = {
  accrual: 'Начисление',
  retention: 'Удержание',
  transfer: 'Трансфер',
}

export const REFUND_REASON_TYPES = {
  other: 'Другое',
}

export const EVENT_STATUS_COLORS = {
  processed: 'notify',
  error: 'error',
  success: 'success',
}

export const EVENT_STATUS_TITLE = {
  processed: 'Обработано',
  error: 'Ошибка',
  success: 'Загружено',
}

export const EVENT_TYPES = {
  auto: 'Автоматическая',
  manual: 'Ручная',
}

export const ROLES = {
  merchant_owner: 'владелец магазина',
}

export const ALL_OPTIONS = 'all'

export const YANDEX_MAP_KEY_SEARCH = '05b73897-064b-4ebb-9f53-d5f351574253';

export const YANDEX_MAP_KEY_GEO = '46893ab2-c9b0-45d7-9bde-96934efc1dff';
