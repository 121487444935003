import { Link } from 'react-router-dom'
import cn from 'classnames'
import { styled, useAppTheme } from 'ui/theme'
import { Tooltip, Typography } from 'ui'

import type { FC } from 'react'
import type { IconProps } from 'ui/icons'

const { Body } = Typography

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  width: 100%;
  padding: 0 24px;
  min-height: 40px;
  align-items: center;

  &:hover .title,
  &.active .title {
    background-color: ${props => props.theme.colors.main.background.gray};
  }
`

const LinkContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  min-width: 40px;
  height: 32px;
  padding-left: 10px;
  border-radius: 12px;
`

interface Props {
  isOpen: boolean
  to: string
  title: string
  icon: FC<IconProps>
  isActive: boolean
  pathName: string
}

const MenuItem: FC<Props> = ({
  isActive,
  isOpen,
  title,
  to,
  icon: Icon,
  pathName,
}) => {
  const theme = useAppTheme()

  return (
    <li data-test-id={`MenuButtons-${pathName}`}>
      {isOpen ? (
        <StyledLink to={to} className={cn(isActive && 'active')}>
          <LinkContent className='title'>
            <Icon color={theme.colors.projects.primaryMarket} />
            <Body>{title}</Body>
          </LinkContent>
        </StyledLink>
      ) : (
        <Tooltip overlay={title}>
          <StyledLink to={to} className={cn(isActive && 'active')}>
            <LinkContent className='title'>
              <Icon color={theme.colors.projects.primaryMarket} />
            </LinkContent>
          </StyledLink>
        </Tooltip>
      )}
    </li>
  )
}

export { MenuItem }
