import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchRefunds } from 'modules/refunds/slices/refunds'

export const useRefunds = () => {
  const dispatch = useAppDispatch()
  const { search } = useAppSelector(state => state.refunds.refunds.filters)

  useEffect(() => {
    dispatch(fetchRefunds())
  }, [dispatch, search])
}
