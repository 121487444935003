import cn from 'classnames'

import { Header, AsideMenu, Main, Footer } from 'modules/common/components'
import { Box } from 'ui'

import { Wrapper } from 'ui/layout/PageLayout'
import { useAppSelector } from 'store/hooks'

import type { FC } from 'react'

const App: FC = ({ children }) => {
  const { isOpen } = useAppSelector(state => state.common.aside)

  return (
    <Wrapper>
      <Header />
      <Box display='flex' flex={1}>
        <AsideMenu />
        <Main className={cn(!isOpen && 'collapsed')}>{children}</Main>
      </Box>
      <Footer />
    </Wrapper>
  )
}

export { App }
