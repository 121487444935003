import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Back: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.7071 3.29289C11.0976 3.68342 11.0976 4.31658 10.7071 4.70711L6.41421 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H6.41421L10.7071 15.2929C11.0976 15.6834 11.0976 16.3166 10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289L9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289Z'
      fill={color}
    />
  </Icon>
)

Back.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { Back }
