import { useEffect } from 'react'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { Box, Highlighter, Pagination, Search, Table } from 'ui'
import { formatCurrency } from 'utils/textFormatting'

import { useUnrelatedProducts } from 'modules/products/hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  changeUnrelatedProductsPage,
  changeUnrelatedProductsLimit,
  changeSearchFilter,
} from 'modules/products/slices/unrelatedProducts'

import type { FC } from 'react'
import type { Product } from 'modules/products/types'
import type { ColumnsType } from 'ui/table/types'

const OnCheckTable: FC = () => {
  const dispatch = useAppDispatch()
  const { search } = useAppSelector(
    state => state.products.unrelatedProducts.filters,
  )
  const pagination = useAppSelector(
    state => state.products.unrelatedProducts.pagination,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const { unrelatedProducts } = useUnrelatedProducts(search)

  const handleSearch = (text: string) => {
    dispatch(changeSearchFilter(text))
  }

  const handleChangePage = (page: number) => {
    dispatch(changeUnrelatedProductsPage(page))
  }

  const handleChangeLimit = (limit: number) => {
    dispatch(changeUnrelatedProductsLimit(limit))
  }

  const columns: ColumnsType<Product> = [
    {
      title: 'Артикул',
      dataIndex: ['products', 'sku'],
      key: 'vendor_code',
      width: 140,
    },
    {
      title: 'Название',
      dataIndex: ['products', 'model'],
      key: 'name',
      width: 300,
      render: name => <Highlighter search={search}>{name}</Highlighter>,
    },
    {
      title: 'Цена',
      dataIndex: ['products', 'price'],
      key: 'products.price',
      width: 150,
      align: 'right',
      render: (price: number) => <>{formatCurrency(price)}</>,
    },
    {
      title: 'Обновление прайс–листа',
      dataIndex: 'updated_at',
      key: 'price',
      width: 232,
      render: (date: string) => (
        <>{format(new Date(date), 'В HH:mm, d MMM yyyy г.', { locale: ru })}</>
      ),
    },
    {
      title: 'Бренд',
      dataIndex: ['products', 'brand'],
      key: 'price',
      width: 200,
    },
  ]

  const isSearch = search !== ''

  return (
    <Box>
      <Box width='400px' mr='8px' mb='24px' px='24px'>
        <Search
          placeholder='Название товара'
          value={search}
          onSearch={handleSearch}
        />
      </Box>

      <Table
        rowKey='id'
        data={unrelatedProducts}
        columns={columns}
        temporarilyEmpty={!isSearch}
        emptyText={!isSearch && 'Пока здесь ничего нет'}
      />

      <Pagination
        {...pagination}
        onPageChange={handleChangePage}
        onLimitChange={handleChangeLimit}
      />
    </Box>
  )
}

export default OnCheckTable
