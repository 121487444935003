import { FC } from 'react'

import { Typography, Box, Button } from 'ui'
import {
  TemporarilyEmptyTable,
  DefaultEmptyTable,
} from 'ui/icons/Illustrations'

const { Subtitle } = Typography

interface DefaultEmptyProps {
  emptyText?: string
  temporarilyEmpty?: boolean
  emptyButton?: {
    onClick: VoidFunction
    text: string
  }
}

const DefaultEmpty: FC<DefaultEmptyProps> = ({
  emptyText,
  emptyButton,
  temporarilyEmpty,
}) => {
  return (
    <Box pb='24px' margin='0 auto' width='421px'>
      {temporarilyEmpty ? <TemporarilyEmptyTable /> : <DefaultEmptyTable />}

      <Subtitle as='p' mt='16px' color='#8F929D' textAlign='center'>
        {emptyText || (
          <>
            Мы искали вдоль и поперёк. <br />К сожалению, ничего не нашлось
          </>
        )}
      </Subtitle>

      {emptyButton && (
        <Button fullWidth mt='16px' size='large' onClick={emptyButton.onClick}>
          {emptyButton.text}
        </Button>
      )}
    </Box>
  )
}

export default DefaultEmpty
