import { useEffect, useState } from 'react'

import { Node } from './components'

import { useControlled } from './useControlled'

import type { FC } from 'react'
import type { TreeNode, NodeProps } from './types'

const getOpenedNodes = (
  tree: TreeNode[],
  nodeId: TreeNode['id'],
): Array<TreeNode['id']> | null => {
  const parents: Array<TreeNode['id']> = []

  for (const node of tree) {
    if (node.id === nodeId) {
      return parents
    }

    if (node.children?.length) {
      parents.push(node.id)

      const nestedParents = getOpenedNodes(node.children, nodeId)

      if (nestedParents) {
        return [...parents, ...nestedParents]
      } else {
        parents.pop()
      }
    }
  }

  return null
}

interface Props {
  data: TreeNode[]
  defaultSelectedNodeIds?: NodeProps['selectedIds']
  selectedNodeIds?: NodeProps['selectedIds']
  onSelect?: (id: NodeProps['selectedIds']) => void
}

const Tree: FC<Props> = ({
  data = [],
  selectedNodeIds: controlledValue,
  defaultSelectedNodeIds: initialValue,
  onSelect,
}) => {
  const [selectedNodeIds, setSelectedNodeIds] = useControlled<
    Array<TreeNode['id']>
  >({
    controlledValue,
    initialValue,
    name: 'node id',
  })
  const [openedNodeIds, setOpenedNodeIds] = useState<Array<TreeNode['id']>>([])

  useEffect(() => {
    if (data?.length && (controlledValue?.length || initialValue?.length)) {
      const nodes = controlledValue?.length ? controlledValue : initialValue

      nodes.forEach(nodeId => {
        const parents = getOpenedNodes(data, nodeId)

        parents &&
          setOpenedNodeIds(oldIds => {
            return Array.from(new Set([...oldIds, ...parents]))
          })
      })
    }
  }, [data, controlledValue, initialValue])

  const handleOpen = (id: TreeNode['id']) => {
    setOpenedNodeIds(oldIds => {
      const index = oldIds.indexOf(id)

      let newIds = [...oldIds]
      if (index > -1) {
        newIds.splice(index, 1)
      } else {
        newIds.push(id)
      }

      return newIds
    })
  }

  const handleSelect = (id: TreeNode['id']) => {
    setSelectedNodeIds([id])

    if (typeof onSelect === 'function') {
      onSelect([id])
    }
  }

  return (
    <ul role='tree'>
      {data.map(node => (
        <Node
          id={node.id}
          key={node.id}
          title={node.title}
          children={node.children}
          openedIds={openedNodeIds}
          selectedIds={selectedNodeIds}
          onSelect={handleSelect}
          onOpen={handleOpen}
        />
      ))}
    </ul>
  )
}

export { Tree }
