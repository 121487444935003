import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { instance } from 'store/configureStore'

import history from 'settings/history'

import { mapOrderProducts } from 'modules/orders/utils/mapOrderProducts'
import { thunkErrorHandler } from 'utils/errorHandler'

import { toast } from 'ui'

import { env } from 'settings/env'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

import type { AxiosResponse } from 'axios'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'modules/common/types'
import type { AppThunk } from 'store/configureStore'
import type {
  OrderProductsType,
  OrdersProfitRes,
  OrderStatusType,
  FetchOrdersRes,
  SortingType,
  OrderType,
  OrderDeliveryPriceType,
} from 'modules/orders/types'
import type {
  FetchOrderDeliveryPriceParams,
  FetchOrderDetailsParams,
  SetPackagesCountParams,
  OrderDecisionParams,
  GeneratePdfParams,
  OrdersState,
} from 'modules/orders/types/interfaces'

/**
 * Async Actions
 */

export const fetchOrders: AppThunk<OrderType[]> = createAsyncThunk(
  'orders/fetch',
  (_, thunkApi) => {
    const { pagination, filter, sorting } = thunkApi.getState().orders.orders

    const config = {
      params: {
        limit: pagination.limit,
        page: pagination.page,
        status: filter.status,
        dateFrom: filter.dateRange.from,
        dateTo: filter.dateRange.to,
        id: filter.search,
        sorting,
      },
    }

    return instance(thunkApi)
      .get('/oms/api/v1/merchant/orders', config)
      .then((res: AxiosResponse<FetchOrdersRes>) => {
        thunkApi.dispatch(changeTotal(res.data.total_count))
        return res.data.orders
      })
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const fetchOrderDetails: AppThunk<OrderType, FetchOrderDetailsParams> =
  createAsyncThunk('orders/detail/fetch', (params, thunkApi) => {
    let timer

    const reFetch = () => {
      if (window.location.pathname === `/orders/${params.orderId}`) {
        timer = setTimeout(() => {
          thunkApi.dispatch(
            fetchOrderDeliveryPrice({
              orderId: params.orderId,
              reFetch,
              clear,
            }),
          )
        }, 1000)
      }
    }

    const clear = () => {
      clearTimeout(timer)
    }

    return instance(thunkApi)
      .get(`/oms/api/v1/merchant/orders/${params.orderId}`)
      .then((res: AxiosResponse<OrderType>) => {
        if (
          res.data.status !== ORDERS_FILTER_STATUS.WAITING_CONFIRMATION &&
          res.data.status !== ORDERS_FILTER_STATUS.ON_ASSEMBLY &&
          res.data.status !== ORDERS_FILTER_STATUS.COMPLETED &&
          res.data.status !== ORDERS_FILTER_STATUS.CANCELED
        ) {
          thunkApi.dispatch(
            fetchOrderDeliveryPrice({
              orderId: params.orderId,
              reFetch,
              clear,
            }),
          )
        }

        return {
          ...res.data,
          products: res.data.products.map(mapOrderProducts),
        }
      })
      .catch(thunkErrorHandler(thunkApi))
  })

export const fetchOrderDeliveryPrice: AppThunk<
  OrderDeliveryPriceType,
  FetchOrderDeliveryPriceParams
> = createAsyncThunk('orders/details/delivery/fetch', (params, thunkApi) => {
  const mid = thunkApi.getState().auth.credentials.mid

  return instance(thunkApi)
    .get(
      `${env.CLS_API_BASE_URL}/mp/order/${
        params.orderId.split('-')[0]
      }/merchant/${mid}`,
    )
    .then((res: AxiosResponse<OrderDeliveryPriceType>) => {
      if (res.data.products.length === 0) {
        params.reFetch()
      } else {
        params.clear()
      }

      return res.data
    })
    .catch(thunkErrorHandler(thunkApi))
})

export const fetchOrdersProfit: AppThunk<number> = createAsyncThunk(
  'orders/profit/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get('/oms/api/v1/merchant/orders/profit')
      .then((res: AxiosResponse<OrdersProfitRes>) => res.data.sum)
      .catch(thunkErrorHandler(thunkApi)),
)

export const orderDecision: AppThunk<void, OrderDecisionParams> =
  createAsyncThunk('orders/details/decision', (body, thunkApi) => {
    const orderDetails = thunkApi.getState().orders.orders.details
    const details = body.details || orderDetails

    const data = {
      reason: body.reason,
      solution: body.solution,
      products: details.products
        .filter(product => !product.removed)
        .map(product => ({
          quantity: Number(product.quantity),
          sku: product.sku,
        })),
    }

    return instance(thunkApi)
      .post(`/oms/api/v1/merchant/orders/${details.id}`, data)
      .then(() => {
        if (body.solution === 'reject') {
          toast(`Заказ №${details.id} отменён`)
          history.push('/orders')
        }

        if (Boolean(body.onShowTip)) {
          body.onShowTip()
        }

        if (Boolean(body.closeCb)) {
          body.closeCb()
        }

        if (body.refresh === 'details') {
          thunkApi.dispatch(
            fetchOrderDetails({
              orderId: details.id,
              refresh: false,
            }),
          )
        } else if (body.refresh === 'list') {
          thunkApi.dispatch(fetchOrders())
        } else if (body.refresh === 'both') {
          thunkApi.dispatch(fetchOrders())
          thunkApi.dispatch(
            fetchOrderDetails({
              orderId: details.id,
              refresh: false,
            }),
          )
        }
      })
      .catch((err: AxiosError) => {
        if (err.response.data.error.message === 'undefined solution command') {
          toast(err.response.data.error.message)
        }

        return thunkErrorHandler(thunkApi)(err)
      })
  })

export const setPackagesCount: AppThunk<void, SetPackagesCountParams> =
  createAsyncThunk('orders/details/setPackagesCount', (params, thunkApi) => {
    const details = thunkApi.getState().orders.orders.details
    const mid = thunkApi.getState().auth.credentials.mid

    const orderId = params.orderId
      ? params.orderId.split('-')[0]
      : details.id.split('-')[0]

    return instance(thunkApi)
      .post(`${env.CLS_API_BASE_URL}/mp/delivery`, [
        {
          orderId,
          packagesAmountByMerchantId: {
            [mid]: Number(params.count),
          },
        },
      ])
      .then(() => {
        if (params.decision) {
          thunkApi.dispatch(
            orderDecision({
              refresh: 'details',
              solution: 'assembled',
              closeCb: params.closeCb,
              onShowTip: params.onShowTip,
            }),
          )
        } else {
          params.closeCb()
        }
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.data) {
          toast(err.response.data.error.message)
        }

        thunkErrorHandler(thunkApi)(err)
      })
  })

export const generatePdf: AppThunk<void, GeneratePdfParams> = createAsyncThunk(
  'orders/details/generatePdfStickers',
  (params, thunkApi) => {
    const details = thunkApi.getState().orders.orders.details
    const merchant_id = thunkApi.getState().auth.credentials.mid

    const orderId = params.orderId
      ? params.orderId.split('-')[0]
      : details.id.split('-')[0]

    const baseFilename = `order-${orderId}`
    const filenameType = params.docType === 'STICKER' ? 'sticker' : 'act'

    const data = {
      merchant_id,
      doc_type: params.docType,
      orderId,
    }

    return instance(thunkApi)
      .post(`${env.CLS_API_BASE_URL}/mp/document`, data, {
        responseType: 'blob',
      })
      .then((res: AxiosResponse<Blob>) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')

        link.href = url
        link.download = baseFilename + '-' + filenameType + '.pdf'

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        )

        setTimeout(() => {
          window.URL.revokeObjectURL(url)
          link.remove()
        }, 100)
      })
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const changeOrderPickupPoint: AppThunk<void, string> = createAsyncThunk(
  'orders/details/changePickupPoint',
  (pickup_point_id, thunkApi) => {
    const details = thunkApi.getState().orders.orders.details

    return instance(thunkApi)
      .put(`/oms/api/v1/merchant/orders/${details.id}/pickup-point`, {
        pickup_point_id,
      })
      .then(() => toast('Точка выдачи изменена'))
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const changeOrdersPage: AppThunk<void, number> = createAsyncThunk(
  'orders/changePage',
  (page, thunkApi) => {
    thunkApi.dispatch(changePage(page))
    thunkApi.dispatch(fetchOrders())
  },
)

export const changeOrdersLimit: AppThunk<void, number> = createAsyncThunk(
  'orders/changeLimit',
  (limit, thunkApi) => {
    thunkApi.dispatch(changeLimit(limit))
    thunkApi.dispatch(fetchOrders())
  },
)

export const changeOrdersSorting: AppThunk<void, SortingType> =
  createAsyncThunk('orders/changeSorting', (sorting, thunkApi) => {
    thunkApi.dispatch(changeSorting(sorting))
    thunkApi.dispatch(fetchOrders())
  })

/**
 * Reducer
 */

export const initialDetails: OrderType = {
  id: '',
  end_at: null,
  created_at: null,
  max_confirm_time: null,
  max_assembly_time: null,
  pickup_point_id: '',
  status: null,
  payment: {
    type: '',
    dividable: false,
  },
  products: [],
  shipping: {
    type: '',
  },
  storage_type: '',
  total_price: '',
  key: '',
}

const initialState: OrdersState = {
  list: [],
  details: initialDetails,
  delivery: {
    products: [],
    totalDeliveryCost: 0,
  },
  profit: 0,

  loadingOfList: false,
  loadingOfProfit: false,
  loadingOfDetails: false,
  loadingOfDelivery: false,
  loadingOfDecision: false,
  loadingOfChanging: false,
  loadingOfProducts: false,
  loadingOfDownloading: false,

  errorOfList: null,
  errorOfProfit: null,
  errorOfDetails: null,
  errorOfDelivery: null,
  errorOfDecision: null,
  errorOfChanging: null,
  errorOfProducts: null,
  errorOfDownloading: null,

  filter: {
    status: '',
    search: '',
    dateRange: {
      from: null,
      to: null,
    },
  },
  pagination: {
    page: 1,
    limit: 20,
    total: null,
  },
  sorting: '',
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    changeStatus: (state, action: PayloadAction<OrderStatusType>) => {
      state.list = []
      state.filter.status = action.payload
    },
    changeSearch: (state, action: PayloadAction<string>) => {
      state.filter.search = action.payload
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.pagination.total = action.payload
    },
    changeDate: (
      state,
      action: PayloadAction<OrdersState['filter']['dateRange']>,
    ) => {
      state.filter.dateRange = action.payload
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    changeLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload
    },
    changeSorting: (state, action: PayloadAction<SortingType>) => {
      state.sorting = action.payload
    },
    changeOrderProducts: (
      state,
      action: PayloadAction<OrderProductsType[]>,
    ) => {
      state.details.products = action.payload
    },
    setOrderDetails: (state, action: PayloadAction<OrderType>) => {
      state.details = action.payload
    },
    clearDetails: state => {
      state.details = initialDetails
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrders.pending, state => {
        state.errorOfList = null
        state.loadingOfList = true
      })
      .addCase(fetchOrders.fulfilled, (state, { payload }) => {
        state.errorOfList = null
        state.loadingOfList = false
        state.list = payload
      })
      .addCase(fetchOrders.rejected, (state, { payload }) => {
        state.errorOfList = payload.error
        state.loadingOfList = false
      })

      .addCase(fetchOrderDetails.pending, (state, { meta }) => {
        state.errorOfDetails = null
        state.loadingOfDetails = true

        if (meta.arg.refresh) {
          state.details = initialDetails
        }
      })
      .addCase(fetchOrderDetails.fulfilled, (state, { payload }) => {
        state.errorOfDetails = null
        state.loadingOfDetails = false
        state.details = payload
      })
      .addCase(fetchOrderDetails.rejected, (state, { payload }) => {
        state.errorOfDetails = payload.error
        state.loadingOfDetails = false
      })

      .addCase(fetchOrderDeliveryPrice.pending, state => {
        state.errorOfDelivery = null
        state.loadingOfDelivery = true
      })
      .addCase(fetchOrderDeliveryPrice.fulfilled, (state, { payload }) => {
        state.errorOfDelivery = null
        state.loadingOfDelivery = false
        state.delivery = payload

        if (payload.products.length !== 0) {
          state.details = {
            ...state.details,
            products: state.details.products.map(product => {
              const filteredProducts = payload.products.filter(
                deliveryProduct => deliveryProduct.sku === product.sku,
              )

              return {
                ...product,
                delivery_cost:
                  filteredProducts.length !== 0
                    ? filteredProducts.reduce(
                        (a, b) => a + b.productDeliveryCost,
                        0,
                      )
                    : 0,
              }
            }),
          }
        }
      })
      .addCase(fetchOrderDeliveryPrice.rejected, (state, { payload }) => {
        state.errorOfDelivery = payload.error
        state.loadingOfDelivery = false
      })

      .addCase(fetchOrdersProfit.pending, state => {
        state.errorOfProfit = null
        state.loadingOfProfit = true
      })
      .addCase(fetchOrdersProfit.fulfilled, (state, { payload }) => {
        state.errorOfProfit = null
        state.loadingOfProfit = false
        state.profit = payload
      })
      .addCase(fetchOrdersProfit.rejected, (state, { payload }) => {
        state.errorOfProfit = payload.error
        state.loadingOfProfit = false
      })

      .addCase(orderDecision.pending, state => {
        state.errorOfDecision = null
        state.loadingOfDecision = true
      })
      .addCase(orderDecision.fulfilled, state => {
        state.errorOfDecision = null
        state.loadingOfDecision = false
      })
      .addCase(orderDecision.rejected, (state, { payload }) => {
        state.errorOfDecision = payload.error
        state.loadingOfDecision = false
      })

      .addCase(setPackagesCount.pending, state => {
        state.errorOfDecision = null
        state.loadingOfDecision = true
      })
      .addCase(setPackagesCount.fulfilled, state => {
        state.errorOfDecision = null
        state.loadingOfDecision = false
      })
      .addCase(setPackagesCount.rejected, (state, { payload }) => {
        state.errorOfDecision = payload.error
        state.loadingOfDecision = false
      })

      .addCase(generatePdf.pending, state => {
        state.errorOfDownloading = null
        state.loadingOfDownloading = true
      })
      .addCase(generatePdf.fulfilled, state => {
        state.errorOfDownloading = null
        state.loadingOfDownloading = false
      })
      .addCase(generatePdf.rejected, (state, { payload }) => {
        state.errorOfDownloading = payload.error
        state.loadingOfDownloading = false
      })

      .addCase(changeOrderPickupPoint.pending, state => {
        state.errorOfChanging = null
        state.loadingOfChanging = true
      })
      .addCase(changeOrderPickupPoint.fulfilled, state => {
        state.errorOfChanging = null
        state.loadingOfChanging = false
      })
      .addCase(changeOrderPickupPoint.rejected, (state, { payload }) => {
        state.errorOfChanging = payload.error
        state.loadingOfChanging = false
      })
  },
})

export const {
  changeOrderProducts,
  setOrderDetails,
  changeSorting,
  changeStatus,
  clearDetails,
  changeSearch,
  changeTotal,
  changeLimit,
  changePage,
  changeDate,
} = ordersSlice.actions

export default ordersSlice.reducer
