const WEEKDAYS_SHORT = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
}

const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
}

const WEEKDAYS_LONG = {
  ru: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
}

const FIRST_DAY_OF_WEEK = {
  ru: 1,
}

const LABELS = {
  ru: {
    nextMonth: 'следующий месяц',
    previousMonth: 'предыдущий месяц',
  },
}

export { WEEKDAYS_SHORT, MONTHS, WEEKDAYS_LONG, FIRST_DAY_OF_WEEK, LABELS }
