import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchWithInstallmentCategories } from '../slices/products'

const useWithInstallmentCategories = () => {
  const dispatch = useAppDispatch()
  const { withInstallmentCategories } = useAppSelector(
    state => state.installments.products,
  )

  useEffect(() => {
    dispatch(fetchWithInstallmentCategories())
  }, [dispatch])

  return { withInstallmentCategories }
}

export { useWithInstallmentCategories }
