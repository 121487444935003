import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Question: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0001 7.5C9.39215 7.5 8.88166 7.73535 8.54203 7.95434C8.07787 8.25363 7.45897 8.11998 7.15968 7.65582C6.8604 7.19166 6.99405 6.57276 7.45821 6.27347C7.99176 5.92944 8.87849 5.5 10.0001 5.5C10.8753 5.5 11.8266 5.63195 12.5941 6.06804C13.4326 6.54447 14.0001 7.36321 14.0001 8.5C14.0001 9.34788 13.7363 10.0236 13.2662 10.533C12.8258 11.01 12.275 11.2614 11.8581 11.4212C11.7042 11.4802 11.5422 11.536 11.4065 11.5826C11.3608 11.5983 11.3181 11.613 11.2797 11.6265C11.0817 11.6958 10.9519 11.8725 10.8368 12.0478C10.6582 12.3202 10.3501 12.5 10.0001 12.5C9.44784 12.5 9.00012 12.0523 9.00012 11.5C9.00012 11.1175 9.1279 10.7744 9.35406 10.4971C9.56068 10.2437 9.81576 10.0862 10.0177 9.98413C10.2218 9.88095 10.4398 9.80146 10.619 9.73876C10.6783 9.718 10.7337 9.69895 10.7867 9.68072C10.9116 9.63775 11.0233 9.59934 11.1422 9.55375C11.4753 9.42608 11.6744 9.30875 11.7966 9.17641C11.8889 9.07638 12.0001 8.90212 12.0001 8.5C12.0001 8.13679 11.8676 7.95553 11.6061 7.80696C11.2736 7.61805 10.7249 7.5 10.0001 7.5Z'
      fill={color}
    />
    <path
      d='M11.0001 14C11.0001 14.5523 10.5524 15 10.0001 15C9.44784 15 9.00012 14.5523 9.00012 14C9.00012 13.4477 9.44784 13 10.0001 13C10.5524 13 11.0001 13.4477 11.0001 14Z'
      fill={color}
    />
  </Icon>
)

Question.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Question }
