import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { instance, AppThunk } from 'store/configureStore'
import { thunkErrorHandler } from 'utils/errorHandler'

import { ALL_OPTIONS } from 'modules/common/constants/dictionaries'
import { endOfMonth, formatISO, startOfMonth } from 'date-fns'

import {
  FetchInstallmentDetailsOptions,
  InstallmentsState,
} from '../types/interfaces'
import { Installment, InstallmentCategory } from '../types/types'
import { AxiosResponse } from 'axios'

/**
 * Initial State
 */

const initialState: InstallmentsState = {
  installments: [],
  installmentsLoading: false,
  installmentsError: null,

  categories: [],
  categoriesLoading: false,
  categoriesError: null,

  installmentDetails: null,
  installmentDetailsLoading: false,
  installmentDetailsError: null,

  filters: {
    category: ALL_OPTIONS,
    month: null,
  },
}

/**
 * Async Actions
 */

export const fetchInstallments: AppThunk<Installment[]> = createAsyncThunk(
  'installmens/fetch',
  (_, thunkApi) => {
    const { category, month } =
      thunkApi.getState().installments.installments.filters

    const params = {
      category: category !== ALL_OPTIONS ? category : undefined,
      start_at:
        month !== null
          ? formatISO(startOfMonth(new Date().setMonth(month)))
          : undefined,
      end_at:
        month !== null
          ? formatISO(endOfMonth(new Date().setMonth(month)))
          : undefined,
    }

    return instance(thunkApi)
      .get('/payplan/api/v1/installments/available', { params })
      .then(
        (res: AxiosResponse<{ installments: Installment[] }>) =>
          res.data.installments,
      )
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const fetchCategories: AppThunk<InstallmentCategory[]> =
  createAsyncThunk('installmens/categories/fetch', (_, thunkApi) =>
    instance(thunkApi)
      .get('/payplan/api/v1/installments/available')
      .then(
        (
          res: AxiosResponse<{
            filters: { categories: InstallmentCategory[] }
          }>,
        ) => res.data.filters?.categories || [],
      )
      .catch(thunkErrorHandler(thunkApi)),
  )

export const fetchInstallmentDetails: AppThunk<
  Installment,
  FetchInstallmentDetailsOptions
> = createAsyncThunk('installmens/details/fetch', (options, thunkApi) => {
  const { id } = options

  return instance(thunkApi)
    .get(`/payplan/api/v1/installments/${id}`)
    .then(res => res.data)
    .catch(thunkErrorHandler(thunkApi))
})

/**
 * Slice
 */

const installmentsSlice = createSlice({
  name: 'installments',
  initialState,
  reducers: {
    changeCategoryFilter: (state, action: PayloadAction<string>) => {
      state.filters.category = action.payload
    },
    changeMonthFilter: (state, action: PayloadAction<number>) => {
      state.filters.month = action.payload
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchInstallments.pending, state => {
        state.installmentsLoading = true
        state.installmentsError = null
      })
      .addCase(fetchInstallments.fulfilled, (state, { payload }) => {
        state.installments = payload
        state.installmentsLoading = false
        state.installmentsError = null
      })
      .addCase(fetchInstallments.rejected, (state, { payload }) => {
        state.installmentsLoading = false
        state.installmentsError = payload.error
      })

      .addCase(fetchCategories.pending, state => {
        state.categoriesLoading = true
        state.categoriesError = null
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.categories = payload
        state.categoriesLoading = false
        state.categoriesError = null
      })
      .addCase(fetchCategories.rejected, (state, { payload }) => {
        state.categoriesLoading = false
        state.categoriesError = payload.error
      })

      .addCase(fetchInstallmentDetails.pending, state => {
        state.installmentDetailsLoading = true
        state.installmentDetailsError = null
      })
      .addCase(fetchInstallmentDetails.fulfilled, (state, { payload }) => {
        state.installmentDetails = payload
        state.installmentDetailsLoading = false
        state.installmentDetailsError = null
      })
      .addCase(fetchInstallmentDetails.rejected, (state, { payload }) => {
        state.installmentDetailsLoading = false
        state.installmentDetailsError = payload.error
      }),
})

export const { changeCategoryFilter, changeMonthFilter } =
  installmentsSlice.actions

export default installmentsSlice.reducer
