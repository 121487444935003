import { useHistory } from 'react-router-dom'

import { isNav } from 'utils/isNav'
import { Dropdown } from 'ui'

import { IRoute } from 'modules/common/types/interfaces'

const NavTabsMenu = (routes: IRoute[]) => {
  const history = useHistory()

  return (
    <Dropdown.Menu>
      {routes.filter(isNav).map(route => (
        <Dropdown.MenuItem
          key={route.path}
          onClick={() => history.push(route.path)}
        >
          {route.nav.name}
        </Dropdown.MenuItem>
      ))}
    </Dropdown.Menu>
  )
}

export { NavTabsMenu }
