import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Star: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M44 0L49.0655 5.52401L55.388 1.49926L58.8512 8.14608L66 5.89488L67.6248 13.2115L75.1127 12.8873L74.7885 20.3752L82.1051 22L79.8539 29.1488L86.5007 32.612L82.476 38.9345L88 44L82.476 49.0655L86.5007 55.388L79.8539 58.8512L82.1051 66L74.7885 67.6248L75.1127 75.1127L67.6248 74.7885L66 82.1051L58.8512 79.8539L55.388 86.5007L49.0655 82.476L44 88L38.9345 82.476L32.612 86.5007L29.1488 79.8539L22 82.1051L20.3752 74.7885L12.8873 75.1127L13.2115 67.6248L5.89488 66L8.14608 58.8512L1.49926 55.388L5.52401 49.0655L0 44L5.52401 38.9345L1.49926 32.612L8.14608 29.1488L5.89488 22L13.2115 20.3752L12.8873 12.8873L20.3752 13.2115L22 5.89488L29.1488 8.14608L32.612 1.49926L38.9345 5.52401L44 0Z'
      fill={color}
    />
  </Icon>
)

Star.defaultProps = {
  originalWidth: 88,
  originalHeight: 88,
  width: 88,
  height: 88,
  color: '#D70C17',
}

export { Star }
