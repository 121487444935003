import { Icon } from 'ui/icons'

import type { FC } from 'react'
import type { IconProps } from 'ui/icons'

const Mailru: FC<IconProps> = ({ originalWidth, originalHeight, ...props }) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path d='M12.8783 10.3411C12.7837 8.51683 11.4157 7.4196 9.76379 7.4196H9.70159C7.79529 7.4196 6.73824 8.90393 6.73824 10.5902C6.73824 12.4778 8.01704 13.6704 9.69439 13.6704C11.5639 13.6704 12.7935 12.314 12.8825 10.71L12.8783 10.3411ZM9.71085 5.78669C10.9846 5.78669 12.1823 6.34424 13.0615 7.21725V7.22051C13.0615 6.80156 13.3464 6.48559 13.7417 6.48559L13.8418 6.48492C14.4601 6.48492 14.5874 7.06446 14.5874 7.24779L14.5902 13.7632C14.5465 14.1899 15.0349 14.4102 15.3055 14.1363C16.3622 13.0611 17.6261 8.60889 14.6486 6.02986C11.8738 3.62486 8.15047 4.02101 6.17034 5.37257C4.06512 6.81013 2.71838 9.99224 4.02671 12.9807C5.45311 16.2402 9.53439 17.2124 11.9602 16.2425C13.1885 15.7516 13.7556 17.3956 12.48 17.9329C10.5526 18.7457 5.18744 18.6637 2.68134 14.3648C0.988022 11.4623 1.07775 6.35519 5.56905 3.70937C9.00448 1.68466 13.5342 2.24505 16.2655 5.06949C19.1205 8.02217 18.9537 13.5516 16.1693 15.701C14.9075 16.6777 13.0334 15.7275 13.0452 14.3043L13.0324 13.8391C12.1539 14.7023 10.9846 15.2059 9.71083 15.2059C7.19423 15.2059 4.97977 13.0139 4.97977 10.5215C4.97977 8.00519 7.19424 5.78669 9.71085 5.78669Z' />
  </Icon>
)

Mailru.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { Mailru }
