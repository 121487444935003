import {css} from '@emotion/react'
import {Theme} from 'ui/theme/src/types'

export const styles = (theme: Theme) => css`
  .rc-dropdown {
    position: absolute;
    left: -9999px;
    top: -9999px;
    z-index: 1070;
    display: block;

    &-hidden {
      display: none;
    }

    &-menu {
      border: none;
      margin: 8px 0 0 0;
      padding: 8px 0;
      border-radius: ${theme.radii.xs};
      background-color: ${theme.colors.main.background.white};
      transform: translate3d(0, 0, 0);
      box-shadow: 0 8px 14px rgba(16, 20, 45, 0.05), 0 3.87387px 9.86002px rgba(16, 20, 45, 0.08);

      &-item {
        font-family: ${theme.fontFamily.ms};
        padding: 16px;
        line-height: 24px;
        font-size: 15px;
        color: #000000;
        border-radius: 0;

        &:hover {
          cursor: pointer;
          background-color: ${theme.greyColorPalette[5]};
        }

        &-disabled {
          cursor: not-allowed;
          pointer-events: none;
        }

        &-divider {
          height: 1px;
          margin: 1px 0;
          line-height: 0;
          overflow: hidden;
          background-color: #e5e5e5;
        }
      }
    }
  }

  .rc-dropdown-slide-up-enter,
  .rc-dropdown-slide-up-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    display: block !important;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  .rc-dropdown-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    display: block !important;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }

  .rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
  .rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
  .rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
  .rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
  .rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
  .rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
    animation-name: rcDropdownSlideUpIn;
    animation-play-state: running;
  }

  .rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
  .rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
  .rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
  .rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
  .rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
  .rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
    animation-name: rcDropdownSlideDownIn;
    animation-play-state: running;
  }

  .rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
  .rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
  .rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
    animation-name: rcDropdownSlideUpOut;
    animation-play-state: running;
  }

  .rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
  .rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
  .rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
    animation-name: rcDropdownSlideDownOut;
    animation-play-state: running;
  }

  @keyframes rcDropdownSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform-origin: 0 0;
      transform: scaleY(1);
    }
  }
  @keyframes rcDropdownSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0 0;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleY(0);
    }
  }
  @keyframes rcDropdownSlideDownIn {
    0% {
      opacity: 0;
      transform-origin: 0 100%;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform-origin: 0 100%;
      transform: scaleY(1);
    }
  }
  @keyframes rcDropdownSlideDownOut {
    0% {
      opacity: 1;
      transform-origin: 0 100%;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0 100%;
      transform: scaleY(0);
    }
  }
`
