import { useMemo } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { useInstructions } from 'modules/faq/hooks'

import { Tree } from 'ui'
import { RenderRoutes } from 'modules/core/components'
import { Footer, Header } from 'modules/common/components'
import { Wrapper, Aside, Nav, Main } from './styles'

import type { FC } from 'react'
import type { RenderRoutesProps } from 'modules/common/types/interfaces'

const generateData = data =>
  data.map(node => ({
    id: node.slug,
    title: node.title.ru,
    children: node.page?.length ? generateData(node.page) : undefined,
  }))

const RenderFaqRoutes: FC<RenderRoutesProps> = ({ routes }) => {
  const { pathname } = useLocation()

  const { instructions } = useInstructions()

  const data = useMemo(
    () => generateData(instructions?.page || []),
    [instructions],
  )

  const ids = pathname.replace('/faq', '').split('/').filter(Boolean)

  if (!ids.length && data?.length) {
    return <Redirect to={`/faq/${data[0].id}`} />
  }

  return (
    <Wrapper>
      <Header isEmpty />
      <Aside>
        <Nav>
          <Tree data={data} selectedNodeIds={ids} />
        </Nav>
      </Aside>
      <Main>
        <RenderRoutes routes={routes} />
      </Main>
      <Footer />
    </Wrapper>
  )
}

export { RenderFaqRoutes }
