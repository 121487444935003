import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const AscSort: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.16667 3C2.52233 3 2 3.52233 2 4.16667C2 4.811 2.52233 5.33333 3.16667 5.33333H5.5C6.14433 5.33333 6.66667 4.811 6.66667 4.16667C6.66667 3.52233 6.14433 3 5.5 3H3.16667ZM3.16667 8.83333C2.52233 8.83333 2 9.35567 2 10C2 10.6443 2.52233 11.1667 3.16667 11.1667H7.83333C8.47766 11.1667 9 10.6443 9 10C9 9.35567 8.47766 8.83333 7.83333 8.83333H3.16667ZM2 15.8333C2 15.189 2.52233 14.6667 3.16667 14.6667H14.8333C15.4777 14.6667 16 15.189 16 15.8333C16 16.4777 15.4777 17 14.8333 17H3.16667C2.52233 17 2 16.4777 2 15.8333ZM14.25 4.16667C14.25 3.52233 13.7277 3 13.0833 3C12.439 3 11.9167 3.52233 11.9167 4.16667V7.18342L11.575 6.84171C11.1193 6.3861 10.3807 6.3861 9.92504 6.84171C9.46943 7.29732 9.46943 8.03601 9.92504 8.49162L12.2584 10.825C12.714 11.2806 13.4527 11.2806 13.9083 10.825L16.2416 8.49162C16.6972 8.03601 16.6972 7.29732 16.2416 6.84171C15.786 6.3861 15.0473 6.3861 14.5917 6.84171L14.25 7.18342V4.16667Z'
      fill='#10142D'
    />
  </Icon>
)

AscSort.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#10142D',
}

export { AscSort }
