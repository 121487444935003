import { styled } from 'ui/theme'
import { Link as RRDLink } from 'react-router-dom'
import { color } from 'styled-system'

const Link = styled(RRDLink)`
  text-decoration: none;
  display: inline-block;
  color: ${props => props.theme.colors.text.link};
  font-size: ${props => props.theme.fontSizes.body.m};

  &:hover {
    text-decoration: underline;
  }

  ${color}
`

export { Link }
