import { TagsWrap } from 'ui/tags/styles'

import type { FC } from 'react'
import type { TagsProps } from 'ui/tags/types'

const Tags: FC<TagsProps> = ({
  list,
  value,
  disabled,
  onChange,
  isWeekdays,
}) => {
  const isMultiple = Array.isArray(value)

  return (
    <TagsWrap isWeekdays={isWeekdays}>
      {list.map(item => {
        const active = isMultiple
          ? value.findIndex(valueItem => valueItem === item.value) !== -1
          : value === item.value

        return (
          <button
            className={`__tags-item ${active ? '__tags-item-active' : ''}`}
            key={item.value}
            type='button'
            disabled={disabled || item.disabled}
            onClick={() => {
              if (active) {
                if (isMultiple) {
                  onChange(
                    value.filter(filterItem => filterItem !== item.value),
                  )
                }
              } else {
                onChange(isMultiple ? [...value, item.value] : item.value)
              }
            }}
          >
            {item.name}
          </button>
        )
      })}
    </TagsWrap>
  )
}

Tags.defaultProps = {
  list: [],
  value: [],
}

export { Tags }
