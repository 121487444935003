import { FC } from 'react'

import { useAppTheme } from 'ui/theme'
import { CloseIcon } from 'ui/icons'
import { Box } from 'ui'

interface ClearValueIconProps {
  onClear: VoidFunction
}

const ClearValueIcon: FC<ClearValueIconProps> = ({ onClear }) => {
  const theme = useAppTheme()

  return (
    <Box className='__form-field-icon' onClick={onClear}>
      <CloseIcon color={theme.colors.icon.secondaryBlack} />
    </Box>
  )
}

export default ClearValueIcon
