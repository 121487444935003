import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useTableCbx } from 'ui/table/useTableCbx'

import { Table as OrderDetailsTable } from 'modules/orders/pages/OrderDetails/components'
import {
  OrdersTableAssembledActions,
  OrdersTableConfirmActions,
} from 'modules/orders/pages/Orders/components'

import { initialDetails, setOrderDetails } from 'modules/orders/slices/orders'
import { mapOrderProducts } from 'modules/orders/utils/mapOrderProducts'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

import type { ExpandableConfig } from 'rc-table/lib/interface'
import type { OrderType } from 'modules/orders/types'

interface Props {
  onRejectOrder: (details: OrderType) => void
  onChangeOrderPackages: (orderId: string) => void
}

export const useOrdersTable = ({
  onRejectOrder,
  onChangeOrderPackages,
}: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { loadingOfList, details, filter } = useAppSelector(
    state => state.orders.orders,
  )
  const lastSuccessfulUpload = useAppSelector(
    state => state.products.productsFeed.lastSuccessfulUpload,
  )

  const { onChangeCbx, onCheckAllCbxChange, onResetCbx, cbxState } =
    useTableCbx(details.products, 'sku')

  const [expandedRowId, setExpandedRowId] = useState(null)

  const isFirstEntry = !Boolean(lastSuccessfulUpload) && !loadingOfList
  const isDataNotFound = filter.search.length !== 0

  const emptyText =
    isFirstEntry && !isDataNotFound
      ? 'Здесь еще нет заказов. Загрузите прайс–лист и начните продавать!'
      : loadingOfList
      ? 'Загрузка ...'
      : null

  const emptyButton =
    isFirstEntry && !isDataNotFound
      ? {
          text: 'ЗАГРУЗИТЬ ПРАЙС–ЛИСТ',
          onClick: () => history.push('/products/upload/manual'),
        }
      : null

  const expandable: ExpandableConfig<OrderType> = {
    expandRowByClick: true,
    fixed: 'right',
    onExpand: (expanded, row) => {
      setExpandedRowId(expanded ? row.id : null)

      if (expanded) {
        dispatch(
          setOrderDetails({
            ...row,
            products: row.products.map(mapOrderProducts),
          }),
        )
      } else {
        dispatch(setOrderDetails(initialDetails))
      }
    },
    expandedRowRender: () => (
      <OrderDetailsTable
        onCheckAllCbxChange={onCheckAllCbxChange}
        products={details.products}
        onChangeCbx={onChangeCbx}
        onResetCbx={onResetCbx}
        cbxState={cbxState}
        details={details}
        isCollapsed
      />
    ),
  }

  return {
    temporarilyEmpty: isFirstEntry || isDataNotFound,
    rowClassName: (row: OrderType) =>
      expandedRowId === row.id ? 'expand-row-parent' : '',
    renderRowActions: (row: OrderType) =>
      row.status === ORDERS_FILTER_STATUS.WAITING_CONFIRMATION ? (
        <OrdersTableConfirmActions row={row} rejectOrder={onRejectOrder} />
      ) : row.status === ORDERS_FILTER_STATUS.ASSEMBLED ? (
        <OrdersTableAssembledActions
          row={row}
          changeOrderPackages={onChangeOrderPackages}
        />
      ) : null,
    expandable,
    emptyButton,
    emptyText,
  }
}
