import { Box, Button, Dropdown, IconButton, Typography } from 'ui'
import { CloseOutlinedIcon, ChevronIcon } from 'ui/icons'
import { styled } from 'ui/theme'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

import type { GeneratePdfType, TipType } from 'modules/orders/types'

interface IRenderTip {
  generatePdf: (docType: GeneratePdfType) => void
  closeTip: VoidFunction
  tip: TipType
}

interface TipProps {
  isDownload: boolean
}

const Tip = styled.div<TipProps>`
  box-shadow: 0 8px 14px rgba(16, 20, 45, 0.05),
    0 3.87387px 9.86002px rgba(16, 20, 45, 0.08);
  background: ${props => props.theme.colors.main.background.white};
  border-radius: 12px;
  padding: 16px;
  width: 365px;
`

const { Subtitle, Body } = Typography

const renderTip = ({ tip, closeTip, generatePdf }: IRenderTip) =>
  tip.isDownload ? (
    <Dropdown.Menu>
      <Dropdown.MenuItem
        onClick={() => {
          generatePdf('STICKER')
          closeTip()
        }}
        data-test-id='GetStickersButton'
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box width='184px' mr='24px'>
            Стикеры грузовых мест
          </Box>

          <ChevronIcon right />
        </Box>
      </Dropdown.MenuItem>
      <Dropdown.MenuItem
        onClick={() => {
          generatePdf('ACT')
          closeTip()
        }}
        data-test-id='GetActsButton'
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box width='184px' mr='24px'>
            Акт приёма передач
          </Box>

          <ChevronIcon right />
        </Box>
      </Dropdown.MenuItem>
    </Dropdown.Menu>
  ) : (
    <Tip isDownload={tip.isDownload}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Subtitle>Подсказка</Subtitle>

        <IconButton onClick={closeTip}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>

      <Body as='p' mt='8px' mb='24px'>
        {tip.type === ORDERS_FILTER_STATUS.ON_ASSEMBLY &&
          'Когда соберёте заказ, нажмите на кнопку “Готов к выдаче”'}

        {tip.type === ORDERS_FILTER_STATUS.ASSEMBLED &&
          `Вы успешно указали количество грузовых мест.
         Не забудьте распечатать стикеры для каждого грузового места`}
      </Body>

      <Button variant='secondary' fullWidth onClick={closeTip}>
        Понятно
      </Button>
    </Tip>
  )

export { renderTip }
