import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import {
  Image,
  Box,
  Link,
  Typography,
  Tooltip,
  Anchor,
  IconButton,
  Table,
  Highlighter,
  Pagination,
  toast,
} from 'ui'
import { Sort } from 'ui/table/components'
import { NewTabIcon } from 'ui/icons'
import {
  RowActions,
  DeliveryModal,
  CancelModal,
  AcceptModal,
} from './components'

import { useSorting } from 'modules/refunds/hooks/useSorting'

import {
  changeRefundsPage,
  changeRefundsLimit,
  updateRefundsState,
} from 'modules/refunds/slices/refunds'

import {
  formatDate,
  formatPhoneNumber,
  formatCurrency,
} from 'utils/textFormatting'

import { env } from 'settings/env'

import { SortingKey } from 'modules/refunds/types'

import type { FC } from 'react'
import type { Refund } from 'modules/refunds/types'
import type { ColumnsType } from 'ui/table/types'

const RefundsTable: FC = () => {
  const dispatch = useAppDispatch()
  const [deliveryModalRefundId, setDeliveryModalRefundId] =
    useState<Refund['id']>(null)
  const [cancelModalRefundId, setCancelModalRefundId] =
    useState<Refund['id']>(null)
  const [acceptModalRefundId, setAcceptModalRefundId] =
    useState<Refund['id']>(null)

  const { refunds, pagination, filters } = useAppSelector(
    state => state.refunds.refunds,
  )
  const { search } = filters
  const { sorting, onChangeSorting } = useSorting()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const handleChangePage = (page: number) => {
    dispatch(changeRefundsPage(page))
  }

  const handleChangeLimit = (limit: number) => {
    dispatch(changeRefundsLimit(limit))
  }

  const handleOpenDelivery = (id: Refund['id']) => {
    setDeliveryModalRefundId(id)
  }

  const handleOpenCancel = (id: Refund['id']) => {
    setCancelModalRefundId(id)
  }

  const handleOpenAccept = (id: Refund['id']) => {
    setAcceptModalRefundId(id)
  }

  const handleCloseDelivery = () => {
    setDeliveryModalRefundId(null)
  }

  const handleCloseCancel = () => {
    setCancelModalRefundId(null)
  }

  const handleCloseAccept = () => {
    setAcceptModalRefundId(null)
  }

  const handleOkDelivery = (id: Refund['id']) => {
    dispatch(
      updateRefundsState({
        id,
        state: 'in_progress',
        onSuccess: () => {
          toast('Курьер будет вызван')
        },
      }),
    )
  }

  const handleOkCancel = (id: Refund['id']) => {
    dispatch(
      updateRefundsState({
        id,
        state: 'rejected_by_merchant',
      }),
    )
  }

  const handleOkAccept = (id: Refund['id']) => {
    dispatch(
      updateRefundsState({
        id,
        state: 'in_progress',
      }),
    )
  }

  const columns: ColumnsType<Refund> = [
    {
      title: '№ заявки на возврат',
      dataIndex: 'id',
      key: 'id',
      width: 250,
    },
    {
      title: (
        <Sort
          onChangeSorting={onChangeSorting}
          sorting={sorting}
          title='№ заказа'
          desc={`${SortingKey.order_id}:desc`}
          asc={`${SortingKey.order_id}:asc`}
        />
      ),
      dataIndex: 'order_id',
      key: 'order_id',
      width: 161,
      noPadding: true,
      render: orderId => (
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography as='p' mr='8px'>
            <Link to={`/orders/${orderId}`}>
              <Highlighter search={search}>{orderId}</Highlighter>
            </Link>
          </Typography>
          <Tooltip overlay='Открыть вкладку в новом окне' placement='top'>
            <Anchor href={`/orders/${orderId}`} target='_blank'>
              <IconButton onClick={e => e.stopPropagation()}>
                <Box display='flex' alignItems='center'>
                  <NewTabIcon color='#319CF3' />
                </Box>
              </IconButton>
            </Anchor>
          </Tooltip>
        </Box>
      ),
    },
    {
      title: 'Фото и SKU товара',
      key: 'product_image_sku',
      dataIndex: 'item',
      width: 175,
      render: item => (
        <Box display='flex' alignItems='center' justifyContent='start'>
          <Box width='44px' height='44px' mr='8px'>
            <Image
              src={item?.details?.image_url}
              width='100%'
              height='100%'
              bgSize='contain'
              isBg
            />
          </Box>
          <Anchor
            href={`${env.CUSTOMER_BASE_URL}/product/${item?.details?.sku}`}
          >
            {item?.details?.sku}
          </Anchor>
          <Tooltip overlay='Открыть вкладку в новом окне' placement='top'>
            <Anchor
              ml='auto'
              target='_blank'
              rel='noreferrer'
              href={`${env.CUSTOMER_BASE_URL}/product/${item?.details?.sku}`}
            >
              <IconButton onClick={e => e.stopPropagation()}>
                <Box display='flex' alignItems='center'>
                  <NewTabIcon color='#319CF3' />
                </Box>
              </IconButton>
            </Anchor>
          </Tooltip>
        </Box>
      ),
    },
    {
      title: 'Название товара',
      dataIndex: 'item',
      key: 'product_title',
      width: 398,
      ellipsis: true,
      render: item => item?.details?.title?.ru,
    },
    {
      title: (
        <Sort
          onChangeSorting={onChangeSorting}
          sorting={sorting}
          title='Дата создания заявки'
          desc={`${SortingKey.created_at}:desc`}
          asc={`${SortingKey.created_at}:asc`}
        />
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 243,
      noPadding: true,
      render: date => formatDate(new Date(date), 'dd MMM yyyy г.'),
    },
    {
      title: (
        <Sort
          onChangeSorting={onChangeSorting}
          sorting={sorting}
          title='Дата завершения возврата'
          desc={`${SortingKey.due_date}:desc`}
          asc={`${SortingKey.due_date}:asc`}
        />
      ),
      dataIndex: 'due_date',
      key: 'due_date',
      width: 283,
      noPadding: true,
      render: date => formatDate(new Date(date), 'dd MMM yyyy г.'),
    },
    {
      title: (
        <Sort
          onChangeSorting={onChangeSorting}
          sorting={sorting}
          title='Стоимость товара при продаже'
          desc={`${SortingKey.total_price}:desc`}
          asc={`${SortingKey.total_price}:asc`}
        />
      ),
      dataIndex: 'total_price',
      key: 'total_price',
      width: 320,
      align: 'right',
      noPadding: true,
      render: price => formatCurrency(price),
    },
    {
      title: 'Имя клиента',
      dataIndex: 'buyer',
      key: 'buyer_name',
      width: 349,
      render: buyer => buyer?.first_name,
    },

    {
      title: 'Телефон клиента',
      dataIndex: 'buyer',
      key: 'phone',
      width: 180,
      render: buyer => formatPhoneNumber(buyer?.phone),
    },
  ]

  return (
    <>
      <Table
        rowKey='id'
        columns={columns}
        data={refunds}
        renderRowActions={row => (
          <>
            <RowActions
              id={row.id}
              onOpenDelivery={handleOpenDelivery}
              onOpenCancel={handleOpenCancel}
              onOpenAccept={handleOpenAccept}
            />
          </>
        )}
      />
      <Pagination
        {...pagination}
        onPageChange={handleChangePage}
        onLimitChange={handleChangeLimit}
      />

      <DeliveryModal
        id={deliveryModalRefundId}
        onClose={handleCloseDelivery}
        onOk={handleOkDelivery}
      />
      <CancelModal
        id={cancelModalRefundId}
        onClose={handleCloseCancel}
        onOk={handleOkCancel}
      />
      <AcceptModal
        id={acceptModalRefundId}
        onClose={handleCloseAccept}
        onOk={handleOkAccept}
      />
    </>
  )
}

export { RefundsTable }
