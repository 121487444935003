import { styled } from 'ui/theme'
import { color, space, typography, flexbox, layout } from 'styled-system'

import { AnchorProps } from './types'

const StyledAnchor = styled.a<AnchorProps>`
  text-decoration: none;

  ${props =>
    props.nowrap &&
    `
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`}
  &:hover {
    text-decoration: underline;
  }

  ${color};
  ${space};
  ${layout};
  ${flexbox};
  ${typography};
`

export { StyledAnchor }
