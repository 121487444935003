import { css } from '@emotion/react'
import { styled } from 'ui/theme'

import { mainHeader } from 'modules/common/constants/properties'

export const getModalStyles = () => css`
  .rc-dialog {
    position: relative !important;
    width: auto !important;
  }

  .rc-dialog-wrap {
    overflow: initial !important;
  }

  .rc-dialog-content {
    padding: 0 !important;
  }

  .rc-dialog-close {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0 !important;
    right: -8px !important;
    transform: translateX(100%);
    background-color: white !important;
    opacity: 1 !important;
    border-radius: 4px;
    width: 60px !important;
    height: 60px !important;

    &:hover {
      box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.15);
    }
  }
`

export const Image = styled.img`
  max-width: 100%;
  vertical-align: middle;
`

const Container = styled.div`
  padding-top: 24px;
  padding-left: 96px;
  max-width: 816px;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    color: ${props => props.theme.colors.text.black};
    scroll-margin-top: ${mainHeader.height};
  }

  h1 {
    font-size: ${props => props.theme.fontSizes.title.m};
    line-height: ${props => props.theme.lineHeights.xxl[1]};
    font-weight: ${props => props.theme.fontWeights.bold};
    margin-bottom: 16px;
  }

  h2 {
    font-size: ${props => props.theme.fontSizes.title.s};
    line-height: ${props => props.theme.lineHeights.xxl[0]};
    font-weight: ${props => props.theme.fontWeights.semibold};
    margin-top: 52px;
    margin-bottom: 16px;
  }

  h3,
  h4 {
    font-size: ${props => props.theme.fontSizes.subtitle};
    line-height: ${props => props.theme.lineHeights.l};
    font-weight: ${props => props.theme.fontWeights.semibold};
    margin-top: 44px;
    margin-bottom: 4px;
  }

  p,
  blockquote,
  li,
  a {
    font-size: ${props => props.theme.fontSizes.body.m};
    line-height: ${props => props.theme.lineHeights.m};
  }

  a {
    cursor: pointer;
    color: ${props => props.theme.colors.text.link};
    text-decoration: underline;
  }

  p {
    margin-bottom: 20px;
  }

  blockquote {
    margin: 0;
    padding: 24px;
    border-radius: 8px;
    font-weight: ${props => props.theme.fontWeights.semibold};
    background-color: ${props => props.theme.colors.main.background.gray};
  }

  img {
    max-width: 100%;
    cursor: pointer;
  }

  ul,
  ol {
    display: grid;
    grid-gap: 8px;

    margin-left: 16px;
  }

  ul {
    list-style-type: disc;
  }

  p,
  blockquote,
  img,
  ul,
  ol,
  .video-container {
    margin-bottom: 20px;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.2%;
    height: 100px;
  }

  .video-container iframe {
    display: initial;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  table,
  th,
  td {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    border-collapse: collapse;
  }

  td {
    padding: 12px 16px;
    vertical-align: top;
    p {
      margin: 0;
    }
  }

  .td-q h3 {
    margin-top: 0;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }
`

export { Container }
