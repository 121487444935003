import { Box, Tooltip } from 'ui'
import { CheckIcon, CloseOutlinedIcon, DeliverySelfIcon } from 'ui/icons'
import { TableOverlayButton } from 'ui/table/components'

import type { FC } from 'react'
import type { Refund } from 'modules/refunds/types'

interface Props {
  onOpenDelivery: (id: Refund['id']) => void
  onOpenCancel: (id: Refund['id']) => void
  onOpenAccept: (id: Refund['id']) => void
  id: Refund['id']
}

const RowActions: FC<Props> = ({
  onOpenDelivery,
  onOpenCancel,
  onOpenAccept,
  id,
}) => (
  <>
    <Box display='flex'>
      <Tooltip
        placement='top'
        overlay={
          <>
            Вызвать
            <br />
            курьера
          </>
        }
      >
        <TableOverlayButton onClick={() => onOpenDelivery(id)}>
          <DeliverySelfIcon color='icon.black' />
        </TableOverlayButton>
      </Tooltip>
      <Tooltip placement='top' overlay='Отказать'>
        <TableOverlayButton onClick={() => onOpenCancel(id)}>
          <CloseOutlinedIcon color='icon.black' />
        </TableOverlayButton>
      </Tooltip>
      <Tooltip placement='top' overlay='Подтвердить'>
        <TableOverlayButton onClick={() => onOpenAccept(id)}>
          <CheckIcon color='icon.black' />
        </TableOverlayButton>
      </Tooltip>
    </Box>
  </>
)

export { RowActions }
