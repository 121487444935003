import { forwardRef } from 'react'

import { useAppTheme } from 'ui/theme'
import { Text } from 'ui'

import type { FC, Ref } from 'react'
import type { TextProps } from 'ui/typography/Text/types'

interface SubtitleProps extends TextProps {
  weight?: 'semibold' | 'bold'
}

const Subtitle: FC<SubtitleProps> = forwardRef(
  ({ weight = 'semibold', color = 'text.black', children, ...props }, ref) => {
    const theme = useAppTheme()

    const lineHeights = {
      bold: theme.lineHeights.xl,
      semibold: theme.lineHeights.l,
    }

    return (
      <Text
        {...props}
        ref={ref as Ref<HTMLSpanElement>}
        color={color}
        lineHeight={lineHeights[weight]}
        fontSize={theme.fontSizes.subtitle.s}
        fontWeight={theme.fontWeights[weight]}
      >
        {children}
      </Text>
    )
  },
)

export { Subtitle }
