import { styled } from 'ui/theme'
import { ifProp } from 'styled-tools'

import { TabsStatus } from 'ui/tabs/types'

export const TabsHead = styled.ul<{ marginBottom: string }>`
  list-style: none;
  margin-bottom: ${props => props.marginBottom};
  display: flex;
  height: 48px;
  min-width: max-content;
`

export const TabsContent = styled.div<TabsStatus>`
  display: ${props => (props.active ? 'block' : 'none')};
`

export const Tab = styled.li<TabsStatus>`
  padding: 12px 16px;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  transition: box-shadow 0.2s, background-color 0.1s;

  &:hover {
    background-color: ${props => props.theme.greyColorPalette[5]};
  }

  ${ifProp('active', 'box-shadow: inset 0px -2px 0px #319cf3')};

  ${ifProp(
    'fullWidth',
    `
    display: flex;
    flex: 1 0 0;
    justify-content: center;
  `,
  )}
`
