import { Typography } from 'ui'
import { formatNumber } from 'utils/textFormatting'

import { BadgeWrap } from './styles'

import type { FC } from 'react'
import type { BadgeProps } from './types'

const { Caption } = Typography

const Badge: FC<BadgeProps> = ({
  count = 0,
  children,
  mini = false,
  unlim = false,
}) => {
  const isPositive = count > 0
  const isLimited = !unlim && count > 99

  const countString = isPositive
    ? isLimited
      ? '99+'
      : formatNumber(count)
    : ''

  return (
    <BadgeWrap isMini={mini} isUnlim={unlim}>
      {children}
      {countString && (
        <Caption level={unlim ? 1 : 2} weight='bold' className='__badge'>
          {(!mini || unlim) && countString}
        </Caption>
      )}
    </BadgeWrap>
  )
}

export { Badge }
