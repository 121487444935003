import { compose, space, display, flexbox } from 'styled-system'
import { ifNotProp, ifProp } from 'styled-tools'

import { variants } from 'ui/button/styles/variants'
import { sizes } from 'ui/button/styles/sizes'

import { styled } from 'ui/theme'

import type { StyledButtonProps } from 'ui/button/types'

export const StyledButton = styled.button<StyledButtonProps>`
  display: ${props => (props.fullWidth ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  background: none;
  transition: box-shadow 0.1s;
  outline: none;
  border-radius: ${props => props.theme.radii.xxs};
  font-weight: ${props => props.theme.fontWeights.semibold};
  position: relative;
  letter-spacing: 0.1em;
  -moz-user-select: none;
  user-select: none;

  .__button-loading {
    font-size: 0;
    position: absolute;
    display: flex;
  }

  .__button-wrap {
    ${ifProp('isLoading', 'opacity: 0')};
    display: flex;
  }

  &:enabled {
    cursor: pointer;

    &:hover {
      box-shadow: 0 8px 19px rgba(0, 0, 0, 0.15);
    }

    &:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${props =>
    ifNotProp(
      'isLoading',
      `
    &:disabled {
      background: ${props.theme.greyColorPalette[5]};
      color: ${props.theme.colors.text.secondaryBlack};

      svg {
        path {
          fill: ${props.theme.colors.text.secondaryBlack};
        }
      }
    }
  `,
    )}

  ${ifProp('fullWidth', 'width: 100%;')}
  ${compose(space, display, flexbox)}
  ${variants}
  ${sizes}
`
