import { css } from '@emotion/react'
import { variant } from 'styled-system'

import type { SizesMap, VariantProps } from 'ui/button/types'
import type { StyledButtonProps } from 'ui/button/types'

const sizesStyles = ({
  theme,
  variant,
}: StyledButtonProps & VariantProps): SizesMap => {
  const nano = css`
    height: 24px;
    padding: 0 8px;
    font-size: ${theme.fontSizes.button.n};
    line-height: ${theme.lineHeights.xxs};

    ${variant === 'default' &&
    `
      padding: 0 7px;
    `}
    svg {
      width: 12px;
      height: 12px;
    }
  `

  const medium = css`
    height: 40px;
    padding: 0 12px;
    font-size: ${theme.fontSizes.button.l};
    line-height: ${theme.lineHeights.m};

    ${variant === 'default' &&
    `
      padding: 0 11px;
    `}
    svg {
      width: 20px;
      height: 20px;
    }
  `

  const large = css`
    height: 64px;
    padding: 0 20px;
    font-size: ${theme.fontSizes.button.xl};
    line-height: ${theme.lineHeights.m};

    ${variant === 'default' &&
    `
      padding: 0 19px;
    `}
    svg {
      width: 20px;
      height: 20px;
    }
  `

  const edit = css`
    height: 48px;
    width: 48px;
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  `

  return {
    nano,
    medium,
    large,
    edit,
  }
}

export const sizes = (props: StyledButtonProps & VariantProps) =>
  variant({
    variants: sizesStyles(props),
    prop: 'size',
  })
