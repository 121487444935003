import { ThemeLineHeights } from 'ui/theme/src/types'

const lineHeights: ThemeLineHeights = {
  xxs: '12px',
  xs: '16px',
  s: '20px',
  m: '24px',
  l: '28px',
  xl: '32px',
  xxl: ['48px', '56px', '72px'],
}

export default lineHeights
