import { styled } from 'ui/theme'

import type { FormCheckboxProps } from 'ui/inputs/Checkbox/types'

export const CheckboxWrap = styled.div<FormCheckboxProps>`
  font-size: 0;
  line-height: 0;

  input {
    vertical-align: middle;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input:checked + .__checkbox {
    border-color: ${props => props.theme.colors.projects.primaryMarket};
    background-color: ${props => props.theme.colors.projects.primaryMarket};
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.64018 0.231804C10.0645 0.585368 10.1218 1.21593 9.76822 1.64021L4.76822 7.64021C4.58836 7.85605 4.32599 7.98627 4.04531 7.999C3.76464 8.01173 3.49156 7.9058 3.29289 7.70713L0.792893 5.20713C0.402369 4.81661 0.402369 4.18344 0.792893 3.79292C1.18342 3.40239 1.81658 3.40239 2.20711 3.79292L3.9328 5.51861L8.23178 0.359841C8.58534 -0.0644363 9.21591 -0.12176 9.64018 0.231804Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A");
  }

  .__checkbox {
    display: inline-block;
    min-width: 16px;
    height: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center;
    transition: 0.2s;
    border: 2px solid ${props => props.theme.colors.icon.secondaryBlack};
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: ${props => props.theme.colors.projects.primaryMarket};
    }

    ${props =>
      props.indeterminate &&
      `
        background-color: ${props.theme.colors.projects.primaryMarket};
        border-color: ${props.theme.colors.projects.primaryMarket};
      `}
    &::before {
      content: '';
      opacity: ${props => (props.indeterminate ? '1' : '0')};
      display: block;
      min-width: 8px;
      height: 2px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.2s;
      border-radius: 8px;
      background-color: ${props => props.theme.colors.icon.white};
    }
  }
`
