import { styled } from 'ui/theme'
import { Typography } from 'ui'

import {
  mainAside,
  mainHeader,
  transitionDuration,
} from 'modules/common/constants/properties'

const { Caption } = Typography

interface Props {
  isOpen: boolean
}

export const Aside = styled.aside<Props>`
  grid-area: aside;
  width: ${props =>
    props.isOpen ? mainAside.width : mainAside.collapsedWidth};

  transition: width ${transitionDuration};
  border-right: 1px solid ${props => props.theme.greyColorPalette[10]};
`

export const Nav = styled.nav<Props>`
  position: sticky;
  top: ${mainHeader.height};
  max-height: calc(100vh - ${mainHeader.height});
  overflow-y: auto;
  white-space: nowrap;
  box-sizing: border-box;
  overflow-x: hidden;
`

export const StyledCaption = styled(Caption)`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 34px;
  min-height: 32px;
  color: ${props => props.theme.colors.text.secondaryBlack};
`
