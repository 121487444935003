import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const MenuCollapse: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M2.02316 16H13.278C13.8407 16 14.3011 15.55 14.3011 15C14.3011 14.45 13.8407 14 13.278 14H2.02316C1.46042 14 1 14.45 1 15C1 15.55 1.46042 16 2.02316 16ZM2.02316 11H10.2085C10.7712 11 11.2316 10.55 11.2316 10C11.2316 9.45 10.7712 9 10.2085 9H2.02316C1.46042 9 1 9.45 1 10C1 10.55 1.46042 11 2.02316 11ZM1 5C1 5.55 1.46042 6 2.02316 6H13.278C13.8407 6 14.3011 5.55 14.3011 5C14.3011 4.45 13.8407 4 13.278 4H2.02316C1.46042 4 1 4.45 1 5ZM18.7007 12.88L15.754 10L18.7007 7.12C19.0998 6.73 19.0998 6.1 18.7007 5.71C18.3017 5.32 17.6571 5.32 17.2581 5.71L13.5849 9.3C13.1859 9.69 13.1859 10.32 13.5849 10.71L17.2581 14.3C17.6571 14.69 18.3017 14.69 18.7007 14.3C19.0895 13.91 19.0998 13.27 18.7007 12.88Z'
      fill={color}
    />
  </Icon>
)

MenuCollapse.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { MenuCollapse }
