import { space } from 'styled-system'

import { useAppTheme, styled } from 'ui/theme'
import { Typography } from 'ui'

import type { FC } from 'react'
import type { ActionButtonProps } from 'ui/button/types'

const { Body } = Typography

export const CheckboxButton = styled.label`
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 8px 12px;
  transition: all 0.2s;
  border-radius: 4px;
  cursor: pointer;
  column-gap: 8px;

  &:hover {
    background-color: ${props => props.theme.greyColorPalette[5]};
  }
`

const StyledButton = styled.button<ActionButtonProps>`
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;
  border: none;
  min-width: 20px;
  min-height: 40px;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: inherit;
  transition: all 0.2s;

  ${space}
  &:hover {
    background-color: ${props => props.theme.greyColorPalette[5]};
  }

  ${props =>
    props.isActive && `background-color: ${props.theme.greyColorPalette[5]};`}
`

const ActionButton: FC<ActionButtonProps> = ({
  icon: Icon,
  isActive,
  children,
  color,
  weight,
  ...props
}) => {
  const theme = useAppTheme()

  return (
    <StyledButton isActive={isActive} {...props}>
      {Boolean(Icon) && <Icon color={color || theme.colors.icon.black} />}
      <Body weight={weight} color={color || theme.colors.text.black}>
        {children}
      </Body>
    </StyledButton>
  )
}

ActionButton.defaultProps = {
  weight: 'semibold',
}

export { ActionButton }
