import { Redirect } from 'react-router-dom'

import {
  Products,
  UploadProducts,
  UploadEventDetails,
} from 'modules/products/pages'

import {
  UploadHistory,
  Automatic,
  Manual,
} from 'modules/products/pages/UploadProducts/components'

import { getDashedStr } from 'utils/getDashedStr'

import type { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  AUTOMATIC = 'AUTOMATIC',
  PRODUCTS = 'PRODUCTS',
  REDIRECT = 'REDIRECT',
  HISTORY = 'HISTORY',
  MANUAL = 'MANUAL',
  UPLOAD = 'UPLOAD',
  EVENT = 'EVENT',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/products',
    exact: true,
    key: getDashedStr(key, KEYS.PRODUCTS),
    component: Products,
  },
  {
    path: '/products/upload/events/:eventId',
    exact: true,
    key: getDashedStr(key, KEYS.EVENT),
    component: UploadEventDetails,
  },
  {
    path: '/products/upload',
    key: getDashedStr(key, KEYS.UPLOAD),
    component: UploadProducts,
  },
]

export const uploadRoutes: IRoute[] = [
  {
    path: '/products/upload',
    exact: true,
    key: getDashedStr(KEYS.PRODUCTS, KEYS.UPLOAD, KEYS.REDIRECT),
    component: () => <Redirect to='/products/upload/manual' />,
  },
  {
    path: '/products/upload/manual',
    exact: true,
    key: getDashedStr(KEYS.PRODUCTS, KEYS.UPLOAD, KEYS.MANUAL),
    component: Manual,
    nav: {
      name: 'Ручная',
    },
  },
  {
    path: '/products/upload/automatic',
    exact: true,
    key: getDashedStr(KEYS.PRODUCTS, KEYS.UPLOAD, KEYS.AUTOMATIC),
    component: Automatic,
    nav: {
      name: 'Автоматическая',
    },
  },
  {
    path: '/products/upload/history',
    exact: true,
    key: getDashedStr(KEYS.PRODUCTS, KEYS.UPLOAD, KEYS.HISTORY),
    component: UploadHistory,
    nav: {
      name: 'История загрузок',
    },
  },
]
