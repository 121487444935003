import { useEffect, useState } from 'react'

import { SearchWrap } from 'ui/inputs/Search/styles'
import { CloseIcon, LoupeIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'

import type { FC } from 'react'
import type { SearchProps } from 'ui/inputs/Search/types'

const Search: FC<SearchProps> = ({ onSearch, onClear, value, ...props }) => {
  const [defaultValue, setDefaultValue] = useState('')
  const theme = useAppTheme()

  useEffect(() => {
    if (value) {
      setDefaultValue(value as string)
    }
  }, [value])

  const onChangeValue = e => {
    setDefaultValue(e.target.value)
  }

  const onClearValue = () => {
    setDefaultValue('')
    if (typeof onSearch === 'function') {
      onSearch('')
    }
  }

  const handleSearch = () => {
    if (typeof onSearch === 'function') {
      onSearch(defaultValue)
    }
  }

  return (
    <SearchWrap>
      <LoupeIcon
        className='__loupe-icon'
        color={theme.colors.projects.secondaryMarket}
      />
      <input
        type='text'
        value={defaultValue}
        onChange={onChangeValue}
        onKeyPress={e => {
          if (e.key === 'Enter' && onSearch) {
            onSearch(defaultValue)
          }
        }}
        {...props}
      />
      {defaultValue.length !== 0 && (
        <CloseIcon
          className='__clear-icon'
          onClick={onClear || onClearValue}
          color={theme.colors.icon.secondaryBlack}
        />
      )}
      <button className='__search-button' onClick={handleSearch}>
        Найти
      </button>
    </SearchWrap>
  )
}

export { Search }
