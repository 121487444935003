import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { instance } from 'store/configureStore'

import { auth as onAuth } from 'modules/auth/slices/credentials'
import { fetchProfile } from 'modules/profile/slices/profile'

import { thunkErrorHandler } from 'utils/errorHandler'
import { isLoadingAction } from 'utils/sliceMatchers'

import history from 'settings/history'

import { ACCOUNT_STATUS } from 'modules/auth/types/enums'

import type { AxiosResponse } from 'axios'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk } from 'store/configureStore'
import type { IResponseError } from 'modules/common/types/interfaces'
import type {
  DefaultCredentials,
  FetchPartnersRes,
  RequestOTPRes,
  CheckPhoneRes,
  AuthState,
} from 'modules/auth/types/interfaces'
import type {
  CreatePasswordParams,
  PartnerSignInParams,
  CheckPhoneParams,
  UserSignInParams,
  VerifyOTPParams,
  AccStatusType,
  PartnerType,
} from 'modules/auth/types'

/**
 * Initial State
 */

const initialState: AuthState = {
  error: null,
  loading: false,
  user_type: null,
  accountStatus: null,
  redirect_denied: false,
  partners: [],
}

/**
 * Async Actions
 */

export const fetchPartners: AppThunk<PartnerType[]> = createAsyncThunk(
  'auth/merchant/signIn',
  (_, thunkApi) =>
    instance(thunkApi)
      .get('/merchant-profile/api/v1/auth')
      .then((res: AxiosResponse<FetchPartnersRes>) => {
        if (res.data.data.length) {
          thunkApi.dispatch(
            changeAccountStatus(ACCOUNT_STATUS.PARTNER_SELECTION),
          )
        } else {
          thunkApi.dispatch(changeAccountStatus(ACCOUNT_STATUS.WAITING))
        }

        return res.data.data
      })
      .catch(thunkErrorHandler(thunkApi)),
)

export const partnerSignIn: AppThunk<void, PartnerSignInParams> =
  createAsyncThunk('auth/partnerSignIn', (params, thunkApi) =>
    instance(thunkApi)
      .post('/merchant-profile/api/v1/auth/sign-in', params)
      .then((res: AxiosResponse<DefaultCredentials>) => {
        thunkApi.dispatch(
          onAuth({
            mid: res.data.mid,
            merchant_access_token: res.data.access_token,
            merchant_refresh_token: res.data.refresh_token,
          }),
        )
      })
      .catch(thunkErrorHandler(thunkApi)),
  )

export const userSignIn: AppThunk<void, UserSignInParams> = createAsyncThunk(
  'auth/userSignIn',
  (body, thunkApi) =>
    instance(thunkApi)
      .post('/sso/api/v1/auth/signin/phone', body)
      .then((res: AxiosResponse<DefaultCredentials>) => {
        thunkApi.dispatch(fetchPartners())
        thunkApi.dispatch(
          onAuth({
            user_access_token: res.data.access_token,
            user_refresh_token: res.data.refresh_token,
          }),
        )
      })
      .catch(thunkErrorHandler(thunkApi)),
)

export const checkPhone: AppThunk<void, CheckPhoneParams> = createAsyncThunk(
  'auth/checkPhone',
  (body, thunkApi) =>
    instance(thunkApi)
      .get(`/sso/api/v1/users/kind/${body.phone}`)
      .then((res: AxiosResponse<CheckPhoneRes>) => {
        thunkApi.dispatch(changeUserType(res.data.user_kind))

        if (res.data.user_kind === 'fast') {
          thunkApi.dispatch(requestOTP(body))
          thunkApi.dispatch(changeAccountStatus(ACCOUNT_STATUS.INACTIVE))
        } else if (res.data.user_kind === 'full') {
          thunkApi.dispatch(changeAccountStatus(ACCOUNT_STATUS.ACTIVE))
        }
      })
      .catch(thunkErrorHandler(thunkApi)),
)

export const requestOTP: AppThunk<RequestOTPRes, CheckPhoneParams> =
  createAsyncThunk('auth/getOTP', (body, thunkApi) =>
    instance(thunkApi)
      .post('/sso/api/v1/auth/signin/fast', body)
      .then((res: AxiosResponse<RequestOTPRes>) => res.data)
      .catch(thunkErrorHandler(thunkApi)),
  )

export const verifyOTP: AppThunk<void, VerifyOTPParams> = createAsyncThunk(
  'auth/verifyOTP',
  (body, thunkApi) =>
    instance(thunkApi)
      .post('/sso/api/v1/auth/signin/fast/verify', body)
      .then((res: AxiosResponse<DefaultCredentials>) => {
        thunkApi.dispatch(fetchPartners())
        thunkApi.dispatch(
          onAuth({
            user_access_token: res.data.access_token,
            user_refresh_token: res.data.refresh_token,
          }),
        )
      })
      .catch(thunkErrorHandler(thunkApi)),
)

export const createPassword: AppThunk<void, CreatePasswordParams> =
  createAsyncThunk(
    'auth/createPassword',
    (body: CreatePasswordParams, thunkApi) =>
      instance(thunkApi)
        .put('/sso/api/v1/users/password', body)
        .then(() => {
          thunkApi.dispatch(changeDeniedStatus(false))
          thunkApi.dispatch(fetchProfile())
          history.replace('/')
        })
        .catch(thunkErrorHandler(thunkApi)),
  )

/**
 * Slice
 */

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAccountStatus: (state, action: PayloadAction<AccStatusType>) => {
      state.accountStatus = action.payload
    },
    changeDeniedStatus: (state, action: PayloadAction<boolean>) => {
      state.redirect_denied = action.payload
    },
    changeUserType: (state, action: PayloadAction<'fast' | 'full'>) => {
      state.user_type = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPartners.fulfilled, (state, { payload }) => {
        state.partners = payload
      })
      .addMatcher(isLoadingAction('auth', 'pending'), state => {
        state.loading = true
        state.error = null
      })
      .addMatcher(isLoadingAction('auth', 'fulfilled'), state => {
        state.loading = false
        state.error = null
      })
      .addMatcher(
        isLoadingAction('auth', 'rejected'),
        (state, action: PayloadAction<IResponseError>) => {
          state.loading = false
          state.error = action.payload.error
        },
      )
  },
})

export const { changeAccountStatus, changeDeniedStatus, changeUserType } =
  authSlice.actions

export default authSlice.reducer
