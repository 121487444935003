import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Shop: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 7.49997C7.89543 7.49997 7 8.3954 7 9.49997V11.5C7 12.6045 7.89543 13.5 9 13.5H11C12.1046 13.5 13 12.6045 13 11.5V9.49997C13 8.3954 12.1046 7.49997 11 7.49997H9ZM9 9.49997H11V11.5H9V9.49997Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.7437 2.01656C10.7006 1.2715 9.29938 1.2715 8.25629 2.01656L3.25629 5.58799C2.4679 6.15113 2 7.06034 2 8.02919V15C2 16.6568 3.34315 18 5 18H15C16.6569 18 18 16.6568 18 15V8.02919C18 7.06034 17.5321 6.15113 16.7437 5.58799L11.7437 2.01656ZM9.41876 3.64403C9.76646 3.39567 10.2335 3.39567 10.5812 3.64403L15.5812 7.21546C15.844 7.40317 16 7.70624 16 8.02919V15C16 15.5522 15.5523 16 15 16H5C4.44772 16 4 15.5522 4 15V8.02919C4 7.70624 4.15597 7.40317 4.41876 7.21546L9.41876 3.64403Z'
      fill={color}
    />
  </Icon>
)

Shop.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Shop }
