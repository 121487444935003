import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchCategories } from 'modules/products/slices/products'

const useCategories = () => {
  const dispatch = useAppDispatch()
  const { categories } = useAppSelector(state => state.products.products)

  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])

  return { categories }
}

export { useCategories }
