import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import {
  Anchor,
  Box,
  Checkbox,
  Highlighter,
  Image,
  Table,
  Typography,
  Pagination,
} from 'ui'

import { formatCurrency, formatPercents } from 'utils/textFormatting'
import {
  asyncChangeAvailableProductsLimit,
  asyncChangeAvailableProductsPage,
} from 'modules/installments/slices/products'

import { env } from 'settings/env'

import type { FC } from 'react'
import type { ColumnsType } from 'ui/table/types'
import type { Product } from 'modules/products/types'
import type { CbxState } from 'modules/common/types/interfaces'

const { Caption } = Typography

interface Props {
  products: Product[]
  onChangeCbx: (checkedId: string | number) => void
  cbxState: CbxState
  installmentId: string
  onResetCbx: VoidFunction
  isSearch: boolean
}

const AddProductsToInstallmentTable: FC<Props> = ({
  products,
  onChangeCbx,
  cbxState,
  installmentId,
  onResetCbx,
  isSearch,
}) => {
  const dispatch = useAppDispatch()
  const { search } = useAppSelector(
    state => state.installments.products.availableProductsFilters,
  )
  const pagination = useAppSelector(
    state => state.installments.products.availableProductsPagination,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const handleRow = row => ({
    onClick: () => {
      onChangeCbx(row.sku)
    },
  })

  const handleChangePage = (page: number) => {
    onResetCbx()
    dispatch(asyncChangeAvailableProductsPage({ page, installmentId }))
  }

  const handleChangeLimit = (limit: number) => {
    onResetCbx()
    dispatch(asyncChangeAvailableProductsLimit({ limit, installmentId }))
  }

  const columns: ColumnsType<Product> = [
    {
      key: 'checkbox',
      dataIndex: 'sku',
      title: '',
      width: 52,
      render: sku => (
        <Checkbox
          id={sku}
          value={sku}
          name='tester'
          onChange={() => onChangeCbx(sku)}
          checked={cbxState.checkedList.some(itemId => itemId === sku)}
        />
      ),
    },
    {
      key: 'image_url',
      dataIndex: 'image_url',
      width: 48,
      render: (url, row) => <Image src={url} alt={row?.title?.ru} />,
    },
    {
      key: 'sku',
      dataIndex: 'sku',
      title: 'Артикул',
      width: 120,
    },
    {
      key: 'name',
      dataIndex: ['title', 'ru'],
      title: 'Название',
      render: (name, row) => (
        <Anchor
          target='_blank'
          rel='noreferrer'
          href={`${env.CUSTOMER_BASE_URL}/product/${row.sku}`}
        >
          <Highlighter search={search}>{name}</Highlighter>
        </Anchor>
      ),
    },
    {
      key: 'category',
      dataIndex: ['category', 'title', 'ru'],
      width: 160,
      title: 'Категория',
    },
    {
      key: 'price',
      dataIndex: ['price', 'current'],
      title: 'Цена на Airba',
      align: 'right',
      width: 140,
      render: price => formatCurrency(price),
    },
    {
      key: 'commission_category',
      dataIndex: ['commissions', 'category'],
      title: 'Комиссия за продажу',
      align: 'right',
      width: 120,
      render: commission => (
        <Box display='grid'>
          {formatCurrency(commission?.value)}
          <Caption color='text.secondaryBlack'>
            {formatPercents(commission?.rate)}
          </Caption>
        </Box>
      ),
    },
    {
      key: 'commission_installment',
      dataIndex: ['commissions', 'installment'],
      title: 'Комиссия за участие в акции',
      align: 'right',
      width: 160,
      render: commission => (
        <Box display='grid'>
          {formatCurrency(commission?.value)}
          <Caption color='text.secondaryBlack'>
            {formatPercents(commission?.rate)}
          </Caption>
        </Box>
      ),
    },
    {
      key: 'commission_total',
      dataIndex: ['commissions', 'total'],
      title: 'Итоговая комиссия',
      align: 'right',
      width: 170,
      render: commission => (
        <Box display='grid'>
          {formatCurrency(commission?.value)}
          <Caption color='text.secondaryBlack'>
            {formatPercents(commission?.rate)}
          </Caption>
        </Box>
      ),
    },
  ]

  return (
    <Box mt='24px'>
      <Table
        columns={columns}
        data={products}
        onRow={handleRow}
        temporarilyEmpty={!isSearch}
        emptyText={!isSearch && 'Пока здесь ничего нет'}
        rowKey='sku'
      />

      <Pagination
        {...pagination}
        onPageChange={handleChangePage}
        onLimitChange={handleChangeLimit}
      />
    </Box>
  )
}

export { AddProductsToInstallmentTable }
