import { styled } from 'ui/theme'

import { DividerProps } from 'ui/divider/types'

export const Line = styled.div<DividerProps>`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.greyColorPalette[10]};

  ${props =>
    props.pageWidth &&
    `
    position: absolute;
    left: 0;
    right: 0;
  `}
`
