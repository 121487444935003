import { LogoIcon } from 'ui/icons'
import { Box } from 'ui'

import type { FC } from 'react'

const SignInHeader: FC = () => (
  <Box mb='12px'>
    <LogoIcon />
  </Box>
)

export { SignInHeader }
