import { css } from '@emotion/react'
import { styled } from 'ui/theme'

export const TimePickerWrap = styled.div`
  margin-top: 8px;

  .rc-time-picker {
    .rc-time-picker-input {
      width: 156px;
      height: 36px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #cccccc;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #888a96;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 12px;

      :active,
      :hover,
      :focus {
        outline: 0;
        outline-offset: 0;
      }

      &:focus {
        border-color: ${props => props.theme.colors.projects.primaryMarket};
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
      }
    }
  }
`

export const getTimeSelectStyles = () => css`
  .rc-time-picker-panel {
    .rc-time-picker-panel-inner {
      .rc-time-picker-panel-combobox {
        display: flex;
      }
    }
  }
`
