import { useHistory, Link } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

import { NotFound as NotFoundIllustration } from 'ui/icons/Illustrations'
import { Typography, Box, ErrorsWrapper, Button, Anchor } from 'ui'
import { LogoIcon } from 'ui/icons'
import { styled } from 'ui/theme'

import { ContactType } from 'modules/common/types'

import type { FC } from 'react'

const NotFoundWrap = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.main.background.white};

  .__links {
    a {
      display: block;
      text-decoration: none;
    }
  }
`

const { Title, Body, Subtitle } = Typography

const NotFound: FC = () => {
  const history = useHistory()
  const contacts = useAppSelector(state => state.common.contacts.list)

  const email = contacts.find(contact => contact.type === ContactType.Email)

  return (
    <NotFoundWrap>
      <ErrorsWrapper>
        <Box mb='24px'>
          <LogoIcon />
        </Box>

        <Title as='p'>
          Страница не найдена
          <sup>
            <Subtitle ml='8px' color='text.secondaryBlack'>
              404
            </Subtitle>
          </sup>
        </Title>
        <Box width='400px' my='8px'>
          <Body as='p'>В адресе есть ошибка или страница удалена.</Body>
          {email && (
            <Body as='p'>
              Если вы уверены что всё сделали правильно, напишите нам на{' '}
              <Anchor href={email?.value}>{email?.comment}</Anchor>
            </Body>
          )}

          <Body as='p' mt='16px'>
            Или начните с одной из популярных страниц
          </Body>
        </Box>

        <Box className='__links' display='flex' mb='24px'>
          <Box width='103px'>
            <Link to='/orders'>
              <Button fullWidth>Заказы</Button>
            </Link>
          </Box>

          <Box width='103px' mx='16px'>
            <Link to='/products'>
              <Button onClick={() => history.push('/products')} fullWidth>
                Товары
              </Button>
            </Link>
          </Box>

          <Box width='103px'>
            <Link to='/finance'>
              <Button onClick={() => history.push('/finance')} fullWidth>
                Финансы
              </Button>
            </Link>
          </Box>
        </Box>

        <NotFoundIllustration />
      </ErrorsWrapper>
    </NotFoundWrap>
  )
}

export { NotFound }
