import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { formatCurrency, formatPercents } from 'utils/textFormatting'

import {
  Box,
  Checkbox,
  Image,
  Switch,
  Table,
  Highlighter,
  Anchor,
  Typography,
  Pagination,
} from 'ui'

import {
  changeProductsLimit,
  changeProductsPage,
  disableProduct,
  enableProduct,
} from 'modules/products/slices/products'

import { env } from 'settings/env'

import type { Product } from 'modules/products/types'
import type { ColumnsType } from 'ui/table/types'
import type { CbxState } from 'modules/common/types/interfaces'
import type { ChangeEventHandler, FC } from 'react'

const { Caption } = Typography

interface Props {
  products: Product[]
  isFiltered: boolean
  onChangeCbx: (checkedId: string | number) => void
  cbxState: CbxState
  search: string
  onResetCbx: VoidFunction
}

const OnSaleTable: FC<Props> = ({
  products,
  isFiltered,
  cbxState,
  search,
  onChangeCbx,
  onResetCbx,
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const pagination = useAppSelector(state => state.products.products.pagination)

  const { lastSuccessfulUpload } = useAppSelector(
    state => state.products.productsFeed,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const handleChangeStatus: ChangeEventHandler<HTMLInputElement> = event => {
    const { name, checked } = event.currentTarget
    if (checked) {
      dispatch(enableProduct({ sku: name }))
    } else {
      dispatch(disableProduct({ sku: name }))
    }
  }

  const handleClickEmptyButton = () => {
    history.push('/products/upload/manual')
  }

  const handleOnRow = row => ({
    onClick: () => {
      onChangeCbx(row.sku)
    },
  })

  const handleChangePage = (page: number) => {
    dispatch(changeProductsPage(page))
    onResetCbx()
  }

  const handleChangeLimit = (limit: number) => {
    dispatch(changeProductsLimit(limit))
    onResetCbx()
  }

  const columns: ColumnsType<Product> = [
    {
      key: 'checkbox',
      dataIndex: 'sku',
      title: '',
      width: 52,
      render: sku => (
        <Checkbox
          id={sku}
          value={sku}
          onChange={() => onChangeCbx(sku)}
          checked={cbxState.checkedList.some(itemId => itemId === sku)}
        />
      ),
    },
    {
      key: 'image_url',
      dataIndex: 'image_url',
      width: 66,
      render: url => (
        <Box width='44px' height='44px'>
          <Image src={url} width='100%' height='100%' bgSize='contain' isBg />
        </Box>
      ),
    },
    {
      key: 'sku',
      dataIndex: 'sku',
      title: 'Артикул',
      width: 110,
    },
    {
      key: 'partner_sku',
      dataIndex: 'merchant_sku',
      title: 'Ваш Артикул',
      width: 130,
    },
    {
      key: 'name',
      dataIndex: ['title', 'ru'],
      title: 'Название',
      render: (name, row) => (
        <Anchor
          target='_blank'
          rel='noreferrer'
          href={`${env.CUSTOMER_BASE_URL}/product/${row.sku}`}
        >
          <Highlighter search={search}>{name}</Highlighter>
        </Anchor>
      ),
    },
    {
      key: 'category',
      dataIndex: ['category', 'title', 'ru'],
      width: 180,
      title: 'Категория',
    },
    {
      key: 'price',
      dataIndex: ['price', 'current'],
      title: 'Цена на Airba',
      align: 'right',
      width: 140,
      render: price => formatCurrency(price),
    },
    {
      key: 'commission',
      dataIndex: ['commissions', 'total'],
      title: 'Комиссия',
      align: 'right',
      width: 120,
      render: commission => (
        <Box display='grid'>
          {formatCurrency(commission?.value)}
          <Caption color='text.secondaryBlack'>
            {formatPercents(commission?.rate)}
          </Caption>
        </Box>
      ),
    },
    {
      key: 'status',
      dataIndex: 'is_enabled',
      title: 'Статус',
      width: 190,
      render: (status, row) => (
        <Box display='flex' justifyContent='space-between'>
          {status ? 'Продаётся' : 'Снят с продажи'}
          <Switch
            name={row.sku}
            checked={status}
            onChange={handleChangeStatus}
          />
        </Box>
      ),
    },
  ]

  const isTemporarily = !Boolean(lastSuccessfulUpload) && !isFiltered

  return (
    <Box>
      <Table
        rowKey='sku'
        onRow={handleOnRow}
        data={products}
        columns={columns}
        temporarilyEmpty={isTemporarily}
        emptyText={
          isTemporarily &&
          'Пока здесь ничего нет. Загрузите прайс–лист и начните продавать!'
        }
        emptyButton={
          isTemporarily && {
            text: 'ЗАГРУЗИТЬ ПРАЙС–ЛИСТ',
            onClick: handleClickEmptyButton,
          }
        }
      />
      <Pagination
        {...pagination}
        onPageChange={handleChangePage}
        onLimitChange={handleChangeLimit}
      />
    </Box>
  )
}

export { OnSaleTable }
