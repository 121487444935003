import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const VisibleOn: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.6569 9.07105C12.5327 5.94686 7.46734 5.94686 4.34315 9.07105L3.70711 9.70709C3.31658 10.0976 2.68342 10.0976 2.29289 9.70709C1.90237 9.31657 1.90237 8.6834 2.29289 8.29288L2.92893 7.65684C6.83418 3.75159 13.1658 3.75159 17.0711 7.65684L17.7071 8.29288C18.0976 8.6834 18.0976 9.31657 17.7071 9.70709C17.3166 10.0976 16.6834 10.0976 16.2929 9.70709L15.6569 9.07105ZM4.52482 12.4865C4.80843 12.0126 5.42252 11.8583 5.89642 12.1419C8.41427 13.6488 11.5857 13.6488 14.1036 12.1419C14.5775 11.8583 15.1916 12.0126 15.4752 12.4865C15.7588 12.9604 15.6045 13.5744 15.1306 13.8581C11.9804 15.7434 8.01961 15.7434 4.86936 13.8581C4.39546 13.5744 4.2412 12.9604 4.52482 12.4865Z'
      fill={color}
    />
    <path
      d='M12 9.99998C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 9.99998C8 8.89541 8.89543 7.99998 10 7.99998C11.1046 7.99998 12 8.89541 12 9.99998Z'
      fill={color}
    />
  </Icon>
)

VisibleOn.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { VisibleOn }
