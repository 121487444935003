import { css } from '@emotion/react'

export const getToastStyles = () => css`
  .rc-notification {
    position: fixed;
    z-index: 1000;
    top: 97px !important;
    transform: translateX(-50%);
  }

  .rc-notification-content {
    display: flex;
  }

  .rc-notification-content-cancel-button {
    cursor: pointer;
    margin: 0 32px 0 16px;

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  .rc-notification-notice {
    padding: 8px 16px;
    border-radius: 4px;
    background: #10142d;
    position: relative;
    margin: 10px 0;
    width: auto;
  }

  .rc-notification-notice-close {
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;

    &:hover {
      path {
        fill: #fcfcff;
      }
    }
  }

  .rc-notification-fade-appear,
  .rc-notification-fade-enter {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }

  .rc-notification-fade-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }

  .rc-notification-fade-appear.rc-notification-fade-appear-active,
  .rc-notification-fade-enter.rc-notification-fade-enter-active {
    animation-name: rcNotificationFadeIn;
    animation-play-state: running;
  }

  .rc-notification-fade-leave.rc-notification-fade-leave-active {
    animation-name: rcDialogFadeOut;
    animation-play-state: running;
  }

  @keyframes rcNotificationFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes rcDialogFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
