import { styled } from 'ui/theme'
import { layout } from 'styled-system'

import type { LayoutProps } from 'styled-system'

interface IconButtonProps {
  hoverBg?: string
}

const IconButton = styled.button<IconButtonProps & LayoutProps>`
  border-radius: ${props => props.theme.radii.r};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  ${layout};

  &:hover {
    background-color: ${props =>
      props.hoverBg || props.theme.greyColorPalette[5]};
  }
`

export { IconButton }
