import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Telegram: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path d='M15.4061 5.06612L4.33354 9.13963C3.57783 9.42884 3.58231 9.83089 4.1958 10.0102L6.95732 10.8323L8.01394 13.9222C8.14239 14.2604 8.07907 14.3946 8.4514 14.3946C8.73874 14.3946 8.8662 14.2696 9.02624 14.1205C9.12801 14.0255 9.73224 13.4652 10.4069 12.8395L13.2794 14.8638C13.808 15.142 14.1897 14.9979 14.3213 14.3956L16.2068 5.92029C16.3999 5.18206 15.9118 4.8472 15.4061 5.06612ZM7.39071 10.6434L13.6152 6.89766C13.9259 6.71791 14.2108 6.81455 13.9769 7.01261L8.64712 11.5994L8.4396 13.7106L7.39071 10.6434Z' />
  </Icon>
)

Telegram.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { Telegram }
