import TimePicker from 'rc-time-picker-date-fns'
import { Global } from '@emotion/react'

import { Typography, Condition } from 'ui'
import { ChevronIcon } from 'ui/icons'
import {
  getTimeSelectStyles,
  TimePickerWrap,
} from 'ui/inputs/TimeSelect/styles'
import { useAppTheme } from 'ui/theme'

import 'rc-time-picker/assets/index.css'

import type { FC } from 'react'
import type { TimeSelectProps } from 'ui/inputs/TimeSelect/types'

const { Caption } = Typography

const TimeSelect: FC<TimeSelectProps> = ({ value, onChange, label }) => {
  const theme = useAppTheme()
  return (
    <TimePickerWrap>
      <Condition match={Boolean(label)}>
        <Caption
          as='p'
          mb='4px'
          weight='semibold'
          color={theme.colors.projects.primaryMarket}
        >
          {label}
        </Caption>
      </Condition>
      <Global styles={getTimeSelectStyles} />
      <TimePicker
        value={value}
        showSecond={false}
        onChange={value => onChange(value)}
        inputIcon={<ChevronIcon color={theme.colors.icon.black} down />}
      />
    </TimePickerWrap>
  )
}

export { TimeSelect }
