import { Fragment } from 'react'

import { profileRoutes } from 'modules/profile/routes'
import { Search, Box, Typography, NavTabs } from 'ui'
import { Table } from './components'

import type { FC } from 'react'

const Employees: FC = () => (
  <Fragment>
    <Box pt='24px' px='24px' mb='24px'>
      <Typography.Title as='p' mb='16px'>
        Профиль
      </Typography.Title>

      <NavTabs routes={profileRoutes} />
    </Box>
    <Box>
      <Box width='75%' display='flex' alignItems='flex-end' px='24px'>
        <Box width='400px' mr='16px'>
          <Search placeholder='ФИО' />
        </Box>
      </Box>

      <Box mt='24px'>
        <Table />
      </Box>
    </Box>
  </Fragment>
)

export { Employees }
