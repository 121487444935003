import { forwardRef } from 'react'

import { useAppTheme } from 'ui/theme'
import { Text } from 'ui'

import type { FC, Ref } from 'react'
import type { TextProps } from 'ui/typography/Text/types'

interface CaptionProps extends TextProps {
  level?: 1 | 2
  weight?: 'regular' | 'semibold' | 'bold'
}

const Caption: FC<CaptionProps> = forwardRef(
  (
    { level = 1, color = 'text.black', weight = 'regular', children, ...props },
    ref,
  ) => {
    const theme = useAppTheme()

    const levels = {
      1: theme.fontSizes.caption.m,
      2: theme.fontSizes.caption.s,
    }

    return (
      <Text
        {...props}
        ref={ref as Ref<HTMLSpanElement>}
        color={color}
        fontSize={levels[level]}
        lineHeight={theme.lineHeights.xs}
        fontWeight={theme.fontWeights[weight]}
      >
        {children}
      </Text>
    )
  },
)

export { Caption }
