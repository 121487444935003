import { Redirect, Route } from 'react-router-dom'

import { Orders, OrderDetails } from 'modules/orders/pages'
import {
  OrderDetailsData,
  OrderDetailsHistory,
} from 'modules/orders/pages/OrderDetails/components'

import { getDashedStr } from 'utils/getDashedStr'

import { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  ORDERS = 'ORDERS',
  INFO = 'INFO',
  HISTORY = 'HISTORY',
  ORDER_DETAILS = 'ORDER_DETAILS',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/orders',
    exact: true,
    key: getDashedStr(key, KEYS.ORDERS),
    component: Orders,
  },
  {
    path: '/orders/:orderId',
    exact: false,
    key: getDashedStr(key, KEYS.ORDER_DETAILS),
    component: OrderDetails,
  },
]

export const orderDetailsRoutes: IRoute[] = [
  {
    path: '/orders/:orderId',
    exact: true,
    key: getDashedStr(KEYS.ORDER_DETAILS, KEYS.ORDER_DETAILS),
    component: () => (
      <Route
        render={({ match }) => (
          <Redirect to={`/orders/${match.params.orderId}/info`} />
        )}
      />
    ),
  },
  {
    path: '/orders/:orderId/info',
    exact: true,
    key: getDashedStr(KEYS.ORDER_DETAILS, KEYS.INFO),
    component: OrderDetailsData,
    nav: {
      name: 'Заказа',
    },
  },
  {
    path: '/orders/:orderId/history',
    exact: true,
    key: getDashedStr(KEYS.ORDER_DETAILS, KEYS.HISTORY),
    component: OrderDetailsHistory,
    nav: {
      name: 'История заказа',
    },
  },
]
