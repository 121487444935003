import {
  changeDateFilter,
  changeStatusFilter,
  changeTypeFilter,
} from 'modules/products/slices/productsFeed'
import { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Box, DatePickerInput, Select } from 'ui'

const { Option } = Select

const HistoryFilters: FC = () => {
  const dispatch = useAppDispatch()
  const {
    date: selectedDate,
    status: selectedStatus,
    type: selectedType,
  } = useAppSelector(state => state.products.productsFeed.uploadHistoryFilters)

  const { statuses, types } = useAppSelector(
    state => state.products.productsFeed,
  )

  useEffect(() => {
    if (!selectedStatus) {
      dispatch(changeStatusFilter(['', ...statuses.map(status => status.id)]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (!selectedType) {
      dispatch(changeTypeFilter(['', ...types.map(type => type.id)]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleChangeDate = (newDate: Date) => {
    dispatch(changeDateFilter(newDate))
  }

  const handleChangeStatus = (newStatus: string) => {
    dispatch(
      changeStatusFilter(
        newStatus === ''
          ? ['', ...statuses.map(status => status.id)]
          : newStatus,
      ),
    )
  }

  const handleChangeType = (newType: string) => {
    dispatch(
      changeTypeFilter(
        newType === '' ? ['', ...types.map(type => type.id)] : newType,
      ),
    )
  }

  return (
    <Box
      display='grid'
      gridAutoFlow='column'
      justifyContent='start'
      alignItems='center'
      gridColumnGap='16px'
      my='24px'
      ml='24px'
    >
      <Box minWidth='192px'>
        <DatePickerInput
          date={selectedDate}
          label='Дата загрузки'
          onChange={handleChangeDate}
        />
      </Box>

      <Box minWidth='296px'>
        <Select
          label='Статусы загрузки'
          value={selectedStatus}
          listHeight={400}
          dropdownMatchSelectWidth={296}
          onChange={handleChangeStatus}
        >
          <Option value=''>Все статусы загрузки</Option>
          {statuses.map(status => (
            <Option key={status.id} value={status.id}>
              {status.title}
            </Option>
          ))}
        </Select>
      </Box>

      <Box minWidth='296px'>
        <Select
          label='Типы загрузки'
          value={selectedType}
          listHeight={400}
          dropdownMatchSelectWidth={296}
          onChange={handleChangeType}
        >
          <Option value=''>Все типы загрузки</Option>
          {types.map(type => (
            <Option key={type.id} value={type.id}>
              {type.title}
            </Option>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export { HistoryFilters }
