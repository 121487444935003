import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchOrdersProfit } from 'modules/orders/slices/orders'
import { fetchTransactions, fetchBalance } from 'modules/finance/slices/finance'

export const useFinance = () => {
  const dispatch = useAppDispatch()
  const search = useAppSelector(state => state.finance.finance.filters.search)

  useEffect(() => {
    dispatch(fetchBalance())
    dispatch(fetchOrdersProfit())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchTransactions())
  }, [search, dispatch])
}
