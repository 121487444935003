import { FC } from 'react'

import { Divider } from 'ui'

import { useTableCbx } from 'ui/table/useTableCbx'

import { useCategories } from 'modules/products/hooks'
import { useProducts } from 'modules/products/hooks/useProducts'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  disableManyProducts,
  enableManyProducts,
  changeSearchFilter,
  changeCategoryFilter,
} from 'modules/products/slices/products'

import { OnSaleFilters, OnSaleActions, OnSaleTable } from './components'

const OnSaleTab: FC = () => {
  const dispatch = useAppDispatch()

  const { search, category: selectedCategory } = useAppSelector(
    state => state.products.products.filters,
  )

  const { products } = useProducts({
    search,
    category:
      typeof selectedCategory === 'string' ? selectedCategory : undefined,
  })

  const { categories } = useCategories()

  const {
    cbxState,
    onChangeCbx,
    onCheckAllCbxChange,
    onResetCbx,
    isAllSelected,
  } = useTableCbx(products, 'sku')

  const handleSearch = (text: string) => {
    onResetCbx()
    dispatch(changeSearchFilter(text))
  }

  const handleChangeCategory = (category: string) => {
    onResetCbx()
    dispatch(
      changeCategoryFilter(
        category === '' ? ['', ...categories.map(item => item.slug)] : category,
      ),
    )
  }

  const handleEnableMany = () => {
    onResetCbx()
    dispatch(enableManyProducts({ skus: cbxState.checkedList as string[] }))
  }

  const handleDisableMany = () => {
    onResetCbx()
    dispatch(disableManyProducts({ skus: cbxState.checkedList as string[] }))
  }

  const isFiltered = [search !== '', typeof selectedCategory === 'string'].some(
    condition => condition === true,
  )

  return (
    <>
      <OnSaleFilters
        search={search}
        onSearch={handleSearch}
        onChangeCategory={handleChangeCategory}
        categories={categories}
        selectedCategory={selectedCategory}
      />

      <Divider />

      <OnSaleActions
        isAllSelected={isAllSelected}
        cbxState={cbxState}
        onCheckAllCbxChange={onCheckAllCbxChange}
        onDisableMany={handleDisableMany}
        onEnableMany={handleEnableMany}
      />

      <Divider />

      <OnSaleTable
        products={products}
        isFiltered={isFiltered}
        onChangeCbx={onChangeCbx}
        cbxState={cbxState}
        onResetCbx={onResetCbx}
        search={search}
      />
    </>
  )
}

export { OnSaleTab }
