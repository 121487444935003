import { styled } from 'ui/theme'
import { LoadingProps } from 'ui/icons/Loading/types'

export const LoadingWrap = styled.div<LoadingProps>`
  width: 44px;
  height: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .lds-ellipsis {
    display: block;
    width: 32px;
    height: 100%;
    position: relative;
  }

  .lds-ellipsis div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-of-type(1) {
    left: 0;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-of-type(2) {
    left: 0;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-of-type(3) {
    left: 12px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-of-type(4) {
    left: 24px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }
`
