import { useState } from 'react'
import { useAppDispatch } from 'store/hooks'

import { matchNumber } from 'utils/textValidation'

import { TableOverlayButton } from 'ui/table/components'

import { CheckIcon, DeleteIcon, ReplayIcon, EditIcon } from 'ui/icons'
import { Box, Condition, Text, toast, Tooltip } from 'ui'
import { styled, useAppTheme } from 'ui/theme'

import { changeOrderProducts } from 'modules/orders/slices/orders'

import type { FC } from 'react'
import type { OrderProductsType } from 'modules/orders/types'
import type { CbxState } from 'modules/common/types/interfaces'

interface TableActionsProps {
  row?: OrderProductsType
  cbxState: CbxState
  onResetCbx: VoidFunction
  products: OrderProductsType[]
}

const Overlay = styled(Box)`
  .__quantity {
    height: 40px;
    overflow: hidden;
    border-radius: ${props => props.theme.radii.xxs};
    border: 1px solid ${props => props.theme.colors.projects.primaryMarket};
    border-right: none;
    display: flex;

    .__quantity-input {
      height: 100%;
      border: none;
      outline: none;
      padding: 8px 12px;
      width: 120px;
      font-size: 15px;
    }

    .__quantity-button {
      background-color: ${props => props.theme.colors.projects.primaryMarket};
      border: none;
      cursor: pointer;
      min-width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const TableActions: FC<TableActionsProps> = ({
  row,
  products,
  cbxState,
  onResetCbx,
}) => {
  const theme = useAppTheme()
  const dispatch = useAppDispatch()

  const [quantity, setQuantity] = useState('')
  const [isQuantityChange, setIsQuantityChange] = useState(false)

  const changeRemoveStatus = (remove: boolean) => {
    const arr = [...products]

    const onChangeStatus = (idx: number) => {
      arr[idx] = {
        ...arr[idx],
        removed: remove,
      }

      if (!remove && arr[idx].quantity === '0') {
        arr[idx] = {
          ...arr[idx],
          quantity: arr[idx].initial_quantity,
        }
      }
    }

    if (row) {
      const idx = arr.findIndex(item => item.sku === row.sku)
      onChangeStatus(idx)
    } else {
      products.forEach((product, idx) => {
        if (cbxState.checkedList.includes(product.sku)) {
          onChangeStatus(idx)
        }
      })

      onResetCbx()
    }

    dispatch(changeOrderProducts(arr))
  }

  const changeQuantity = () => {
    const arr = [...products]

    const onChangeQuantity = (idx: number, product: OrderProductsType) => {
      if (Number(quantity) === 0) {
        arr[idx] = {
          ...arr[idx],
          quantity,
          removed: true,
        }
      } else if (Number(quantity) <= Number(product.initial_quantity)) {
        arr[idx] = {
          ...arr[idx],
          quantity,
          removed: false,
        }
      }
    }

    if (quantity.length !== 0) {
      if (
        (row && Number(quantity) > Number(row.initial_quantity)) ||
        products.some(
          product => Number(quantity) > Number(product.initial_quantity),
        )
      ) {
        toast('Введенное количество товаров больше изначальной')
        return
      }

      if (row) {
        const idx = arr.findIndex(item => item.sku === row.sku)
        onChangeQuantity(idx, products[idx])
      } else {
        products.forEach((product, idx) => {
          if (cbxState.checkedList.includes(product.sku)) {
            onChangeQuantity(idx, product)
          }
        })

        onResetCbx()
      }

      toast('Количество товаров изменено')
      dispatch(changeOrderProducts(arr))
    }

    setQuantity('')
    setIsQuantityChange(false)
  }

  return (
    <Overlay padding={isQuantityChange ? '0 12px' : '0'}>
      {isQuantityChange ? (
        <Box className='__quantity' key='1'>
          <input
            className='__quantity-input'
            placeholder='Кол-во ед.'
            type='text'
            value={quantity}
            onInput={matchNumber}
            onChange={e => setQuantity(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                changeQuantity()
              }
            }}
          />
          <Tooltip overlay='Сохранить кол-во' placement='top'>
            <button className='__quantity-button' onClick={changeQuantity}>
              <CheckIcon color={theme.colors.icon.white} />
            </button>
          </Tooltip>
        </Box>
      ) : (
        <Box display='flex' key='2'>
          <Condition
            match={
              row ? !row.removed : products.some(product => !product.removed)
            }
          >
            <Tooltip overlay='Убрать' placement='top'>
              <TableOverlayButton
                onClick={() => changeRemoveStatus(true)}
                data-test-id='DeleteProductHoverButton'
              >
                <DeleteIcon color={theme.colors.icon.black} />
              </TableOverlayButton>
            </Tooltip>
          </Condition>
          <Condition match={!row?.removed}>
            <Tooltip
              overlay={
                <Text textAlign='center' as='p'>
                  Изменить <br />
                  количество
                </Text>
              }
              placement='top'
            >
              <TableOverlayButton
                onClick={() => setIsQuantityChange(true)}
                data-test-id='ChangeQtyHoverButton'
              >
                <EditIcon color={theme.colors.icon.black} />
              </TableOverlayButton>
            </Tooltip>
          </Condition>
          <Condition
            match={
              row ? row.removed : products.some(product => product.removed)
            }
          >
            <Tooltip overlay='Вернуть' placement='top'>
              <TableOverlayButton onClick={() => changeRemoveStatus(false)}>
                <ReplayIcon color={theme.colors.icon.black} />
              </TableOverlayButton>
            </Tooltip>
          </Condition>
        </Box>
      )}
    </Overlay>
  )
}

export { TableActions }
