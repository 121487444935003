import { FC } from 'react'
import { Box, Search, Select } from 'ui'

import { useAppDispatch } from 'store/hooks'
import {
  changeAvailableProductsCategoryFilter,
  changeAvailableProductsSearchFilter,
} from 'modules/installments/slices/products'

import { ALL_OPTIONS } from 'modules/common/constants/dictionaries'
import { useAvailableCategories } from 'modules/installments/hooks'
import { Installment } from 'modules/installments/types/types'

const { Option } = Select

interface Props {
  id: Installment['id']
  search: string
  selectedCategory: string
}

const AddProductsToInstallmentFilters: FC<Props> = ({
  id,
  search,
  selectedCategory,
}) => {
  const dispatch = useAppDispatch()

  const { availableCategories } = useAvailableCategories(id)

  const handleSearch = (text: string) => {
    dispatch(changeAvailableProductsSearchFilter(text))
  }

  const handleChangeCategory = (category: string) => {
    dispatch(changeAvailableProductsCategoryFilter(category))
  }

  return (
    <>
      <Box width='400px'>
        <Search
          placeholder='Название или артикул товара'
          value={search}
          onSearch={handleSearch}
        />
      </Box>

      <Box width='296px'>
        <Select
          label='Категории'
          value={
            selectedCategory === ALL_OPTIONS
              ? [ALL_OPTIONS, ...availableCategories.map(cat => cat.slug)]
              : selectedCategory
          }
          listHeight={400}
          dropdownMatchSelectWidth={296}
          onChange={handleChangeCategory}
        >
          <Option value={ALL_OPTIONS}>Все категории</Option>
          {availableCategories.map(category => (
            <Option key={category.slug} value={category.slug}>
              {category?.title?.ru}
            </Option>
          ))}
        </Select>
      </Box>
    </>
  )
}

export { AddProductsToInstallmentFilters }
