import { Faq } from 'modules/faq/pages'

import { getDashedStr } from 'utils/getDashedStr'
import { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  FAQ = 'FAQ',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/faq',
    exact: true,
    key: getDashedStr(key, KEYS.FAQ),
    component: () => <div></div>,
  },
  {
    path: '/faq/:slug',
    exact: true,
    key: getDashedStr(key, KEYS.FAQ),
    component: Faq,
  },
]
