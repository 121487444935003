import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchCategories } from '../slices/installments'

const useInstallmentCategories = () => {
  const { categories } = useAppSelector(
    state => state.installments.installments,
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])

  return { categories }
}

export { useInstallmentCategories }
