import { FC, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { formatPhoneNumber } from 'utils/textFormatting'

import { fetchEmployeesPermissions } from 'modules/profile/slices/employees'

import { Table as BaseTable, Box, Typography, Modal, Divider, Button } from 'ui'
import { styled, useAppTheme } from 'ui/theme'
import { QuestionIcon, ChevronIcon, BackIcon } from 'ui/icons'

import {
  EmployeePermissionType,
  EmployeeType,
  IsMultipleRolesType,
  RoleType,
} from 'modules/profile/types'
import { ColumnsType } from 'ui/table/types'

const { Body } = Typography

const RolesAndPermissionBox = styled(Box)`
  width: 167px;
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: #f2f2f2;
  }
`

const RoleBox = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`

const BackButton = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 11px 8px 24px;
`

const ScrollableBox = styled(Box)<IsMultipleRolesType>`
  height: ${props => props.isMultipleRoles ? '340px' : '388px'};
`

const Table: FC = () => {
  const { list, listOfPermissions } = useAppSelector(state => state.profile.employees)
  const [mRoles, setMRoles] = useState([])
  const [visible, setVisible] = useState(false)
  const [isDescription, setIsDescription] = useState(false)
  const [role, setRole] = useState<RoleType>({
    role: '',
    id: '',
  })
  const [permissions, setPermissions] = useState<EmployeePermissionType[]>([{
    permissions: [],
    role: ''
  }])
  const dispatch = useAppDispatch()
  const theme = useAppTheme()

  const columns: ColumnsType<EmployeeType> = [
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => `${row.surname} ${row.name} ${row.patronymic}`,
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: phone_number => formatPhoneNumber(phone_number),
    },
    {
      title: 'Роль',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles, row) => {
        return (
          <RolesAndPermissionBox
            onClick={() => {
              dispatch(fetchEmployeesPermissions({tdid: row.tdid}))
              setVisible(true)
              setMRoles(roles)
            }}
          >
            <QuestionIcon color='#319CF3' />
            <Body as='p' color='#319CF3' paddingLeft='4px'>
              Роли и доступы
            </Body>
          </RolesAndPermissionBox>
        )
      },
    },
  ]

  return (
    <>
      <BaseTable columns={columns} data={list} scroll={{ x: true }} />
      <Modal
        title='Роли и доступы'
        width={356}
        onClose={() => {
          setMRoles([])
          setVisible(false)
          setIsDescription(false)
          setRole({
            role: '',
            id: '',
          })
          setPermissions([{
            permissions: [],
            role: ''
          }])
        }}
        visible={visible}
        bodyStyle={{
          height: '452px',
          margin: '0 -16px',
        }}
      >
        {isDescription && (
          <BackButton onClick={() => setIsDescription(false)}>
            <BackIcon color={theme.colors.icon.black} />
            <Body weight='semibold' ml='8px'>
              Назад
            </Body>
          </BackButton>
        )}
        <Box pt='8px'>
          <Divider />
        </Box>
        <ScrollableBox isMultipleRoles={mRoles.length > 1 && isDescription} pl='16px'>
          {mRoles.length > 1 ? (
            isDescription ? (
              <Box overflowY='auto' height='340px'>
                <Body as='p' py='8px' weight='semibold'>
                  {role?.role}
                </Body>
                {permissions[0].permissions.map((desc, idx) => (
                    <Box
                      key={`desc${idx}`}
                      display='flex'
                      alignItems='top'
                      mb='6px'
                      pr='16px'
                    >
                      <Body as='p' pr='4px'>
                        {`${idx + 1}.`}
                      </Body>
                      <Body>{desc}</Body>
                    </Box>
                  ))}
              </Box>
            ) : (
              mRoles.map((key, idx) => (
                <RoleBox
                  key={idx}
                  onClick={() => {
                    setRole(key)
                    setIsDescription(true)
                    setPermissions(listOfPermissions.filter(permission => key.id === permission.role))
                  }}
                >
                  <Body>{key.role}</Body>
                  <Box pr='16px'>
                    <ChevronIcon right color={theme.colors.icon.black} />
                  </Box>
                </RoleBox>
              ))
            )
          ) : (
            <Box overflowY='auto' height='388px'>
              <Body as='p' py='8px' weight='semibold'>
                {mRoles[0]?.role}
              </Body>
              {listOfPermissions[0]?.permissions.map((desc, idx) => (
                <Box
                  key={`desc${idx}`}
                  display='flex'
                  alignItems='top'
                  mb='6px'
                  pr='16px'
                >
                  <Body as='p' pr='4px'>
                    {`${idx + 1}.`}
                  </Body>
                  <Body>{desc}</Body>
                </Box>
              ))}
            </Box>
          )}
        </ScrollableBox>
        <Divider />

        <Box height='72px' p='16px' borderRadius='0 0 8px 8px'>
          <Button
            fullWidth
            onClick={() => {
              setMRoles([])
              setVisible(false)
              setIsDescription(false)
              setRole({
                role: '',
                id: '',
              })
              setPermissions([{
                permissions: [],
                role: ''
              }])
            }}
          >
            Закрыть
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export { Table }
