import { Children, cloneElement } from 'react'

import TabsContentItem from 'ui/tabs/TabsGroup/TabsContent/TabsContentItem'

import type { ReactElement } from 'react'
import type { TabsContentProps } from 'ui/tabs/TabsGroup/types'

const TabsContent: TabsContentProps = ({ activeTab, children }) => (
  <>
    {children &&
      Children.map<ReactElement, any>(children, (child: ReactElement) =>
        cloneElement(child, {
          active: child.props.tabValue === activeTab,
        }),
      )}
  </>
)

TabsContent.Item = TabsContentItem

export { TabsContent }
