import { styled } from 'ui/theme'
import { Typography, Button as UIButton } from 'ui'

const { Body } = Typography

export const FileName = styled(Body)`
  overflow-wrap: break-word;
`

export const Button = styled(UIButton)`
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 19px rgba(0, 0, 0, 0.15);
  }

  &:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
`
