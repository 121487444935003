import { combineReducers } from 'redux'

import storage from 'redux-persist/lib/storage'

import faqReducer from 'modules/faq/slices'
import authReducer from 'modules/auth/slices'
import ordersReducer from 'modules/orders/slices'
import commonReducer from 'modules/common/slices'
import profileReducer from 'modules/profile/slices'
import financeReducer from 'modules/finance/slices'
import refundsReducer from 'modules/refunds/slices'
import productsReducer from 'modules/products/slices'
import installmentsReducer from 'modules/installments/slices'
import notificationsReducer from 'modules/notifications/slices'

const reducers = combineReducers({
  faq: faqReducer,
  auth: authReducer,
  orders: ordersReducer,
  common: commonReducer,
  profile: profileReducer,
  finance: financeReducer,
  refunds: refundsReducer,
  products: productsReducer,
  installments: installmentsReducer,
  notifications: notificationsReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'auth/credentials/clearAll') {
    storage.removeItem('persist:merchant_cabinet_credentials').then(r => r)

    return reducers(undefined, action)
  } else if (action.type === 'auth/credentials/clearAllData') {
    const emptyState = {
      ...state,
    }

    if (state) {
      Object.keys(state).forEach(key => {
        key === 'auth'
          ? (emptyState[key] = state.auth)
          : (emptyState[key] = undefined)
      })
    }

    return reducers(emptyState, action)
  }

  return reducers(state, action)
}
