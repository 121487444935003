import { styled } from 'ui/theme'
import type { TagsWrapProps } from 'ui/tags/types'

export const TagsWrap = styled.div<TagsWrapProps>`
  display: flex;
  flex-wrap: wrap;

  .__tags-item {
    margin-right: 8px;
    padding: ${props => (props.isWeekdays ? '8px' : '4px 12px')};
    box-sizing: border-box;
    width: ${props => (props.isWeekdays ? '40px' : '100%')};
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.1s;
    -moz-user-select: none;
    user-select: none;
    background: ${props => props.theme.colors.main.background.gray};
    font-weight: ${props => props.theme.fontWeights.semibold};
    font-weight: ${props =>
      props.isWeekdays
        ? props.theme.fontWeights.regular
        : props.theme.fontWeights.semibold};
    font-size: ${props => props.theme.fontSizes.body.m};
    line-height: ${props => props.theme.lineHeights.m};
    color: ${props => props.theme.colors.text.black};

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    &:not(&-active) {
      &:enabled {
        &:hover {
          background: ${props => props.theme.greyColorPalette[5]};
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    &-active {
      background: ${props => props.theme.colors.projects.primaryMarket};
      color: ${props => props.theme.colors.text.white};
    }
  }
`
