import { useLocation } from 'react-router-dom'
import { RenderRoutes } from 'modules/core/components'
import { Box, NavTabs } from 'ui'
import { ActiveFeedCard } from 'modules/products/pages/UploadProducts/components'

import { uploadRoutes } from 'modules/products/routes'

import type { FC } from 'react'

const UploadProducts: FC = () => {
  const { pathname } = useLocation()

  const isAuto = pathname === '/products/upload/automatic'
  const isHistory = pathname === '/products/upload/history'

  return (
    <Box display='flex'>
      <Box>
        <NavTabs mt='24px' ml='24px' routes={uploadRoutes} />

        <RenderRoutes routes={uploadRoutes} />
      </Box>

      {!isHistory && (
        <ActiveFeedCard
          download={!isAuto}
          pathname={
            isAuto ? '/templ/airba.xml' : '/download/template-to-upload.xlsx'
          }
          format={isAuto ? 'XML' : 'XLSX'}
        />
      )}
    </Box>
  )
}

export { UploadProducts }
