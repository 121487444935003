function match(event, reg) {
  if (event.target.value.search(reg) !== -1) {
    event.target.value = event.target.value.replace(reg, '')
  }
}

export const matchNumber = event => {
  const reg = /\D*/g
  match(event, reg)
}
