import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { Box, Button, FormField, Tooltip } from 'ui'

import { createPassword } from 'modules/auth/slices/auth'

import { validatePassword } from 'utils/passValidation'

import type { FC } from 'react'
import type { AuthInputError } from 'modules/auth/types/interfaces'

const NewPasswordForm: FC<AuthInputError> = ({ error, setErrorStatus }) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(state => state.auth.auth.loading)

  const [visibleMainPassTooltip, setVisibleMainPassTooltip] = useState(false)
  const [visibleRepPassTooltip, setVisibleRepPassTooltip] = useState(false)
  const [tooltipMessage, setTooltipMessage] = useState(null)
  const [mainPassError, setMainPassError] = useState(false)

  const [values, setValues] = useState({
    password: '',
    repeatedPassword: '',
  })

  const onSubmit = event => {
    event.preventDefault()
    matchPasswords()

    if (values.password === values.repeatedPassword && !mainPassError) {
      dispatch(
        createPassword({
          password: values.password,
        }),
      )

      setErrorStatus(false)
    } else {
      setErrorStatus(true)
    }
  }

  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const matchPasswords = () => {
    if (values.password.length !== 0 && values.repeatedPassword.length !== 0) {
      if (values.password !== values.repeatedPassword) {
        setErrorStatus(true)
        setVisibleRepPassTooltip(true)
      }
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Box mb='16px'>
        <Tooltip overlay={tooltipMessage} visible={visibleMainPassTooltip}>
          <FormField
            placeholder='Придумайте пароль'
            label='Придумайте пароль'
            type='password'
            name='password'
            size='large'
            value={values.password}
            error={error || mainPassError}
            onBlur={() => {
              setVisibleMainPassTooltip(false)
              matchPasswords()
            }}
            onFocus={() => {
              if (mainPassError) {
                setVisibleMainPassTooltip(true)
              }
            }}
            onChange={event => {
              if (event.target.value === values.repeatedPassword) {
                setErrorStatus(false)
                setVisibleRepPassTooltip(false)
              }

              onChange(event)

              validatePassword(
                event.target.value,
                setMainPassError,
                setTooltipMessage,
                setVisibleMainPassTooltip,
              )
            }}
          />
        </Tooltip>
      </Box>

      <Tooltip visible={visibleRepPassTooltip} overlay='Пароли не совпадают!'>
        <FormField
          placeholder='Подтвердите пароль'
          label='Подтвердите пароль'
          name='repeatedPassword'
          type='password'
          size='large'
          value={values.repeatedPassword}
          onBlur={matchPasswords}
          error={error}
          onChange={event => {
            if (event.target.value === values.password) {
              setErrorStatus(false)
              setVisibleRepPassTooltip(false)
            }

            onChange(event)
          }}
        />
      </Tooltip>

      <Button mt='24px' type='submit' loading={loading} fullWidth>
        Сохранить и войти
      </Button>
    </form>
  )
}

export { NewPasswordForm }
