import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import {
  changeTransactionsPage,
  changeTransactionsLimit,
} from 'modules/finance/slices/finance'

import { Highlighter, Pagination, Table, Box } from 'ui'
import { formatCurrency, formatDate } from 'utils/textFormatting'
import {
  TRANSACTION_TYPES,
  TRANSACTION_REASONS,
} from 'modules/common/constants/dictionaries'

import type { FC } from 'react'
import type { TransactionType } from 'modules/finance/types'
import type { ColumnsType } from 'ui/table/types'

const FinanceTable: FC = () => {
  const dispatch = useAppDispatch()
  const { pagination, filters, list } = useAppSelector(
    state => state.finance.finance,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const handlePageChange = (page: number) => {
    dispatch(changeTransactionsPage(page))
  }

  const handleChangeLimit = (limit: number) => {
    dispatch(changeTransactionsLimit(limit))
  }

  const columns: ColumnsType<TransactionType> = [
    {
      title: 'ID платежа',
      dataIndex: 'uuid',
      key: 'uuid',
      width: 170,
      render: uuid => <Highlighter search={filters.search}>{uuid}</Highlighter>,
    },
    {
      title: 'Дата и время платежа',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: date => formatDate(new Date(date), 'HH:mm, dd MMM yyyy г.'),
    },
    {
      title: 'Номер заказа',
      dataIndex: ['reason', 'id'],
      key: 'id',
      width: 150,
    },
    {
      title: 'Тип платежа',
      dataIndex: 'type',
      key: 'type',
      width: 170,
      render: type => TRANSACTION_TYPES[type],
    },
    {
      title: 'Основание для платежа',
      dataIndex: ['reason', 'type'],
      key: 'reason',
      width: 280,
      render: type => TRANSACTION_REASONS[type],
    },
    {
      title: 'Сумма',
      dataIndex: 'amount_in_cents',
      key: 'amount_in_cents',
      align: 'right',
      width: 115,
      render: formatCurrency,
    },
  ]

  return (
    <Box>
      <Table
        data={list}
        columns={columns}
        temporarilyEmpty={filters.search.length && !list.length}
        emptyText={
          !filters.search.length && !list.length && 'Пока здесь ничего нет'
        }
      />

      <Pagination
        {...pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleChangeLimit}
      />
    </Box>
  )
}

export { FinanceTable }
