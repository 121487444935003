import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchAvailableProducts } from '../slices/products'

const useAvailableProducts = (installmentId: string) => {
  const dispatch = useAppDispatch()
  const { availableProducts } = useAppSelector(
    state => state.installments.products,
  )

  const { search, category } = useAppSelector(
    state => state.installments.products.availableProductsFilters,
  )

  useEffect(() => {
    dispatch(fetchAvailableProducts({ installmentId }))
  }, [installmentId, search, category, dispatch])

  return { availableProducts }
}

export { useAvailableProducts }
