import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/hooks'

export const useAccountStatus = () => {
  const error = useAppSelector(state => state.auth.auth.error)
  const status = useAppSelector(state => state.auth.auth.accountStatus)

  const [errorStatus, setErrorStatus] = useState<boolean>(false)

  useEffect(() => {
    if (error) {
      setErrorStatus(true)
    }
  }, [error])

  return {
    status,
    errorStatus,
    setErrorStatus,
  }
}
