export enum ContactType {
  Link = 'link',
  Phone = 'phone',
  Email = 'email',
  Social = 'social_network',
}

export const enum Key {
  Space = 'Space',
  Enter = 'Enter',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  Home = 'Home',
  End = 'End',
}
