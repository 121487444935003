import { useAppSelector } from 'store/hooks'

import { formatCurrency } from 'utils/textFormatting'
import { Typography, Box } from 'ui'

import type { FC } from 'react'

const { Subtitle, Caption, Body } = Typography

const OrdersTitle: FC = () => {
  const profit = useAppSelector(state => state.orders.orders.profit)
  const sold = useAppSelector(state => state.finance.finance.balance.in_account)

  return (
    <Box display='flex' mb='16px'>
      <Box
        p='16px'
        mr='16px'
        width='272px'
        borderRadius='xxs'
        border='1px solid #ebebeb'
      >
        <Body as='p' weight='semibold'>
          Активные заказы
        </Body>

        <Subtitle as='p' my='4px'>
          {formatCurrency(profit)}
        </Subtitle>

        <Caption color='text.secondaryBlack'>
          Сумма по подтверждённым, но незавершённым заказам
        </Caption>
      </Box>

      <Box
        p='16px'
        width='272px'
        borderRadius='xxs'
        border='1px solid #ebebeb'
      >
        <Body as='p' weight='semibold'>
          Продано
        </Body>

        <Subtitle as='p' my='4px'>
          {formatCurrency(sold)}
        </Subtitle>

        <Caption color='text.secondaryBlack'>
          Сумма по всем проданным товарам, кроме возвратов и отменённых
        </Caption>
      </Box>
    </Box>
  )
}

export { OrdersTitle }
