import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Linkedin: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.7778 18C17.0053 18 18 17.0053 18 15.7778V4.22222C18 2.99468 17.0053 2 15.7778 2H4.22222C2.99512 2 2 2.99468 2 4.22222V15.7778C2 17.0053 2.99512 18 4.22222 18H15.7778ZM6.88889 7.77778H4.66667V15.3333H6.88889V7.77778ZM5.75868 6.88889H5.7711C6.46002 6.88889 6.88889 6.39421 6.88889 5.77734C6.87646 5.14665 6.46002 4.66667 5.78402 4.66667C5.10889 4.66667 4.66667 5.14665 4.66667 5.77734C4.66667 6.39464 5.09554 6.88889 5.75868 6.88889ZM13.1111 15.3333H15.3333L15.3329 11.0107C15.3329 8.77778 14.1405 7.77778 12.5502 7.77778C11.2667 7.77778 10.7649 8.44444 10.4444 8.94043V7.77778H8.22222V15.3333H10.4444V11.3333C10.4444 11.2962 10.4439 11.2529 10.4433 11.2059C10.4403 10.9772 10.4363 10.6592 10.4893 10.5302C10.6644 10.0955 11.0253 9.64578 11.6924 9.64578C12.5667 9.64578 13.1111 10.3124 13.1111 11.2893V15.3333Z'
    />
  </Icon>
)

Linkedin.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { Linkedin }
