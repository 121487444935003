import { useEffect } from 'react'
import { useAppDispatch } from 'store/hooks'

import { fetchDocuments, fetchProfile } from 'modules/profile/slices/profile'
import { fetchPickupPoints } from 'modules/profile/slices/pickupPoints'
import { fetchEmployees } from 'modules/profile/slices/employees'

export const useProfile = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPickupPoints())
    dispatch(fetchEmployees())
    dispatch(fetchDocuments())
    dispatch(fetchProfile())
  }, [dispatch])
}
