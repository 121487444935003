import { Fragment } from 'react'
import { useAppSelector } from 'store/hooks'

import { profileRoutes } from 'modules/profile/routes'

import { formatPhoneNumber } from 'utils/textFormatting'

import {
  Typography,
  Condition,
  Document,
  NavTabs,
  Anchor,
  Image,
  Box,
} from 'ui'

import profileImageScr from 'assets/images/profile.png'

import { ContactType } from 'modules/common/types'

import type { FC } from 'react'

const { Subtitle, Body } = Typography

const ProfileInfo: FC = () => {
  const contacts = useAppSelector(state => state.common.contacts.list)
  const { profile, documents } = useAppSelector(state => state.profile.profile)
  const { CITIES } = useAppSelector(state => state.common.cities)

  const email = contacts.find(contact => contact.type === ContactType.Email)
  const phone = contacts.find(contact => contact.type === ContactType.Phone)
  const cityName = CITIES[profile.company_info.address.city]?.title?.ru || ''

  return (
    <Fragment>
      <Box pt='24px' px='24px' mb='24px'>
        <Typography.Title as='p' mb='16px'>
          Профиль
        </Typography.Title>

        <NavTabs routes={profileRoutes} />
      </Box>
      {profile && (
        <Box px='24px' pb='24px'>
          <Box mb='24px'>
            <Subtitle as='p' mb='16px'>
              Личная информация
            </Subtitle>

            <Box display='flex'>
              <Image
                src={profile.logo_url || profileImageScr}
                borderRadius='r'
                height={88}
                width={88}
                isBg
              />

              <Box ml='16px'>
                <Subtitle as='p' mb='4px'>
                  {profile.store_name}
                </Subtitle>
                <Box display='flex' mb='4px'>
                  <Body as='p' mr='8px'>
                    {`${profile.contact_info?.first_name} ${profile.contact_info?.last_name}`}
                  </Body>
                  <Body>{formatPhoneNumber(profile.contact_info.phone)}</Body>
                </Box>
                <Body>{profile.company_info?.email}</Body>
              </Box>

              <Box ml='72px'>
                <Subtitle as='p' mb='4px'>
                  Контакт центр
                </Subtitle>
                <Body as='p' mb='4px'>
                  <Anchor color='text.black' href={phone?.value}>
                    {formatPhoneNumber(phone?.comment)}
                  </Anchor>
                </Body>
                <Anchor href={email?.value}>{email?.comment}</Anchor>
              </Box>
            </Box>
          </Box>

          <Box mb='24px'>
            <Subtitle as='p' mb='16px'>
              Юридические данные компании
            </Subtitle>

            <Box mb='8px' display='flex'>
              <Box width='192px' mr='16px'>
                <Body>Юридическое название компании</Body>
              </Box>
              <Box width='296px'>
                <Body>
                  {`${profile.company_info?.ownership} ${profile.company_info?.company_name}`}
                </Body>
              </Box>
            </Box>
            <Box mb='8px' display='flex'>
              <Box width='192px' mr='16px'>
                <Body>Форма собственности</Body>
              </Box>
              <Box width='296px'>
                <Body>
                  <Body weight='bold'>{`(${profile.company_info?.ownership})`}</Body>
                </Body>
              </Box>
            </Box>
            <Box mb='8px' display='flex'>
              <Box width='192px' mr='16px'>
                <Body>ФИО Директора</Body>
              </Box>
              <Box width='296px'>
                <Body>
                  {profile.company_info.last_name_ceo}{' '}
                  {profile.company_info.first_name_ceo}{' '}
                  {profile.company_info.patronymic_ceo}
                </Body>
              </Box>
            </Box>
            <Box mb='8px' display='flex'>
              <Box width='192px' mr='16px'>
                <Body>Юридический адрес</Body>
              </Box>
              <Box width='296px'>
                <Body>
                  {`${profile.company_info.address.country}, ${cityName}, ${profile.company_info.address.street}`}
                </Body>
              </Box>
            </Box>
            <Box display='flex'>
              <Box width='192px' mr='16px'>
                <Body>Индекс</Body>
              </Box>
              <Box width='296px'>
                <Body>{profile.company_info.address.index || '-'}</Body>
              </Box>
            </Box>
          </Box>

          <Box>
            <Subtitle as='p' mb='16px'>
              Банковские реквизиты
            </Subtitle>

            <Box display='flex' mb='8px'>
              <Body as='p' minWidth='127px' mr='16px'>
                ИИН/БИН
              </Body>
              <Body>{profile.company_info.payment_requisites.bin}</Body>
            </Box>
            <Box display='flex' mb='8px'>
              <Body as='p' minWidth='127px' mr='16px'>
                Кбе
              </Body>
              <Body>{profile.company_info.payment_requisites.kbe || '-'}</Body>
            </Box>
            <Box display='flex' mb='8px'>
              <Body as='p' minWidth='127px' mr='16px'>
                Номер счёта
              </Body>
              <Body>
                {profile.company_info.payment_requisites.account_bank_number ||
                  '-'}
              </Body>
            </Box>
            <Box display='flex'>
              <Body as='p' minWidth='127px' mr='16px'>
                БИК банка
              </Body>
              <Body>{profile.company_info.payment_requisites.bik || '-'}</Body>
            </Box>
          </Box>

          <Condition match={documents.length}>
            <Box mt='24px'>
              <Subtitle as='p' mb='16px'>
                Документы
              </Subtitle>

              <Box display='flex' flexWrap='wrap'>
                {documents.map(document => (
                  <Document
                    key={document.id}
                    link={document.link}
                    title={document.name}
                  />
                ))}
              </Box>
            </Box>
          </Condition>
        </Box>
      )}
    </Fragment>
  )
}

export { ProfileInfo }
