import { Box, TimeSelect } from 'ui'

import type { Dispatch, SetStateAction, FC } from 'react'
import type { ScheduleType } from 'modules/profile/types'

interface Props {
  setSchedule: Dispatch<SetStateAction<ScheduleType[]>>
  schedule: ScheduleType[]
  weekDaysNames: string[]
  weekdays: string[]
  timeRanges: {
    from: string | Date
    to: string | Date
  }[]
  index: number
  day: number
}

const UploadSchedule: FC<Props> = ({
  weekDaysNames,
  setSchedule,
  timeRanges,
  weekdays,
  schedule,
  index,
  day,
}) => {
  const changeTimeFrom = value => {
    let items = [...schedule]
    items[index] = {
      ...items[index],
      time_ranges: [
        {
          from: value,
          to: items[index].time_ranges.length && items[index].time_ranges[0].to,
        },
      ],
    }
    setSchedule(items)
  }

  const changeTimeTo = value => {
    let items = [...schedule]
    items[index] = {
      ...items[index],
      time_ranges: [
        {
          from:
            items[index].time_ranges.length && items[index].time_ranges[0].from,
          to: value,
        },
      ],
    }
    setSchedule(items)
  }

  return (
    <>
      {weekdays.includes(`${day}`) && (
        <Box mt='16px' key={`${weekDaysNames[+day - 1]}-${day}`}>
          {weekDaysNames[+day - 1]}
          <Box width='320px' display='flex' justifyContent='space-between'>
            <TimeSelect
              value={timeRanges.length && (timeRanges[0].from as Date)}
              onChange={changeTimeFrom}
              label='Время открытия'
            />
            <TimeSelect
              value={timeRanges.length && (timeRanges[0].to as Date)}
              onChange={changeTimeTo}
              label='Время закрытия'
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export { UploadSchedule }
