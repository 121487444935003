import { useAppDispatch, useAppSelector } from 'store/hooks'
import { changeRefundsSorting } from '../slices/refunds'

import type { Sorting } from 'modules/refunds/types'

const useSorting = () => {
  const dispatch = useAppDispatch()
  const { sorting } = useAppSelector(state => state.refunds.refunds)

  const handleChangeSorting = (sorting: Sorting) => {
    dispatch(changeRefundsSorting(sorting))
  }

  return { sorting, onChangeSorting: handleChangeSorting }
}

export { useSorting }
