import { FC } from 'react'

import { OldLogo } from 'ui/icons'
import { Text } from 'ui'

import { useAppTheme, styled } from 'ui/theme'

const LogoPartnerWrap = styled.div`
  display: flex;
  align-items: center;

  .__auth-logo-text {
    background: ${props => props.theme.colors.main.brand.gradientCold};
    border-left: 1px solid ${props => props.theme.greyColorPalette[25]};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    padding-left: 12px;
    margin-left: 16px;
  }
`

const LogoPartner: FC = () => {
  const theme = useAppTheme()

  return (
    <LogoPartnerWrap>
      <OldLogo />

      <Text
        as='p'
        className='__auth-logo-text'
        fontSize={theme.fontSizes.subtitle.s}
        fontWeight={theme.fontWeights.medium}
      >
        Partner
      </Text>
    </LogoPartnerWrap>
  )
}

export { LogoPartner }
