import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { instance, AppThunk } from 'store/configureStore'
import { thunkErrorHandler } from 'utils/errorHandler'

import { toast } from 'ui/toast'
import { ALL_OPTIONS } from 'modules/common/constants/dictionaries'
import { removeEmptyStrings } from 'utils/removeEmptyStrings'

import { endOfMonth, formatISO, startOfMonth } from 'date-fns'

import {
  InstallmentProductsState,
  FetchAvailableProductsOptions,
  AddProductsToInstallmentOptions,
  ChangeProductsWithInstallmentPage,
  ChangeAvailableProductsPageOptions,
  RemoveProductsFromInstallmentOptions,
  ChangeAvailableProductsLimitOptions,
  ChangeProductsWithInstallmentLimit,
} from '../types/interfaces'
import { Product, ProductCategory } from 'modules/products/types'

/**
 * Initial State
 */

const initialState: InstallmentProductsState = {
  availableProducts: [],
  availableProductsLoading: false,
  availableProductsError: null,
  availableProductsPagination: {
    page: 1,
    limit: 20,
    total: null,
  },
  availableProductsFilters: {
    search: '',
    category: ALL_OPTIONS,
  },

  availableCategories: [],
  availableCategoriesLoading: false,
  availableCategoriesError: null,

  withInstallmentProducts: [],
  withInstallmentProductsLoading: false,
  withInstallmentProductsError: null,
  withInstallmentProductsPagination: {
    page: 1,
    limit: 20,
    total: null,
  },
  withInstallmentProductsFilters: {
    category: ALL_OPTIONS,
    month: null,
  },

  withInstallmentCategories: [],
  withInstallmentCategoriesLoading: false,
  withInstallmentCategoriesError: null,

  addProductLoading: false,
  addProductError: null,

  removeProductLoading: false,
  removeProductError: null,
}

/**
 * Async Actions
 */

export const fetchAvailableProducts: AppThunk<
  Product[],
  FetchAvailableProductsOptions
> = createAsyncThunk(
  'installments/availableProducts/fetch',
  (options, thunkApi) => {
    const { installmentId } = options

    const { page, limit } =
      thunkApi.getState().installments.products.availableProductsPagination

    const { search, category } =
      thunkApi.getState().installments.products.availableProductsFilters

    const params = {
      page,
      limit,
      search,
      category: category !== ALL_OPTIONS ? category : undefined,
    }

    return instance(thunkApi)
      .get(`/storefront/api/v1/merchants/installments/${installmentId}`, {
        params: removeEmptyStrings(params),
      })
      .then(res => {
        thunkApi.dispatch(changeAvailableProductsTotal(res.data.total))
        return res.data.products
      })
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const fetchAvailableProductsCategories: AppThunk<
  ProductCategory[],
  FetchAvailableProductsOptions
> = createAsyncThunk(
  'installments/availableProductsCategories/fetch',
  (options, thunkApi) => {
    const { installmentId } = options

    return instance(thunkApi)
      .get(`/storefront/api/v1/merchants/installments/${installmentId}`)
      .then(res => {
        thunkApi.dispatch(changeAvailableProductsTotal(res.data.total))
        return res.data.filters?.categories || []
      })
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const fetchWithInstallmentProducts: AppThunk<Product[]> =
  createAsyncThunk(
    'installment/withInstallmentProductsCategories/fetch',
    (_, thunkApi) => {
      const { limit, page } =
        thunkApi.getState().installments.products
          .withInstallmentProductsPagination

      const { category, month } =
        thunkApi.getState().installments.products.withInstallmentProductsFilters

      const config = {
        params: {
          category: category !== ALL_OPTIONS ? category : undefined,
          limit,
          page,
          start_at:
            month !== null
              ? formatISO(startOfMonth(new Date().setMonth(month)))
              : undefined,
          end_at:
            month !== null
              ? formatISO(endOfMonth(new Date().setMonth(month)))
              : undefined,
          with_installment: true,
        },
      }

      return instance(thunkApi)
        .get('/storefront/api/v1/merchants/products-info', config)
        .then(res => {
          thunkApi.dispatch(changeWithInstallmentProductsTotal(res.data.total))
          return res.data.products.map(product => ({
            ...product,
            key: product.sku,
          }))
        })
        .catch(thunkErrorHandler(thunkApi))
    },
  )

export const fetchWithInstallmentCategories: AppThunk<ProductCategory[]> =
  createAsyncThunk(
    'installment/withInstallmentCategories/fetch',
    (_, thunkApi) => {
      const config = {
        params: {
          limit: 1,
          page: 1,
          with_installment: true,
        },
      }

      return instance(thunkApi)
        .get('/storefront/api/v1/merchants/products-info', config)
        .then(res => {
          thunkApi.dispatch(changeWithInstallmentProductsTotal(res.data.total))
          return res.data.filters?.categories || []
        })
        .catch(thunkErrorHandler(thunkApi))
    },
  )

export const addProductsToInstallment: AppThunk<
  void,
  AddProductsToInstallmentOptions
> = createAsyncThunk('installments/products/add', (options, thunkApi) => {
  const { skus, installmentId } = options

  const body = { skus }

  return instance(thunkApi)
    .put(`/storefront/api/v1/merchants/installments/${installmentId}`, body)
    .then(() => {
      thunkApi.dispatch(
        asyncChangeAvailableProductsPage({ installmentId, page: 1 }),
      )
      thunkApi.dispatch(fetchAvailableProductsCategories({ installmentId }))

      toast('Товары добавлены в акцию')
    })
    .catch(thunkErrorHandler(thunkApi))
})

export const removeProductsFromInstallment: AppThunk<
  void,
  RemoveProductsFromInstallmentOptions
> = createAsyncThunk('installments/products/remove', (options, thunkApi) => {
  const { skus } = options

  const data = { skus }

  return instance(thunkApi)
    .delete('/storefront/api/v1/merchants/installments', { data })
    .then(() => {
      thunkApi.dispatch(asyncChangeProductsWithInstallmentPage({ page: 1 }))
      thunkApi.dispatch(fetchWithInstallmentCategories())
      toast('Товары убраны с акции')
    })
    .catch(thunkErrorHandler(thunkApi))
})

export const asyncChangeAvailableProductsPage: AppThunk<
  void,
  ChangeAvailableProductsPageOptions
> = createAsyncThunk(
  'installments/products/changePage',
  (options, thunkApi) => {
    const { page, installmentId } = options

    thunkApi.dispatch(changeAvailableProductsPage(page))
    thunkApi.dispatch(fetchAvailableProducts({ installmentId }))
  },
)

export const asyncChangeAvailableProductsLimit: AppThunk<
  void,
  ChangeAvailableProductsLimitOptions
> = createAsyncThunk(
  'installments/products/changeLimit',
  (options, thunkApi) => {
    const { limit, installmentId } = options

    thunkApi.dispatch(changeAvailableProductsLimit(limit))
    thunkApi.dispatch(fetchAvailableProducts({ installmentId }))
  },
)

export const asyncChangeProductsWithInstallmentPage: AppThunk<
  void,
  ChangeProductsWithInstallmentPage
> = createAsyncThunk(
  'installments/products/changePage',
  (options, thunkApi) => {
    const { page } = options

    thunkApi.dispatch(changeWithInstallmentProductsPage(page))
    thunkApi.dispatch(fetchWithInstallmentProducts())
  },
)

export const asyncChangeProductsWithInstallmentLimit: AppThunk<
  void,
  ChangeProductsWithInstallmentLimit
> = createAsyncThunk(
  'installments/products/changeLimit',
  (options, thunkApi) => {
    const { limit } = options

    thunkApi.dispatch(changeWithInstallmentProductsLimit(limit))
    thunkApi.dispatch(fetchWithInstallmentProducts())
  },
)

/**
 * Slice
 */

const productsSlice = createSlice({
  name: 'installments/products',
  initialState,
  reducers: {
    /** Available */
    changeAvailableProductsSearchFilter: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.availableProductsFilters.search = action.payload
    },
    changeAvailableProductsCategoryFilter: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.availableProductsPagination.page = 1
      state.availableProductsFilters.category = action.payload
    },
    changeAvailableProductsTotal: (state, action: PayloadAction<number>) => {
      state.availableProductsPagination.total = action.payload
    },
    changeAvailableProductsPage: (state, action: PayloadAction<number>) => {
      state.availableProductsPagination.page = action.payload
    },
    changeAvailableProductsLimit: (state, action: PayloadAction<number>) => {
      state.availableProductsPagination.limit = action.payload
    },
    /** With Installment */
    changeWithInstallmentProductsCategoryFilter: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.withInstallmentProductsPagination.page = 1
      state.withInstallmentProductsFilters.category = action.payload
    },
    changeWithInstallmentProductsMonthFilter: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.withInstallmentProductsPagination.page = 1
      state.withInstallmentProductsFilters.month = action.payload
    },
    changeWithInstallmentProductsTotal: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.withInstallmentProductsPagination.total = action.payload
    },
    changeWithInstallmentProductsPage: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.withInstallmentProductsPagination.page = action.payload
    },
    changeWithInstallmentProductsLimit: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.withInstallmentProductsPagination.limit = action.payload
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchAvailableProducts.pending, state => {
        state.availableProductsLoading = true
        state.availableProductsError = null
      })
      .addCase(fetchAvailableProducts.fulfilled, (state, { payload }) => {
        state.availableProducts = payload
        state.availableProductsLoading = false
        state.availableProductsError = null
      })
      .addCase(fetchAvailableProducts.rejected, (state, { payload }) => {
        state.availableProductsLoading = false
        state.availableProductsError = payload.error
      })

      .addCase(fetchAvailableProductsCategories.pending, state => {
        state.availableCategoriesLoading = true
        state.availableCategoriesError = null
      })
      .addCase(
        fetchAvailableProductsCategories.fulfilled,
        (state, { payload }) => {
          state.availableCategories = payload
          state.availableCategoriesLoading = false
          state.availableCategoriesError = null
        },
      )
      .addCase(
        fetchAvailableProductsCategories.rejected,
        (state, { payload }) => {
          state.availableCategoriesLoading = false
          state.availableCategoriesError = payload.error
        },
      )

      .addCase(fetchWithInstallmentProducts.pending, state => {
        state.withInstallmentProductsLoading = true
        state.withInstallmentProductsError = null
      })
      .addCase(fetchWithInstallmentProducts.fulfilled, (state, { payload }) => {
        state.withInstallmentProducts = payload
        state.withInstallmentProductsLoading = false
        state.withInstallmentProductsError = null
      })
      .addCase(fetchWithInstallmentProducts.rejected, (state, { payload }) => {
        state.withInstallmentProductsLoading = false
        state.withInstallmentProductsError = payload.error
      })

      .addCase(fetchWithInstallmentCategories.pending, state => {
        state.withInstallmentCategoriesLoading = true
        state.withInstallmentCategoriesError = null
      })
      .addCase(
        fetchWithInstallmentCategories.fulfilled,
        (state, { payload }) => {
          state.withInstallmentCategories = payload
          state.withInstallmentCategoriesLoading = false
          state.withInstallmentCategoriesError = null
        },
      )
      .addCase(
        fetchWithInstallmentCategories.rejected,
        (state, { payload }) => {
          state.withInstallmentCategoriesLoading = false
          state.withInstallmentCategoriesError = payload.error
        },
      )

      .addCase(addProductsToInstallment.pending, state => {
        state.addProductLoading = true
        state.addProductError = null
      })
      .addCase(addProductsToInstallment.fulfilled, state => {
        state.addProductLoading = false
        state.addProductError = null
      })
      .addCase(addProductsToInstallment.rejected, (state, { payload }) => {
        state.addProductLoading = false
        state.addProductError = payload.error
      })

      .addCase(removeProductsFromInstallment.pending, state => {
        state.removeProductLoading = true
        state.removeProductError = null
      })
      .addCase(removeProductsFromInstallment.fulfilled, state => {
        state.removeProductLoading = false
        state.removeProductError = null
      })
      .addCase(removeProductsFromInstallment.rejected, (state, { payload }) => {
        state.removeProductLoading = false
        state.removeProductError = payload.error
      }),
})

export const {
  changeAvailableProductsCategoryFilter,
  changeAvailableProductsSearchFilter,
  changeAvailableProductsTotal,
  changeAvailableProductsPage,
  changeAvailableProductsLimit,

  changeWithInstallmentProductsCategoryFilter,
  changeWithInstallmentProductsMonthFilter,
  changeWithInstallmentProductsTotal,
  changeWithInstallmentProductsPage,
  changeWithInstallmentProductsLimit,
} = productsSlice.actions

export default productsSlice.reducer
