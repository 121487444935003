import { Redirect } from 'react-router-dom'
import { Profile } from 'modules/profile/pages/Profile'
import {
  Settings,
  Employees,
  ProfileInfo,
  PickupPoints,
  CreatePickupPoints,
} from 'modules/profile/pages/Profile/components'

import { getDashedStr } from 'utils/getDashedStr'

import { Button } from 'ui'

import { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  INFO = 'INFO',
  PROFILE = 'PROFILE',
  SETTINGS = 'SETTINGS',
  REDIRECT = 'REDIRECT',
  EMPLOYEES = 'EMPLOYEES',
  PICKUP_POINTS = 'PICKUP_POINTS',
  CREATE_PICKUP_POINTS = 'CREATE_PICKUP_POINTS',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/profile',
    exact: false,
    key: getDashedStr(key, KEYS.PROFILE),
    component: Profile,
  },
]

export const profileRoutes: IRoute[] = [
  {
    path: '/profile',
    exact: true,
    key: getDashedStr(KEYS.PROFILE, KEYS.REDIRECT),
    component: () => <Redirect to='/profile/info' />,
  },
  {
    path: '/profile/info',
    exact: true,
    key: getDashedStr(KEYS.PROFILE, KEYS.INFO),
    component: ProfileInfo,
    nav: {
      name: 'Профиль',
    },
  },
  {
    path: '/profile/pickup-points',
    exact: true,
    key: getDashedStr(KEYS.PROFILE, KEYS.PICKUP_POINTS),
    component: PickupPoints,
    nav: {
      name: 'Точки выдачи',
    },
    isButton: {
      path: '/profile/pickup-points',
      button: history => (
        <Button
          style={{ position: 'absolute', right: '67px' }}
          onClick={() => history.push('/profile/pickup-points/create')}
        >
          добавить точку выдачи
        </Button>
      ),
    },
  },
  {
    path: '/profile/pickup-points/:id',
    exact: true,
    key: getDashedStr(KEYS.PROFILE, KEYS.CREATE_PICKUP_POINTS),
    component: CreatePickupPoints,
  },
  {
    path: '/profile/employees',
    exact: true,
    key: getDashedStr(KEYS.PROFILE, KEYS.EMPLOYEES),
    component: Employees,
    nav: {
      name: 'Сотрудники',
    },
  },
  {
    path: '/profile/settings',
    exact: true,
    key: getDashedStr(KEYS.PROFILE, KEYS.SETTINGS),
    component: Settings,
    nav: {
      name: 'Настройки',
    },
  },
]
