import { useAppDispatch, useAppSelector } from 'store/hooks'

import {
  changeAccountStatus,
  changeDeniedStatus,
  partnerSignIn,
} from 'modules/auth/slices/auth'

import { toast } from 'ui'

import { ACCOUNT_STATUS } from 'modules/auth/types/enums'
import { PartnerType } from 'modules/auth/types'

export const usePartnerSelection = () => {
  const dispatch = useAppDispatch()

  const { user_type, partners } = useAppSelector(state => state.auth.auth)

  const scrollable = partners.length > 3

  const onSelect = (partner: PartnerType) => () => {
    if (partner.is_confirmed) {
      if (user_type === 'fast') {
        dispatch(changeAccountStatus(ACCOUNT_STATUS.NO_PASSWORD))
        dispatch(changeDeniedStatus(true))
      }

      dispatch(partnerSignIn({ mid: partner.mid }))
    } else {
      toast('Партнер не подтвержден')
    }
  }

  return {
    onSelectPartner: onSelect,
    scrollable,
    partners,
  }
}
