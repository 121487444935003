import { Fragment } from 'react'

import { DatePickerInputWrap } from 'ui/datePicker/styles'
import { CalendarIcon, CloseIcon } from 'ui/icons'
import { Condition, Typography } from 'ui'
import { useAppTheme } from 'ui/theme'

import type { FC } from 'react'
import type { DatePickerInputProps } from 'ui/datePicker/types'

interface Props extends DatePickerInputProps {
  value: string
  visible: boolean
  showClear: boolean
  onClear: VoidFunction
}

const BaseInputPicker: FC<Props> = ({
  size,
  value,
  label,
  visible,
  subText,
  showClear,
  onClear,
}) => {
  const theme = useAppTheme()

  return (
    <Fragment>
      <Condition match={Boolean(label)}>
        <Typography.Caption
          as='p'
          mb='4px'
          weight='semibold'
          color={theme.colors.projects.primaryMarket}
        >
          {label}
        </Typography.Caption>
      </Condition>

      <DatePickerInputWrap active={visible} size={size}>
        {value}

        {showClear ? (
          <CloseIcon
            className='__field-icon'
            color={theme.colors.icon.secondaryBlack}
            onClick={e => {
              e.stopPropagation()

              onClear()
            }}
          />
        ) : (
          <CalendarIcon
            className='__field-icon'
            color={theme.colors.icon.black}
          />
        )}
      </DatePickerInputWrap>

      <Condition match={Boolean(subText)}>
        <Typography.Caption
          as='p'
          mt='8px'
          weight='semibold'
          color={theme.colors.text.secondaryBlack}
        >
          {subText}
        </Typography.Caption>
      </Condition>
    </Fragment>
  )
}

export { BaseInputPicker }
