import { DividerProps } from 'ui/divider/types'
import { Line } from 'ui/divider/styles'

import type { FC } from 'react'

const Divider: FC<DividerProps> = ({ pageWidth }) => (
  <Line pageWidth={pageWidth} />
)

export { Divider }
