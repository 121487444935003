import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { Box, Search, Select } from 'ui'

import {
  allRefundReasons,
  allRefundTypes,
} from 'modules/refunds/pages/Refunds/filters'

import { changeSearchFilter } from 'modules/refunds/slices/refunds'

import type { FC } from 'react'

const { Option } = Select

const RefundsActions: FC = () => {
  const dispatch = useAppDispatch()

  const [selectedRefundReason, setSelectedRefundReason] =
    useState(allRefundReasons)
  const [selectedRefundType, setSelectedRefundType] = useState(allRefundTypes)

  const { search } = useAppSelector(state => state.refunds.refunds.filters)

  const handleSearch = (text: string) => {
    dispatch(changeSearchFilter(text))
  }

  return (
    <Box display='flex' alignItems='end' mt='24px' mb='16px' ml='24px'>
      <Box width='296px'>
        <Search
          placeholder='Номер заказа'
          value={search}
          onSearch={handleSearch}
        />
      </Box>

      <Box width='296px' ml='16px'>
        <Select
          label='Статусы заявки'
          placeholder='Выберите статус'
          value={selectedRefundReason}
          listHeight={352}
          onChange={value =>
            setSelectedRefundReason(value === 'all' ? allRefundReasons : value)
          }
        >
          <Option value='all'>Все причины возврата</Option>
          <Option value='defects'>Брак</Option>waiting
          <Option value='changed_mind'>Передумал покупать</Option>
          <Option value='damaged'>Повреждена упакова</Option>
          <Option value='quality'>Не устраивает качество</Option>
          <Option value='other'>Другое</Option>
        </Select>
      </Box>

      <Box width='296px' ml='16px'>
        <Select
          label='Статус заказа'
          placeholder='Выберите статус'
          value={selectedRefundType}
          onChange={value =>
            setSelectedRefundType(value === 'all' ? allRefundTypes : value)
          }
        >
          <Option value='all'>Все статусы</Option>
          <Option value='full'>Полный</Option>
          <Option value='partial'>Частичный</Option>
        </Select>
      </Box>
    </Box>
  )
}

export { RefundsActions }
