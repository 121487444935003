import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { Table } from 'modules/orders/pages/Orders/components'
import { Pagination, Box } from 'ui'

import {
  changeOrdersPage,
  changeOrdersLimit,
} from 'modules/orders/slices/orders'

import type { FC } from 'react'

const OrdersTable: FC = () => {
  const dispatch = useAppDispatch()
  const pagination = useAppSelector(state => state.orders.orders.pagination)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const handlePageChange = (page: number) => {
    dispatch(changeOrdersPage(page))
  }

  const handleLimitChange = (limit: number) => {
    dispatch(changeOrdersLimit(limit))
  }

  return (
    <Box>
      <Table />
      <Pagination
        {...pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </Box>
  )
}

export { OrdersTable }
