import { useState } from 'react'

export const usePackagesModal = () => {
  const [packagesModal, setPackagesModal] = useState({
    visible: false,
    orderId: null,
  })

  const onClose = () => {
    setPackagesModal({
      visible: false,
      orderId: null,
    })
  }

  const onShow = (orderId: string) => {
    setPackagesModal({
      visible: true,
      orderId,
    })
  }

  return {
    visible: packagesModal.visible,
    orderId: packagesModal.orderId,
    onClose,
    onShow,
  }
}
