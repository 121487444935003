import { FC } from 'react'

import { Typography } from 'ui'
import { Tab } from 'ui/tabs/styles'

import { TabsHeadItemProps } from 'ui/tabs/TabsGroup/types'

const TabsHeadItem: FC<TabsHeadItemProps> = ({
  active,
  onClick,
  children,
  fullWidth = false,
}) => (
  <Tab active={active} onClick={onClick} fullWidth={fullWidth}>
    <Typography.Body weight='semibold'>{children}</Typography.Body>
  </Tab>
)

export default TabsHeadItem
