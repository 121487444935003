import { Fragment } from 'react'

import { useOrders } from 'modules/orders/hooks/useOrders'

import {
  OrdersActions,
  // OrdersTitle,
  OrdersTable,
} from 'modules/orders/pages/Orders/components'

import { Box } from 'ui'

import type { FC } from 'react'

const Orders: FC = () => {
  useOrders()

  return (
    <Fragment>
      <Box pt='24px' px='24px'>
        {/*<OrdersTitle />*/}
        <OrdersActions />
      </Box>
      <OrdersTable />
    </Fragment>
  )
}

export { Orders }
