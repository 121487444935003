import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Profile: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2939 9.566C14.0422 8.76241 14.5 7.68467 14.5 6.5C14.5 4.01472 12.4853 2 10 2C7.51474 2 5.50002 4.01472 5.50002 6.5C5.50002 7.68467 5.9578 8.76241 6.70612 9.566C5.51823 10.1857 4.59611 11.2737 4.20961 12.6264L3.76691 14.1758C3.21936 16.0923 4.65835 18 6.65148 18H13.3486C15.3417 18 16.7807 16.0923 16.2331 14.1758L15.7904 12.6264C15.4039 11.2737 14.4818 10.1857 13.2939 9.566ZM7.50002 6.5C7.50002 5.11929 8.61931 4 10 4C11.3807 4 12.5 5.11929 12.5 6.5C12.5 7.88071 11.3807 9 10 9C8.61931 9 7.50002 7.88071 7.50002 6.5ZM9.01723 11C7.67779 11 6.50063 11.8879 6.13266 13.1758L5.68996 14.7253C5.50744 15.3641 5.9871 16 6.65148 16H13.3486C14.0129 16 14.4926 15.3641 14.3101 14.7253L13.8674 13.1758C13.4994 11.8879 12.3223 11 10.9828 11H9.01723Z'
      fill={color}
    />
  </Icon>
)

Profile.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Profile }
