import { useEffect, useState } from 'react'

import { formatDate } from 'utils/textFormatting'

import { BaseInputPicker } from 'ui/datePicker/BaseInputPicker'
import { DatePicker, Dropdown } from 'ui'

import type { FC } from 'react'
import type {
  DatePickerOverlayProps,
  DatePickerInputProps,
} from 'ui/datePicker/types'

const DatePickerInput: FC<DatePickerInputProps & DatePickerOverlayProps> = ({
  size = 'default',
  label,
  subText,
  ...props
}) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (props.date) {
      setVisible(false)
    }
  }, [props.date])

  const value = props.date
    ? formatDate(props.date as Date, 'dd MMM yyyy г.')
    : 'Не выбрано'

  return (
    <Dropdown
      overlay={<DatePicker {...props} />}
      visible={visible}
      onVisibleChange={value => setVisible(value)}
    >
      <div>
        <BaseInputPicker
          size={size}
          value={value}
          label={label}
          subText={subText}
          visible={visible}
          showClear={Boolean(props.date)}
          onClear={() => props.onChange(null)}
        />
      </div>
    </Dropdown>
  )
}

export { DatePickerInput }
