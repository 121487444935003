import { useParams } from 'react-router-dom'
import { useOrderDetails } from 'modules/orders/hooks'

import { Box, NavTabs } from 'ui'

import { RenderRoutes } from 'modules/core/components'
import { OrderDetailsTitle } from 'modules/orders/pages/OrderDetails/components'

import { orderDetailsRoutes } from 'modules/orders/routes'

import type { FC } from 'react'
import type { IOrderRouteMatch } from 'modules/orders/types/interfaces'

const OrderDetails: FC = () => {
  useOrderDetails()

  const { orderId } = useParams<IOrderRouteMatch>()

  return (
    <Box>
      <Box pt='24px' px='24px' mb='16px'>
        <OrderDetailsTitle />
      </Box>

      <NavTabs
        px='24px'
        mb='24px'
        routes={orderDetailsRoutes}
        params={{ orderId }}
      />

      <RenderRoutes routes={orderDetailsRoutes} />
    </Box>
  )
}

export { OrderDetails }
