/* tslint:disable */
import type { RootState } from 'store/configureStore'
import type {
  CredentialsState,
  RefreshResponse,
} from 'modules/auth/types/interfaces'
import type {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosResponse,
  AxiosError,
} from 'axios'

const userUrls = {
  passwordCreating: '/sso/api/v1/users/password',
  partnersFetching: '/merchant-profile/api/v1/auth',
  authorization: '/merchant-profile/api/v1/auth/sign-in',
}

class AxiosInterceptors {
  private instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  onRequestSuccess(getState: () => RootState) {
    return function (config: AxiosRequestConfig): AxiosRequestConfig {
      const credentials = getState().auth.credentials

      if (config.url.indexOf('refresh') >= 0) {
        return config
      } else if (
        config.url === userUrls.passwordCreating ||
        config.url === userUrls.partnersFetching ||
        config.url === userUrls.authorization
      ) {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: 'Bearer ' + credentials.user_access_token,
          },
        }
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: 'Bearer ' + credentials.merchant_access_token,
        },
      }
    }
  }

  onRequestError() {
    return function (error: AxiosError): Promise<AxiosError> {
      // tslint:disable-next-line:no-console
      console.log(error)
      return Promise.reject(error)
    }
  }

  onResponseSuccess() {
    return function (response: AxiosResponse): AxiosResponse {
      return response
    }
  }

  onResponseError(
    getState: () => RootState,
    onAuthCb: (credentials: CredentialsState) => void,
    onLogoutCb: VoidFunction,
  ) {
    const ctx = this
    const userRefreshUrl = '/sso/api/v1/auth/refresh'
    const partnerRefreshUrl = '/merchant-profile/api/v1/auth/refresh'

    const credentials = getState().auth.credentials
    const partnerBody = {
      refresh_token: credentials.merchant_refresh_token,
    }

    const userBody = {
      refresh_token: credentials.user_refresh_token,
    }

    let request: Promise<AxiosResponse<RefreshResponse>>

    return function (error: AxiosError): Promise<unknown> {
      const isUserRefresh =
        error.response.status === 401 &&
        error.config.url === '/merchant-profile/api/v1/auth'

      // tslint:disable-next-line:no-console
      console.log(error)

      // error without response when problem cors
      if (!error.response) {
        return Promise.reject({
          ...error.toJSON(),
          response: {
            data: {
              error: {
                message: 'Blocked by CORS policy',
              },
            },
          },
        })
      }

      if (
        error.response.status !== 401 &&
        error.config.url !== partnerRefreshUrl &&
        error.config.url !== userRefreshUrl
      ) {
        return Promise.reject(error)
      }

      // Logout user if token refresh didn't work
      if (
        error.config.url === partnerRefreshUrl ||
        error.config.url === userRefreshUrl
      ) {
        onLogoutCb()

        return Promise.reject(error)
      }

      // Try request again with new token
      if (isUserRefresh) {
        request = ctx.instance.post(userRefreshUrl, userBody)
      } else {
        request = ctx.instance.post(partnerRefreshUrl, partnerBody)
      }

      return request
        .then(({ data }) => {
          const config = error.config

          if (isUserRefresh) {
            onAuthCb({
              user_access_token: data.access_token,
            })
          } else {
            onAuthCb({
              merchant_access_token: data.access_token,
            })
          }

          return new Promise((resolve, reject) => {
            ctx.instance
              .request(config)
              .then(res => resolve(res))
              .catch(err => reject(err))
          })
        })
        .catch(err => Promise.reject(err.toJSON()))
    }
  }
}

export default AxiosInterceptors
