import { Switch, Route } from 'react-router-dom'

import { NotFound } from 'modules/core/components'

import type { FC } from 'react'
import type { IRoute, RenderRoutesProps } from 'modules/common/types/interfaces'

const RouteWithSubRoutes: FC<IRoute> = ({
  path,
  exact,
  routes,
  component: Component,
}) => (
  <Route path={path} exact={exact}>
    {routes ? <Component routes={routes} /> : <Component />}
  </Route>
)

const RenderRoutes: FC<RenderRoutesProps> = ({ routes }) => (
  <Switch>
    {routes.map(route => (
      <RouteWithSubRoutes key={route.key} {...route} />
    ))}
    <Route component={NotFound} />
  </Switch>
)

export { RenderRoutes }
