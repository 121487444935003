import { useState } from 'react'
import { NotificationIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'
import { Badge, Dropdown, IconButton } from 'ui'

import { Notifications } from 'modules/notifications/components'
import { useNotificationsCount } from 'modules/notifications/hooks'

import { useAppDispatch } from 'store/hooks'
import {
  changeStatus,
  fetchActionable,
  fetchInformational,
} from 'modules/notifications/slices/notifications'
import { NotificationStatus } from 'modules/notifications/types'

import type { FC } from 'react'

const NotificationsWidget: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppTheme()
  const { count } = useNotificationsCount()
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [seenNotificationsIds, setSeenNotificationsIds] = useState<string[]>([])

  const handleVisibleChange = (isOpen: boolean): void => {
    setDropdownIsOpen(isOpen)

    if (isOpen === true) {
      dispatch(fetchActionable())
      dispatch(fetchInformational())
    }

    if (isOpen === false && seenNotificationsIds.length) {
      setSeenNotificationsIds([])
      dispatch(
        changeStatus({
          ids: seenNotificationsIds,
          status: NotificationStatus.Read,
        }),
      )
    }
  }

  const handleClose = () => {
    handleVisibleChange(false)
  }

  const handleOnInView = (id: string) => {
    setSeenNotificationsIds(old => [...old, id])
  }

  return (
    <Dropdown
      overlay={
        <Notifications onClose={handleClose} onInView={handleOnInView} />
      }
      placement='bottomRight'
      onVisibleChange={handleVisibleChange}
      visible={dropdownIsOpen}
    >
      <IconButton size='40px'>
        <Badge count={count}>
          <NotificationIcon color={theme.colors.icon.black} />
        </Badge>
      </IconButton>
    </Dropdown>
  )
}

export { NotificationsWidget }
