import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Delete: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z'
      fill={color}
    />
    <path
      d='M8 9C8.55228 9 9 9.44772 9 10V13C9 13.5523 8.55228 14 8 14C7.44772 14 7 13.5523 7 13V10C7 9.44772 7.44772 9 8 9Z'
      fill={color}
    />
    <path
      d='M13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V10Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 6C2 5.44772 2.44772 5 3 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7V15C17 16.6569 15.6569 18 14 18H6C4.34315 18 3 16.6569 3 15V7C2.44772 7 2 6.55228 2 6ZM5 7H15V15C15 15.5523 14.5523 16 14 16H6C5.44772 16 5 15.5523 5 15V7Z'
      fill={color}
    />
  </Icon>
)

Delete.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Delete }
