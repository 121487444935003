import { RenderRoutes, RenderMainRoutes } from 'modules/core/components'
import { RenderFaqRoutes } from 'modules/faq/components'

import { getRoutes as getInstallmentsRoutes } from 'modules/installments/routes'
import { getRoutes as getProductsRoutes } from 'modules/products/routes'
import { getRoutes as getFinanceRoutes } from 'modules/finance/routes'
import { getRoutes as getProfileRoutes } from 'modules/profile/routes'
import { getRoutes as getRefundsRoutes } from 'modules/refunds/routes'
import { getRoutes as getOrdersRoutes } from 'modules/orders/routes'
import { getRoutes as getAuthRoutes } from 'modules/auth/routes'
import { getRoutes as getFaqRoutes } from 'modules/faq/routes'

import type { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  FAQ = 'FAQ',
  AUTH = 'AUTH',
  INDEX = 'INDEX',
  ORDERS = 'ORDERS',
  PROFILE = 'PROFILE',
  FINANCE = 'FINANCE',
  REFUNDS = 'REFUNDS',
  PRODUCTS = 'PRODUCTS',
  INSTALLMENTS = 'INSTALLMENTS',
}

const mainRoutes: IRoute[] = [
  {
    path: '/orders',
    key: KEYS.ORDERS,
    exact: false,
    routes: getOrdersRoutes(KEYS.ORDERS),
    component: RenderRoutes,
  },
  {
    path: '/refunds',
    key: KEYS.REFUNDS,
    exact: false,
    routes: getRefundsRoutes(KEYS.REFUNDS),
    component: RenderRoutes,
  },
  {
    path: '/products',
    key: KEYS.PRODUCTS,
    exact: false,
    routes: getProductsRoutes(KEYS.PRODUCTS),
    component: RenderRoutes,
  },
  {
    path: '/finance',
    key: KEYS.FINANCE,
    exact: false,
    routes: getFinanceRoutes(KEYS.FINANCE),
    component: RenderRoutes,
  },
  {
    path: '/installments',
    key: KEYS.INSTALLMENTS,
    exact: false,
    routes: getInstallmentsRoutes(KEYS.INSTALLMENTS),
    component: RenderRoutes,
  },
  {
    path: '/profile',
    key: KEYS.PROFILE,
    exact: false,
    routes: getProfileRoutes(KEYS.PROFILE),
    component: RenderRoutes,
  },
]

export const routes: IRoute[] = [
  {
    path: '/auth',
    key: KEYS.AUTH,
    component: RenderRoutes,
    routes: getAuthRoutes(KEYS.AUTH),
  },
  {
    path: '/faq',
    key: KEYS.FAQ,
    component: RenderFaqRoutes,
    routes: getFaqRoutes(KEYS.FAQ),
  },
  {
    path: '/',
    key: KEYS.INDEX,
    component: RenderMainRoutes,
    routes: mainRoutes,
  },
]
