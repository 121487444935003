import { useAppSelector } from 'store/hooks'

import { InfoIcon } from 'ui/icons'
import { Typography, Box } from 'ui'
import { useAppTheme } from 'ui/theme'

import type { FC } from 'react'

const Tip: FC = () => {
  const theme = useAppTheme()
  const details = useAppSelector(state => state.orders.orders.details)

  return (
    Boolean(details.id) && (
      <Box mt='36px' mb='68px'>
        <Box
          backgroundColor='main.background.gray'
          padding='16px 16px 16px 8px'
          borderRadius='xs'
          maxWidth='296px'
          display='flex'
        >
          <Box minWidth='20px' mr='8px'>
            <InfoIcon color={theme.colors.icon.black} />
          </Box>
          <Typography.Caption>
            {details.payment.dividable
              ? `В этом заказе можно отменить часть товаров.
                 Если статус неделимости «Нет» — это значит, что из заказа
                 можно убирать товары, которые вы не можете сейчас продать,
                 и доставить оставшиеся.`
              : `В этом заказе нельзя отменить часть товаров. Если статус
                 неделимости «Да» — это значит, что заказ должен быть доставлен
                 за один раз со всеми товарами. К таким заказам относятся:
                 акции с подарочными товарами; скидки «2+1», «1+1» и т.п.;
                 рассрочка или кредит.`}
          </Typography.Caption>
        </Box>
      </Box>
    )
  )
}

export { Tip }
