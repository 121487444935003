import { styled } from 'ui/theme'
import { space } from 'styled-system'

const BreadcrumbsWrap = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
  ${space}
`

export { BreadcrumbsWrap }
