import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Tiktok: FC<IconProps> = ({ originalWidth, originalHeight, ...props }) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path d='M13.0484 2H10.3519V12.8985C10.3519 14.1971 9.31479 15.2638 8.02418 15.2638C6.73356 15.2638 5.69646 14.1971 5.69646 12.8985C5.69646 11.6232 6.71051 10.5797 7.95505 10.5333V7.79711C5.21248 7.84347 3 10.0928 3 12.8985C3 15.7276 5.25857 18 8.04723 18C10.8358 18 13.0944 15.7044 13.0944 12.8985V7.31013C14.1085 8.05218 15.353 8.49276 16.6667 8.51596V5.77971C14.6386 5.71015 13.0484 4.04058 13.0484 2Z' />
  </Icon>
)

Tiktok.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { Tiktok }
