import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Star: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.69328 2.28203C9.26704 1.26406 10.733 1.26406 11.3067 2.28203L13.3444 5.89715L17.4122 6.71792C18.5576 6.94903 19.0106 8.3432 18.2198 9.20346L15.4113 12.2585L15.8877 16.3809C16.0219 17.5417 14.8359 18.4033 13.7734 17.917L10 16.19L6.22661 17.917C5.16408 18.4033 3.97813 17.5417 4.11228 16.3809L4.58872 12.2585L1.7802 9.20346C0.989362 8.3432 1.44235 6.94903 2.5878 6.71792L6.65564 5.89715L8.69328 2.28203ZM10 4.03685L8.28558 7.07853C8.07153 7.4583 7.70285 7.72616 7.27553 7.81238L3.85293 8.50296L6.21596 11.0734C6.51099 11.3943 6.65182 11.8277 6.60177 12.2608L6.2009 15.7293L9.37576 14.2762C9.77215 14.0948 10.2279 14.0948 10.6242 14.2762L13.7991 15.7293L13.3982 12.2608C13.3482 11.8277 13.489 11.3943 13.784 11.0734L16.1471 8.50296L12.7245 7.81238C12.2971 7.72616 11.9285 7.4583 11.7144 7.07853L10 4.03685Z'
      fill={color}
    />
  </Icon>
)

Star.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Star }
