import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { orderDecision } from 'modules/orders/slices/orders'
import { toast, Typography } from 'ui'
import { ChevronIcon, EditIcon } from 'ui/icons'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'
import type { TipType } from 'modules/orders/types'

export const useDecisions = (
  rejectModalVisible: boolean,
  onResetCbx: VoidFunction,
) => {
  const dispatch = useAppDispatch()
  const {
    details,
    loadingOfDecision,
    loadingOfChanging,
    loadingOfDownloading,
  } = useAppSelector(state => state.orders.orders)

  const [countModalVisible, setCountModalVisible] = useState(false)
  const [tip, setTip] = useState<TipType>({
    isDownload: false,
    visible: false,
    type: null,
  })

  const isRemoved = details.products.every(
    product => product.removed || !product.is_active,
  )
  const conditions =
    details.status === ORDERS_FILTER_STATUS.WAITING_CONFIRMATION ||
    details.status === ORDERS_FILTER_STATUS.ON_ASSEMBLY ||
    details.status === ORDERS_FILTER_STATUS.REASSEMBLY ||
    details.status === ORDERS_FILTER_STATUS.ASSEMBLED

  const showTip = (type: ORDERS_FILTER_STATUS, isDownload = false) => {
    setTip({
      isDownload: isDownload,
      visible: true,
      type,
    })
  }

  const closeTip = () => {
    setTip({
      isDownload: tip.isDownload,
      visible: false,
      type: tip.type,
    })
  }

  const showCountModal = () => {
    setCountModalVisible(true)
  }

  const closeCountModal = () => {
    setCountModalVisible(false)
  }

  const onAccept = () => {
    onResetCbx()

    if (!isRemoved) {
      if (details.status === ORDERS_FILTER_STATUS.WAITING_CONFIRMATION) {
        dispatch(
          orderDecision({
            solution: 'accept',
            refresh: 'details',
            onShowTip: () => showTip(ORDERS_FILTER_STATUS.ON_ASSEMBLY),
          }),
        )
      } else if (
        details.status === ORDERS_FILTER_STATUS.ON_ASSEMBLY ||
        details.status === ORDERS_FILTER_STATUS.REASSEMBLY
      ) {
        showCountModal()
      } else if (details.status === ORDERS_FILTER_STATUS.ASSEMBLED) {
        showTip(ORDERS_FILTER_STATUS.ASSEMBLED, true)
      }
    } else {
      toast(
        <Typography as='p' textAlign='center' lineHeight='20px'>
          Невозможно подтвердить <br /> Все товары убраны!
        </Typography>,
      )
    }
  }

  return {
    tip,
    conditions,
    countModalVisible,
    showTip,
    closeTip,
    closeCountModal,
    acceptButtonProps: {
      icon:
        details.status === ORDERS_FILTER_STATUS.ASSEMBLED ? ChevronIcon : null,
      iconPlacement: 'right' as 'left' | 'right',
      disabled:
        (loadingOfDecision && rejectModalVisible) ||
        (loadingOfDecision && countModalVisible) ||
        loadingOfChanging,
      loading:
        (loadingOfDecision && !rejectModalVisible && !countModalVisible) ||
        loadingOfDownloading,
      onClick: onAccept,
    },
    countButtonProps: {
      icon: EditIcon,
      disabled:
        (loadingOfDecision && rejectModalVisible) ||
        (loadingOfDecision && countModalVisible) ||
        loadingOfDownloading,
      loading: loadingOfDecision && !rejectModalVisible && !countModalVisible,
      onClick: showCountModal,
    },
  }
}
