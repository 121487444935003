import { Modal, Typography } from 'ui'

import type { FC } from 'react'
import type { Refund } from 'modules/refunds/types'

const { Body } = Typography

interface Props {
  id: Refund['id']
  onClose: VoidFunction
  onOk: (id: Refund['id']) => void
}

const DeliveryModal: FC<Props> = ({ id, onClose, onOk }) => (
  <Modal
    title='Вызов курьера'
    cancelText='Отмена'
    okText='Вызвать'
    width='364px'
    visible={Boolean(id)}
    onClose={onClose}
    onCancel={onClose}
    onOk={() => onOk(id)}
  >
    <Body>Вы уверены что хотите вызвать курьера?</Body>
  </Modal>
)

const CancelModal: FC<Props> = ({ id, onClose, onOk }) => (
  <Modal
    title='Отказ по заявке'
    cancelText='Отмена'
    okText='Отказать'
    width='364px'
    visible={Boolean(id)}
    onClose={onClose}
    onCancel={onClose}
    onOk={() => onOk(id)}
  >
    <Body>Вы уверены что хотите отказать по возврату заявки?</Body>
  </Modal>
)

const AcceptModal: FC<Props> = ({ id, onClose, onOk }) => (
  <Modal
    title='Подтверждения заявки'
    cancelText='Отмена'
    okText='Подтвердить'
    width='364px'
    visible={Boolean(id)}
    onClose={onClose}
    onCancel={onClose}
    onOk={() => onOk(id)}
  >
    <Body>Вы уверены что хотите подтвердить заявку на возврат?</Body>
  </Modal>
)

export { DeliveryModal, CancelModal, AcceptModal }
