import { ifProp } from 'styled-tools'

import { sizes } from 'ui/inputs/FormField/styles/sizes'
import { styled } from 'ui/theme'

import type { FormFieldWrapProps } from 'ui/inputs/FormField/types'

export const FormFieldWrap = styled.div<FormFieldWrapProps>`
  width: ${props => props.width ? props.width : '100%'};
  .__form-field-container {
    position: relative;

    .__form-field-error-icon,
    .__form-field-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0;
    }

    .__form-field-icon {
      cursor: pointer;
      right: 12px;
    }

    .__form-field-error-enter {
      opacity: 0;
    }

    .__form-field-error-enter-active {
      opacity: 1;
      transition: opacity 100ms;
    }

    .__form-field-error-exit {
      opacity: 1;
    }

    .__form-field-error-exit-active {
      opacity: 0;
      transition: opacity 100ms;
    }

    .__form-field-error-icon {
      left: 12px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .__form-field-input {
    border: 1px solid;
    font-size: ${props => props.theme.fontSizes.body.m};
    line-height: ${props => props.theme.lineHeights.m};
    color: ${props => props.theme.colors.text.black};
    border-radius: ${props =>
      props.isPaginate ? props.theme.radii.xxxs : props.theme.radii.xxs};
    padding: ${({ error, allowClear }) =>
      error ? '0 40px 1px' : allowClear ? '0 40px 1px 12px' : '0 12px'};
    box-sizing: border-box;
    transition: all 0.2s;
    background: #ffffff;
    outline: none;
    width: 100%;

    &::placeholder {
      color: ${props => props.theme.colors.text.secondaryBlack};
    }

    &:focus {
      border-color: ${props => props.theme.colors.projects.primaryMarket};
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }

    &:enabled {
      border-color: ${props => props.theme.greyColorPalette[20]};

      ${({ value, theme, isPaginate }) =>
        String(value).length !== 0 &&
        `
        border-color: ${
          isPaginate
            ? theme.greyColorPalette[10]
            : theme.colors.text.secondaryBlack
        };
      `}

      ${props =>
        ifProp(
          'error',
          `
        border-color: ${props.theme.colors.main.status.error};
        box-shadow: 0px 4px 12px rgba(241, 85, 21, 0.2);
      `,
        )}
    }

    &:disabled {
      cursor: not-allowed;
      color: ${props => props.theme.greyColorPalette[20]};
      background: ${props => props.theme.greyColorPalette[15]};
      border-color: ${props => props.theme.greyColorPalette[15]};

      &::placeholder {
        color: ${props => props.theme.greyColorPalette[20]};
      }
    }
  }

  .__form-field-textarea {
    resize: vertical;
    padding: 12px 12px;
    height: ${props => props.height || '108px'};
    transition: resize 0s;
  }

  ${sizes}
`
