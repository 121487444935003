import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const DefaultSort: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.7071 6.70711C17.0976 6.31658 17.0976 5.68342 16.7071 5.29289L13.7071 2.29289C13.3166 1.90237 12.6834 1.90237 12.2929 2.29289L9.29289 5.29289C8.90237 5.68342 8.90237 6.31658 9.29289 6.70711C9.68342 7.09763 10.3166 7.09763 10.7071 6.70711L12 5.41421V12C12 12.5523 12.4477 13 13 13C13.5523 13 14 12.5523 14 12V5.41421L15.2929 6.70711C15.6834 7.09763 16.3166 7.09763 16.7071 6.70711Z'
      fill='#10142D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.7071 13.2929C11.0976 13.6834 11.0976 14.3166 10.7071 14.7071L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071L3.29289 14.7071C2.90237 14.3166 2.90237 13.6834 3.29289 13.2929C3.68342 12.9024 4.31658 12.9024 4.70711 13.2929L6 14.5858L6 8C6 7.44772 6.44772 7 7 7C7.55229 7 8 7.44772 8 8V14.5858L9.29289 13.2929C9.68342 12.9024 10.3166 12.9024 10.7071 13.2929Z'
      fill='#10142D'
    />
  </Icon>
)

DefaultSort.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#10142D',
}

export { DefaultSort }
