import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import {
  fetchActionable,
  fetchInformational,
} from 'modules/notifications/slices/notifications'

const useNotifications = () => {
  const dispatch = useAppDispatch()
  const {
    informationalNotifications,
    informationalLoading,
    informationalError,
    actionableNotifications,
    actionableLoading,
    actionableError,
  } = useAppSelector(state => state.notifications.notifications)

  useEffect(() => {
    dispatch(fetchActionable())
    dispatch(fetchInformational())
  }, [dispatch])

  return {
    informationalNotifications,
    informationalLoading,
    informationalError,
    actionableNotifications,
    actionableLoading,
    actionableError,
  }
}
export { useNotifications }
