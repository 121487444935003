import { useState } from 'react'
import { useAppDispatch } from 'store/hooks'

import { Box, Checkbox, ActionButton, Typography, Modal } from 'ui'
import { CheckboxButton } from 'ui/button/ActionButton'
import { CloseOutlinedIcon } from 'ui/icons'

import { removeProductsFromInstallment } from 'modules/installments/slices/products'

import type { ChangeEventHandler, FC } from 'react'
import type { CbxState } from 'modules/common/types/interfaces'

const { Body } = Typography

interface Props {
  isAllSelected: boolean
  cbxState: CbxState
  onCheckAllCbxChange: ChangeEventHandler<HTMLInputElement>
  onResetCbx: VoidFunction
}

const WithInstallmentProductsActions: FC<Props> = ({
  isAllSelected,
  cbxState,
  onCheckAllCbxChange,
  onResetCbx,
}) => {
  const dispatch = useAppDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const selectedCount = cbxState.checkedList.length
  const isSelected = selectedCount > 0

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleRemoveProduct = () => {
    const skus = cbxState.checkedList as string[]

    dispatch(removeProductsFromInstallment({ skus }))
    setModalIsOpen(false)
    onResetCbx()
  }

  return (
    <>
      <Box
        mt='24px'
        display='grid'
        gridAutoFlow='column'
        justifyContent='start'
        alignItems='center'
        gridColumnGap='16px'
        pr='24px'
        pl='12px'
      >
        <CheckboxButton htmlFor='table-checkbox'>
          <Checkbox
            name='tester'
            value='tester'
            id='table-checkbox'
            checked={cbxState.checkAll}
            onChange={onCheckAllCbxChange}
            indeterminate={cbxState.indeterminate}
          />

          <Typography.Body weight='semibold'>
            {isSelected ? selectedCount : ''}{' '}
            {isAllSelected ? 'Снять выделение' : 'Выделить всё'}
          </Typography.Body>
        </CheckboxButton>

        {isSelected && (
          <ActionButton icon={CloseOutlinedIcon} onClick={handleOpenModal}>
            Убрать с акции
          </ActionButton>
        )}
      </Box>

      <Modal
        title='Убрать с акции'
        okText='Убрать с акции'
        width='365px'
        visible={modalIsOpen}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
        onOk={handleRemoveProduct}
      >
        <Body as='p'>Вы уверены, что хотите убрать товары с акции?</Body>
      </Modal>
    </>
  )
}

export { WithInstallmentProductsActions }
