import { useAppDispatch, useAppSelector } from 'store/hooks'

import { generatePdf } from 'modules/orders/slices/orders'

import { Box, Button, Condition, Dropdown } from 'ui'
import { renderTip } from './renderTip'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

import type { FC } from 'react'
import type { GeneratePdfType, TipType } from 'modules/orders/types'
import type { ButtonProps } from 'ui/button/types'

interface DecisionsActionsProps {
  conditions: boolean
  tip: TipType
  closeTip: VoidFunction
  showRejectModal: VoidFunction
  countButtonProps: {
    disabled: ButtonProps['disabled']
    loading: ButtonProps['loading']
    onClick: ButtonProps['onClick']
    icon: ButtonProps['icon']
  }
  acceptButtonProps: {
    iconPlacement: ButtonProps['iconPlacement']
    disabled: ButtonProps['disabled']
    loading: ButtonProps['loading']
    onClick: ButtonProps['onClick']
    icon: ButtonProps['icon']
  }
}

const DecisionsActions: FC<DecisionsActionsProps> = ({
  tip,
  conditions,
  countButtonProps,
  acceptButtonProps,
  closeTip,
  showRejectModal,
}) => {
  const dispatch = useAppDispatch()
  const details = useAppSelector(state => state.orders.orders.details)

  const dataTestId = () => {
    if (details.status === ORDERS_FILTER_STATUS.WAITING_CONFIRMATION) {
      return 'AcceptOrderButton'
    } else if (
      details.status === ORDERS_FILTER_STATUS.ON_ASSEMBLY ||
      details.status === ORDERS_FILTER_STATUS.REASSEMBLY
    ) {
      return 'ReadyForShippingButton'
    } else if (details.status === ORDERS_FILTER_STATUS.ASSEMBLED) {
      return 'GetDocsButton'
    }
  }

  return conditions ? (
    <Box display='flex'>
      <Button
        mr='16px'
        variant='secondary'
        onClick={showRejectModal}
        data-test-id='CancelOrderButton'
      >
        Отменить заказ
      </Button>

      <Dropdown
        visible={tip.visible}
        placement='bottomRight'
        overlay={renderTip({
          generatePdf: (docType: GeneratePdfType) =>
            dispatch(
              generatePdf({
                docType,
              }),
            ),
          closeTip,
          tip,
        })}
        onVisibleChange={visible => {
          if (!visible && tip.isDownload) {
            closeTip()
          }
        }}
      >
        <Button {...acceptButtonProps} data-test-id={dataTestId()}>
          {details.status === ORDERS_FILTER_STATUS.WAITING_CONFIRMATION &&
            'Подтвердить заказ'}
          {(details.status === ORDERS_FILTER_STATUS.ON_ASSEMBLY ||
            details.status === ORDERS_FILTER_STATUS.REASSEMBLY) &&
            'Готов к выдаче'}
          {details.status === ORDERS_FILTER_STATUS.ASSEMBLED && 'Скачать...'}
        </Button>
      </Dropdown>

      <Condition match={details.status === ORDERS_FILTER_STATUS.ASSEMBLED}>
        <Button ml='16px' variant='default' {...countButtonProps}>
          Кол–во грузовых мест
        </Button>
      </Condition>
    </Box>
  ) : null
}

export { DecisionsActions }
