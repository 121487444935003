import { FC } from 'react'
import { useAppSelector } from 'store/hooks'

import { formatCurrency } from 'utils/textFormatting'

import { Withdraw, Active, Sold } from './TooltipaOverlays'

import { Typography, Tooltip, IconButton, Box } from 'ui'
import { QuestionIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'

const { Subtitle, Title, Body } = Typography

const FinanceTitle: FC = () => {
  const theme = useAppTheme()
  const balance = useAppSelector(state => state.finance.finance.balance)
  const profit = useAppSelector(state => state.orders.orders.profit)

  return (
    <Box pt='24px' px='24px' mb='32px'>
      <Title as='p' mb='16px'>
        Финансы
      </Title>

      <Box display='flex'>
        <Box mr='24px'>
          <Box display='flex' alignItems='center' mb='4px'>
            <Body as='p' mr='8px' color='text.secondaryBlack'>
              Можно вывести
            </Body>

            <Tooltip placement='right' overlay={Withdraw}>
              <IconButton>
                <QuestionIcon color={theme.colors.icon.black} />
              </IconButton>
            </Tooltip>
          </Box>

          <Subtitle as='p' color='main.status.success'>
            {formatCurrency(balance.to_withdrawal)}
          </Subtitle>
        </Box>

        <Box mr='24px'>
          <Box display='flex' alignItems='center' mb='4px'>
            <Body as='p' mr='8px' color='text.secondaryBlack'>
              Активные заказы
            </Body>

            <Tooltip placement='right' overlay={Active}>
              <IconButton>
                <QuestionIcon color={theme.colors.icon.black} />
              </IconButton>
            </Tooltip>
          </Box>

          <Subtitle as='p'>{formatCurrency(profit)}</Subtitle>
        </Box>

        <Box>
          <Box display='flex' alignItems='center' mb='4px'>
            <Body as='p' mr='8px' color='text.secondaryBlack'>
              Продано
            </Body>

            <Tooltip placement='right' overlay={Sold}>
              <IconButton>
                <QuestionIcon color={theme.colors.icon.black} />
              </IconButton>
            </Tooltip>
          </Box>

          <Subtitle as='p'>{formatCurrency(balance.in_account)}</Subtitle>
        </Box>
      </Box>
    </Box>
  )
}

export default FinanceTitle
