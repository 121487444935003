import { styled } from 'ui/theme'

export const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 0 24px 24px;

  .__pagination-container {
    display: flex;
    margin-right: 16px;

    * {
      user-select: none;
    }

    li {
      margin-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .__page {
      a {
        display: block;
        color: ${props => props.theme.colors.text.black};
        line-height: ${props => props.theme.lineHeights.m};
        font-weight: ${props => props.theme.fontWeights.regular};
        padding: 0 7px;
      }
    }

    .__page,
    .__prev-button,
    .__next-button {
      background-color: ${props => props.theme.colors.main.background.white};
      border: 1px solid #e6e6e6;
      border-radius: 2px;
      overflow: hidden;
      min-width: 32px;
      height: 32px;

      a {
        padding: 1px 7px;
        transition: all 0.1s;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:is(.__disabled) {
        path {
          fill: ${props => props.theme.greyColorPalette[10]};
        }
      }

      &:not(.__disabled) {
        cursor: pointer;

        &:hover {
          border: 1px solid #319cf3;

          a {
            color: ${props => props.theme.colors.icon.active};

            path {
              fill: ${props => props.theme.colors.icon.active};
            }
          }
        }
      }
    }

    .__break {
      height: 32px;
      cursor: pointer;

      &:hover {
        path {
          fill: ${props => props.theme.colors.icon.active};
        }
      }
    }

    .__prev-button,
    .__next-button,
    .__break {
      a {
        font-size: 0;
      }
    }

    .__disabled {
      cursor: not-allowed;
    }

    .__active-page {
      border: 1px solid #319cf3;

      a {
        color: ${props => props.theme.colors.icon.active};
      }
    }
  }
`
