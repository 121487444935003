import { Icon } from 'ui/icons'

import type { FC } from 'react'
import type { IconProps } from 'ui/icons'

const DeliverySelf: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6ZM3 12C3 10.2552 4.11714 8.77132 5.67518 8.22461C5.24874 7.58854 5 6.82332 5 6C5 3.79086 6.79086 2 9 2C11.2091 2 13 3.79086 13 6C13 8.20914 11.2091 10 9 10H7C5.89543 10 5 10.8954 5 12V13C5 14.1046 5.89543 15 7 15H8.375C8.92728 15 9.375 15.4477 9.375 16C9.375 16.5523 8.92728 17 8.375 17H7C4.79086 17 3 15.2091 3 13V12ZM11 10C10.4477 10 10 10.4477 10 11V16C10 16.5523 10.4477 17 11 17H16C16.5523 17 17 16.5523 17 16V11C17 10.4477 16.5523 10 16 10H11ZM12 15V12H15V12.5C14.4477 12.5 14 12.9477 14 13.5C14 14.0523 14.4477 14.5 15 14.5V15H12Z'
      fill={color}
    />
  </Icon>
)

DeliverySelf.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { DeliverySelf }
