import { ORDER_PRODUCT_STATUSES } from 'modules/common/constants/dictionaries'
import { formatCurrency, formatDate } from 'utils/textFormatting'

import { Sort } from 'ui/table/components'
import { NewTabIcon } from 'ui/icons'
import {
  Highlighter,
  IconButton,
  Typography,
  Tooltip,
  Anchor,
  Link,
  Box,
} from 'ui'

import { SORTING_TYPES } from 'modules/orders/types/enums'

import type { PickupPointType } from 'modules/profile/types'
import type { OrderType } from 'modules/orders/types'
import type { ColumnsType } from 'ui/table/types'

import { theme } from 'ui/theme'

interface IColumns {
  search: string
  sorting: string
  pickupPoints: PickupPointType[]
  onClearDetails: VoidFunction
  onChangeSorting: (sorting: string) => void
}

const renderMaxConfirmTime = (maxConfirmTime: string) => {
  return formatDate(new Date(maxConfirmTime), 'До HH:mm, dd MMM yyyy г.')
}

const renderCreatedDate = (createdDate: string) => {
  return formatDate(new Date(createdDate), 'В HH:mm, dd MMM yyyy г.')
}

const renderCourierArrivalDate = (courierArrivalDate: string) => {
  return formatDate(new Date(courierArrivalDate), 'dd MMM yyyy г.')
}

const renderEndDate = (endDate?: string) => {
  return endDate && formatDate(new Date(endDate), 'В HH:mm, dd MMM yyyy г.')
}

const renderOrderId = (id: number, search: string, onClear: VoidFunction) => (
  <Box display='flex' alignItems='center' justifyContent='space-between'>
    <Typography as='p' mr='8px'>
      <Link
        to={`/orders/${id}`}
        onClick={e => {
          e.stopPropagation()
          onClear()
        }}
      >
        <Highlighter search={search}>{id}</Highlighter>
      </Link>
    </Typography>
    <Tooltip
      overlay={
        <Typography as='p' textAlign='center' lineHeight='m'>
          Открыть вкладку <br /> в новом окне
        </Typography>
      }
      placement='top'
    >
      <Anchor href={`/orders/${id}`} target='_blank'>
        <IconButton
          onClick={e => e.stopPropagation()}
          hoverBg={theme.colors.main.background.white}
        >
          <Box display='flex' alignItems='center'>
            <NewTabIcon color='#319CF3' />
          </Box>
        </IconButton>
      </Anchor>
    </Tooltip>
  </Box>
)

export const getAllColumns = ({
  search,
  sorting,
  pickupPoints,
  onClearDetails,
  onChangeSorting,
}: IColumns): ColumnsType<OrderType> => [
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='№ заказа'
        desc={SORTING_TYPES.ID_DESC}
        asc={SORTING_TYPES.ID_ASC}
      />
    ),
    dataIndex: 'id',
    key: 'id',
    width: 173,
    noPadding: true,
    render: id => renderOrderId(id, search, onClearDetails),
  },
  {
    title: 'Товары, шт.',
    dataIndex: 'products',
    key: 'products',
    width: 143,
    align: 'right',
    render: products => products.length,
  },
  {
    title: 'Статус заказа',
    dataIndex: 'status',
    key: 'status',
    width: 224,
    render: status => ORDER_PRODUCT_STATUSES[status] || status,
  },
  {
    title: (
      <Sort
        title='Сумма'
        sorting={sorting}
        onChangeSorting={onChangeSorting}
        desc={SORTING_TYPES.TOTAL_PRICE_DESC}
        asc={SORTING_TYPES.TOTAL_PRICE_ASC}
      />
    ),
    dataIndex: 'total_price',
    key: 'total_price',
    width: 130,
    align: 'right',
    noPadding: true,
    render: formatCurrency,
  },
  {
    title: 'Срок подтверждения',
    dataIndex: 'max_confirm_time',
    key: 'max_confirm_time',
    width: 231,
    render: renderMaxConfirmTime,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Дата и время оформления'
        desc={SORTING_TYPES.CREATED_AT_DESC}
        asc={SORTING_TYPES.CREATED_AT_ASC}
      />
    ),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 282,
    noPadding: true,
    render: renderCreatedDate,
  },
  {
    title: 'Дата приезда курьера',
    dataIndex: 'courier_arrival_date',
    key: 'courier_arrival_date',
    width: 236,
    render: renderCourierArrivalDate,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Дата и время завершения'
        desc={SORTING_TYPES.COMPLETED_TIME_DESC}
        asc={SORTING_TYPES.COMPLETED_TIME_ASC}
      />
    ),
    dataIndex: 'end_at',
    key: 'end_at',
    width: 278,
    noPadding: true,
    render: renderEndDate,
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    width: 304,
    ellipsis: true,
    render: ppId =>
      pickupPoints.find(pickupPoint => pickupPoint.store_id === ppId)?.name,
  },
  {
    title: 'Стоимость доставки заказа',
    dataIndex: '',
    key: '',
    width: 277,
    render: () => '—',
  },
]

export const getWaitingColumns = ({
  search,
  sorting,
  pickupPoints,
  onClearDetails,
  onChangeSorting,
}: IColumns): ColumnsType<OrderType> => [
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='№ заказа'
        desc={SORTING_TYPES.ID_DESC}
        asc={SORTING_TYPES.ID_ASC}
      />
    ),
    dataIndex: 'id',
    key: 'id',
    width: 173,
    noPadding: true,
    render: id => renderOrderId(id, search, onClearDetails),
  },
  {
    title: 'Товары, шт.',
    dataIndex: 'products',
    key: 'products',
    width: 143,
    align: 'right',
    render: products => products.length,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Сумма'
        desc={SORTING_TYPES.TOTAL_PRICE_DESC}
        asc={SORTING_TYPES.TOTAL_PRICE_ASC}
      />
    ),
    dataIndex: 'total_price',
    key: 'total_price',
    width: 130,
    align: 'right',
    noPadding: true,
    render: formatCurrency,
  },
  {
    title: 'Срок подтверждения',
    dataIndex: 'max_confirm_time',
    key: 'max_confirm_time',
    width: 231,
    render: renderMaxConfirmTime,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Дата и время оформления'
        desc={SORTING_TYPES.CREATED_AT_DESC}
        asc={SORTING_TYPES.CREATED_AT_ASC}
      />
    ),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 282,
    noPadding: true,
    render: renderCreatedDate,
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    width: 304,
    ellipsis: true,
    render: ppId =>
      pickupPoints.find(pickupPoint => pickupPoint.store_id === ppId)?.name,
  },
]

export const getCompletedColumns = ({
  search,
  sorting,
  pickupPoints,
  onClearDetails,
  onChangeSorting,
}: IColumns): ColumnsType<OrderType> => [
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='№ заказа'
        desc={SORTING_TYPES.ID_DESC}
        asc={SORTING_TYPES.ID_ASC}
      />
    ),
    dataIndex: 'id',
    key: 'id',
    width: 173,
    noPadding: true,
    render: id => renderOrderId(id, search, onClearDetails),
  },
  {
    title: 'Товары, шт.',
    dataIndex: 'products',
    key: 'products',
    width: 143,
    align: 'right',
    render: products => products.length,
  },
  {
    title: 'Статус заказа',
    dataIndex: 'status',
    key: 'status',
    width: 224,
    render: status => ORDER_PRODUCT_STATUSES[status],
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Сумма'
        desc={SORTING_TYPES.TOTAL_PRICE_DESC}
        asc={SORTING_TYPES.TOTAL_PRICE_ASC}
      />
    ),
    dataIndex: 'total_price',
    key: 'total_price',
    width: 130,
    align: 'right',
    noPadding: true,
    render: formatCurrency,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Дата и время оформления'
        desc={SORTING_TYPES.CREATED_AT_DESC}
        asc={SORTING_TYPES.CREATED_AT_ASC}
      />
    ),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 282,
    noPadding: true,
    render: renderCreatedDate,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Дата и время завершения'
        desc={SORTING_TYPES.COMPLETED_TIME_DESC}
        asc={SORTING_TYPES.COMPLETED_TIME_ASC}
      />
    ),
    dataIndex: 'end_at',
    key: 'end_at',
    width: 278,
    noPadding: true,
    render: renderEndDate,
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    width: 250,
    ellipsis: true,
    render: ppId =>
      pickupPoints.find(pickupPoint => pickupPoint.store_id === ppId)?.name,
  },
]

export const getReadyColumns = ({
  search,
  sorting,
  pickupPoints,
  onClearDetails,
  onChangeSorting,
}: IColumns): ColumnsType<OrderType> => [
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='№ заказа'
        desc={SORTING_TYPES.ID_DESC}
        asc={SORTING_TYPES.ID_ASC}
      />
    ),
    dataIndex: 'id',
    key: 'id',
    width: 173,
    noPadding: true,
    render: id => renderOrderId(id, search, onClearDetails),
  },
  {
    title: 'Товары, шт.',
    dataIndex: 'products',
    key: 'products',
    width: 143,
    align: 'right',
    render: products => products.length,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Сумма'
        desc={SORTING_TYPES.TOTAL_PRICE_DESC}
        asc={SORTING_TYPES.TOTAL_PRICE_ASC}
      />
    ),
    dataIndex: 'total_price',
    key: 'total_price',
    width: 130,
    align: 'right',
    noPadding: true,
    render: formatCurrency,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Дата и время оформления'
        desc={SORTING_TYPES.CREATED_AT_DESC}
        asc={SORTING_TYPES.CREATED_AT_ASC}
      />
    ),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 282,
    noPadding: true,
    render: renderCreatedDate,
  },
  {
    title: 'Дата приезда курьера',
    dataIndex: 'courier_arrival_date',
    key: 'courier_arrival_date',
    width: 236,
    render: renderCourierArrivalDate,
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    width: 250,
    ellipsis: true,
    render: ppId =>
      pickupPoints.find(pickupPoint => pickupPoint.store_id === ppId)?.name,
  },
  {
    title: 'Стоимость доставки заказа',
    dataIndex: '',
    key: '',
    width: 277,
    render: () => '—',
  },
]

export const getDeliveringColumns = ({
  search,
  sorting,
  pickupPoints,
  onClearDetails,
  onChangeSorting,
}: IColumns): ColumnsType<OrderType> => [
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='№ заказа'
        desc={SORTING_TYPES.ID_DESC}
        asc={SORTING_TYPES.ID_ASC}
      />
    ),
    dataIndex: 'id',
    key: 'id',
    width: 173,
    render: id => renderOrderId(id, search, onClearDetails),
    noPadding: true,
  },
  {
    title: 'Товары, шт.',
    dataIndex: 'products',
    key: 'products',
    width: 143,
    align: 'right',
    render: products => products.length,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Сумма'
        desc={SORTING_TYPES.TOTAL_PRICE_DESC}
        asc={SORTING_TYPES.TOTAL_PRICE_ASC}
      />
    ),
    dataIndex: 'total_price',
    key: 'total_price',
    width: 130,
    align: 'right',
    noPadding: true,
    render: formatCurrency,
  },
  {
    title: (
      <Sort
        onChangeSorting={onChangeSorting}
        sorting={sorting}
        title='Дата и время оформления'
        desc={SORTING_TYPES.CREATED_AT_DESC}
        asc={SORTING_TYPES.CREATED_AT_ASC}
      />
    ),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 282,
    noPadding: true,
    render: renderCreatedDate,
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    width: 250,
    ellipsis: true,
    render: ppId =>
      pickupPoints.find(pickupPoint => pickupPoint.store_id === ppId)?.name,
  },
]
