import { Box } from 'ui'
import { DefaultEmpty } from 'ui/table/components'
import { NotificationItem } from '../NotificationItem'

import type { FC } from 'react'
import type { Notification } from 'modules/notifications/types'

interface Props {
  notifications: Notification[]
  loading: boolean
  onInView: (id: Notification['id']) => void
  onClickItem: () => void
}

const NeedActionTab: FC<Props> = ({
  loading,
  notifications,
  onInView,
  onClickItem,
}) => {
  const isEmpty = !loading && notifications.length <= 0

  return (
    <Box overflow='auto' maxHeight='592px'>
      {notifications.map((note, index) => (
        <NotificationItem
          id={note.id}
          status={note.status}
          key={index}
          body={note.body}
          createdAt={note.created_at}
          onInView={onInView}
          link={note.link}
          onClick={onClickItem}
        />
      ))}
      {isEmpty && (
        <Box padding='24px'>
          <DefaultEmpty temporarilyEmpty emptyText='Пока здесь ничего нет' />
        </Box>
      )}
    </Box>
  )
}

export { NeedActionTab }
