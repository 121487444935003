import styled from '@emotion/styled'

export const SignInWrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SignInContainer = styled.div`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 20px 24px 24px;
  box-sizing: border-box;
  width: 368px;

  .__auth-text-link {
    cursor: pointer;
  }

  .__create-partner-button {
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  .__back-button {
    background: none;
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    height: 40px;
    margin-bottom: 8px;
  }
`
