import { Fragment } from 'react'
import { useParams } from 'react-router-dom'

import { Typography, Breadcrumbs, Link } from 'ui'

import type { IOrderRouteMatch } from 'modules/orders/types/interfaces'
import type { FC } from 'react'

const { Title, Body } = Typography

const OrderDetailsTitle: FC = () => {
  const { orderId } = useParams<IOrderRouteMatch>()

  return (
    <Fragment>
      <Title as='p' mb='16px'>
        Заказ №{orderId}
      </Title>

      <Breadcrumbs>
        <Link to='/orders'>Список заказов</Link>

        <Body>Заказ №{orderId}</Body>
      </Breadcrumbs>
    </Fragment>
  )
}

export { OrderDetailsTitle }
