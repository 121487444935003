import { Icon } from 'ui/icons'

import type { FC } from 'react'
import type { IconProps } from 'ui/icons'

const Check: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6247 4.21911C17.056 4.56412 17.1259 5.19341 16.7809 5.62467L8.78087 15.6247C8.60318 15.8468 8.33931 15.9827 8.0553 15.9984C7.77129 16.0142 7.49403 15.9082 7.29289 15.7071L3.29289 11.7071C2.90237 11.3166 2.90237 10.6834 3.29289 10.2929C3.68342 9.90234 4.31658 9.90234 4.70711 10.2929L7.91708 13.5028L15.2191 4.37528C15.5641 3.94402 16.1934 3.8741 16.6247 4.21911Z'
      fill={color}
    />
  </Icon>
)

Check.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Check }
