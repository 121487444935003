import { Refunds } from 'modules/refunds/pages'

import { getDashedStr } from 'utils/getDashedStr'

import type { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  REFUNDS = 'REFUNDS',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/refunds',
    exact: true,
    key: getDashedStr(key, KEYS.REFUNDS),
    component: Refunds,
  },
]
