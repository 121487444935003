import { FC } from 'react'
import { useRefunds } from 'modules/refunds/hooks'

import {
  RefundsActions,
  RefundsTable,
} from 'modules/refunds/pages/Refunds/components'

const Refunds: FC = () => {
  useRefunds()

  return (
    <>
      <RefundsActions />
      <RefundsTable />
    </>
  )
}

export { Refunds }
