import { styled } from 'ui/theme'
import { SwitchProps } from './types'

const Label = styled.label`
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
`

const Base = styled.div`
  position: relative;
  display: block;
  width: 34px;
  height: 14px;
  border-radius: 7px;
  margin: 3px;
  background: ${props => props.theme.greyColorPalette[30]};
`

const Slider = styled.div`
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  transition: 120ms;
  background: ${props => props.theme.greyColorPalette[0]};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
`

const Input = styled.input<SwitchProps>`
  display: none;

  &:checked + div {
    background: ${props => props.theme.colors.projects.primaryLightMarket};
  }

  &:checked + div > div {
    left: 17px;
    background: ${props => props.theme.colors.projects.primaryMarket};
  }
`

export { Input, Label, Slider, Base }
