import { Box } from 'ui/layout'
import { styled } from 'ui/theme'
import { IconButton, Typography, Anchor } from 'ui'
import { NewTabIcon } from 'ui/icons'

import type { FC } from 'react'

const { Body } = Typography

const DocumentBox = styled(Box)`
  box-shadow: 0 8px 14px rgba(16, 20, 45, 0.05),
    0 3.87387px 9.86002px rgba(16, 20, 45, 0.08);
  border-radius: 4px;
  background: ${props => props.theme.colors.main.background.white};
  margin-right: 16px;
  margin-bottom: 16px;
  width: calc(25% - 12px);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;

  &:nth-of-type(4n) {
    margin-right: 0;
  }
`

interface Props {
  title: string
  link: string
}

const Document: FC<Props> = ({ title, link }) => (
  <DocumentBox>
    <Body as='p' weight='semibold'>
      {title}
    </Body>
    <Anchor href={link} target='_blank'>
      <IconButton>
        <NewTabIcon />
      </IconButton>
    </Anchor>
  </DocumentBox>
)

export { Document }
