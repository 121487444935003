import { useEffect } from 'react'

import { parseISO } from 'date-fns'

import { Table, Checkbox, Image, Anchor, Box, Typography, Pagination } from 'ui'

import {
  formatCurrency,
  formatPercents,
  formatDate,
} from 'utils/textFormatting'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  asyncChangeProductsWithInstallmentLimit,
  asyncChangeProductsWithInstallmentPage,
} from 'modules/installments/slices/products'

import { env } from 'settings/env'

import type { FC } from 'react'
import type { Product } from 'modules/products/types'
import type { ColumnsType } from 'ui/table/types'
import type { CbxState } from 'modules/common/types/interfaces'

const { Caption } = Typography

interface Props {
  products: Product[]
  onChangeCbx: (checkedId: string | number) => void
  cbxState: CbxState
  isSearch: boolean
}

const WithInstallmentProductsTable: FC<Props> = ({
  products,
  onChangeCbx,
  cbxState,
  isSearch,
}) => {
  const dispatch = useAppDispatch()
  const pagination = useAppSelector(
    state => state.installments.products.withInstallmentProductsPagination,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination.page])

  const handleChangePage = (page: number) => {
    dispatch(asyncChangeProductsWithInstallmentPage({ page }))
  }

  const handleChangeLimit = (limit: number) => {
    dispatch(asyncChangeProductsWithInstallmentLimit({ limit }))
  }

  const handleRow = row => ({
    onClick: () => {
      onChangeCbx(row.sku)
    },
  })

  const columns: ColumnsType<Product> = [
    {
      key: 'checkbox',
      dataIndex: 'sku',
      title: '',
      width: 52,
      render: sku => (
        <Checkbox
          id={sku}
          value={sku}
          name='tester'
          onChange={() => onChangeCbx(sku)}
          checked={cbxState.checkedList.some(itemId => itemId === sku)}
        />
      ),
    },
    {
      key: 'image_url',
      dataIndex: 'image_url',
      width: 48,
      render: (url, row) => (
        <Box width='44px' height='44px'>
          <Image
            src={url}
            alt={row?.title?.ru}
            width='100%'
            height='100%'
            bgSize='contain'
            isBg
          />
        </Box>
      ),
    },
    {
      key: 'sku',
      dataIndex: 'sku',
      title: 'Артикул',
      width: 120,
    },
    {
      key: 'name',
      dataIndex: ['title', 'ru'],
      title: 'Название',
      width: 200,
      render: (name, row) => (
        <Anchor
          target='_blank'
          rel='noreferrer'
          href={`${env.CUSTOMER_BASE_URL}/product/${row.sku}`}
        >
          {name}
        </Anchor>
      ),
    },
    {
      key: 'category',
      dataIndex: ['category', 'title', 'ru'],
      width: 140,
      title: 'Категория',
    },
    {
      key: 'type',
      title: 'Тип акции',
      dataIndex: ['installment', 'title', 'ru'],
      width: 120,
    },
    {
      key: 'interval',
      title: 'Период прохождения акции',
      dataIndex: ['installment'],
      width: 135,
      render: installment => (
        <>
          {formatDate(parseISO(installment?.start_at), 'С dd MMM')}
          <br />
          {formatDate(parseISO(installment?.end_at), 'по dd MMM')}
        </>
      ),
    },
    {
      key: 'price',
      dataIndex: ['price', 'current'],
      title: 'Цена на Airba',
      align: 'right',
      width: 140,
      render: price => formatCurrency(price),
    },
    {
      key: 'commission_category',
      dataIndex: ['commissions', 'category'],
      title: 'Комиссия за продажу',
      align: 'right',
      width: 120,
      render: commission => (
        <Box display='grid'>
          {formatCurrency(commission?.value)}
          <Caption color='text.secondaryBlack'>
            {formatPercents(commission?.rate)}
          </Caption>
        </Box>
      ),
    },
    {
      key: 'commission_installment',
      dataIndex: ['commissions', 'installment'],
      title: 'Комиссия за участие в акции',
      align: 'right',
      width: 160,
      render: commission => (
        <Box display='grid'>
          {formatCurrency(commission?.value)}
          <Caption color='text.secondaryBlack'>
            {formatPercents(commission?.rate)}
          </Caption>
        </Box>
      ),
    },
    {
      key: 'commission_total',
      dataIndex: ['commissions', 'total'],
      title: 'Итоговая комиссия',
      align: 'right',
      width: 170,
      render: commission => (
        <Box display='grid'>
          {formatCurrency(commission?.value)}
          <Caption color='text.secondaryBlack'>
            {formatPercents(commission?.rate)}
          </Caption>
        </Box>
      ),
    },
  ]

  return (
    <Box mt='24px'>
      <Table
        columns={columns}
        data={products}
        onRow={handleRow}
        rowKey='sku'
        temporarilyEmpty={!isSearch}
        emptyText={!isSearch && 'Пока здесь ничего нет'}
      />
      <Pagination
        onPageChange={handleChangePage}
        onLimitChange={handleChangeLimit}
        {...pagination}
      />
    </Box>
  )
}

export { WithInstallmentProductsTable }
