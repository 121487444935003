import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchInstructions } from 'modules/faq/slices/instructions'

const useInstructions = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchInstructions())
  }, [dispatch])

  const { instructions } = useAppSelector(state => state.faq.instructions)

  return { instructions }
}

export { useInstructions }
