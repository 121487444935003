import { ifProp } from 'styled-tools'
import { layout } from 'styled-system'

import { Condition } from 'ui'
import { styled } from 'ui/theme'

import type { FC } from 'react'
import type { LayoutProps, SpaceProps } from 'styled-system'

type StyledImageProps = {
  width?: number | string
  height?: number | string
  isBg?: boolean
  src: string
  borderRadius?: string
  bgColor?: boolean
  border?: string
  bgSize?: string
  bgPosition?: string
}

type ImageProps = StyledImageProps &
  LayoutProps & {
    alt?: string
  }

const StyledImage = styled.div<StyledImageProps & SpaceProps & LayoutProps>`
  border-radius: ${props => props.theme.radii[props.borderRadius]};
  background-color: ${props => (props.bgColor ? props.bgColor : 'transparent')};
  border: ${props => (props.border ? props.border : 'none')};
  overflow: hidden;
  ${layout};

  ${ifProp(
    'isBg',
    props => `
    background-image: url(${props.src});
    background-size: ${props.bgSize};
    background-position: ${props.bgPosition};
    background-repeat: no-repeat;
  `,
  )}
  img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`

const Image: FC<ImageProps> = ({
  width = 32,
  height = 32,
  bgPosition = 'center center',
  bgSize = 'cover',
  isBg,
  src,
  alt,
  ...props
}) => (
  <StyledImage
    width={width}
    height={height}
    bgPosition={bgPosition}
    bgSize={bgSize}
    src={isBg && src}
    isBg={isBg}
    {...props}
  >
    <Condition match={!isBg && src}>
      <img src={src} alt={alt} width={width} height={height} />
    </Condition>
  </StyledImage>
)

export { Image }
