import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

export const tableFilters = [
  {
    name: 'Все заказы',
    value: '',
  },
  {
    name: 'Ожидают подтверждения',
    value: ORDERS_FILTER_STATUS.WAITING_CONFIRMATION,
  },
  {
    name: 'В сборке',
    value: ORDERS_FILTER_STATUS.ON_ASSEMBLY,
  },
  {
    name: 'Готовы к выдаче',
    value: ORDERS_FILTER_STATUS.ASSEMBLED,
  },
  {
    name: 'В доставке',
    value: ORDERS_FILTER_STATUS.DELIVERING,
  },
  {
    name: 'Завершённые',
    value: ORDERS_FILTER_STATUS.COMPLETED,
  },
  {
    name: 'Пересборка',
    value: ORDERS_FILTER_STATUS.REASSEMBLY,
  },
  {
    name: 'Отменен',
    value: ORDERS_FILTER_STATUS.CANCELED,
  },
]
