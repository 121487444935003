import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppThunk, instance } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'
import {
  Notification,
  NotificationStatus,
  NotificationsState,
  FetchCountOptions,
  ChangeStatusOptions,
} from '../types'

export const fetchCount: AppThunk<number, FetchCountOptions> = createAsyncThunk(
  'notifications/count/fetch',
  ({ status }, thunkApi) =>
    instance(thunkApi)
      .get(`/notes/api/v1/merchant/notes/count`, {
        params: {
          status,
        },
      })
      .then(res => res.data.count)
      .catch(thunkErrorHandler(thunkApi)),
)

export const fetchInformational: AppThunk<Notification[]> = createAsyncThunk(
  'notifications/informational/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get(`/notes/api/v1/merchant/notes`, {
        params: { note_type: 'informational', page: 1, limit: 15 },
      })
      .then(res => res.data.notes)
      .catch(thunkErrorHandler(thunkApi)),
)

export const fetchActionable: AppThunk<Notification[]> = createAsyncThunk(
  'notifications/actionable/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get(`/notes/api/v1/merchant/notes`, {
        params: { note_type: 'actionable', page: 1, limit: 15 },
      })
      .then(res => res.data.notes)
      .catch(thunkErrorHandler(thunkApi)),
)

export const changeStatus: AppThunk<
  void,
  ChangeStatusOptions
> = createAsyncThunk(
  'notes/status/change',
  ({ ids, status = NotificationStatus.Read }, thunkApi) =>
    instance(thunkApi)
      .patch(`/notes/api/v1/merchant/notes/status`, {
        ids,
        status,
      })
      .then(res => {
        thunkApi.dispatch(fetchActionable())
        thunkApi.dispatch(fetchInformational())
        thunkApi.dispatch(fetchCount({ status: NotificationStatus.New }))
        return res.data
      })
      .catch(thunkErrorHandler(thunkApi)),
)

const initialState: NotificationsState = {
  count: 0,
  countLoading: false,
  countError: null,

  actionableNotifications: [],
  actionableLoading: false,
  actionableError: null,

  informationalNotifications: [],
  informationalLoading: false,
  informationalError: null,

  changingStatusLoading: false,
  changingStatusError: null,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCount.pending, state => {
        state.countLoading = true
        state.countError = null
      })
      .addCase(fetchCount.fulfilled, (state, action) => {
        state.countLoading = false
        state.count = action.payload
      })
      .addCase(fetchCount.rejected, (state, action) => {
        state.countLoading = false
        state.countError = action.payload.error
      })
      .addCase(fetchInformational.pending, state => {
        state.informationalLoading = true
        state.informationalError = null
      })
      .addCase(fetchInformational.fulfilled, (state, action) => {
        state.informationalLoading = false
        state.informationalNotifications = action.payload
      })
      .addCase(fetchInformational.rejected, (state, action) => {
        state.informationalLoading = false
        state.informationalError = action.payload.error
      })
      .addCase(fetchActionable.pending, state => {
        state.actionableLoading = true
        state.actionableError = null
      })
      .addCase(fetchActionable.fulfilled, (state, action) => {
        state.actionableLoading = false
        state.actionableNotifications = action.payload
      })
      .addCase(fetchActionable.rejected, (state, action) => {
        state.actionableLoading = false
        state.actionableError = action.payload.error
      })
      .addCase(changeStatus.pending, state => {
        state.changingStatusLoading = true
        state.changingStatusError = null
      })
      .addCase(changeStatus.fulfilled, state => {
        state.changingStatusLoading = false
      })
      .addCase(changeStatus.rejected, (state, action) => {
        state.changingStatusLoading = false
        state.changingStatusError = action.payload.error
      })
  },
})

export default notificationsSlice.reducer
