import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchUploadHistory } from '../slices/productsFeed'

const useUploadHistory = () => {
  const dispatch = useAppDispatch()
  const { uploadHistory, uploadHistoryFilters } = useAppSelector(
    state => state.products.productsFeed,
  )
  const { date, status, type } = uploadHistoryFilters

  useEffect(() => {
    dispatch(fetchUploadHistory())
  }, [date, status, type, dispatch])

  return { uploadHistory }
}

export { useUploadHistory }
