import { forwardRef } from 'react'

import { Text } from 'ui'

import { useAppTheme } from 'ui/theme'

import type { FC, Ref } from 'react'
import type { TextProps } from 'ui/typography/Text/types'

interface BodyProps extends TextProps {
  level?: 1 | 2
  weight?: 'regular' | 'semibold' | 'bold'
}

const Body: FC<BodyProps> = forwardRef(
  (
    { level = 1, color = 'text.black', weight = 'regular', children, ...props },
    ref,
  ) => {
    const theme = useAppTheme()

    const levels = {
      1: {
        fontSize: theme.fontSizes.body.m,
        lineHeight: theme.lineHeights.m,
      },
      2: {
        fontSize: theme.fontSizes.body.s,
        lineHeight: theme.lineHeights.s,
      },
    }

    return (
      <Text
        {...props}
        ref={ref as Ref<HTMLSpanElement>}
        color={color}
        fontSize={levels[level].fontSize}
        lineHeight={levels[level].lineHeight}
        fontWeight={theme.fontWeights[weight]}
      >
        {children}
      </Text>
    )
  },
)

export { Body }
