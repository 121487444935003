import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Dates: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5ZM5 4C4.44772 4 4 4.44772 4 5V7H16V5C16 4.44772 15.5523 4 15 4H5ZM16 9H4V15C4 15.5523 4.44772 16 5 16H15C15.5523 16 16 15.5523 16 15V9ZM5 11C5 10.4477 5.44772 10 6 10H6.66667C7.21895 10 7.66667 10.4477 7.66667 11C7.66667 11.5523 7.21895 12 6.66667 12H6C5.44772 12 5 11.5523 5 11ZM8.66667 11C8.66667 10.4477 9.11438 10 9.66667 10H10.3333C10.8856 10 11.3333 10.4477 11.3333 11C11.3333 11.5523 10.8856 12 10.3333 12H9.66667C9.11438 12 8.66667 11.5523 8.66667 11ZM12.3333 11C12.3333 10.4477 12.781 10 13.3333 10H14C14.5523 10 15 10.4477 15 11C15 11.5523 14.5523 12 14 12H13.3333C12.781 12 12.3333 11.5523 12.3333 11ZM5 14C5 13.4477 5.44772 13 6 13H6.66667C7.21895 13 7.66667 13.4477 7.66667 14C7.66667 14.5523 7.21895 15 6.66667 15H6C5.44772 15 5 14.5523 5 14ZM8.66667 14C8.66667 13.4477 9.11438 13 9.66667 13H10.3333C10.8856 13 11.3333 13.4477 11.3333 14C11.3333 14.5523 10.8856 15 10.3333 15H9.66667C9.11438 15 8.66667 14.5523 8.66667 14Z'
      fill={color}
    />
  </Icon>
)

Dates.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Dates }
