import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Instagram: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.59322 6.01534C3.46499 7.30993 3.36064 8.82878 3.36064 10.3333C3.36064 11.7207 3.44942 13.0335 3.56302 14.1436C3.68818 15.3666 4.63917 16.3159 5.90305 16.4457C7.16149 16.5748 8.64065 16.6803 10.1052 16.6803C11.5037 16.6803 12.8931 16.5841 14.0844 16.4631C15.3564 16.3338 16.3367 15.3526 16.4667 14.07C16.5925 12.829 16.694 11.3806 16.694 9.94649C16.694 8.54275 16.5968 7.14515 16.4748 5.94738C16.3454 4.67648 15.368 3.69888 14.0926 3.56848C12.8878 3.4453 11.4895 3.34696 10.1052 3.34696C8.70824 3.34696 7.27318 3.4471 6.03049 3.57206C4.72665 3.70318 3.72262 4.70889 3.59322 6.01534ZM1.93467 5.85106C1.80301 7.18033 1.69397 8.75701 1.69397 10.3333C1.69397 11.7895 1.78703 13.1604 1.90502 14.3133C2.11271 16.3427 3.70352 17.8953 5.73287 18.1036C7.0271 18.2365 8.5662 18.347 10.1052 18.347C11.5758 18.347 13.0239 18.2461 14.2529 18.1212C16.3175 17.9114 17.9156 16.3028 18.1249 14.2381C18.2543 12.9614 18.3606 11.4539 18.3606 9.94649C18.3606 8.47033 18.2587 7.0138 18.1329 5.77855C17.9232 3.71872 16.3218 2.12105 14.2621 1.91046C13.021 1.78357 11.5632 1.6803 10.1052 1.6803C8.63461 1.6803 7.14053 1.78536 5.86373 1.91376C3.76917 2.12439 2.14217 3.75619 1.93467 5.85106Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.69397 10.3333C1.69397 8.75701 1.80301 7.18032 1.93467 5.85106C2.14218 3.75603 3.76933 2.12438 5.86373 1.91376C7.14051 1.78536 8.6346 1.6803 10.1052 1.6803C11.5632 1.6803 13.0211 1.78357 14.2621 1.91046C16.3221 2.12108 17.9232 3.71902 18.1329 5.77855C18.1486 5.93296 18.164 6.09082 18.1788 6.25175C18.2826 7.37823 18.3606 8.65484 18.3606 9.94649C18.3606 11.4539 18.2543 12.9614 18.1249 14.2381C18.1118 14.3672 18.0933 14.4945 18.0696 14.6198C18.0259 14.8508 17.9644 15.075 17.8867 15.2909C17.7538 15.6603 17.5731 16.0055 17.352 16.3191C17.0896 16.6911 16.7701 17.0188 16.4054 17.29C15.9907 17.5985 15.5175 17.834 15.0032 17.9788C14.7616 18.0469 14.5109 18.095 14.2529 18.1212C13.0239 18.2461 11.5758 18.347 10.1052 18.347C8.5662 18.347 7.02709 18.2365 5.73287 18.1036C3.70069 17.895 2.11242 16.3399 1.90502 14.3133C1.78703 13.1604 1.69397 11.7895 1.69397 10.3333ZM3.59322 6.01534C3.46499 7.30993 3.36064 8.82878 3.36064 10.3333C3.36064 11.7207 3.44942 13.0335 3.56302 14.1436C3.68818 15.3666 4.63917 16.3159 5.90305 16.4457C7.16149 16.5748 8.64065 16.6803 10.1052 16.6803C11.5037 16.6803 12.8931 16.5841 14.0844 16.4631C15.3564 16.3338 16.3367 15.3526 16.4667 14.07C16.5925 12.829 16.694 11.3806 16.694 9.94649C16.694 8.54275 16.5968 7.14515 16.4748 5.94738C16.4587 5.78852 16.4292 5.63424 16.3876 5.48562C16.0964 4.44532 15.2085 3.68258 14.0926 3.56848C12.8878 3.4453 11.4895 3.34696 10.1052 3.34696C8.70824 3.34696 7.27318 3.4471 6.03049 3.57206C4.72665 3.70318 3.72262 4.70889 3.59322 6.01534Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 12.5C11.3808 12.5 12.5 11.3808 12.5 10C12.5 8.61933 11.3808 7.50004 10 7.50004C8.61933 7.50004 7.50004 8.61933 7.50004 10C7.50004 11.3808 8.61933 12.5 10 12.5ZM14.1667 10C14.1667 12.3012 12.3012 14.1667 10 14.1667C7.69885 14.1667 5.83337 12.3012 5.83337 10C5.83337 7.69885 7.69885 5.83337 10 5.83337C12.3012 5.83337 14.1667 7.69885 14.1667 10Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1667 10C14.1667 12.3012 12.3012 14.1667 10 14.1667C7.69885 14.1667 5.83337 12.3012 5.83337 10C5.83337 7.69885 7.69885 5.83337 10 5.83337C12.3012 5.83337 14.1667 7.69885 14.1667 10ZM10 12.5C11.3808 12.5 12.5 11.3808 12.5 10C12.5 8.61933 11.3808 7.50004 10 7.50004C8.61933 7.50004 7.50004 8.61933 7.50004 10C7.50004 11.3808 8.61933 12.5 10 12.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5273 6.01359C14.9875 6.01359 15.3606 5.64049 15.3606 5.18026C15.3606 4.72002 14.9875 4.34692 14.5273 4.34692C14.3054 4.34692 14.1038 4.43362 13.9545 4.57499C13.7941 4.72688 13.694 4.94188 13.694 5.18026C13.694 5.64049 14.0671 6.01359 14.5273 6.01359Z'
    />
  </Icon>
)

Instagram.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export { Instagram }
