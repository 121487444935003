import { Transition } from 'react-transition-group'

import { TableOverlayWrap } from 'ui/table/components/TableOverlay/styles'

import { getFadeStyles } from 'utils/getTransitionStyles'

import type { FC } from 'react'
import type { SpaceProps, PositionProps } from 'styled-system'

export interface TableOverlayProps {
  placement?: 'center' | 'right' | 'left'
  show?: boolean
}

const transitionDuration = 150

const TableOverlay: FC<TableOverlayProps & SpaceProps & PositionProps> = ({
  show,
  children,
  top,
  ...props
}) => (
  <Transition in={show} timeout={transitionDuration} unmountOnExit>
    {state => (
      <TableOverlayWrap
        style={{
          top,
          ...getFadeStyles(transitionDuration).default,
          ...getFadeStyles(transitionDuration).transition[state],
        }}
        {...props}
      >
        {children}
      </TableOverlayWrap>
    )}
  </Transition>
)

export { TableOverlay }
