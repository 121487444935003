import { Table } from 'ui'

import { Sort } from 'ui/table/components'

import type { FC } from 'react'
import type { ColumnsType } from 'ui/table/types'

const columns: ColumnsType<unknown> = [
  {
    title: 'Статус заказа',
    dataIndex: 'status',
    key: 'status',
    width: 152,
  },
  {
    title: 'Изменение',
    dataIndex: 'change',
    key: 'change',
  },
  {
    title: 'Имя и Фамилия',
    dataIndex: 'name',
    key: 'name',
    width: 188,
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    width: 165,
  },
  {
    title: (
      <Sort
        onChangeSorting={() => console.log('change')}
        title='Дата и время'
        sorting='sort'
        desc='DESC'
        asc='ASC'
      />
    ),
    dataIndex: 'date',
    key: 'date',
    width: 210,
  },
]

const OrderDetailsHistory: FC = () => {
  return <Table columns={columns} data={[]} />
}

export { OrderDetailsHistory }
