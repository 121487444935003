import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Credit: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9C14 9.55228 14.4477 10 15 10C15.5523 10 16 9.55228 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.0494 3.94932 14.641 6.66675 15.6014C7.18747 15.7855 7.75879 15.5126 7.94284 14.9918C8.12689 14.4711 7.85396 13.8998 7.33325 13.7157C5.38991 13.0289 4 11.1755 4 9Z'
      fill={color}
    />
    <path
      d='M9 5C9.55228 5 10 5.44772 10 6V9C10 9.55228 9.55228 10 9 10H7C6.44772 10 6 9.55228 6 9C6 8.44772 6.44772 8 7 8H8V6C8 5.44772 8.44772 5 9 5Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 11C12.8073 11 11.6829 11.1796 10.8204 11.503C10.3929 11.6633 9.97747 11.8784 9.65093 12.1679C9.32556 12.4563 9 12.9042 9 13.5C9 14.0958 9.32556 14.5437 9.65093 14.8321C9.97747 15.1216 10.3929 15.3367 10.8204 15.497C11.6829 15.8204 12.8073 16 14 16C15.1927 16 16.3171 15.8204 17.1796 15.497C17.6071 15.3367 18.0225 15.1216 18.3491 14.8321C18.6744 14.5437 19 14.0958 19 13.5C19 12.9042 18.6744 12.4563 18.3491 12.1679C18.0225 11.8784 17.6071 11.6633 17.1796 11.503C16.3171 11.1796 15.1927 11 14 11ZM11.5227 13.3757C11.4092 13.4182 11.3143 13.4603 11.2358 13.5C11.3143 13.5397 11.4092 13.5818 11.5227 13.6243C12.108 13.8438 12.9836 14 14 14C15.0164 14 15.892 13.8438 16.4773 13.6243C16.5908 13.5818 16.6857 13.5397 16.7642 13.5C16.6857 13.4603 16.5908 13.4182 16.4773 13.3757C15.892 13.1562 15.0164 13 14 13C12.9836 13 12.108 13.1562 11.5227 13.3757Z'
      fill={color}
    />
    <path
      d='M10.9553 16.3141C10.5765 15.9122 9.94362 15.8935 9.54172 16.2723C9.13981 16.6511 9.12109 17.284 9.4999 17.6859C9.96491 18.1793 10.6887 18.4904 11.4061 18.684C12.1596 18.8872 13.0544 19 14 19C14.9456 19 15.8403 18.8872 16.5938 18.684C17.3113 18.4904 18.0351 18.1793 18.5001 17.6859C18.8789 17.284 18.8602 16.6511 18.4583 16.2723C18.0564 15.8935 17.4235 15.9122 17.0447 16.3141C16.9606 16.4033 16.669 16.5922 16.0729 16.753C15.5129 16.9041 14.796 17 14 17C13.204 17 12.4871 16.9041 11.9271 16.753C11.331 16.5922 11.0394 16.4033 10.9553 16.3141Z'
      fill={color}
    />
  </Icon>
)

Credit.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Credit }
