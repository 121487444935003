import { styled } from 'ui/theme'

interface PartnersProps {
  scrollable: boolean
}

export const Partners = styled.div<PartnersProps>`
  margin: 0 -24px 24px;

  ${props =>
    props.scrollable &&
    `
    max-height: 256px;
    overflow: auto;
    padding: 16px 0;
    border-top: 1px solid ${props.theme.greyColorPalette[10]};
    border-bottom: 1px solid ${props.theme.greyColorPalette[10]};
  `}
`

export const Partner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  margin: 0 24px;
  border: 1px solid ${props => props.theme.greyColorPalette[10]};
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
