import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AppThunk, instance } from 'store/configureStore'

import { removeEmptyStrings } from 'utils/removeEmptyStrings'
import { thunkErrorHandler } from 'utils/errorHandler'
import { toast } from 'ui'

import {
  ProductCategory,
  ProductsState,
  Product,
  DisableManyProductsOptions,
  EnableManyProductsOptions,
  AddInstallmentOptions,
  FetchProductsOptions,
  DisableProductOptions,
  EnableProductOptions,
} from 'modules/products/types'

/**
 * Init State
 */

const initialState: ProductsState = {
  products: [],
  productsLoading: false,
  productsError: null,

  categories: [],
  categoriesLoading: false,
  categoriesError: null,

  addInstallmentLoading: false,
  addInstallmentError: null,

  diableManyProductsLoading: false,
  disableManyProductsError: null,

  enableManyProductsLoading: false,
  enableManyProductsError: null,

  filters: {
    search: '',
    category: [''],
    withInstallment: false,
  },

  pagination: {
    page: 1,
    limit: 20,
    total: null,
  },
}

/**
 * Async Actions
 */

export const fetchProducts: AppThunk<Product[], FetchProductsOptions> =
  createAsyncThunk('products/fetch', (options, thunkApi) => {
    const { limit, page } = thunkApi.getState().products.products.pagination
    const { search, category, withInstallment } = removeEmptyStrings(options)

    const config = {
      params: {
        search,
        category,
        with_installment: withInstallment,
        limit,
        page,
      },
    }

    const promise = instance(thunkApi)
      .get(`/storefront/api/v1/merchants/products-info`, config)
      .then(res => {
        thunkApi.dispatch(productsSlice.actions.changeTotal(res.data.total))
        return res.data.products
      })
      .catch(thunkErrorHandler(thunkApi))

    return promise
  })

export const fetchCategories: AppThunk<ProductCategory[]> = createAsyncThunk(
  'products/categories/fetch',
  (_, thunkApi) => {
    const promise = instance(thunkApi)
      .get(`/storefront/api/v1/merchants/categories`)
      .then((res: AxiosResponse<ProductCategory[]>) => {
        const categories = res.data
        thunkApi.dispatch(
          changeCategoryFilter([
            '',
            ...categories.map(category => category.slug),
          ]),
        )
        return categories
      })
      .catch(thunkErrorHandler(thunkApi))

    return promise
  },
)

export const addInstallment: AppThunk<void, AddInstallmentOptions> =
  createAsyncThunk('products/installment/add', (options, thunkApi) => {
    const { skus, onClose } = options
    const { mid } = thunkApi.getState().auth.credentials

    return Promise.allSettled(
      skus.map(sku =>
        instance(thunkApi)
          .post(`/payplan/api/v1/installments/products`, {
            merchant_id: mid,
            sku,
          })
          .then(res => res),
      ),
    )
      .then(res => {
        const fulfilledCount = res.filter(
          response => response.status === 'fulfilled',
        ).length
        if (fulfilledCount > 0) {
          toast(`Товары добавлены в рассрочку`)
          if (typeof onClose === 'function') {
            onClose()
          }
        } else {
          toast(`Ошибка`)
        }
      })
      .catch(thunkErrorHandler(thunkApi))
  })

export const disableProduct: AppThunk<Product[], DisableProductOptions> =
  createAsyncThunk('products/disable', (options, thunkApi) => {
    const { sku } = options

    const promise = instance(thunkApi)
      .put(`/storefront/api/v1/merchants/products/${sku}/disable`)
      .then(() => {
        const { products } = thunkApi.getState().products.products

        toast(`Товар снят с продажи`)

        return products.map(product =>
          product.sku === sku ? { ...product, is_enabled: false } : product,
        )
      })
      .catch(thunkErrorHandler(thunkApi))

    return promise
  })

export const enableProduct: AppThunk<Product[], EnableProductOptions> =
  createAsyncThunk('products/enable', (options, thunkApi) => {
    const { sku } = options

    const promise = instance(thunkApi)
      .put(`/storefront/api/v1/merchants/products/${sku}/enable`)
      .then(() => {
        const { products } = thunkApi.getState().products.products

        toast(`Товар добавлен в продажу`)

        return products.map(product =>
          product.sku === sku ? { ...product, is_enabled: true } : product,
        )
      })
      .catch(thunkErrorHandler(thunkApi))

    return promise
  })

export const disableManyProducts: AppThunk<
  Product[],
  DisableManyProductsOptions
> = createAsyncThunk('products/disableMany', (options, thunkApi) => {
  const { skus } = options

  const promise = instance(thunkApi)
    .put(`/storefront/api/v1/merchants/products/disableMany`, { skus })
    .then(() => {
      const { products } = thunkApi.getState().products.products

      toast(`Товары сняты с продажи`)

      return products.map(product =>
        skus.some(sku => sku === product.sku)
          ? { ...product, is_enabled: false }
          : product,
      )
    })
    .catch(thunkErrorHandler(thunkApi))

  return promise
})

export const enableManyProducts: AppThunk<
  Product[],
  EnableManyProductsOptions
> = createAsyncThunk('products/enableMany', (options, thunkApi) => {
  const { skus } = options

  const promise = instance(thunkApi)
    .put(`/storefront/api/v1/merchants/products/enableMany`, { skus })
    .then(() => {
      const { products } = thunkApi.getState().products.products

      toast(`Товары добавлены в продажу`)

      return products.map(product =>
        skus.some(sku => sku === product.sku)
          ? { ...product, is_enabled: true }
          : product,
      )
    })
    .catch(thunkErrorHandler(thunkApi))

  return promise
})

export const changeProductsPage: AppThunk<void, number> = createAsyncThunk(
  'products/changePage',
  (page, thunkApi) => {
    thunkApi.dispatch(productsSlice.actions.changePage(page))
    thunkApi.dispatch(fetchProducts({}))
  },
)

export const changeProductsLimit: AppThunk<void, number> = createAsyncThunk(
  'products/changeLimit',
  (limit, thunkApi) => {
    thunkApi.dispatch(productsSlice.actions.changeLimit(limit))
    thunkApi.dispatch(fetchProducts({}))
  },
)

/**
 * Reducer
 */

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    changeLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload
    },
    changeSearchFilter: (state, action: PayloadAction<string>) => {
      state.pagination.page = 1
      state.filters.search = action.payload
    },
    changeCategoryFilter: (state, action: PayloadAction<string | string[]>) => {
      state.pagination.page = 1
      state.filters.category = action.payload
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.pagination.total = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.pending, state => {
        state.productsLoading = true
        state.productsError = null
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsLoading = false
        state.products = action.payload
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.productsLoading = false
        state.productsError = action.payload.error
      })

      .addCase(fetchCategories.pending, state => {
        state.categoriesLoading = true
        state.categoriesError = null
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categoriesLoading = false
        state.categories = action.payload
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.categoriesLoading = false
        state.categoriesError = action.payload.error
      })

      .addCase(addInstallment.pending, state => {
        state.addInstallmentLoading = true
        state.addInstallmentError = null
      })
      .addCase(addInstallment.fulfilled, state => {
        state.addInstallmentLoading = false
      })
      .addCase(addInstallment.rejected, (state, action) => {
        state.addInstallmentLoading = false
        state.addInstallmentError = action.payload.error
      })

      .addCase(disableProduct.fulfilled, (state, action) => {
        state.products = action.payload
      })

      .addCase(enableProduct.fulfilled, (state, action) => {
        state.products = action.payload
      })

      .addCase(disableManyProducts.pending, state => {
        state.diableManyProductsLoading = true
        state.disableManyProductsError = null
      })
      .addCase(disableManyProducts.fulfilled, (state, action) => {
        state.diableManyProductsLoading = false
        state.products = action.payload
      })
      .addCase(disableManyProducts.rejected, (state, action) => {
        state.diableManyProductsLoading = false
        state.disableManyProductsError = action.payload.error
      })

      .addCase(enableManyProducts.pending, state => {
        state.enableManyProductsLoading = true
        state.enableManyProductsError = null
      })
      .addCase(enableManyProducts.fulfilled, (state, action) => {
        state.enableManyProductsLoading = false
        state.products = action.payload
      })
      .addCase(enableManyProducts.rejected, (state, action) => {
        state.enableManyProductsLoading = false
        state.enableManyProductsError = action.payload.error
      })
  },
})

export const { changeSearchFilter, changeCategoryFilter } =
  productsSlice.actions

export default productsSlice.reducer
