import { StyledAnchor } from './styles'

import type { FC } from 'react'
import type { AnchorProps } from './types'

const Anchor: FC<AnchorProps> = ({
  color = 'text.link',
  fontSize = 'body.m',
  ...props
}) => {
  const handleClick = event => {
    event.stopPropagation()
  }

  return (
    <StyledAnchor
      onClick={handleClick}
      fontSize={fontSize}
      color={color}
      {...props}
    />
  )
}

export { Anchor }
