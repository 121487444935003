import { styled } from 'ui/theme'
import { sizes } from 'ui/inputs/Select/styles'
import { ifProp } from 'styled-tools'

import type { DatePickerInputWrapProps } from 'ui/datePicker/types'

export const DatepickerWrap = styled.div`
  .DayPicker {
    display: inline-block;
    background: ${props => props.theme.colors.main.background.white};
    filter: drop-shadow(0px 8px 14px rgba(16, 20, 45, 0.16))
      drop-shadow(0px 3.87387px 9.86002px rgba(16, 20, 45, 0.0898405));
    border-radius: 4px;
    font-family: ${props => props.theme.fontFamily.ms};

    &--interactionDisabled .DayPicker-Day {
      cursor: default;
    }

    &-wrapper {
      position: relative;
      flex-direction: row;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .DayPicker-Months {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .DayPicker-Month {
          margin: 16px 12px;

          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .DayPicker-Caption {
            margin-bottom: 12px;
            margin-left: 8px;

            & > div {
              font-weight: ${props => props.theme.fontWeights.semibold};
              font-size: ${props => props.theme.fontSizes.subtitle.s};
              line-height: ${props => props.theme.lineHeights.l};
              color: ${props => props.theme.colors.text.black};
            }
          }

          .DayPicker-Weekdays {
            display: table-header-group;

            .DayPicker-WeekdaysRow {
              display: table-row;

              .DayPicker-Weekday {
                display: table-cell;
                padding: 8px;
                text-align: center;
                color: ${props => props.theme.colors.text.secondaryBlack};
                font-size: ${props => props.theme.fontSizes.body.m};
                line-height: ${props => props.theme.lineHeights.m};

                abbr[title] {
                  border-bottom: none;
                  text-decoration: none;
                }
              }
            }
          }

          .DayPicker-Body {
            display: table-row-group;

            .DayPicker-Week {
              display: table-row;

              .DayPicker-Day {
                display: table-cell;
                border-radius: 50%;
                text-align: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                box-sizing: border-box;
                font-size: ${props => props.theme.fontSizes.body.m};
                line-height: ${props => props.theme.lineHeights.m};
                color: ${props => props.theme.colors.text.black};
                padding: 8px;
                transition: all 0.1s;

                &--today {
                  color: ${props => props.theme.colors.projects.primaryMarket};
                }

                &--weekends {
                  color: ${props => props.theme.colors.main.special.discount};
                }

                &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                  position: relative;
                  background-color: ${props =>
                    props.theme.colors.projects.primaryMarket};
                }

                &--outside {
                  color: ${props => props.theme.colors.text.secondaryBlack};
                  cursor: default;
                }

                &--disabled {
                  color: #dce0e0;
                  cursor: default;
                }
              }
            }
          }
        }
      }

      .DayPicker-NavButton {
        position: absolute;
        top: 1em;
        left: auto;
        display: inline-block;
        width: 28px;
        height: 28px;
        box-sizing: border-box;
        background-position: center;
        background-repeat: no-repeat;
        background-color: ${props => props.theme.colors.projects.primaryMarket};
        cursor: pointer;
        border-radius: 4px;
        background-size: 80%;

        &--prev {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='white' d='M11.7071 14.7071C12.0976 14.3166 12.0976 13.6834 11.7071 13.2929L8.41421 10L11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071L10.2929 14.7071C10.6834 15.0976 11.3166 15.0976 11.7071 14.7071Z'/%3e%3c/svg%3e");
          right: 48px;
        }

        &--next {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='white' d='M8.29289 5.29289C7.90237 5.68342 7.90237 6.31658 8.29289 6.70711L11.5858 10L8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071C8.68342 15.0976 9.31658 15.0976 9.70711 14.7071L13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289L9.7071 5.29289C9.31658 4.90237 8.68342 4.90237 8.29289 5.29289Z'/%3e%3c/svg%3e");
          right: 12px;
        }

        &--interactionDisabled {
          display: none;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .DayPicker-Day--outside {
    color: ${props => props.theme.colors.text.secondaryBlack};
    cursor: default;
  }

  /* Example modifiers */

  .DayPicker-Day--sunday {
    background-color: #f7f8f8;
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #51a0fa;
  }

  .DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: ${props => props.theme.greyColorPalette[5]};
    }
  }

  .__datepicker {
    .DayPicker-Day {
      &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        color: ${props => props.theme.colors.text.white} !important;
      }
    }
  }

  .__rangePicker {
    .DayPicker-Month {
      &:first-of-type {
        border-right: 1px solid ${props => props.theme.greyColorPalette[10]};
        margin-right: 0;
        padding-right: 16px;
      }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: ${props =>
        props.theme.colors.projects.primaryLightMarket} !important;
    }

    .DayPicker-Day {
      border-radius: 0 !important;
    }

    .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
      color: ${props => props.theme.colors.text.white} !important;
    }

    .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
      color: ${props => props.theme.colors.text.white} !important;
    }
  }
`

export const DatePickerInputWrap = styled.div<DatePickerInputWrapProps>`
  border: 1px solid ${props => props.theme.colors.text.secondaryBlack};
  font-size: ${props => props.theme.fontSizes.body.m};
  line-height: ${props => props.theme.lineHeights.m};
  color: ${props => props.theme.colors.text.black};
  border-radius: ${props => props.theme.radii.xxs};
  display: inline-flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  padding: 0 40px 1px 12px;
  box-sizing: border-box;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;

  ${props =>
    ifProp(
      'active',
      `
    border: 1px solid ${props.theme.colors.projects.primaryMarket};
  `,
    )}
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

  .__field-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    font-size: 0;
    transform: translateY(-50%);
  }

  ${sizes};
`
