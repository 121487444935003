import {
  Installments,
  AddProductsToInstallment,
} from 'modules/installments/pages'

import { getDashedStr } from 'utils/getDashedStr'

enum KEYS {
  INSTALLMENTS = 'INSTALLMENTS',
  INSTALLMENT = 'INSTALLMENT',
}

export const getRoutes = key => [
  {
    path: '/installments',
    exact: true,
    key: getDashedStr(key, KEYS.INSTALLMENTS),
    component: Installments,
  },
  {
    path: '/installments/:installmentId',
    exact: true,
    key: getDashedStr(key, KEYS.INSTALLMENT),
    component: AddProductsToInstallment,
  },
]
