import { useChangeOrderPickupPoint } from 'modules/orders/hooks'

import { PickupPointSelection, PickupPoint, Info, Tip } from './components'

import { Box } from 'ui'

import type { FC } from 'react'

const OrderDetailsInfo: FC = () => {
  const { activePickupPoint, onChangePickupPoint } = useChangeOrderPickupPoint()

  return (
    <Box display='flex'>
      <Box mr='73px'>
        <Info />
        <PickupPoint activePickupPoint={activePickupPoint} />
      </Box>

      <Box>
        <Tip />
        <PickupPointSelection
          activePickupPoint={activePickupPoint}
          onChangePickupPoint={onChangePickupPoint}
        />
      </Box>
    </Box>
  )
}

export { OrderDetailsInfo }
