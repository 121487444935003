export const allRefundReasons = [
  'all',
  'defects',
  'changed_mind',
  'damaged',
  'quality',
  'other',
]

export const allRefundTypes = ['all', 'full', 'partial']

export const tableFilters = [
  {
    name: 'Все заявки',
    value: 'ALL',
  },
  {
    name: 'Ждёт подтверждения',
    value: 'WAITING_APPROVE',
  },
  {
    name: 'В работе',
    value: 'IN_PROGRESS',
  },
  {
    name: 'Спор',
    value: 'DISPUTE',
  },
  {
    name: 'Завершённые',
    value: 'DONE',
  },
]
