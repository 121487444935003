import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Ellipsis: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M9.69352 19.62C9.10685 19.62 8.60018 19.4133 8.17352 19C7.76018 18.5867 7.55352 18.0667 7.55352 17.44C7.55352 16.8267 7.76018 16.3133 8.17352 15.9C8.60018 15.4867 9.10685 15.28 9.69352 15.28C10.2802 15.28 10.7868 15.4867 11.2135 15.9C11.6402 16.3133 11.8535 16.8267 11.8535 17.44C11.8535 18.0667 11.6402 18.5867 11.2135 19C10.7868 19.4133 10.2802 19.62 9.69352 19.62ZM16.4904 19.62C15.9037 19.62 15.3971 19.4133 14.9704 19C14.5571 18.5867 14.3504 18.0667 14.3504 17.44C14.3504 16.8267 14.5571 16.3133 14.9704 15.9C15.3971 15.4867 15.9037 15.28 16.4904 15.28C17.0771 15.28 17.5837 15.4867 18.0104 15.9C18.4371 16.3133 18.6504 16.8267 18.6504 17.44C18.6504 18.0667 18.4371 18.5867 18.0104 19C17.5837 19.4133 17.0771 19.62 16.4904 19.62ZM23.2873 19.62C22.7006 19.62 22.1939 19.4133 21.7673 19C21.3539 18.5867 21.1473 18.0667 21.1473 17.44C21.1473 16.8267 21.3539 16.3133 21.7673 15.9C22.1939 15.4867 22.7006 15.28 23.2873 15.28C23.8739 15.28 24.3806 15.4867 24.8073 15.9C25.2339 16.3133 25.4473 16.8267 25.4473 17.44C25.4473 18.0667 25.2339 18.5867 24.8073 19C24.3806 19.4133 23.8739 19.62 23.2873 19.62Z'
      fill={color}
      fillOpacity='0.25'
    />
  </Icon>
)

Ellipsis.defaultProps = {
  originalWidth: 32,
  originalHeight: 32,
  width: 32,
  height: 32,
  color: '#000000',
}

export { Ellipsis }
