import { combineReducers } from 'redux'

import settings from 'modules/profile/slices/settings'
import pickupPoints from 'modules/profile/slices/pickupPoints'
import employees from 'modules/profile/slices/employees'
import profile from 'modules/profile/slices/profile'

export default combineReducers({
  pickupPoints,
  employees,
  settings,
  profile,
})
