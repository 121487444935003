import { Redirect } from 'react-router-dom'

import { SignIn } from 'modules/auth/pages'

import { getDashedStr } from 'utils/getDashedStr'

import { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  REDIRECT = 'REDIRECT',
  SIGN_IN = 'SIGN_IN',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/auth',
    exact: true,
    key: getDashedStr(key, KEYS.REDIRECT),
    component: () => <Redirect to='/auth/sign-in' />,
  },
  {
    path: '/auth/sign-in',
    exact: false,
    key: getDashedStr(key, KEYS.SIGN_IN),
    component: SignIn,
  },
]
