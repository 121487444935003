import { Input, Label, Slider, Base } from './styles'
import { Typography } from 'ui'

import type { FC } from 'react'
import type { SwitchProps } from './types'

const { Body } = Typography

const Switch: FC<SwitchProps> = ({ name, label = '', checked, onChange }) => {
  return (
    <Label>
      {Boolean(label) && <Body weight='semibold'>{label}</Body>}
      <Input
        name={name}
        type='checkbox'
        checked={checked}
        onChange={onChange}
      />
      <Base>
        <Slider />
      </Base>
    </Label>
  )
}

export { Switch }
