import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchInstructionPage } from 'modules/faq/slices/instructions'

import type { Instruction } from '../types'

const useInstructionPage = (slug: Instruction['slug']) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchInstructionPage({ slug }))
  }, [dispatch, slug])

  const { instructionPage } = useAppSelector(state => state.faq.instructions)

  return { instructionPage }
}

export { useInstructionPage }
