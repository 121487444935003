import { useAppSelector } from 'store/hooks'

import { useIsOrderAccepted } from 'modules/orders/hooks'
import { Box, Select } from 'ui'

import type { FC } from 'react'
import type { PickupPointType } from 'modules/profile/types'

interface PickupPointSelectionProps {
  activePickupPoint: PickupPointType
  onChangePickupPoint: (ppId: string) => void
}

const PickupPointSelection: FC<PickupPointSelectionProps> = ({
  activePickupPoint,
  onChangePickupPoint,
}) => {
  const isOrderAccepted = useIsOrderAccepted()
  const pickupPoints = useAppSelector(state => state.profile.pickupPoints.list)
  const loading = useAppSelector(state => state.orders.orders.loadingOfChanging)
  const details = useAppSelector(state => state.orders.orders.details)

  return details.storage_type === 'fbs' &&
    Boolean(activePickupPoint) &&
    !isOrderAccepted ? (
    <Box maxWidth='296px'>
      <Select
        value={activePickupPoint.store_id}
        onChange={onChangePickupPoint}
        maxTagTextLength={5}
        disabled={loading}
        subText='Вы можете поменять точку выдачи'
        label='Точка выдачи'
        data-test-id='PickupPointList'
      >
        {pickupPoints.map(pickupPoint => (
          <Select.Option
            value={pickupPoint.store_id}
            key={pickupPoint.store_id}
          >
            {pickupPoint.address.street} {pickupPoint.address.house}, офис{' '}
            {pickupPoint.address.apartment}, этаж {pickupPoint.address.floor}
          </Select.Option>
        ))}
      </Select>
    </Box>
  ) : null
}

export { PickupPointSelection }
