import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { orderDecision } from 'modules/orders/slices/orders'

import { Radio, Textarea, Typography, Box, Modal } from 'ui'

import type { FC } from 'react'
import type { OrderType } from 'modules/orders/types'

interface RejectReasonProps {
  visible: boolean
  details?: OrderType
  onClose: VoidFunction
}

const { Caption, Body } = Typography

const RejectReasonModal: FC<RejectReasonProps> = ({
  visible,
  details,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(state => state.orders.orders.loadingOfDecision)

  const [radioValue, setRadioValue] = useState('not_available')
  const [reasonValue, setReasonValue] = useState('')

  return (
    <Modal
      width={365}
      okText='Готово'
      title='Отмена заказа'
      visible={visible}
      onClose={onClose}
      onCancel={onClose}
      okLoading={loading}
      onOk={() => {
        dispatch(
          orderDecision({
            details,
            closeCb: onClose,
            solution: 'reject',
            refresh: details ? 'both' : undefined,
            reason:
              radioValue === 'not_available' ? 'Нет в наличии' : reasonValue,
          }),
        )
      }}
    >
      <Body as='p' mb='16px'>
        Выберите причину отмены заказа
      </Body>

      <Box mb='16px'>
        <Radio
          id='order:not_available'
          name='reject_reason'
          label='Нет в наличии'
          defaultChecked={radioValue === 'not_available'}
          onChange={() => setRadioValue('not_available')}
        />

        <Caption as='p' mt='4px' color='text.secondaryBlack'>
          У вас снизится рейтинг при отклонении заказа. Рекомендуем снять с
          продажи товары, которых нет в наличии.{' '}
          <Typography color='text.link'>Подробнее про отмену заказа</Typography>
        </Caption>
      </Box>

      <Radio
        id='order:other_reason'
        name='reject_reason'
        label='Другая причина'
        onChange={() => setRadioValue('other_reason')}
      />

      {radioValue === 'other_reason' && (
        <Box mt='8px'>
          <Textarea
            label='Причина отмены'
            value={reasonValue}
            onChange={event => setReasonValue(event.target.value)}
            placeholder='Введите причину отмены'
          />
        </Box>
      )}
    </Modal>
  )
}

export { RejectReasonModal }
