import { Link } from 'react-router-dom'

import { NotificationsWidget, ProfileWidget } from './components'

import { Box } from 'ui'
import { LogoIcon } from 'ui/icons'

import { styled } from 'ui/theme'
import { mainHeader } from 'modules/common/constants/properties'

import type { FC } from 'react'

const HeaderWrapper = styled.header`
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${mainHeader.height};
  padding-right: 52px;

  border-bottom: 1px solid ${props => props.theme.greyColorPalette[10]};
  background-color: ${props => props.theme.colors.main.background.white};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 0;
`

interface Props {
  isEmpty?: boolean
}

const Header: FC<Props> = ({ isEmpty = false }) => (
  <HeaderWrapper>
    <Box
      display='flex'
      width='344px'
      alignItems='center'
      justifyContent='space-between'
      pl='36px'
      pr='12px'
    >
      <StyledLink to='/'>
        <LogoIcon />
      </StyledLink>

      {!isEmpty && <NotificationsWidget />}
    </Box>

    {!isEmpty && <ProfileWidget />}
  </HeaderWrapper>
)

export { Header }
