import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchAvailableProductsCategories } from '../slices/products'

const useAvailableCategories = (installmentId: string) => {
  const dispatch = useAppDispatch()
  const { availableCategories } = useAppSelector(
    state => state.installments.products,
  )

  useEffect(() => {
    dispatch(fetchAvailableProductsCategories({ installmentId }))
  }, [installmentId, dispatch])

  return { availableCategories }
}

export { useAvailableCategories }
