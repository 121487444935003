import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchLastSuccessfulUpload } from '../slices/productsFeed'

const useLastSuccessfulUpload = () => {
  const dispatch = useAppDispatch()
  const { lastSuccessfulUpload } = useAppSelector(
    state => state.products.productsFeed,
  )

  useEffect(() => {
    dispatch(fetchLastSuccessfulUpload())
  }, [dispatch])

  return { lastSuccessfulUpload }
}

export { useLastSuccessfulUpload }
