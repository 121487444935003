import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { fetchCount } from 'modules/notifications/slices/notifications'

import { NotificationStatus } from 'modules/notifications/types'

const intervalTime = 10000

const useNotificationsCount = () => {
  const dispatch = useAppDispatch()
  const { count } = useAppSelector(state => state.notifications.notifications)

  useEffect(() => {
    dispatch(fetchCount({ status: NotificationStatus.New }))

    const timer = setInterval(() => {
      dispatch(fetchCount({ status: NotificationStatus.New }))
    }, intervalTime)

    return () => clearInterval(timer)
  }, [dispatch])

  return { count }
}

export { useNotificationsCount }
