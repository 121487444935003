import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, instance } from 'store/configureStore'

import { removeEmptyStrings } from 'utils/removeEmptyStrings'
import { thunkErrorHandler } from 'utils/errorHandler'

import {
  UnrelatedProductsState,
  Product,
  FetchUnrelatedProductsOptions,
} from '../types'

export const fetchUnrelatedProducts: AppThunk<
  Product[],
  FetchUnrelatedProductsOptions
> = createAsyncThunk('products/unrelated/fetch', (options, thunkApi) => {
  const { search } = removeEmptyStrings(options)
  const pagination = thunkApi.getState().products.unrelatedProducts.pagination

  const promise = instance(thunkApi)
    .get('/merchant-gateway/api/v1/merchant/documents/products', {
      params: { search, limit: pagination.limit, page: pagination.page },
    })
    .then(res => {
      thunkApi.dispatch(
        unrelatedProductsSlice.actions.changePaginationTotal(res.data.count),
      )
      if (!search) {
        thunkApi.dispatch(
          unrelatedProductsSlice.actions.changeTotal(res.data.count),
        )
      }
      return res.data.products
    })
    .catch(thunkErrorHandler(thunkApi))
  return promise
})

export const changeUnrelatedProductsPage: AppThunk<void, number> =
  createAsyncThunk('products/unrelated/changePage', (page, thunkApi) => {
    thunkApi.dispatch(unrelatedProductsSlice.actions.changePage(page))
    thunkApi.dispatch(fetchUnrelatedProducts({}))
  })

export const changeUnrelatedProductsLimit: AppThunk<void, number> =
  createAsyncThunk('products/unrelated/changeLimit', (limit, thunkApi) => {
    thunkApi.dispatch(unrelatedProductsSlice.actions.changeLimit(limit))
    thunkApi.dispatch(fetchUnrelatedProducts({}))
  })

const initialState: UnrelatedProductsState = {
  unrelatedProducts: [],
  unrelatedProductsLoading: false,
  unrelatedProductsError: null,
  unrelatedProductsTotal: null,

  filters: {
    search: '',
  },

  pagination: {
    page: 1,
    limit: 20,
    total: null,
  },
}

const unrelatedProductsSlice = createSlice({
  name: 'unrelatedProducts',
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    changeLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload
    },
    changePaginationTotal: (state, action: PayloadAction<number>) => {
      state.pagination.total = action.payload
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.unrelatedProductsTotal = action.payload
    },
    changeSearchFilter: (state, action: PayloadAction<string>) => {
      state.pagination.page = 1
      state.filters.search = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUnrelatedProducts.pending, state => {
        state.unrelatedProductsLoading = true
        state.unrelatedProductsError = null
      })
      .addCase(fetchUnrelatedProducts.fulfilled, (state, action) => {
        state.unrelatedProductsLoading = false
        state.unrelatedProducts = action.payload
      })
      .addCase(fetchUnrelatedProducts.rejected, (state, action) => {
        state.unrelatedProductsLoading = false
        state.unrelatedProductsError = action.payload.error
      })
  },
})

export const { changeSearchFilter } = unrelatedProductsSlice.actions

export default unrelatedProductsSlice.reducer
