import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { matchNumberMask } from 'utils/matchInputMask'
import { Box, Button, FormField, Typography } from 'ui'

import { requestOTP, verifyOTP } from 'modules/auth/slices/auth'

import type { FC } from 'react'
import type { AuthInputError } from 'modules/auth/types/interfaces'

interface SmsFormProps {
  phone: string
}

let counter

const { Body } = Typography

const SmsForm: FC<SmsFormProps & AuthInputError> = ({
  phone,
  error,
  setErrorStatus,
}) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(state => state.auth.auth.loading)

  const [code, setCode] = useState('')
  const [time, setTime] = useState(60)

  useEffect(() => {
    if (time > 0) {
      counter = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    }

    return () => {
      clearTimeout(counter)
    }
  })

  const onSubmit = event => {
    event.preventDefault()

    const matchedPhone = matchNumberMask(phone)
    const matchedCode = matchNumberMask(code)

    if (matchedCode.length === 4) {
      dispatch(
        verifyOTP({
          phone: matchedPhone,
          otp: matchedCode,
        }),
      )

      setErrorStatus(false)
    } else {
      setErrorStatus(true)
    }
  }

  return (
    <Box>
      <Body as='p'>
        Отправили SMS с кодом <br /> на {phone}
      </Body>

      <Box
        className='__auth-text-link'
        display='inline-block'
        mb='16px'
        mt='4px'
        onClick={() => window.location.reload()}
      >
        <Body color='text.link'>Изменить номер</Body>
      </Box>

      <form onSubmit={onSubmit}>
        <FormField
          type='text'
          size='large'
          maskType='CODE'
          label='SMS-код'
          placeholder='Введите код из SMS'
          value={code}
          error={error}
          onChange={e => setCode(e.target.value)}
          onClear={() => {
            setErrorStatus(false)
            setCode('')
          }}
        />

        <Button
          mt='4px'
          size='nano'
          type='button'
          variant='secondary'
          disabled={time !== 0}
          fullWidth
          onClick={() => {
            setTime(60)

            dispatch(
              requestOTP({
                phone: matchNumberMask(phone),
              }),
            )
          }}
        >
          {time === 0
            ? 'Выслать код повторно'
            : `Выслать код повторно через ${time} сек.`}
        </Button>

        <Button mt='24px' type='submit' loading={loading} fullWidth>
          Продолжить
        </Button>
      </form>
    </Box>
  )
}

export { SmsForm }
