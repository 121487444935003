import { MiniSwitchOnIcon, MiniSwitchOffIcon } from 'ui/icons'
import { Box, Checkbox, ActionButton, Typography } from 'ui'
import { CheckboxButton } from 'ui/button/ActionButton'

import type { ChangeEventHandler, FC } from 'react'
import type { CbxState } from 'modules/common/types/interfaces'

interface Props {
  isAllSelected: boolean
  cbxState: CbxState
  onCheckAllCbxChange: ChangeEventHandler<HTMLInputElement>
  onEnableMany: VoidFunction
  onDisableMany: VoidFunction
}

const { Body } = Typography

const OnSaleActions: FC<Props> = ({
  isAllSelected,
  cbxState,
  onCheckAllCbxChange,
  onEnableMany,
  onDisableMany,
}) => {
  const selectedCount = cbxState.checkedList.length
  const isSelected = selectedCount > 0

  return (
    <Box
      display='grid'
      gridAutoFlow='column'
      justifyContent='start'
      alignItems='center'
      gridColumnGap='16px'
      py='8px'
      pr='24px'
      pl='12px'
    >
      <CheckboxButton htmlFor='on_sale_table_cbx'>
        <Checkbox
          id='on_sale_table_cbx'
          checked={cbxState.checkAll}
          onChange={onCheckAllCbxChange}
          indeterminate={cbxState.indeterminate}
        />

        <Body weight='semibold'>
          {isAllSelected
            ? `${cbxState.checkedList.length} Снять выделение`
            : 'Выделить всё'}
        </Body>
      </CheckboxButton>

      {isSelected && (
        <>
          <ActionButton icon={MiniSwitchOnIcon} onClick={onEnableMany}>
            Выставить на продажу
          </ActionButton>
          <ActionButton icon={MiniSwitchOffIcon} onClick={onDisableMany}>
            Снять с продажи
          </ActionButton>
        </>
      )}
    </Box>
  )
}

export { OnSaleActions }
