import { Typography } from 'ui'
import RadioGroup from 'ui/inputs/Radio/RadioGroup'
import { ActiveCircleIcon } from 'ui/icons'

import { RadioWrap } from 'ui/inputs/Radio/styles'

import type { RadioProps } from 'ui/inputs/Radio/types'

const { Body } = Typography

const Radio: RadioProps = ({ label, ...props }) => (
  <RadioWrap>
    <input type='radio' {...props} hidden />
    <ActiveCircleIcon className='__checked' />
    <label htmlFor={props.id}>
      <Body>{label}</Body>
    </label>
  </RadioWrap>
)

Radio.Group = RadioGroup

export { Radio }
