import { styled } from 'ui/theme'

const NotificationWrap = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: 469px;

  .__createdAt {
    color: ${props => props.theme.colors.text.secondaryBlack};
  }

  .__newStatusMarker {
    position: absolute;
    top: 20px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: red;
  }

  &.actionable:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.main.background.gray};
  }
`

export { NotificationWrap }
