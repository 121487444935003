import { useHistory } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'

import { partnerLogout } from 'modules/auth/slices/credentials'

import { Divider } from 'rc-menu'
import { useAppTheme } from 'ui/theme'
import { Dropdown, Typography, Box } from 'ui'
import { ExitIcon, ProfileIcon, ShopIcon } from 'ui/icons'

const ProfileMenu = ({ setVisible }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const theme = useAppTheme()

  return (
    <Dropdown.Menu>
      <Dropdown.MenuItem
        key='PROFILE'
        onClick={() => history.push('/profile/info')}
        data-test-id='ProfileButton'
      >
        <Box display='flex' alignItems='center'>
          <ProfileIcon color={theme.colors.icon.black} />
          <Typography ml='8px'>Профиль</Typography>
        </Box>
      </Dropdown.MenuItem>
      {/*<Dropdown.MenuItem*/}
      {/*  key='SETTINGS'*/}
      {/*  onClick={() => history.push('/profile/settings')}*/}
      {/*>*/}
      {/*  <Box display='flex' alignItems='center'>*/}
      {/*    <SettingsIcon color={theme.colors.icon.black} />*/}
      {/*    <Typography ml='8px'>Настройки</Typography>*/}
      {/*  </Box>*/}
      {/*</Dropdown.MenuItem>*/}
      {/*<Dropdown.MenuItem key='RATING' disabled>*/}
      {/*  <Box display='flex' alignItems='center'>*/}
      {/*    <StarIcon color={theme.colors.icon.black} />*/}
      {/*    <Typography ml='8px'>Рейтинг</Typography>*/}
      {/*  </Box>*/}
      {/*</Dropdown.MenuItem>*/}
      {/*<Dropdown.MenuItem key='HELP' disabled>*/}
      {/*  <Box display='flex' alignItems='center'>*/}
      {/*    <QuestionIcon color={theme.colors.icon.black} />*/}
      {/*    <Typography ml='8px'>Помощь</Typography>*/}
      {/*  </Box>*/}
      {/*</Dropdown.MenuItem>*/}
      {/*<Dropdown.MenuItem key='APPROVE' disabled>*/}
      {/*  <Box display='flex' alignItems='center'>*/}
      {/*    <TimeIcon color={theme.colors.icon.black} />*/}
      {/*    <Typography ml='8px'>График работы</Typography>*/}
      {/*  </Box>*/}
      {/*</Dropdown.MenuItem>*/}
      <Divider />
      <Dropdown.MenuItem
        key='PARTNERS'
        onClick={() => dispatch(partnerLogout())}
        data-test-id='StoreListButton'
      >
        <Box display='flex' alignItems='center'>
          <ShopIcon color={theme.colors.icon.black} />
          <Typography ml='8px'>Магазины</Typography>
        </Box>
      </Dropdown.MenuItem>
      <Dropdown.MenuItem
        key='EXIT'
        onClick={() => setVisible(true)}
        data-test-id='LogOutButton'
      >
        <Box display='flex' alignItems='center'>
          <ExitIcon color={theme.colors.main.features.afterPrice} />
          <Typography ml='8px' color='main.features.afterPrice'>
            Выйти
          </Typography>
        </Box>
      </Dropdown.MenuItem>
    </Dropdown.Menu>
  )
}

export { ProfileMenu }
