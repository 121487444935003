import { FC } from 'react'
import { Box, Typography } from 'ui'

const { Title } = Typography

interface LayoutProps {
  title: string
}

const Layout: FC<LayoutProps> = ({ title, children }) => (
  <Box px='24px' py='16px'>
    <Title as='p' mb='16px'>
      {title}
    </Title>

    {children}
  </Box>
)

export { Layout }
