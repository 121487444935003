import { FC, Fragment } from 'react'
import { useFinance } from 'modules/finance/hooks'

import {
  FinanceActions,
  // FinanceTitle,
  FinanceTable,
} from 'modules/finance/pages/Finance/components'

const Finance: FC = () => {
  useFinance()

  return (
    <Fragment>
      {/*<FinanceTitle />*/}
      <FinanceActions />
      <FinanceTable />
    </Fragment>
  )
}

export default Finance
