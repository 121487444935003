import { FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'

import { Breadcrumbs, Button, Link, Typography, Box } from 'ui'

import {
  InstallmentDetails,
  AddProductsToInstallmentTable,
  AddProductsToInstallmentFilters,
  AddProductsToInstallmentActions,
} from './components'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useTableCbx } from 'ui/table/useTableCbx'

import { useAvailableProducts } from 'modules/installments/hooks'
import { addProductsToInstallment } from 'modules/installments/slices/products'

import { ALL_OPTIONS } from 'modules/common/constants/dictionaries'

const { Title, Body } = Typography

interface Params {
  installmentId: string
}

const AddProductsToInstallment: FC = () => {
  const { installmentId } = useParams<Params>()
  const dispatch = useAppDispatch()

  const { availableProducts } = useAvailableProducts(installmentId)

  const { search, category: selectedCategory } = useAppSelector(
    state => state.installments.products.availableProductsFilters,
  )

  const {
    cbxState,
    onChangeCbx,
    onCheckAllCbxChange,
    onResetCbx,
    isAllSelected,
  } = useTableCbx(availableProducts, 'sku')

  const handleClickAddToInstallment = () => {
    const skus = cbxState.checkedList as string[]

    onResetCbx()
    dispatch(addProductsToInstallment({ installmentId, skus }))
  }

  const selectedCount = cbxState.checkedList.length
  const isSelected = selectedCount > 0

  const isSearch = search !== '' || selectedCategory !== ALL_OPTIONS

  return (
    <Fragment>
      <Box pt='24px' px='24px'>
        <Title as='p' mb='16px'>
          Добавление товаров в акцию
        </Title>

        <Breadcrumbs>
          <Link to='/installments' color='text.secondaryBlack'>
            Список акций
          </Link>

          <Body color='text.secondaryBlack'>Добавление товаров в акцию</Body>
        </Breadcrumbs>
      </Box>

      <InstallmentDetails id={installmentId} />

      <Box
        mt='24px'
        display='grid'
        gridTemplateColumns='auto auto 1fr'
        alignItems='end'
        justifyItems='end'
        gridColumnGap='16px'
        px='24px'
      >
        <AddProductsToInstallmentFilters
          id={installmentId}
          search={search}
          selectedCategory={selectedCategory}
        />

        <Button disabled={!isSelected} onClick={handleClickAddToInstallment}>
          Добавить в акцию
        </Button>
      </Box>

      <AddProductsToInstallmentActions
        isAllSelected={isAllSelected}
        cbxState={cbxState}
        onCheckAllCbxChange={onCheckAllCbxChange}
      />

      <AddProductsToInstallmentTable
        installmentId={installmentId}
        cbxState={cbxState}
        onChangeCbx={onChangeCbx}
        products={availableProducts}
        onResetCbx={onResetCbx}
        isSearch={isSearch}
      />
    </Fragment>
  )
}

export { AddProductsToInstallment }
