import { StyledButton } from 'ui/button/styles'
import { LoadingIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'
import { Box } from 'ui'

import { ButtonProps } from 'ui/button/types'

import type { FC } from 'react'

const Button: FC<ButtonProps> = ({
  children,
  icon: Icon,
  iconPlacement,
  loading,
  disabled,
  size,
  as,
  ...props
}) => {
  const theme = useAppTheme()

  return (
    <StyledButton
      as={as}
      disabled={disabled || loading}
      isLoading={loading}
      size={size}
      {...props}
    >
      {loading && (
        <Box className='__button-loading'>
          <LoadingIcon
            color={
              props.variant === 'primary'
                ? theme.colors.icon.white
                : theme.colors.icon.secondaryBlack
            }
          />
        </Box>
      )}
      <Box className='__button-wrap'>
        {Boolean(Icon) && iconPlacement === 'left' && (
          <Box
            mr={Boolean(children) ? (size === 'nano' ? '4px' : '8px') : '0'}
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Icon />
          </Box>
        )}

        {children}

        {Boolean(Icon) && iconPlacement === 'right' && (
          <Box
            ml={Boolean(children) ? (size === 'nano' ? '4px' : '8px') : '0'}
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Icon />
          </Box>
        )}
      </Box>
    </StyledButton>
  )
}

Button.defaultProps = {
  size: 'medium',
  variant: 'primary',
  iconPlacement: 'left',
}

export { Button }
