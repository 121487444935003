import { Children, cloneElement } from 'react'

import { TabsHead as TabsHeadWrap } from 'ui/tabs/styles'

import TabsHeadItem from 'ui/tabs/TabsGroup/TabsHead/TabsHeadItem'

import type { ReactElement } from 'react'
import type { TabsHeadProps } from 'ui/tabs/TabsGroup/types'

const _TabsHead = <T extends string>({
  activeTab,
  onChange,
  children,
  marginBottom = '24px',
  fullWidth = false,
}: TabsHeadProps<T>) => (
  <TabsHeadWrap marginBottom={marginBottom}>
    {children &&
      Children.map<ReactElement, any>(children, (child: ReactElement) =>
        cloneElement(child, {
          active: child.props.tabValue === activeTab,
          onClick: () => onChange(child.props.tabValue),
          fullWidth,
        }),
      )}
  </TabsHeadWrap>
)

const TabsHead = _TabsHead as typeof _TabsHead & { Item: typeof TabsHeadItem }

TabsHead.Item = TabsHeadItem

export { TabsHead }
