import { combineReducers } from 'redux'

import products from 'modules/products/slices/products'
import unrelatedProducts from 'modules/products/slices/unrelatedProducts'
import productsFeed from 'modules/products/slices/productsFeed'

export default combineReducers({
  productsFeed,
  unrelatedProducts,
  products,
})
