import { useAppDispatch, useAppSelector } from 'store/hooks'

import { formatRFC3339 } from 'date-fns'

import {
  fetchOrders,
  changeSorting,
  changeSearch,
  changeStatus,
  changePage,
  changeDate,
} from 'modules/orders/slices/orders'

import { tableFilters } from 'modules/orders/pages/Orders/filters'

import type { OrderStatusType } from 'modules/orders/types'
import type { DateRange } from 'ui/datePicker/types'

export const useOrderActions = () => {
  const dispatch = useAppDispatch()
  const filter = useAppSelector(state => state.orders.orders.filter)

  const handleChangeStatus = (value: OrderStatusType) => {
    dispatch(changePage(1))
    dispatch(changeStatus(value))
    dispatch(changeSearch(''))
    dispatch(changeSorting(''))
  }

  const handleSearch = (value: string) => {
    dispatch(changeSearch(value))
  }

  const handleDateChange = (date: DateRange) => {
    dispatch(
      changeDate({
        from: date.from && formatRFC3339(new Date(date.from.setHours(0))),
        to: date.to && formatRFC3339(new Date(date.to.setHours(0))),
      }),
    )

    if (date.from && date.to) {
      dispatch(fetchOrders())
    } else if (!date.from && !date.to) {
      dispatch(fetchOrders())
    }
  }

  return {
    search: {
      value: filter.search,
      onSearch: handleSearch,
    },
    status: {
      value:
        filter.status === ''
          ? tableFilters.map(filter => filter.value)
          : filter.status,
      onChange: handleChangeStatus,
    },
    datepicker: {
      onChange: handleDateChange,
      date: {
        from: filter.dateRange.from && new Date(filter.dateRange.from),
        to: filter.dateRange.to && new Date(filter.dateRange.to),
      },
    },
  }
}
