import { createElement, Fragment } from 'react'

import { ConditionProps } from 'ui/condition/types'

import type { FC } from 'react'

const Condition: FC<ConditionProps> = ({ children, match }) => {
  if (match) {
    return createElement(Fragment, {}, children)
  }

  return null
}

export { Condition }
