import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { instance, AppThunk } from 'store/configureStore'
import { thunkErrorHandler } from 'utils/errorHandler'

import { CitiesState } from '../types/interfaces'
import { City } from '../types'
import { AxiosResponse } from 'axios'

/**
 * Initial State
 */

const initialState: CitiesState = {
  cities: [],
  CITIES: {},
  citiesLoading: false,
  citiesError: null,
}

/**
 * Async Actions
 */

export const fetchCities: AppThunk<{
  cities: City[]
  CITIES: CitiesState['CITIES']
}> = createAsyncThunk('config/cities/fetch', (_, thunkApi) =>
  instance(thunkApi)
    .get('/config-discovery/api/v1/cities')
    .then((res: AxiosResponse<{ cities: City[] }>) => {
      return {
        cities: res.data.cities,
        CITIES: res.data.cities.reduce(
          (map, city) => ({ ...map, [city.id]: city }),
          {},
        ),
      }
    })
    .catch(thunkErrorHandler(thunkApi)),
)

/**
 * Slice
 */

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchCities.pending, state => {
        state.citiesLoading = true
        state.citiesError = null
      })
      .addCase(fetchCities.fulfilled, (state, { payload }) => {
        state.citiesLoading = false
        state.citiesError = null
        state.cities = payload.cities
        state.CITIES = payload.CITIES
      })
      .addCase(fetchCities.rejected, (state, { payload }) => {
        state.citiesLoading = false
        state.citiesError = payload.error
      }),
})

export default citiesSlice.reducer
