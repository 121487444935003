import { useAppDispatch, useAppSelector } from 'store/hooks'

import { formatRFC3339 } from 'date-fns'

import {
  changeStatusFilter,
  changeDateFilter,
  changeSearch,
  fetchTransactions,
} from 'modules/finance/slices/finance'

import { FinanceStatusType } from 'modules/finance/types'
import { DateRange } from 'ui/datePicker/types'

export const useFinanceActions = () => {
  const dispatch = useAppDispatch()
  const { dateRange, search, status } = useAppSelector(
    state => state.finance.finance.filters,
  )

  const handleChange = (date: DateRange) => {
    dispatch(
      changeDateFilter({
        from: date.from && formatRFC3339(date.from),
        to: date.to && formatRFC3339(date.to),
      }),
    )

    if (date.from && date.to) {
      dispatch(fetchTransactions())
    } else if (!date.from && !date.to) {
      dispatch(fetchTransactions())
    }
  }

  const handleChangeFilter = (value: FinanceStatusType) => {
    dispatch(changeStatusFilter(value))
  }

  const handleSearch = (value: string) => {
    dispatch(changeSearch(value))
  }

  return {
    search: {
      onSearch: handleSearch,
      value: search,
    },
    datepicker: {
      onChange: handleChange,
      date: {
        from: dateRange.from && new Date(dateRange.from),
        to: dateRange.to && new Date(dateRange.to),
      },
    },
    status: {
      onChange: handleChangeFilter,
      value: status,
    },
  }
}
