import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Menu: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M2 16H18C18.55 16 19 15.55 19 15C19 14.45 18.55 14 18 14H2C1.45 14 1 14.45 1 15C1 15.55 1.45 16 2 16ZM2 11H18C18.55 11 19 10.55 19 10C19 9.45 18.55 9 18 9H2C1.45 9 1 9.45 1 10C1 10.55 1.45 11 2 11ZM1 5C1 5.55 1.45 6 2 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H2C1.45 4 1 4.45 1 5Z'
      fill={color}
    />
  </Icon>
)

Menu.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Menu }
