import type { ReactNode, InputHTMLAttributes } from 'react'
import type { SerializedStyles } from '@emotion/react'

export type SizesType = 'default' | 'large' | 'nano'

type InputDefaultProps = InputHTMLAttributes<HTMLInputElement>

type InputProps = {
  size?: SizesType
} & Omit<InputDefaultProps, 'size'>

export type SizesMap = { [name in SizesType]: SerializedStyles }

export interface FormFieldProps extends InputProps {
  label?: string
  subText?: string
  error?: boolean
  tooltip?: {
    overlay: ReactNode
    visible: boolean
    placement?: string
  }
  onClear?: VoidFunction
  maskType?: 'PHONE' | 'FULL_PHONE' | 'CODE' | 'LATITUDE_LONGITUDE'
  allowClear?: boolean
  isPaginate?: boolean
  width?: string
}

export interface FormFieldWrapProps {
  size: SizesType
  error: boolean
  width?: string
  height?: string
  allowClear?: boolean
  value: string | ReadonlyArray<string> | number | undefined
  isPaginate?: boolean
}

export enum FormFieldMaskType {
  PHONE = '+7 (###) ###-##-##',
  FULL_PHONE = '+# (###) ###-##-##',
  CODE = '# # # #',
  LATITUDE_LONGITUDE = '##.######',
}
