import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Right: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6247 4.21917C17.056 4.56418 17.1259 5.19347 16.7809 5.62473L8.78087 15.6247C8.60318 15.8468 8.33931 15.9828 8.0553 15.9985C7.77129 16.0142 7.49403 15.9083 7.29289 15.7071L3.29289 11.7071C2.90237 11.3166 2.90237 10.6835 3.29289 10.2929C3.68342 9.90241 4.31658 9.90241 4.70711 10.2929L7.91708 13.5029L15.2191 4.37534C15.5641 3.94408 16.1934 3.87416 16.6247 4.21917Z'
      fill={color}
    />
  </Icon>
)

Right.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Right }
