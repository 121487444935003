import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const VisibleOff: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.29289 9.29289C2.68342 8.90237 3.31658 8.90237 3.70711 9.29289L4.34315 9.92893C4.64506 10.2308 4.96457 10.5032 5.29819 10.7462C6.05055 11.2942 6.87677 11.6945 7.73754 11.9473C8.47456 12.1637 9.2371 12.2721 10 12.2721C10.7629 12.2721 11.5254 12.1637 12.2625 11.9473C13.1232 11.6945 13.9494 11.2942 14.7018 10.7462C15.0354 10.5032 15.3549 10.2308 15.6569 9.92893L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L17.0711 11.3431C17.0531 11.3611 17.035 11.379 17.0169 11.3969C17.4784 11.6788 17.6331 12.279 17.3612 12.75C17.0851 13.2283 16.4735 13.3921 15.9952 13.116L15.333 12.7337C14.9111 13 14.4737 13.2318 14.0247 13.429L14.4595 14.1527C14.744 14.6261 14.5908 15.2404 14.1174 15.5249C13.644 15.8093 13.0297 15.6562 12.7452 15.1828L12.0687 14.0568C11.7151 14.1313 11.3583 14.1864 11 14.2222V15.5C11 16.0523 10.5523 16.5 10 16.5C9.44772 16.5 9 16.0523 9 15.5V14.2222C8.64171 14.1864 8.28493 14.1313 7.93133 14.0568L7.25478 15.1828C6.97033 15.6562 6.35597 15.8093 5.88257 15.5249C5.40917 15.2404 5.25601 14.6261 5.54046 14.1527L5.97528 13.429C5.52634 13.2318 5.08889 13 4.66703 12.7337L4.00481 13.116C3.52652 13.3921 2.91493 13.2283 2.63878 12.75C2.36687 12.279 2.5216 11.6788 2.98306 11.3969C2.96497 11.379 2.94692 11.3611 2.92893 11.3431L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289Z'
      fill={color}
    />
  </Icon>
)

VisibleOff.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { VisibleOff }
