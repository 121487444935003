import { FC } from 'react'
import { Icon } from 'ui/icons'

import type { IconProps } from 'ui/icons'

const Finance: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 6C1 4.34315 2.34315 3 4 3H16C17.6569 3 19 4.34315 19 6V14C19 15.6569 17.6569 17 16 17H4C2.34315 17 1 15.6569 1 14V6ZM4 5C3.44772 5 3 5.44772 3 6V14C3 14.5523 3.44772 15 4 15H16C16.5523 15 17 14.5523 17 14V6C17 5.44772 16.5523 5 16 5H4ZM7 7C7.55228 7 8 7.44772 8 8V12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12V8C6 7.44772 6.44772 7 7 7ZM10 7C9.44771 7 9 7.44772 9 8V12C9 12.5523 9.44771 13 10 13H12.5C13.0523 13 13.5 12.5523 13.5 12V8C13.5 7.44772 13.0523 7 12.5 7H10ZM11 11V9H11.5V11H11Z'
      fill={color}
    />
  </Icon>
)

Finance.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export { Finance }
