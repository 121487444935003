import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import {
  changeOrderProducts,
  orderDecision,
} from 'modules/orders/slices/orders'

export const useAutoRejectModal = () => {
  const dispatch = useAppDispatch()
  const details = useAppSelector(state => state.orders.orders.details)

  const [productsCache, setProductsCache] = useState([])
  const [autoRejectModalVisible, setAutoRejectModalVisible] = useState(false)

  useEffect(() => {
    if (details.products.length !== 0) {
      if (details.products.every(product => product.removed)) {
        setAutoRejectModalVisible(true)
      }

      if (!details.products.every(product => product.removed)) {
        setProductsCache(details.products)
      }
    }
  }, [details.products])

  const onCloseRejectModal = () => {
    dispatch(changeOrderProducts(productsCache))
    setAutoRejectModalVisible(false)
  }

  const rejectOrder = () => {
    dispatch(
      orderDecision({
        solution: 'reject',
        refresh: 'both',
        closeCb: () => {
          setProductsCache([])
          setAutoRejectModalVisible(false)
        },
      }),
    )
  }

  return {
    onOk: rejectOrder,
    onClose: onCloseRejectModal,
    visible: autoRejectModalVisible,
  }
}
