import { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { validatePassword } from 'utils/passValidation'
import { matchNumberMask } from 'utils/matchInputMask'

import { profileRoutes } from 'modules/profile/routes'
import {
  recoveryPhoneOTP,
  recoveryPhone,
} from 'modules/profile/slices/settings'

import {
  Typography,
  FormField,
  Tooltip,
  NavTabs,
  Button,
  Modal,
  Radio,
  Box,
} from 'ui'
import { styled } from 'ui/theme'

import type { FC } from 'react'

const { Subtitle, Caption, Body } = Typography

const SoonBox = styled(Box)`
  background: #319cf3;
  border-radius: 2px;
  text-align: center;
  height: 20px;
  width: 48px;
`

let counter

const Settings: FC = () => {
  const settings = useAppSelector(state => state.profile.settings)
  const dispatch = useAppDispatch()

  const [radioValue, setRadioValue] = useState('ru')
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [visibleOtp, setVisibleOtp] = useState(false)
  const [visibleMainPassTooltip, setVisibleMainPassTooltip] = useState(false)
  const [visibleRepPassTooltip, setVisibleRepPassTooltip] = useState(false)
  const [visibleTooltipOTP, setVisibleTooltipOTP] = useState(false)
  const [values, setValues] = useState({
    password: '',
    repeatedPassword: '',
  })
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [time, setTime] = useState(60)

  const [error, setError] = useState(false)
  const [matchError, setMatchError] = useState(false)
  const [passMessage, setPassMessage] = useState(null)

  useEffect(() => {
    if (visiblePassword) {
      onClearValues()
    }
  }, [visiblePassword])

  useEffect(() => {
    if (visibleOtp) {
      if (time > 0) {
        counter = setTimeout(() => {
          setTime(time - 1)
        }, 1000)
      }
    }

    return () => {
      clearTimeout(counter)
    }
  }, [visibleOtp, time])

  useEffect(() => {
    if (!settings.loading) {
      setPhone(settings.maskedPhone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.loading])

  useEffect(() => {
    if (settings.OTPError) {
      setCodeError(true)
    }
  }, [settings])

  const onClearValues = () => {
    setVisibleMainPassTooltip(false)
    setVisibleRepPassTooltip(false)
    setVisibleTooltipOTP(false)
    setValues({
      password: '',
      repeatedPassword: '',
    })
    setPhone('')
    setCode('')
    setCodeError(false)
    setTime(60)
    setError(false)
    setMatchError(false)
    setPassMessage(null)
  }

  const showModal = () => {
    setVisiblePassword(true)
  }

  const onValidatePassword = () => {
    if (!error && !matchError) {
      dispatch(recoveryPhone())
      setVisibleOtp(true)
      setVisiblePassword(false)
    }
  }

  const handleClosePasswordModal = () => {
    setVisiblePassword(false)
    setValues({
      password: '',
      repeatedPassword: '',
    })
    setCode('')
  }

  const closeModalOtp = () => {
    setVisibleOtp(false)
    setValues({
      password: '',
      repeatedPassword: '',
    })
    setCode('')
  }

  const matchedCode = matchNumberMask(code)

  const handleClick = () => {
    if (matchedCode.length < 4) {
      setVisibleTooltipOTP(true)
      setPassMessage(
        <Box>
          Погодите–ка, сперва введите <br /> код из SMS
        </Box>,
      )
    } else {
      dispatch(
        recoveryPhoneOTP({
          otp: matchedCode,
          password: values.password,
          onCloseCb: closeModalOtp,
        }),
      )
    }
  }

  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const matchPasswords = () => {
    if (values.password.length !== 0 && values.repeatedPassword.length !== 0) {
      if (values.password !== values.repeatedPassword) {
        setMatchError(true)
        setVisibleRepPassTooltip(true)
      }
    }
  }

  return (
    <Fragment>
      <Box pt='24px' px='24px' mb='24px'>
        <Typography.Title as='p' mb='16px'>
          Профиль
        </Typography.Title>

        <NavTabs routes={profileRoutes} />
      </Box>
      <Box px='24px' pb='24px'>
        <Box mb='32px'>
          <Subtitle as='p' mb='32px'>
            Язык
          </Subtitle>
          <Box mb='28px'>
            <Radio
              id='settings:ru_lang'
              name='change_lang'
              label='Русский'
              defaultChecked={radioValue === 'ru'}
              onChange={() => setRadioValue('ru')}
            />
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            width='400px'
            mb='28px'
          >
            <Radio
              id='settings:kk_lang'
              name='change_lang'
              label='Казахский'
              disabled
              defaultChecked={radioValue === 'kk'}
              onChange={() => setRadioValue('kk')}
            />
            <SoonBox>
              <Caption weight='semibold' color='#FFFFFF'>
                Скоро
              </Caption>
            </SoonBox>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            width='400px'
            mb='28px'
          >
            <Radio
              id='settings:en_lang'
              name='change_lang'
              label='Английский'
              disabled
              defaultChecked={radioValue === 'en'}
              onChange={() => setRadioValue('en')}
            />
            <SoonBox>
              <Caption weight='semibold' color='#FFFFFF'>
                Скоро
              </Caption>
            </SoonBox>
          </Box>
        </Box>

        <Box>
          <Subtitle as='p' mt='24px' mb='16px'>
            Пароль
          </Subtitle>
          <Button onClick={showModal}>Изменить пароль</Button>
        </Box>

        <Modal
          width={356}
          okText='ПРОДОЛЖИТЬ'
          visible={visiblePassword}
          onClose={handleClosePasswordModal}
          onOk={onValidatePassword}
          title='Изменение пароля'
          onCancel={handleClosePasswordModal}
        >
          <Body as='p' mb='16px'>
            Придумайте и подтвердите пароль
          </Body>
          <Box mb='16px'>
            <Tooltip
              placement='right'
              visible={visibleMainPassTooltip}
              overlay={passMessage}
            >
              <FormField
                placeholder='Придумайте пароль'
                label='Придумайте пароль'
                type='password'
                name='password'
                size='large'
                onBlur={() => {
                  setVisibleMainPassTooltip(false)
                  matchPasswords()
                }}
                onFocus={() => {
                  if (error) {
                    setVisibleMainPassTooltip(true)
                  }
                }}
                value={values.password}
                onChange={event => {
                  if (event.target.value === values.repeatedPassword) {
                    setMatchError(false)
                    setVisibleRepPassTooltip(false)
                  }

                  onChange(event)

                  validatePassword(
                    event.target.value,
                    setError,
                    setPassMessage,
                    setVisibleMainPassTooltip,
                  )
                }}
                error={error || matchError}
              />
            </Tooltip>
          </Box>

          <Tooltip
            visible={visibleRepPassTooltip}
            overlay='Пароли не совпадают!'
          >
            <FormField
              placeholder='Подтвердите пароль'
              label='Подтвердите пароль'
              name='repeatedPassword'
              type='password'
              size='large'
              value={values.repeatedPassword}
              error={matchError}
              onBlur={matchPasswords}
              onChange={event => {
                if (event.target.value === values.password) {
                  setMatchError(false)
                  setVisibleRepPassTooltip(false)
                }

                onChange(event)
              }}
            />
          </Tooltip>
        </Modal>

        <Modal
          width={356}
          okText='ПРОДОЛЖИТЬ'
          onClose={closeModalOtp}
          visible={visibleOtp}
          fullWidthButtons
          onOk={handleClick}
        >
          <Subtitle>Введите одноразовый код</Subtitle>
          <Body as='p' color='text.secondaryBlack' pt='8px' pb='16px'>
            Отправили SMS с кодом на
            <br />
            {`+ ${phone}`}
          </Body>

          <Box>
            <Tooltip
              placement='right'
              visible={visibleTooltipOTP}
              overlay={passMessage}
            >
              <FormField
                type='text'
                size='large'
                maskType='CODE'
                label='SMS-код'
                placeholder='Введите код из SMS'
                value={code}
                error={codeError}
                onChange={e => setCode(e.target.value)}
                onClear={() => {
                  setCodeError(false)
                  setCode('')
                }}
              />
            </Tooltip>

            <Button
              mt='4px'
              size='nano'
              type='button'
              variant='secondary'
              disabled={time !== 0}
              fullWidth
              onClick={() => {
                dispatch(recoveryPhone())
                setTime(60)
              }}
            >
              {time === 0
                ? 'Выслать код повторно'
                : `Выслать код повторно через ${time} сек.`}
            </Button>
          </Box>
        </Modal>
      </Box>
    </Fragment>
  )
}

export { Settings }
