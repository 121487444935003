import { useDecisions } from 'modules/orders/hooks'

import { PackagesCountModal, DecisionsActions } from './components'

import { Typography, Box } from 'ui'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'
import type { FC } from 'react'

interface DecisionsProps {
  onResetCbx: VoidFunction
  showRejectModal: VoidFunction
  rejectModalVisible: boolean
}

const { Subtitle } = Typography

const Decisions: FC<DecisionsProps> = ({
  onResetCbx,
  showRejectModal,
  rejectModalVisible,
}) => {
  const {
    tip,
    conditions,
    countButtonProps,
    acceptButtonProps,
    countModalVisible,
    showTip,
    closeTip,
    closeCountModal,
  } = useDecisions(rejectModalVisible, onResetCbx)

  return (
    <Box
      mb='16px'
      px='24px'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <Subtitle>Состав заказа</Subtitle>

      <DecisionsActions
        tip={tip}
        conditions={conditions}
        countButtonProps={countButtonProps}
        acceptButtonProps={acceptButtonProps}
        showRejectModal={showRejectModal}
        closeTip={closeTip}
      />

      <PackagesCountModal
        showTip={() => showTip(ORDERS_FILTER_STATUS.ASSEMBLED)}
        visible={countModalVisible}
        onClose={closeCountModal}
      />
    </Box>
  )
}

export { Decisions }
