import { Checkbox, Box, Typography } from 'ui'
import { CheckboxButton } from 'ui/button/ActionButton'

import type { ChangeEventHandler, FC } from 'react'
import type { CbxState } from 'modules/common/types/interfaces'

interface Props {
  isAllSelected: boolean
  cbxState: CbxState
  onCheckAllCbxChange: ChangeEventHandler<HTMLInputElement>
}

const AddProductsToInstallmentActions: FC<Props> = ({
  isAllSelected,
  cbxState,
  onCheckAllCbxChange,
}) => {
  const selectedCount = cbxState.checkedList.length
  const isSelected = selectedCount > 0

  return (
    <Box pl='12px' pr='24px' mt='24px'>
      <CheckboxButton htmlFor='table-checkbox'>
        <Checkbox
          name='tester'
          value='tester'
          id='table-checkbox'
          checked={cbxState.checkAll}
          onChange={onCheckAllCbxChange}
          indeterminate={cbxState.indeterminate}
        />

        <Typography.Body weight='semibold'>
          {isSelected ? selectedCount : ''}{' '}
          {isAllSelected ? 'Снять выделение' : 'Выделить всё'}
        </Typography.Body>
      </CheckboxButton>
    </Box>
  )
}

export { AddProductsToInstallmentActions }
