import { styled } from 'ui/theme'
import { compose, space, position } from 'styled-system'

import type { TableOverlayProps } from './TableOverlay'
import type { SpaceProps, PositionProps } from 'styled-system'

const TableOverlayWrap = styled.div<
  TableOverlayProps & SpaceProps & PositionProps
>`
  position: absolute;
  box-shadow: 0 8px 14px rgba(16, 20, 45, 0.05),
    0 3.87387px 9.86002px rgba(16, 20, 45, 0.08);
  border-radius: ${props => props.theme.radii.xs};
  background: ${props => props.theme.colors.main.background.white};
  display: flex;
  align-items: center;
  height: 52px;
  z-index: 99;

  ${props =>
    props.placement === 'center' &&
    `
    transform: translateX(-50%);
    left: 50%;
  `}

  ${props =>
    props.placement === 'right' &&
    `
    right: 72px;
  `}

  ${props =>
    props.placement === 'left' &&
    `
    left: 0;
  `}
  ${compose(space, position)}
`

const TableOverlayButton = styled.button`
  min-width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background: ${props => props.theme.colors.main.background.gray};
  }
`

export { TableOverlayButton, TableOverlayWrap }
