import axios from 'axios'
import AxiosInterceptors from 'store/configureStore/middleware/api/interceptors'

import type { AxiosInstance } from 'axios'
import type { AppDispatch, RootState } from 'store/configureStore'

import { logout, auth } from 'modules/auth/slices/credentials'

import { env } from 'settings/env'

interface IInstanceProps {
  dispatch: AppDispatch
  getState: () => RootState
}

const instance = ({ dispatch, getState }: IInstanceProps): AxiosInstance => {
  const instance: AxiosInstance = axios.create({
    baseURL: env.API_BASE_URL,
  })

  const axiosInterceptors = new AxiosInterceptors(instance)

  instance.interceptors.request.use(
    axiosInterceptors.onRequestSuccess(getState),
    axiosInterceptors.onRequestError(),
  )

  instance.interceptors.response.use(
    axiosInterceptors.onResponseSuccess(),
    axiosInterceptors.onResponseError(
      getState,
      token => dispatch(auth(token)),
      () => dispatch(logout()),
    ),
  )

  return instance
}

export { instance }
