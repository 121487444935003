import { Finance } from 'modules/finance/pages'

import { getDashedStr } from 'utils/getDashedStr'

import { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  FINANCE = 'FINANCE',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/finance',
    exact: true,
    key: getDashedStr(key, KEYS.FINANCE),
    component: Finance,
  },
]
