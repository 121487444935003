import { Condition, Typography } from 'ui'

import { FormFieldWrap } from 'ui/inputs/FormField/styles'

import type { FC } from 'react'
import type { TextareaProps } from 'ui/inputs/Textarea/types'

const { Caption } = Typography

const Textarea: FC<TextareaProps> = ({ height, label, ...props }) => (
  <FormFieldWrap size='large' error={false} value={props.value} height={height}>
    <Condition match={Boolean(label)}>
      <Caption as='p' mb='4px' weight='semibold' color='projects.primaryMarket'>
        {label}
      </Caption>
    </Condition>
    <textarea className='__form-field-input __form-field-textarea' {...props} />
  </FormFieldWrap>
)

export { Textarea }
