import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { instance, AppThunk } from 'store/configureStore'

import { thunkErrorHandler } from 'utils/errorHandler'

import { ProfileState } from 'modules/profile/types/interfaces'
import { AxiosResponse } from 'axios'
import { DocumentType, ProfileType } from 'modules/profile/types'

/**
 * Actions
 */

export const fetchProfile: AppThunk<ProfileType> = createAsyncThunk(
  'profile/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get('/merchant-profile/api/v1/profile')
      .then((res: AxiosResponse<ProfileType>) => res.data)
      .catch(thunkErrorHandler(thunkApi)),
)

export const fetchDocuments: AppThunk<DocumentType[]> = createAsyncThunk(
  'profile/documents/fetch',
  (_, thunkApi) =>
    instance(thunkApi)
      .get('/merchant-profile/api/v1/profile/documents')
      .then((res: AxiosResponse<DocumentType[]>) => res.data)
      .catch(thunkErrorHandler(thunkApi)),
)

/**
 * Reducer
 */

const initialProfile: ProfileType = {
  category: '',
  company_info: {
    address: {
      city: '',
      country: '',
      index: '',
      street: '',
    },
    company_name: '',
    email: '',
    first_name_ceo: '',
    holidays: [],
    last_name_ceo: '',
    ownership: '',
    patronymic_ceo: '',
    payment_requisites: {
      account_bank_number: '',
      bank_name: '',
      bik: '',
      bin: '',
      kbe: '',
    },
    phone_number: '',
    schedule: [],
    site_url: '',
  },
  contact_info: {
    email: '',
    first_name: '',
    last_name: '',
    patronymic: '',
    phone: '',
  },
  created: '',
  enabled: true,
  feedbacks_count: 0,
  id: '',
  is_accepted_user_agreement: true,
  logo_url: '',
  pickup_points: [],
  rating: 0,
  status: '',
  store_name: '',
  updated: '',
}

const initialState: ProfileState = {
  profile: initialProfile,
  loadingOfProfile: false,
  errorOfProfile: null,

  documents: [],
  loadingOfDocuments: false,
  errorOfDocuments: null,
}

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProfile.pending, state => {
        state.loadingOfProfile = true
        state.errorOfProfile = null
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loadingOfProfile = false
        state.errorOfProfile = null
        state.profile = action.payload
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loadingOfProfile = false
        state.errorOfProfile = action.payload.error
      })
      .addCase(fetchDocuments.pending, state => {
        state.loadingOfDocuments = true
        state.errorOfDocuments = null
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.loadingOfDocuments = false
        state.errorOfDocuments = null
        state.documents = action.payload
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.loadingOfDocuments = false
        state.errorOfDocuments = action.payload.error
      })
  },
})

export default profile.reducer
