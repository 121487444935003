import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { instance } from 'store/configureStore'
import { thunkErrorHandler } from 'utils/errorHandler'

import type { AppThunk } from 'store/configureStore'
import type {
  Instruction,
  FaqState,
  FetchInstructionPageOptions,
} from 'modules/faq/types'

/**
 * Initial State
 */

const initialState: FaqState = {
  instructions: null,
  instructionsLoading: false,
  instructionsError: null,

  instructionPage: null,
  instructionPageLoading: false,
  instructionPageError: null,
}

/**
 * Async Actions
 */

export const fetchInstructions: AppThunk<Instruction> = createAsyncThunk(
  'faq/instructions/fetch',
  (_, thunkApi) => {
    const params = {
      root: true,
      depth: 3,
      platform: 'web',
    }

    return instance(thunkApi)
      .get(`/config-discovery/api/v1/faqs/merchant/pages`, {
        params,
      })
      .then(res => res.data)
      .catch(thunkErrorHandler(thunkApi))
  },
)

export const fetchInstructionPage: AppThunk<
  Instruction,
  FetchInstructionPageOptions
> = createAsyncThunk('faq/instructionPage/fetch', (options, thunkApi) => {
  const { slug } = options

  return instance(thunkApi)
    .get(`/config-discovery/api/v1/faqs/merchant/pages/${slug}`)
    .then(res => res.data)
    .catch(thunkErrorHandler(thunkApi))
})

const instructionsSlice = createSlice({
  name: 'faq/instructions',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchInstructions.pending, state => {
        state.instructionsLoading = true
        state.instructionsError = null
      })
      .addCase(fetchInstructions.fulfilled, (state, { payload }) => {
        state.instructions = payload
        state.instructionsLoading = false
        state.instructionsError = null
      })
      .addCase(fetchInstructions.rejected, (state, { payload }) => {
        state.instructionsLoading = false
        state.instructionsError = payload.error
      })

      .addCase(fetchInstructionPage.pending, state => {
        state.instructionPageLoading = true
        state.instructionPageError = null
      })
      .addCase(fetchInstructionPage.fulfilled, (state, { payload }) => {
        state.instructionPage = payload
        state.instructionPageLoading = false
        state.instructionPageError = null
      })
      .addCase(fetchInstructionPage.rejected, (state, { payload }) => {
        state.instructionPageLoading = false
        state.instructionPageError = payload.error
      }),
})

export default instructionsSlice.reducer
