import { styled } from 'ui/theme'

export const RadioWrap = styled.div`
  position: relative;

  .__checked {
    position: absolute;
    left: -2px;
    top: 3px;
    display: none;
  }

  input:checked {
    ~ label::before {
      border-color: transparent;
    }

    ~ .__checked {
      display: block;
    }
  }

  label {
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      border: 2px solid ${props => props.theme.colors.icon.secondaryBlack};
      border-radius: ${props => props.theme.radii.r};
      box-sizing: border-box;
      margin-right: 8px;
    }

    &:hover {
      &::before {
        border-color: ${props => props.theme.colors.icon.hover};
      }
    }
  }
`
